<template>
  <div class="tableListBox">
    <div class="listBox">
      <ul v-for="(item, index) of productList"
        :class="{ 'offline-sale-item': item.offlineSaleFlag && item.offlineSaleFlag == '1' }"
        :key="'productLi' + index">
        <li class="name">
          <img v-if="item.picUrl && item.picUrl !== ''" class="imgIcon" src="@/assets/images/product/list/proImg.png"
            alt="产品图片" />
          <img v-else class="imgIcon" src="@/assets/images/product/list/noProImg.png" alt="产品图片" />
          <router-link :to="{
            path: '/productDetail',
            query: { productId: item.productId },
          }" :title="item.name" target="_blank">
            {{ item.name }}
          </router-link>

          <div v-if="item.picUrl && item.picUrl !== ''" class="productImg">
            <span class="listImgBox">
              <!-- 查看产品标签遮罩图 -->
              <img v-if="
                item.productLabelList && item.productLabelList.length > 0 && prolabelMaskImg(item.productLabelList) !== ''
              " :src="prolabelMaskImg(item.productLabelList)" class="maskImg" alt="产品遮罩图" />
              <img :src="productImg(item)" />
            </span>
          </div>
        </li>
        <li :title="item.format" class="format">
          {{ item.format ? item.format : "暂无信息" }}
        </li>
        <li class="manufacturer">{{ testReturnData(item.manufacturer) }}</li>
        <li v-if="false" class="validity">
          <div v-if="
            item.inventoryList &&
            item.inventoryList.length > 0 &&
            item.inventoryList[0].endDate &&
            item.inventoryList[0].endDate !== ''
          ">
            {{
              item.inventoryList[0].endDate &&
                item.inventoryList[0].endDate !== ""
                ? item.inventoryList[0].endDate
                : ""
            }}
          </div>
          <div v-else>
            {{ loginState? "----/--/--": "会员可见" }}
          </div>
        </li>
        <li class="inventory">
          {{ showInventoryText(item.inventoryNum, item) }}
        </li>
        <li class="discountPrice">
          <div v-if="item.inventoryList && item.inventoryList.length > 0" class="fhsj" :style="
            item.inventoryList[0].deliveryTime === '24'
              ? 'color:#009944'
              : 'color:#0f4c81'
          ">
            <span class="fhsjSpan">{{
              item.inventoryList[0].deliveryTime
                ? item.inventoryList[0].deliveryTime
                : "24H"
            }}</span>
          </div>
        </li>
        <li class="price">
          {{ item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleShowPrice !== '1' ? '请联系开票员' : showPrice(item) }}
        </li>
        <li class="amount">
          <div v-if="item.inventoryList && item.inventoryList.length > 0 && item.productNotputSaleCreateOrder !== '0'" class="amoutBox">
            <span @click="lessNum(item.inventoryList[0], item)">-</span>
            <input :class="{
              shoppingHas:
                item.inventoryList[0].shoppingCartAmount &&
                item.inventoryList[0].shoppingCartAmount > 0,
            }" :value="
  item.inventoryList[0].addCartQuantity" @focus="selectShopAmount($event)" oninput="value=value.replace(/[^0-9.]/g,'')"
              @keyup.enter="jionShopCart(item.inventoryList[0], item, $event)"
              @change="changeNum(item.inventoryList[0], item, $event)" type="text" />
            <span @click="addNum(item.inventoryList[0], item)">+</span>
          </div>
          <div v-else-if="item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleCreateOrder == '0'" class="amoutBox">
            请联系开票员订货
          </div>
          <div v-else class="amoutBox">
            暂无库存
          </div>
        </li>
        <li class="operation actioveBox" style="width: 130px !important">
          <a v-if="
            item.inventoryList &&
            item.inventoryList.length > 0 &&
            item.inventoryList[0].inventory > 0
          " href="javascript:;">
            <img @click="jionShopCart(item.inventoryList[0], item, $event)"
              src="@/assets/images/product/list/jionShop.png" alt="" />
          </a>
          <a v-if="item.isCollect == 1" title="取消收藏" href="javascript:;">
            <img @click="cancelCollcet(item)" src="@/assets/images/center/cancelIcon.png" alt="" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ProductMixin } from "@/mixins/ProductMixin";
import { deleteAction } from "../../api/manage";
export default {
  name: "TableProList",
  props: ["productList"],
  mixins: [ProductMixin],
  methods: {
    cancelCollcet(item) {
      let _this = this;
      let data = {
        productId: item.productId,
      };
      let url =
        "/customerCollectProduct/customerCollectProduct/cancelProductCollect";
      deleteAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          _this.$message({
            message: "已取消收藏该产品",
            offset: 400,
            duration: 1000,
            type: "info",
          });
          item.isCollect = 0;
          _this.$emit("reloadProduct");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/product/list/list";

.name,
.manufacturer {
  width: 170px !important;
}

.operation {
  width: 135px;
}

.actioveBox {
  width: 130px !important;

  a {
    margin: 0 10px !important;
  }
}
</style>