import { } from "../store/mutation-types";
import {
  deleteAction,
  postAction
} from "../api/manage";
import {
  getUserInfo
} from "../assets/js/common/common";

import { mapGetters } from 'vuex';

export const ProductMixin = {
  data() {
    return {
      pageNo: 1,
      pageSize: 20,
      loginState: false,
      selectedProductIdVer: '',
      productStep: 1,
    }
  },
  created() {
    let userInfo = getUserInfo()
    if (userInfo) {
      this.loginState = true
    } else {
      this.loginState = false
    }
  },
  computed: {
    // 获取当前公司配置
    ...mapGetters(['companyConfigData'])
  },
  methods: {
    // 跳转开票员开单页面
    linkToSaleForm(productId) {
      this.$router.push({ path: "/linkToSale", query: { productId: productId } })
    },
    // 计算产品库存展示文字;
    // 显示方式液体大于10件显示充裕  其他是大于2件显示充裕
    // 计算方式: 件数 = 总库存 / 大包装数量
    showInventoryText(inventoryNum, productData) {
      // 获取当前公司配置
      let companyConfigData = this.companyConfigData;
      // 获取当前选中库存的数量
      let inventory = parseInt(inventoryNum);
      // 当前产品的大包装数量
      let bigPkgSize = productData.bigPkgSize;
      // 获取当前的产品类型为液体
      let liquidFlag = productData.liquidFlag;
      // 定义返回库存的文本类型
      let inventoryShowText = ''
      // 计算当前的库存件数
      // 未挂网产品库存显示
      let productNotputSaleShowInventory = productData.productNotputSaleShowInventory
      if (productNotputSaleShowInventory && productNotputSaleShowInventory == '0') {
        inventoryShowText = '充裕'
      } else if (inventory && inventory > 0) {
        // 判断公司是否设置了模糊库存展示 inventoryTextDisplayType  1:库存数量展示  2:模糊库存展示
        let inventoryTextDisplayType = companyConfigData.inventoryTextDisplayType || '1';
        if (inventoryTextDisplayType == '2') {
          // 判断当前产品是否有大包装数量
          if (bigPkgSize && bigPkgSize !== '') {
            let proSize = inventory / bigPkgSize
            // 液体超过两件提示充裕  其他超过5件提示充裕
            // 查看液体库存件数设置
            let liquidBigSizeNumber = companyConfigData.liquidBigSizeNumber
            // 查看非液体库存件数设置
            let notLiquidBigSizeNumber = companyConfigData.notLiquidBigSizeNumber
            if (liquidFlag == '1' && liquidBigSizeNumber && liquidBigSizeNumber > 0 && proSize > liquidBigSizeNumber) {
              inventoryShowText = '充裕'
            } else if (liquidFlag !== '1' && notLiquidBigSizeNumber && notLiquidBigSizeNumber > 0 && proSize > notLiquidBigSizeNumber) {
              inventoryShowText = '充裕'
            } else {
              inventoryShowText = `${inventory}`
            }
          } else { // 产品没有大包装
            inventoryShowText = `${inventory}`
          }
        } else {
          inventoryShowText = `${inventory}`
        }
      } else {
        inventoryShowText = '暂无库存'
      }
      return inventoryShowText
    },
    // 判断用户的产品可购买的最大数量,selectedInventory:用户选中的产品库存信息，productData：当前的产品信息
    getMaxInventoryNumber(selectedInventory) {
      let inventory = 0
      let inventoryNum = selectedInventory.inventory; // 当前选中的库存数据的产品库存数量
      inventory = inventoryNum
      inventory = parseInt(inventory)
      if (isNaN(inventory)) {
        inventory = 0
      }
      return inventory
    },
    // 判断输入框所展示的数据,selectedInventory:用户选中的产品库存信息，productData：当前的产品信息
    getShowInputNumber(selectedInventory, productData) {
      let step = 1; // 步进数量
      // 1. 获取当前产品的步进数量
      if (productData.step) {
        step = parseInt(productData.step);
      }
      let inputNum = 0;
      // 2.判断后台返回的添加购物车字段信息
      if (selectedInventory.addCartQuantity) {
        inputNum = selectedInventory.addCartQuantity;
      } else {
        inputNum = step;
      }
      // 强转显示的数据类型
      inputNum = parseInt(inputNum);
      if (isNaN(inputNum)) {
        inputNum = 1
      }
      return inputNum
    },
    // 秒杀活动展示的输入框数据
    getShowInputNumber2(selectedInventory) {
      let inputNum = 0;
      // 2.判断后台返回的添加购物车字段信息
      if (selectedInventory.addCartQuantity) {
        inputNum = selectedInventory.addCartQuantity;
      } else {
        inputNum = 1;
      }
      // 强转显示的数据类型
      inputNum = parseInt(inputNum);
      if (isNaN(inputNum)) {
        inputNum = 1
      }
      return inputNum
    },
    prolabelMaskImg(labelList) {
      let productBackgroundPic = ""
      for (let key of labelList) {
        if (key.productBackgroundPic && key.productBackgroundPic !== '') {
          productBackgroundPic = key.productBackgroundPic;
          break;
        }
      }
      return productBackgroundPic;
    },
    // 验证产品的数据是否存在
    testReturnData(dataName) {
      if (dataName && dataName !== '') {
        return dataName
      } else {
        return '暂无数据'
      }
    },
    // 展示的产品原价
    showPrice(item) {
      let loginState = this.loginState
      // 判断客户是否登录
      if (loginState) {
        let inventoryList = item.inventoryList
        // 判断商品是否有库存
        if (inventoryList && inventoryList.length > 0) {
          // 判断是否有秒杀活动
          if (item.productActivityPriceStrategyFlag === 1 && item.productActivityPriceStrategyDetail && item.productActivityPriceStrategyDetail.canSaleAmount) {
            if (item.productActivityPriceStrategyDetail.price) {
              return `￥${item.productActivityPriceStrategyDetail.price}`
            } else {
              return '￥--/--'
            }
          } else if (item.inventoryList[0].price) {
            return `￥${item.inventoryList[0].price}`
          } else {
            return '￥--/--'
          }
        } else {
          return '￥--/--'
        }
      } else {
        return '会员可见'
      }
    },
    // 光标选中全选文字
    selectShopAmount(e) {
      e.currentTarget.select();
    },
    // 发货时间
    fahuoTime(item) {
      let supId = item.supId
      switch (supId) {
        case '18':
          return '48小时内发货'
        default:
          return '24小时内发货'
      }
    },
    // 点击厂家搜索
    searchFatory(key) {
      let routerName = this.$route.name
      if (routerName === 'productList') {
        this.$emit('searchProduct', key)
        this.$router.push({
          name: 'productList',
          query: {
            manufacturer: key
          }
        })
      } else {
        this.$router.push({
          name: 'productList',
          query: {
            manufacturer: key
          }
        })
      }
    },
    // 购物车有该产品的时候，添加背景色
    shopNumColor(item) {
      if (parseInt(item.shoppigCartNum) > 0) {
        return 'background:#0b51e1;color:#fff'
      } else {
        return '#000'
      }
    },
    // 游客登录隐藏单价
    hideMoney(money) {
      let loginState = this.loginState
      if (loginState && typeof (money) !== 'undefined' && money !== '') {
        return money
      } else {
        return '--/--'
      }
    },
    // 添加数量
    addNum(item, itemF) {
      // 1.获取用户可购买的最大库存数据
      console.log(item)
      let inventory = this.getMaxInventoryNumber(item);
      // 2.获取输入框显示的数据
      let inputNum = this.getShowInputNumber(item, itemF)
      // 3. 获取当前产品的步进数量
      let step = 1; // 步进数量
      let productStep = this.productStep
      if (itemF.step > 1) {
        step = parseInt(itemF.step);
      } else if (productStep > 1) {
        step = parseInt(productStep);
      }
      // 4.判断操作修改数据
      if ((inputNum > inventory - step && inputNum < inventory) || inputNum >= inventory) {
        inputNum = inventory;
        this.$message({
          message: "该产品最大购买量为:" + inventory,
          offset: 400,
          duration: 2000,
          type: "error",
        });
      } else if (inputNum >= 0 && inputNum <= inventory - step) {
        inputNum = inputNum + step;
        if (inputNum % step) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
        }
      }
      item.addCartQuantity = inputNum;
      this.$forceUpdate();
    },
    // 减少数量
    lessNum(item, itemF) {
      // 1.获取用户可购买的最大库存数据
      let inventory = this.getMaxInventoryNumber(item);
      // 2.获取输入框显示的数据
      let inputNum = this.getShowInputNumber(item, itemF)
      // 3. 获取当前产品的步进数量
      let step = 1; // 步进数量
      let productStep = this.productStep
      if (itemF.step > 1) {
        step = parseInt(itemF.step);
      } else if (productStep > 1) {
        step = parseInt(productStep);
      }
      // 4.判断操作修改数据
      if ((inputNum > 0 && inputNum <= step && step < inventory) || inputNum <= 0) {
        inputNum = step;
      } else if (inputNum > step && inputNum <= inventory) {
        inputNum = inputNum - step;
        if (inputNum % step) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
        }
      }
      item.addCartQuantity = inputNum;
      this.$forceUpdate();
    },
    // 文本框改变数量
    changeNum(item, itemF, e) {
      e.target.value = e.target.value.replace(/[^0-9-]+/, '');
      // 1.获取用户可购买的最大库存数据
      let inventory = this.getMaxInventoryNumber(item);
      // 2.获取输入框显示的数据
      let inputNum = this.getShowInputNumber(item, itemF)
      // 3. 获取当前产品的步进数量
      let step = 1; // 步进数量
      if (itemF.step) {
        step = parseInt(itemF.step);
      }
      // 验证输入框数据
      inputNum = parseInt(e.target.value);
      if (isNaN(inputNum)) {
        inputNum = "";
      } else {
        // 4.判断操作修改数据
        if (inputNum >= inventory) {
          if (inputNum > inventory) {
            this.$message({
              message: `该产品最大购买量为:${inventory}, 已按照最大购买量添加！`,
              offset: 400,
              duration: 2000,
              type: 'success'
            })
          }
          inputNum = inventory;
        } else {
          if (inputNum <= 0) {
            // 输入的小于 0, 按照默认步进来计算
            inputNum = step;
          }
        }
      }
      e.target.value = inputNum;
      item.addCartQuantity = inputNum;
      this.$forceUpdate();
    },
    // 添加购物车
    jionShopCart(item, itemF, e) {
      console.log("添加购物车的产品信息为", item)
      console.log("添加购物车的itemF产品信息为", itemF)
      e.stopPropagation()
      let _this = this
      this.$util.debounce(() => {
        let loginState = this.loginState
        // 判断客户是否登录 登录后添加到购物车，未登录则跳转登录页面
        if (loginState) {
          // 判断当前产品是否是未挂网产品
          if (itemF.offlineSaleFlag && itemF.offlineSaleFlag == '1' && item.giftFlag !== '1' && itemF.productNotputAddShopcartStrategy == '2') {
            if (itemF.productNotputSaleCreateOrder && itemF.productNotputSaleCreateOrder == '1') {
              _this.$router.push({ path: '/linkToSale', query: { productId: itemF.productId, amount: item.addCartQuantity } })
            } else {
              _this.$message({
                message: '当前产品为未挂网产品，请联系开票员进行购买！',
                offset: 400,
                duration: 2000,
                type: 'error'
              })
            }
          } else {
            let overInventoryStatus = false; // 添加数量超过购物车提示状态
            let stepTipStatus = false; // 强制添加数量按照步进
            let overActAmountStatus = false; // 添加的数量是否超过活动数量
            let inputNum = 0; // 定义最终添加购物车的数量
            // 1.获取用户可购买的最大库存数据
            let inventory = _this.getMaxInventoryNumber(item);
            // 2. 判断用户添加的产品数量是否大于0操作
            if (item.addCartQuantity && item.addCartQuantity > 0) {
              inputNum = item.addCartQuantity;
            }
            // 3. 判断产品的步进
            let step = 1; // 步进数量
            if (itemF.step) {
              step = parseInt(itemF.step);
            }
            // 3.1: 如果步进不为1，提示用户已按照指定的步进数添加至购物车
            if (step !== 1 && inputNum < inventory) {
              if (inputNum % step > 0) {
                stepTipStatus = true
                let mulNum = (inputNum - (inputNum % step)) / step + 1;
                inputNum = mulNum * step;
                if (inputNum > inventory) {
                  inputNum = step * (mulNum - 1)
                }
              }
            }
            // 4: 赋值addCartQuantity数量真实添加购物车的数量
            if (inputNum >= inventory) {
              if (inputNum > inventory) {
                overInventoryStatus = true
              }
              inputNum = inventory
            }
            // 判断当前产品是否为液体，单个液体产品添加购物车不能为5件
            // 获取当前添加数据的件数

            // 获取当前选中库存的数量
            // 当前产品的大包装数量
            if (itemF.bigPkgSize && itemF.liquidFlag && itemF.liquidFlag == '1') {
              let bigPkgSize = itemF.bigPkgSize;
              let sizeNumber = inputNum / bigPkgSize
              let companyConfigData = _this.companyConfigData;
              let orderLiquidProductAstrictFlag = companyConfigData.orderLiquidProductAstrictFlag || '0'
              let orderLiquidProductAstrictAmount = companyConfigData.orderLiquidProductAstrictAmount || 0
              if (orderLiquidProductAstrictFlag == '1' && orderLiquidProductAstrictAmount > 0 && sizeNumber > orderLiquidProductAstrictAmount) {
                _this.$message({
                  message: `单笔订单内液体制剂单品最大购买量为${orderLiquidProductAstrictAmount}件`,
                  offset: 400,
                  duration: 2000,
                  type: 'error'
                })
                // item.addCartQuantity = orderLiquidProductAstrictAmount
                return false;
              }
            }
            item.addCartQuantity = inputNum
            // 5. 添加购物车操作
            let selectedProductIdVer = _this.selectedProductIdVer
            if (inputNum > 0 && item.uid !== selectedProductIdVer) {
              let addPrice = item.price;
              let amount = inputNum;
              let uid = item.uid;
              let addShopData = {
                addPrice: addPrice,
                amount: amount,
                uid: uid
              };
              // 赋值给当前的选中产品id防止多次操作造成后端主键冲突
              _this.selectedProductIdVer = item.uid
              // 5.1: 判断选择的产品库存数据是否有价格策略指定id
              if (item.productPriceStrategyId && item.productPriceStrategyId !== '') {
                addShopData.productPriceStrategyId = item.productPriceStrategyId;
              }
              let canSaleAmount = 0; // 活动可购买的活动产品数量
              // 5.2: 判断当前添加的产品库存数据是否有秒杀活动
              if (item.productActivityPriceStrategyFlag && item.productActivityPriceStrategyDetail) {
                addShopData.addPrice = item.productActivityPriceStrategyDetail.price;
                addShopData.uid = item.productActivityPriceStrategyDetail.productInventoryUid;
                addShopData.productActivityPriceStrategyId = item.productActivityPriceStrategyDetail.productActivityPriceStrategyId;
                if (item.productActivityPriceStrategyDetail.canSaleAmount && item.productActivityPriceStrategyDetail.canSaleAmount !== '') {
                  canSaleAmount = parseInt(item.productActivityPriceStrategyDetail.canSaleAmount)
                }
                if (canSaleAmount >= 0 && inputNum > canSaleAmount) {
                  overActAmountStatus = true
                }
              } else if (itemF.productActivityPriceStrategyFlag && itemF.productActivityPriceStrategyDetail && itemF.productActivityPriceStrategyDetail.canSaleAmount > 0) { // 5.2: 判断当前添加的产品默认活动数据是否有秒杀活动
                addShopData.addPrice = itemF.productActivityPriceStrategyDetail.price;
                addShopData.uid = itemF.productActivityPriceStrategyDetail.productInventoryUid;
                addShopData.productActivityPriceStrategyId = itemF.productActivityPriceStrategyDetail.productActivityPriceStrategyId;
                if (itemF.productActivityPriceStrategyDetail.canSaleAmount && itemF.productActivityPriceStrategyDetail.canSaleAmount !== '') {
                  canSaleAmount = parseInt(itemF.productActivityPriceStrategyDetail.canSaleAmount)
                }
                if (canSaleAmount >= 0 && inputNum > canSaleAmount) {
                  overActAmountStatus = true
                }
              }
              let addShopUrl = "/shopping/cart/add?getList=1";
              postAction(addShopUrl, addShopData).then(res => {
                _this.selectedProductIdVer = ""
                if (res.success && res.code == 200) {
                  item.shoppingCartAmount = amount
                  // 判断显示的数量大于实际添加购物数量的提示
                  if (overInventoryStatus) {
                    _this.$message({
                      message: `该产品最大购买量为:${inventory}, 已按照最大购买量添加至购物车！`,
                      offset: 400,
                      duration: 2000,
                      type: 'success'
                    })
                  } else if (stepTipStatus) { // 判断步进不为1（产品不拆零售卖的提示）
                    _this.$message({
                      message: `该产品不拆零销售, 添加至购物车的数量为:${amount}`,
                      offset: 400,
                      duration: 2000,
                      type: 'success'
                    })
                  } else if (overActAmountStatus) {
                    _this.$message({
                      message: `活动产品个人限制最大购买数量为:${canSaleAmount},超出数量已按照原价添加至购物车！`,
                      offset: 400,
                      duration: 2000,
                      type: 'success'
                    })
                  } else {
                    _this.$message({
                      message: '商品已添加到购物车!',
                      offset: 400,
                      duration: 2000,
                      type: 'success'
                    })
                  }
                  let shoppingCartCount = res.result.shoppingCartListcount;
                  // 2023-07-31判断是否需要刷新商品价格
                  let refreshFlag = res.result.refreshFlag;
                  let routePath = _this.$route.path;
                  if (refreshFlag && refreshFlag == '1' && routePath == '/productDetail') {
                    _this.getProductDetail()
                  }
                  _this.$store.commit('SHOPPING_CART_COUNT', shoppingCartCount)
                  _this.$forceUpdate()
                } else {
                  _this.$alert(res.message, '提示信息', {
                    type: 'error'
                  })
                  if (res.result && typeof (res.result) == Number && res.result > 0) {
                    item.addCartQuantity = res.result;
                  }
                }
              }).catch((error) => {
                _this.selectedProductIdVer = ""
              })
            }
          }
        } else {
          // 用户没有登录让用户跳转到登录界面
          _this.$alert('请登录后操作!', '提示信息：')
          _this.$router.push({
            path: '/login'
          })
        }
      }, 300)
    },

    //秒杀活动添加数量
    addNumber(item) {
      // 1.获取用户可购买的最大库存数据
      console.log(item)
      let inventory = this.getMaxInventoryNumber(item);
      // 2.获取输入框显示的数据
      let inputNum = this.getShowInputNumber2(item)
      // 3. 获取当前产品的步进数量
      let step = 1; // 步进数量
      // 4.判断操作修改数据
      if ((inputNum > inventory - step && inputNum < inventory) || inputNum >= inventory) {
        inputNum = inventory;
        this.$message({
          message: "该产品最大购买量:" + inventory,
          offset: 400,
          duration: 1000,
          type: "success",
        });
      } else if (inputNum >= 0 && inputNum <= inventory - step) {
        inputNum = inputNum + step;
        if (inputNum % step) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
        }
      }
      item.addCartQuantity = inputNum;
      this.$forceUpdate();
    },
    // 秒杀活动减少数量操作
    lessNumber(item) {
      // 1.获取用户可购买的最大库存数据
      let inventory = this.getMaxInventoryNumber(item);
      // 2.获取输入框显示的数据
      let inputNum = this.getShowInputNumber2(item)
      // 3. 获取当前产品的步进数量
      let step = 1; // 步进数量
      // 4.判断操作修改数据
      if ((inputNum > 0 && inputNum <= step) || inputNum <= 0) {
        inputNum = step;
      } else if (inputNum > step && inputNum <= inventory) {
        inputNum = inputNum - step;
        if (inputNum % step) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
        }
      }
      item.addCartQuantity = inputNum;
      this.$forceUpdate();
    },
    // 秒杀活动修改文本框数量
    changeActInput(item, e) {
      e.target.value = e.target.value.replace(/[^0-9-]+/, '');
      // 1.获取用户可购买的最大库存数据
      let inventory = this.getMaxInventoryNumber(item);
      // 2.获取输入框显示的数据
      let inputNum = this.getShowInputNumber2(item)
      // 3. 获取当前产品的步进数量
      let step = 1; // 步进数量
      // 验证输入框数据
      inputNum = parseInt(e.target.value);
      if (isNaN(inputNum)) {
        inputNum = "";
      } else {
        // 4.判断操作修改数据
        if (inputNum > inventory) {
          inputNum = inventory;
          this.$message({
            message: "该产品最大购买量为:" + inventory,
            offset: 400,
            duration: 1000,
            type: "error",
          });
        } else {
          if (inputNum > inventory - step && inputNum <= inventory) {
            inputNum = inventory;
          } else if (inputNum <= 0) {
            // 输入的小于 0, 按照默认步进来计算
            inputNum = step;
          }
        }
      }
      e.target.value = inputNum;
      item.addCartQuantity = inputNum;
      this.$forceUpdate()
    },
    // 秒杀活动添加购物车
    actJionShopCart(item) {
      let _this = this;
      let loginState = this.loginState;
      // 判断客户是否登录 登录后添加到购物车，未登录则跳转登录页面
      if (loginState) {
        let overInventoryStatus = false; // 添加数量超过购物车提示状态
        let overActAmountStatus = false; // 添加的数量是否超过活动数量
        let inputNum = 1; // 定义最终添加购物车的数量
        // 1.获取用户可购买的最大库存数据
        let inventory = this.getMaxInventoryNumber(item);
        // 2. 判断用户添加的产品数量是否大于0操作
        if (item.addCartQuantity && item.addCartQuantity > 0) {
          inputNum = item.addCartQuantity;
        }
        if (inputNum > inventory) {
          overInventoryStatus = true;
        }
        // 4.3: 赋值addCartQuantity数量真实添加购物车的数量
        item.addCartQuantity = inputNum
        if (inputNum > 0) {
          let addPrice = item.price;
          let amount = inputNum;
          let uid = item.uid;
          let addShopData = {
            addPrice: addPrice,
            amount: amount,
            uid: uid
          };
          if (item.productPriceStrategyId && item.productPriceStrategyId !== '') {
            addShopData.productPriceStrategyId = item.productPriceStrategyId;
          }
          let canSaleAmount = 0; // 活动可购买的活动产品数量
          if (item.canSaleAmount && item.canSaleAmount !== '') {
            canSaleAmount = parseInt(item.canSaleAmount)
          }
          if (canSaleAmount >= 0 && inputNum > canSaleAmount) {
            overActAmountStatus = true
          }
          addShopData.addPrice = item.price;
          addShopData.uid = item.productInventoryUid;
          addShopData.productActivityPriceStrategyId = item.productActivityPriceStrategyId;
          console.log('添加购物车的数据为++++++', addShopData);
          let addShopUrl = '/shopping/cart/add?getList=1';
          postAction(addShopUrl, addShopData).then((res) => {
            if (res.success && res.code == 200) {
              item.shoppingCartAmount = amount;
              // 判断显示的数量大于实际添加购物数量的提示
              // 判断步进不为1（产品不拆零售卖的提示）
              if (overInventoryStatus) {
                this.$message({
                  message: `该产品最大购买量为:${inventory}, 已按照最大购买量添加至购物车！`,
                  offset: 400,
                  duration: 2000,
                  type: "success",
                });
              } else if (overActAmountStatus) {
                this.$message({
                  message: `活动产品个人限制最大购买数量为:${canSaleAmount},超出数量已按照原价添加至购物车！`,
                  offset: 400,
                  duration: 2000,
                  type: "success",
                });
              } else {
                this.$message({
                  message: "商品已添加到购物车！",
                  offset: 400,
                  duration: 1000,
                  type: "success",
                });
              }
              let shoppingCartCount = res.result.shoppingCartListcount;
              _this.$store.commit("SHOPPING_CART_COUNT", shoppingCartCount);
            } else {
              this.$alert(res.message, "提示信息", {
                type: "error",
              });
            }
          });
        } else {
          this.$message({
            message: "该产品现无库存！",
            offset: 400,
            duration: 1000,
            type: "error",
          });
        }
      } else {
        // 用户没有登录让用户跳转到登录界面
        this.$alert("请登录后操作!", "提示信息：");
        this.$router.push({
          path: "/login",
        });
      }
    },
    // 获取应该提交的产品价格
    productShopPrice(item) {
      // 查看当前的产品是否是秒杀产品
      if (item.productActivityPriceStrategyFlag === 1) {
        return item.productActivityPriceStrategyDetail.price
      } else {
        return item.inventoryList[0].price
      }
    },
    // 显示产品图片
    productImg(item) {
      if (item.picUrl && item.picUrl !== '') {
        let imgsArr = item.picUrl.split(',');
        // let imgSrc = imgsArr[0]
        let imgSrc = this.$util.resizeImg(imgsArr[0],200,false)
        return imgSrc
      } else {
        let imgSrc = ''
        return imgSrc
      }
    },
    // 去登录
    gotoLogin() {
      this.$alert('请登录后操作!', '提示信息：')
      this.$router.push({
        path: '/login'
      })
    },
    // 添加求购信息
    qiugouDenglu(item) {
      let productName = item.name
      let format = item.format
      let manufacturer = item.manufacturer
      let productId = item.productId
      let data = {
        productName: productName,
        format: format,
        manufacturer: manufacturer,
        productId: productId,
      }
      return data

    },
    // 添加取消收藏信息
    customerCollect(item, flag) {
      let url = ""
      let data = {};
      if (flag === 1) {
        data = {
          productId: item.productId,
        }
        url = '/customerCollectProduct/customerCollectProduct/add'
        postAction(url, data).then(res => {
          if (res.code === 200 && res.success) {
            item.isCollect = 1
          }
        })
      } else {
        data = {
          productId: item.productId
        }
        url = '/customerCollectProduct/customerCollectProduct/cancelProductCollect'
        deleteAction(url, data).then(res => {
          if (res.code === 200 && res.success) {
            item.isCollect = 0
          }
        })
      }

    }
  }

}