<template>
  <div class="cetnerList container_box">
    <order-left></order-left>
    <router-view></router-view>
  </div>
</template>

<script>
import OrderLeft from '../components/order/OrderLeft.vue'
export default {
  name: 'Center',
  components: { OrderLeft },
  data () {
    return {
      selectIndex: 1
    }
  }
}
</script>

<style lang="scss" scoped>
.cetnerList {
  @include clear;
  margin-top: 10px;
  margin-bottom: 25px;
}
@import "../assets/styles/order/left";
</style>