<template>
  <div class="giftRightBox">
    <div class="giftListBox">
      <ul class="title">
        <li class="name">礼品名称</li>
        <li class="detail">礼品详情</li>
        <li class="getTime">领取时间</li>
        <li class="getSource">来源</li>
      </ul>
      <div class="listMain" v-if="giftList.length > 0" v-loading="giftloading">
        <ul class="listContent" v-for="(item,index) of giftList" :key="'giftItem' + item.id + index">
          <li class="name">{{item.giftName}}</li>
          <li class="detail">{{item.giftDetail}}</li>
          <li class="getTime">{{item.createTime}}</li>
          <li class="getSource">{{item.activityName}}</li>
        </ul>
      </div>
      <div v-else-if="giftList == 0" class="dataNone">
        <div class="dataImg "></div>
      </div>
    </div>
     <div class="pageFooter">
      <el-pagination
        background
        :hide-on-single-page="true"
        v-if="pageCount > 0"
        layout="prev, pager, next, jumper"
        :current-page="pageNo"
        @current-change="currentChange"
        :page-size="pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAction } from "../api/manage";
export default {
  name: "GiftList",
  data() {
    return {
      giftList: [],
      giftloading: false,
      pageCount: 0, // 总页数
      total: 0, // 总页数
      pageNo: 1, // 当前页
      pageSize: 10, // 当前页
    };
  },
  created() {
    this.getGiftList();
  },
  methods: {
    getGiftList() {
      let _this = this;
      this.giftloading = true;
      let url = "/gift/customerGift/list";
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      // 给后台传的数据
      let data = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      getAction(url, data).then((res) => {
        _this.giftloading = false;
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.giftList = resData.records;
          _this.pageNo = resData.current;
          _this.total = resData.total;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/center/giftList";
</style>