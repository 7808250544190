<template>
  <div id="goodsImgBox" class="container">
    <div class="container_box">
      <ul class="imglistbox">
        <li v-for="(item, index) of productList" v-bind:key="'imgProduct' + index"
          :class="{ 'offline-sale-item': item.offlineSaleFlag && item.offlineSaleFlag == '1' }">
          <div>
            <div class="productImgBox">
              <router-link :to="{
                path: '/productDetail',
                query: { productId: item.productId },
              }" :title="item.name" target="_blank">
                <!-- 查看专区产品遮罩图 -->
                <img v-if="specialAreaMaskImg && specialAreaMaskImg !== ''" :src="specialAreaMaskImg" class="maskImg"
                  alt="产品遮罩图" />
                <!-- 查看产品标签遮罩图 -->
                <img v-else-if="item.productLabelList && item.productLabelList.length > 0
                  && prolabelMaskImg(item.productLabelList) !== ''
                  " :src="prolabelMaskImg(item.productLabelList)" class="maskImg" alt="产品遮罩图" />
                <img class="productImg" v-lazy="productImg(item)" />
              </router-link>
            </div>
          </div>
          <!--产品名称-->
          <div class="img-mingzi">
            {{ item.name }}
          </div>
          <!--判断产品是否有库存-->
          <div v-if="!item.inventoryList || item.inventoryList.length === 0" class="proPriceContent clearfix">
            <p class="priceBox fl" style="font-size: 17px">
              {{ loginState ? "￥--/--" : "会员可见" }}
            </p>
          </div>
          <div v-else-if="item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleShowPrice !== '1'"
            class="proPriceContent clearfix">
            <p class="priceBox fl" style="font-size: 17px">
              请联系开票员
            </p>
          </div>
          <!-- 秒杀产品显示的价格 -->
          <div v-else-if="item.productActivityPriceStrategyFlag === 1 &&
            item.productActivityPriceStrategyDetail &&
            item.productActivityPriceStrategyDetail.canSaleAmount
            " class="proPriceContent clearfix">
            <p class="priceBox fl">
              ￥{{ item.productActivityPriceStrategyDetail.price }}
            </p>
            <p class="yjPriceBox fl">
              <!-- <i class="priceName">原价:</i> -->
              ￥{{ item.productActivityPriceStrategyDetail.originalPrice }}
            </p>
          </div>
          <!-- 2.再判断产品是否参与折扣活动-->
          <div v-else-if="item.inventoryList[0].discountPrice &&
            item.inventoryList[0].discountPrice !== ''
            " class="proPriceContent clearfix">
            <p class="priceBox fl">
              ￥{{ item.inventoryList[0].discountPrice }}
            </p>
            <p class="yjPriceBox fl">
              <!-- <i class="priceName">原价:</i> -->
              ￥{{ item.inventoryList[0].price }}
            </p>
          </div>
          <!-- 3.再判断产品策略里面是否定义了原价-->
          <div v-else-if="item.inventoryList[0].originalPrice &&
            item.inventoryList[0].originalPrice !== ''
            " class="proPriceContent clearfix">
            <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
            <p class="yjPriceBox fl">
              <!-- <i class="priceName">原价:</i> -->
              ￥{{ item.inventoryList[0].originalPrice }}
            </p>
          </div>
          <!-- 4.显示原价 -->
          <div v-else class="proPriceContent clearfix">
            <p class="priceBox fl">￥{{ item.inventoryList[0].price }}</p>
          </div>
          <div v-if="loginState && item.inventoryList && item.inventoryList.length > 0
            " class="tipBox">
            <el-tooltip v-if="item.productActivityPriceStrategyFlag === 1 &&
              item.productActivityPriceStrategyDetail &&
              item.productActivityPriceStrategyDetail.canSaleAmount
              " class="item" effect="dark" content="该产品为秒杀产品" placement="top">
              <span class="tip1">秒杀</span>
            </el-tooltip>

            <el-tooltip v-if="item.inventoryList[0].newCustomerPriceFlag &&
              item.inventoryList[0].newCustomerPriceFlag == '1'" class="item" effect="dark" content="新用户特价产品"
              placement="top">
              <span class="tip3">特价</span>
            </el-tooltip>
            <span class="tip1" :style="item.inventoryList[0].deliveryTime === '24'
              ? 'background-color:#009944'
              : 'background-color:#0f4c81'
              ">{{
    item.inventoryList[0].deliveryTime
    ? item.inventoryList[0].deliveryTime
    : "24H"
  }}</span>
            <span class="tip2" v-if="item.inventoryList[0].priceTitle &&
                item.inventoryList[0].priceTitle !== ''
                ">{{ item.inventoryList[0].priceTitle }}</span>

            <el-tooltip v-if="item.policyFlag && item.productPolicyList && item.productPolicyList.length > 0" class="item"
              effect="dark" :content="item.productPolicyList[0].remark" placement="top">
              <span class="tip3">促销</span>
            </el-tooltip>
            <el-tooltip v-if="item.inventoryList[0].nearDateFlag" class="item" effect="dark" content="该产品为近效期产品"
              placement="top">
              <span class="tip4">近效期</span>
            </el-tooltip>
            <span class="tip5" v-if="(item.productActivityPriceStrategyFlag !== 1 ||
              item.productActivityPriceStrategyDetail.canSaleAmount ===
              0) &&
              item.minDiscountTicket &&
              item.minDiscountTicket.name !== ''
              ">
              {{ item.minDiscountTicket.name }}
            </span>
          </div>
          <div class="tipBox" v-else>
            <span v-if="!loginState" class="tip1" style="background-color:#009944">24H</span>
          </div>
          <div style="cursor: pointer" @click="searchFatory(item.manufacturer)" class="img-compamy"
            :title="item.manufacturer">
            {{
              item.manufacturer && item.manufacturer.replace(/\s*/g, "") !== ""
              ? item.manufacturer
              : "暂无厂家信息！"
            }}
          </div>
          <div class="img-guige">
            规格：<span :title="item.format">{{ item.format }}</span>
          </div>
          <div v-if="loginState" class="img-guige">
            有效期:
            {{
              item.inventoryList &&
              item.inventoryList.length > 0 &&
              item.inventoryList[0].endDate &&
              item.inventoryList[0].endDate !== ""
              ? item.inventoryList[0].endDate
              : "暂无效期"
            }}
          </div>
          <div v-else class="img-guige">
            有效期: <span style="color:#fd2c17">会员可见</span>
          </div>
          <div class="img-xiaoqi">
            <span v-if="item.inventoryList && item.inventoryList.length > 0" class="kucun">
              库存：{{ showInventoryText(item.inventoryNum, item) }}
            </span>
            <span v-else-if="loginState" class="kucun">库存：暂无库存</span>
            <span v-else class="kucun">库存：<i style="font-style:normal;color:#fd2c17">会员可见</i></span>
            <span :title="`${item.mediumPkgSize}/${item.bigPkgSize}`" class="baozhuang">中/大包装：{{
              item.mediumPkgSize
            }}/{{ item.bigPkgSize }}</span>
          </div>
          <div v-if="!loginState" class="cz_content">
            <div class="caozuoBox">
              <p class="zwkcText" style="color:#fd2c17">会员可见</p>
            </div>
            <div @click="gotoLogin" class="jionBtnBox">
              立即抢购
            </div>
          </div>

          <div v-else-if="defaultInventory(item.inventoryList).length > 0" class="cz_content">
            <div v-if="item.inventoryList && item.inventoryList.length > 0 && item.productNotputSaleCreateOrder !== '0'"
              class="amoutBox">
              <span @click="lessNum(item.inventoryList[0], item)">-</span>
              <input :class="{
                shoppingHas:
                  item.inventoryList[0].shoppingCartAmount &&
                  item.inventoryList[0].shoppingCartAmount > 0,
              }" :value="item.inventoryList[0].addCartQuantity" @focus="selectShopAmount($event)"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                @keyup.enter="jionShopCart(item.inventoryList[0], item, $event)"
                @change="changeNum(item.inventoryList[0], item, $event)" type="text" />
              <span @click="addNum(item.inventoryList[0], item)">+</span>
            </div>
            <div @click="jionShopCart(item.inventoryList[0], item, $event)" class="jionBtnBox"
              :style="(item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleCreateOrder == '0') ? 'width: 100%' : ''">
              <img src="../../assets/images/product/img/jionShop.png" />
              {{ (item.offlineSaleFlag && item.offlineSaleFlag == '1') ? item.productNotputSaleCreateOrder == '1' ?
                '开票员订货' : '请联系开票员订货' : '加入购物车' }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ProductMixin } from "@/mixins/ProductMixin";
import { getAction } from "../../api/manage";
import Vue from 'vue'
export default {
  name: "PromotionGoods",
  props: [
    'selectedIndex',
    "isClinicAccount",
    "hyProductStatus",
    "specialAreaMaskImg",
  ],
  mixins: [ProductMixin],
  data() {
    return {
      showMoveDot: [],
      elLeft: 0,
      elTop: 0,
      dropImage: false,
      pageNo: 1,
      pages: 1,
      pageSize: 20,
      totalCount: 1,
      searchVal: "",
      collectFlag: "",
      productShowType: '1',
      productLoading: true,
      productList: [],
      manufacturer: "",
      hasStocket: false, // 只展示有库存的
      maxPrice: "",
      minPrice: "",
      showRangeStatus: false,
      screenList: ["默认排序", "销量", "价格", "人气"],
      screenType: 0, // 4:销量 2:价格 3:人气
      sortType: 1, // 1:升序 2:降序
      sortTypeText: "由低到高", // 排序提示
      productCategory: "", // 产品分类
      nearDateFlag: "", // 产品分类
      productLabelList: "", // 产品标签数据
      newCustomerPriceFlag: "", // 搜索指定新人特价产品
      discountTicketId: "", // 搜索指定优惠券的产品数据
    };
  },
  created() {
    let productShowType = Vue.ls.get('productShowType');
    if (productShowType && productShowType !== '') {
      this.productShowType = productShowType
    } else {
      this.productShowType = '1'
    }
    // 检测平台设置的默认产品列表展示模式
    let searchVal = this.$route.query.searchVal;
    let manufacturer = this.$route.query.manufacturer;
    let productCategory = this.$route.query.productCategory;
    let nearDateFlag = this.$route.query.nearDateFlag;
    let collectFlag = this.$route.query.collectFlag;
    let productLabelList = this.$route.query.productLabelList;
    let newCustomerPriceFlag = this.$route.query.newCustomerPriceFlag;
    let discountTicketId = this.$route.query.discountTicketId;
    this.manufacturer = manufacturer;
    this.productCategory = productCategory;
    this.nearDateFlag = nearDateFlag;
    this.newCustomerPriceFlag = newCustomerPriceFlag;
    this.discountTicketId = discountTicketId;
    this.productLabelList = productLabelList;
    this.searchVal = searchVal;
    this.collectFlag = collectFlag;
    this.getProductListData();
  },
  methods: {
    changeProductListType() {
      let productShowType = this.productShowType;
      if (productShowType == '1') {
        this.productShowType = '2'
      } else {
        this.productShowType = '1'
      }
      // 记录用户选择的列表展示模式
      this.$ls.set('productShowType', this.productShowType)
    },
    inputBlur() {
      let minPrice = this.minPrice;
      let maxPrice = this.maxPrice;
      if (minPrice == '' && maxPrice == '') {
        this.showRangeStatus = false
      }
    },
    // 价格区间查询
    priceSearch() {
      this.showRangeStatus = false;
      this.getProductListData();
    },
    // 清空价格区间筛选
    clearPriceNum() {
      this.minPrice = "";
      this.maxPrice = "";
      this.showRangeStatus = false;
      this.getProductListData();
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    },
    // 选择商品筛选排序条件
    selectScreen(item, index) {
      let sortType = this.sortType;
      let screenType = this.screenType;
      // 人气默认由高到低
      if (screenType == index && index > 0) {
        if (sortType == 1) {
          this.sortTypeText = "由高到低";
          this.sortType = 2;
        } else {
          this.sortTypeText = "由低到高";
          this.sortType = 1;
        }
      } else if (screenType !== index && index > 0) {
        // 初始人气、销量默认从高到低
        if (index == 3 || index == 1) {
          this.sortType = 2;
          this.sortTypeText = "由高到低";
        } else {
          this.sortType = 1;
          this.sortTypeText = "由低到高";
        }
      } else {
        this.sortType = 1;
        this.sortTypeText = "";
      }
      this.screenType = index;
      this.getProductListData();
    },
    // 获取产品列表数据
    getProductListData() {
      this.backTop();
      let _this = this;
      this.productLoading = true;
      let productCategory = this.productCategory;
      let productLabelList = this.productLabelList;
      let newCustomerPriceFlag = this.newCustomerPriceFlag;
      let discountTicketId = this.discountTicketId;
      let nearDateFlag = this.nearDateFlag;
      let manufacturer = this.manufacturer;
      let searchVal = this.searchVal;
      let collectFlag = this.collectFlag;
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let data = {
        searchVal: searchVal,
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (newCustomerPriceFlag && newCustomerPriceFlag !== "" && (!searchVal || searchVal == "")) {
        data.newCustomerPriceFlag = newCustomerPriceFlag;
      }
      if (discountTicketId && discountTicketId !== "" && (!searchVal || searchVal == "")) {
        data.discountTicketId = discountTicketId;
      }
      if (productCategory && productCategory !== "") {
        data.productCategory = productCategory;
      }
      if (nearDateFlag && nearDateFlag !== "" && (!searchVal || searchVal == "")) {
        data.nearDateFlag = nearDateFlag;
      }
      if (manufacturer && manufacturer !== "") {
        data.manufacturer = manufacturer;
      }
      if (productLabelList && productLabelList !== "") {
        data.productLabelList = productLabelList;
      }
      if (collectFlag && collectFlag !== "") {
        data.collectFlag = collectFlag;
      }
      // 判断筛选条件
      // 1. 判断是否只显示有库存的产品数据
      let hasStocket = this.hasStocket;
      if (hasStocket) {
        data["productScreenBO.isInventory"] = 1;
      } else {
        data["productScreenBO.isInventory"] = 0;
      }
      // 2.判断价格区间
      let maxPrice = this.maxPrice;
      let minPrice = this.minPrice;
      if (maxPrice !== "") {
        data["productScreenBO.maxPrice"] = maxPrice;
      }
      if (minPrice !== "") {
        data["productScreenBO.minPrice"] = minPrice;
      }
      // 判断筛选条件和升降序
      let screenType = this.screenType;
      let sortType = this.sortType;
      // screenType: 4: 销量 2:价格 3.人气
      // sortType: 1: 升序  2.降序
      if (screenType !== "" && screenType > 0) {
        if (screenType == 1) {
          data["productScreenBO.screenType"] = 4;
        } else {
          data["productScreenBO.screenType"] = screenType;
        }
        data["productScreenBO.sortType"] = sortType;
      }
      let url = "/product/getlistE";
      console.log("请求列表的数据为+++++++", data)
      getAction(url, data, true)
        .then((res) => {
          _this.productLoading = false;
          if (res.code === 200 && res.success) {
            let pageData = res.result.pageData;
            _this.productList = pageData.records;
            _this.pageNo = pageData.current;
            _this.pages = pageData.pages;
            _this.totalCount = pageData.total;
            let productList = this.productList;
            if (newCustomerPriceFlag
              && newCustomerPriceFlag !== ''
              && productList.length > 0
              && productList[0].inventoryList
              && productList[0].inventoryList.length > 0
              && productList[0].inventoryList[0].newCustomerPriceFlag !== '1') {
              // 5秒后跳转首页
              setTimeout(function () {
                _this.$router.push('/')
              }, 5000)
            }
          } else {
            _this.productList = [];
            _this.productLoading = false;
          }
        })
        .catch((error) => {
          _this.productList = [];
          _this.productLoading = false;
        });
    },
    // 搜索产品的操作
    searchProduct(searchName, labelFlag) {
      this.clearSearchUrlData();
      this.searchVal = searchName;
      this.pageNo = 1;
      let productCategory = this.$route.query.productCategory;
      if (productCategory && productCategory !== '') {
        delete this.$route.query.productCategory
      }
      let productLabelList = this.$route.query.productLabelList;
      if (!labelFlag && productLabelList && productLabelList !== '') {
        delete this.$route.query.productLabelList
      }
      console.log("当前搜索的数据为this.$route.query", this.$route.query)
      console.log("当前搜索的数据为", searchName)
      console.log("this.$router", this.$router)
      this.$router.push({
        query: merge(this.$route.query, { 'searchVal': searchName, t: Date.now() })
      })
      // todo 这里解开会导致搜索会请求两次
      // this.getProductListData();
    },
    // 搜索收藏夹产品的操作
    searchCollectProduct(searchName) {
      this.clearSearchUrlData();
      this.searchVal = searchName;
      this.collectFlag = 1;
      this.pageNo = 1;
      this.getProductListData();
    },
    searchProductCategory(productCategory) {
      this.clearSearchUrlData();
      this.productCategory = productCategory;
      this.getProductListData();
    },
    clearSearchUrlData() {
      this.productCategory = "";
      this.productLabelList = "";
      this.manufacturer = "";
      this.collectFlag = "";
      this.searchVal = "";
      this.newCustomerPriceFlag = "";
      this.discountTicketId = "";
      this.pageNo = 1;
    },
    currentChange(val) {
      this.pageNo = val;
      this.getProductListData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getProductListData();
    },
    listTypeChange(num) {
      this.$emit("listTypeChange", num);
    },
    beforeEnter(el) {
      el.style.transform = `translate3d(${this.elLeft + 50}px,${this.elTop - 300
        }px , 0)`;
      el.style.opacity = 0;
    },
    defaultInventory(list) {
      const arr = [];
      if (list && typeof list !== "undefined" && list.length > 0) {
        arr.push(list[0]);
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/product/img/img";
</style>
