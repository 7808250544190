
<template>
  <div class="cartBoxApp" v-loading="shopLoading === 0">
    <div v-if="shopLoading === 1" class="shoppingCartBox container_box">
      <div class="cartTop">
        <div v-if="false" class="add-remark-btn customerAdd">
          <a @click="addOrderRemark" href="javascript:;">添加备注信息</a>
        </div>
        <div v-if="customerAddressList.length > 0" class="customerAdd">
          <div class="addLeftBox">
            <i class="addIcon"></i>
            <div v-if="actAddressListData.length > 0" class="addressText">
              收货:
              <span>{{ actAddressListData[0].contactsName }}</span>
              <span>{{ actAddressListData[0].contactsTel }}</span>
              <span>{{ actAddressListData[0].address }}</span>
            </div>
            <div v-if="addressShowState" class="customerList">
              <el-scrollbar class="addressBoxList">
                <el-radio-group @change="actAddress" v-model="selecedAddId">
                  <el-radio class="addIdItem" v-for="(item, index) of customerAddressList"
                    :key="'addId' + item.id + 'index' + index" :label="item.id">
                    <div class="addressData">
                      <p>收货人: {{ item.contactsName }}</p>
                      <p>{{ item.contactsTel }}</p>
                    </div>
                    <div :title="item.address" class="add">
                      收货地址: {{ item.address }}
                    </div>
                  </el-radio>
                </el-radio-group>
              </el-scrollbar>
            </div>
            <div class="addActBox">
              <a v-if="false" @click="editAddStatus = true" href="javascript:;">修改本地址</a>
              <a @click="addressShowState = !addressShowState" href="javascript:;">
                使用其他地址
                <i v-if="addressShowState" class="el-icon-arrow-down"></i>
                <i v-else class="el-icon-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="liuchengBox">
          <p class="liuchengText">
            <span>购物车</span>
            <span>创建订单</span>
            <span>等待配货</span>
          </p>
          <img class="liuchengImg" src="../assets/images/shoppingCart/liucheng.png" alt="" />
        </div>
      </div>
      <div class="cartList">
        <div class="cartMain" v-loading="listOperatLoaing">
          <ul class="cartListHeader">
            <li class="checkbox checkAll">
              <el-checkbox :indeterminate="isIndeterminate" v-model="shopCartCheckAll" @change="handleCheckAllChange">
              </el-checkbox>
            </li>
            <li class="name">商品名称</li>
            <li class="format" :style="updatePriceFlag ? 'width: 135px' : ''">规格</li>
            <li class="manufacturer" :style="updatePriceFlag ? 'width: 200px' : ''">生产厂家</li>
            <li class="price" :style="updatePriceFlag ? 'width: 100px' : ''">单价</li>
            <li v-if="updatePriceFlag" class="sale-man-price">业务员价</li>
            <li class="discountPrice" :style="updatePriceFlag ? 'width: 100px' : ''">折扣价</li>
            <li class="amount">数量</li>
            <li class="subtotal">小计</li>
            <li class="operation">操作</li>
          </ul>
          <el-checkbox-group v-model="checkedCart" @change="handleCheckedCitiesChange">
            <!-- 购物车商品列表 -->
            <div v-if="noActivityShoppingCart.length > 0" class="listBox clearfix">
              <div v-for="(item, index) of noActivityShoppingCart" :key="'noActivity' + index" class="cartItemBox">

                <ul>
                  <li class="checkbox checkItemId">
                    <el-checkbox :label="item.id"></el-checkbox>
                  </li>

                  <li class="name">

                    <p class="label-box">
                      <span v-if="item.productOfflineSaleFlag && item.productOfflineSaleFlag == '1'"
                        class="tips tip1">开票员订货</span>
                      <span style="cursor: pointer" v-else-if="item.inventoryTypeId && item.inventoryTypeId == 'YUSHOU'
                        " class="tips tip1">{{ item.inventoryTypeName }}</span>
                      <span v-else-if="item.productActivityPriceStrategyId &&
                        item.productActivityPriceStrategyId !== ''
                        " class="tips tip1">秒杀</span>

                      <span v-else-if="item.newCustomerPriceFlag &&
                        item.newCustomerPriceFlag == 1
                        " class="tips tip3">特价</span>
                      <el-tooltip v-else-if="item.policyFlag == '1'" class="item" effect="dark"
                        :content="item.policyRemark" placement="right">
                        <span class="tips tip3">促销</span>
                      </el-tooltip>
                      <span v-else-if="item.nearDateFlag &&
                        item.nearDateFlag == 1
                        " class="tips tip4">近效期</span>
                      <span v-if="item.inventoryTypeId && item.inventoryTypeId == 'YUSHOU'" @click.stop="gotoDhh">
                        <span style="cursor: pointer" class="tips tip3" v-for="labelItem in classifyLabelList(item)"
                          :key="labelItem.id">
                          {{ labelItem.name }}
                        </span>
                      </span>
                    </p>
                    <router-link :to="{
                      path: '/productDetail',
                      query: { productId: item.productId },
                    }" :title="item.productName" target="_blank">
                      {{ item.productName }}
                    </router-link>
                  </li>
                  <li class="format" :style="updatePriceFlag ? 'width: 135px' : ''">{{ item.productFormat }}</li>
                  <li class="manufacturer" :style="updatePriceFlag ? 'width: 200px' : ''">
                    <router-link :to="{
                      path: '/productList',
                      query: { manufacturer: item.productManufacturer },
                    }" :title="item.productManufacturer">
                      {{ item.productManufacturer }}
                    </router-link>
                    <p>商品效期: {{ getTime(item.endDate)}}</p>
                  </li>
                  <li class="price" :style="updatePriceFlag ? 'width: 100px' : ''">
                    ￥ {{ item.originalPrice ? item.originalPrice : "--/--" }}
                  </li>
                  <li v-if="updatePriceFlag" class="sale-man-price">
                    {{ !item.giftFlag || item.giftFlag !== '1' ? '￥' : '￥' + item.originalPrice }} <input
                      v-if="!item.giftFlag || item.giftFlag !== '1'" type="text" @change="changeSalePrice(item, $event)"
                      oninput="value=value.replace(/[^\d^\.]+/g,'')" onkeyup="value=value.replace(/[^\d^\.]+/g,'')"
                      v-model="item.salePrice" class="sale-price-input">
                  </li>
                  <li class="discountPrice" :style="updatePriceFlag ? 'width: 100px' : ''">
                    {{
                      item.discountPrice ? `￥${item.discountPrice}` : "暂无优惠"
                    }}
                  </li>
                  <li class="amount">
                    <div v-if="item.productActivityId && item.productActivityId !== ''" class="amoutBox">
                      <p title="活动产品不允许在购物车修改，请前往商品详情进行修改！" class="activityAmount">
                        {{ item.amount }}
                        <router-link :to="{
                          path: '/productDetail',
                          query: { productId: item.productId },
                        }">
                          [修改]
                        </router-link>
                      </p>
                    </div>
                    <div v-else class="amoutBox">
                      <span @click="lessAmount(item)">&minus;</span>
                      <input @change="changeAmount(item, $event)" onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
                        :value="item.amount" type="text" />
                      <span @click="addAmount(item)">&plus;</span>
                    </div>
                  </li>
                  <li class="subtotal">
                    ￥ {{ item.productMoney ? item.productMoney : "--/--" }}
                  </li>
                  <li class="operation delAct">
                    <a @click="removeProduct(item)" href="javascript:;"> 删除 </a>
                  </li>
                </ul>
                <!-- 赠品栏数据展示 -->
                <!-- giftType:1 赠品为产品展示 -->
                <ul v-if="item.policySendFlag == '1' && item.productPolicy && item.productPolicy.giftType == '1'">
                  <li class="checkbox checkItemId">
                    <input class="giftCheckBox" @change="removeSendGift(item.productPolicy.id)"
                      :checked="sendGiftIds.indexOf(item.productPolicy.id) > -1 ? true : false" type="checkbox">
                  </li>
                  <li class="name">

                    <a href="javascrit:;" :title="item.productPolicy.giftProductName">
                      <span class="tips tipzp">赠品</span>
                      {{ item.productPolicy.giftProductName }}
                    </a>
                  </li>
                  <li class="format" :style="updatePriceFlag ? 'width: 135px' : ''">{{
                    item.productPolicy.giftProductFormat ? item.productPolicy.giftProductFormat :
                    '暂无信息'
                  }}
                  </li>
                  <li class="manufacturer" :style="updatePriceFlag ? 'width: 200px' : ''">
                    <router-link :to="{
                      path: '/productList',
                      query: { manufacturer: item.productPolicy.giftProductManufacturer },
                    }" :title="item.productPolicy.giftProductManufacturer">
                      {{ item.productPolicy.giftProductManufacturer }}
                    </router-link>
                    <p>商品效期: {{ getTime(item.endDate)}}</p>
                  </li>
                  <li class="price" :style="updatePriceFlag ? 'width: 100px' : ''">
                    ￥ {{ item.productPolicy.sendNeedPrice ? item.productPolicy.sendNeedPrice : "--/--" }}
                  </li>
                  <li class="discountPrice" :style="updatePriceFlag ? 'width: 100px' : ''">
                    {{ item.productPolicy.discountPrice ? item.productPolicy.discountPrice : "暂无优惠" }}
                  </li>
                  <li class="amount">
                    <div class="amoutBox">
                      {{ item.productPolicy.sendNumber }}
                    </div>
                  </li>
                  <li class="subtotal">
                    ￥{{ item.productPolicy.sendNeedMoney ? item.productPolicy.sendNeedMoney : "--/--" }}
                  </li>
                  <li class="operation delAct">
                  </li>
                </ul>

                <!-- giftType:2 赠品为礼品展示 -->
                <ul v-else-if="item.policySendFlag == '1' && item.productPolicy && item.productPolicy.giftType == '2'">

                  <li class="checkbox checkItemId">
                    <input class="giftCheckBox" @change="removeSendGift(item.productPolicy.id)"
                      :checked="sendGiftIds.indexOf(item.productPolicy.id) > -1 ? true : false" type="checkbox">
                  </li>
                  <li class="name">

                    <a href="javascript:;" target="_blank">
                      <span class="tips tipzp">赠品</span>
                      {{ item.productPolicy.giftName }}
                    </a>
                  </li>
                  <li class="format" :style="updatePriceFlag ? 'width: 135px' : ''">{{ item.productPolicy.giftFormat ?
                    item.productPolicy.giftFormat : '暂无信息' }}
                  </li>
                  <li class="manufacturer" :style="updatePriceFlag ? 'width: 200px' : ''">
                    <router-link :to="{
                      path: '/productList',
                      query: { manufacturer: item.productPolicy.giftManufacturer },
                    }" :title="item.productPolicy.giftManufacturer">
                      {{ item.productPolicy.giftManufacturer }}
                    </router-link>
                    <p>商品效期: {{ getTime(item.endDate)}}</p>
                  </li>
                  <li class="price" :style="updatePriceFlag ? 'width: 100px' : ''">
                    ￥ {{ item.productPolicy.sendNeedPrice ? item.productPolicy.sendNeedPrice : "--/--" }}
                  </li>
                  <li class="discountPrice" :style="updatePriceFlag ? 'width: 100px' : ''">
                    {{ item.productPolicy.discountPrice ? item.productPolicy.discountPrice : "暂无优惠" }}
                  </li>
                  <li class="amount">
                    <div class="amoutBox">
                      {{ item.productPolicy.sendNumber }}
                    </div>
                  </li>
                  <li class="subtotal">
                    ￥{{ item.productPolicy.sendNeedMoney ? item.productPolicy.sendNeedMoney : "--/--" }}
                  </li>
                  <li class="operation delAct">
                  </li>
                </ul>
              </div>
            </div>
            <!--END 购物车商品列表 -->
            <!-- 折扣商品 -->
            <div v-if="activityShoppingCart.length > 0" class="listBox clearfix">
              <div v-if="canUseDiscountTicketList.length > 0" class="ticketListContent">
                <p class="zhekouTitle">折扣商品</p>
                <div class="ticketImgList">
                  <p @click="ticketShowState = !ticketShowState" class="ticketIconTitle">
                    优惠券
                    <i v-if="!ticketShowState" class="ticketRight el-icon-arrow-right"></i>
                    <i v-else class="ticketRight el-icon-arrow-down"></i>
                  </p>
                  <div v-if="ticketShowState" class="ticketList">
                    <h3 class="ticketTitle">可用优惠券</h3>
                    <el-scrollbar class="ticketListMain">
                      <div class="listContent" v-for="(item, index) of canUseDiscountTicketList"
                        :key="'ticketItem' + index">
                        <i class="ticketIcon"></i>
                        <div class="ticketDetailD">
                          <!-- <span>平台券</span> -->
                          <span>{{ item.name }}</span>
                          <!-- <p>{{ item.detail }}</p> -->
                        </div>
                        <span @click="selectTicket(item)" class="useBtn">使用</span>
                      </div>
                    </el-scrollbar>
                  </div>
                </div>
                <p class="ticketDeatil">{{ actTicketData.name }}</p>
              </div>
              <!-- 活动产品数据 -->
              <div v-for="(item, index) of activityShoppingCart" :key="'activity' + index" class="cartItemBox">
                <!-- 产品栏数据展示 -->
                <ul>
                  <li class="checkbox checkItemId">
                    <el-checkbox :label="item.id"></el-checkbox>
                  </li>
                  <li class="name">

                    <p class="label-box">
                      <span v-if="item.productOfflineSaleFlag && item.productOfflineSaleFlag == '1'"
                        class="tips tip1">开票员订货</span>
                      <span style="cursor: pointer" @click.stop="gotoDhh" v-else-if="item.inventoryTypeId && item.inventoryTypeId == 'YUSHOU'
                        " class="tips tip1">{{ item.inventoryTypeName }}</span>
                      <span v-else-if="item.productActivityPriceStrategyId &&
                        item.productActivityPriceStrategyId !== ''
                        " class="tips tip1">秒杀</span>

                      <span v-else-if="item.newCustomerPriceFlag &&
                        item.newCustomerPriceFlag == 1
                        " class="tips tip3">特价</span>
                      <el-tooltip v-else-if="item.policyFlag == '1'" class="item" effect="dark"
                        :content="item.policyRemark" placement="right">
                        <span class="tips tip3">促销</span>
                      </el-tooltip>
                      <span v-else-if="item.nearDateFlag &&
                        item.nearDateFlag == 1
                        " class="tips tip4">近效期</span>
                      <span v-if="item.inventoryTypeId && item.inventoryTypeId == 'YUSHOU'" @click.stop="gotoDhh">
                        <span style="cursor: pointer" class="tips tip3" v-for="labelItem in classifyLabelList(item)"
                          :key="labelItem.id">
                          {{ labelItem.name }}
                        </span>
                      </span>
                    </p>
                    <router-link :to="{
                      path: '/productDetail',
                      query: { productId: item.productId },
                    }" :title="item.productName" target="_blank">
                      {{ item.productName }}
                    </router-link>
                  </li>
                  <li class="format" :style="updatePriceFlag ? 'width: 135px' : ''">{{ item.productFormat }}</li>
                  <li class="manufacturer" :style="updatePriceFlag ? 'width: 200px' : ''">
                    <router-link :to="{
                      path: '/productList',
                      query: { manufacturer: item.productManufacturer },
                    }" :title="item.productManufacturer">
                      {{ item.productManufacturer }}
                    </router-link>
                    <p>商品效期: {{ getTime(item.endDate)}}</p>
                  </li>
                  <li class="price" :style="updatePriceFlag ? 'width: 100px' : ''">
                    ￥ {{ item.originalPrice ? item.originalPrice : "--/--" }}
                  </li>
                  <li v-if="updatePriceFlag" class="sale-man-price">
                    {{ !item.giftFlag || item.giftFlag !== '1' ? '￥' : '￥' + item.originalPrice }} <input
                      v-if="!item.giftFlag || item.giftFlag !== '1'" type="text" @change="changeSalePrice(item, $event)"
                      oninput="value=value.replace(/[^\d^\.]+/g,'')" onkeyup="value=value.replace(/[^\d^\.]+/g,'')"
                      v-model="item.salePrice" class="sale-price-input">
                  </li>
                  <li class="discountPrice" :style="updatePriceFlag ? 'width: 100px' : ''">
                    {{ item.discountPrice ? item.discountPrice : "暂无优惠" }}
                  </li>
                  <li class="amount">

                    <div v-if="item.productActivityId && item.productActivityId !== ''" class="amoutBox">
                      <p title="活动产品不允许在购物车修改，请前往商品详情进行修改！" class="activityAmount">
                        {{ item.amount }}
                        <router-link :to="{
                          path: '/productDetail',
                          query: { productId: item.productId },
                        }">
                          [修改]
                        </router-link>
                      </p>
                    </div>
                    <div class="amoutBox">
                      <span @click="lessAmount(item)">&minus;</span>
                      <input @change="changeAmount(item, $event)" onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
                        :value="item.amount" type="text" />
                      <span @click="addAmount(item)">&plus;</span>
                    </div>
                  </li>
                  <li class="subtotal">
                    ￥{{ item.productMoney ? item.productMoney : "--/--" }}
                  </li>
                  <li class="operation delAct">
                    <a @click="removeProduct(item)" href="javascript:;"> 删除 </a>
                  </li>
                </ul>
                <!-- 赠品栏数据展示 -->
                <!-- giftType:1 赠品为产品展示 -->
                <ul v-if="item.policySendFlag == '1' && item.productPolicy && item.productPolicy.giftType == '1'">
                  <li class="checkbox checkItemId">
                    <input class="giftCheckBox" @change="removeSendGift(item.productPolicy.id)"
                      :checked="sendGiftIds.indexOf(item.productPolicy.id) > -1 ? true : false" type="checkbox">
                  </li>
                  <li class="name">

                    <router-link :to="{
                      path: '/productDetail',
                      query: { productId: item.productPolicy.giftId },
                    }" :title="item.productPolicy.giftProductName" target="_blank">
                      <span class="tips tipzp">赠品</span>
                      {{ item.productPolicy.giftProductName }}
                    </router-link>
                  </li>
                  <li class="format" :style="updatePriceFlag ? 'width: 135px' : ''">{{
                    item.productPolicy.giftProductFormat ? item.productPolicy.giftProductFormat :
                    '暂无信息'
                  }}
                  </li>
                  <li class="manufacturer" :style="updatePriceFlag ? 'width: 200px' : ''">
                    <router-link :to="{
                      path: '/productList',
                      query: { manufacturer: item.productPolicy.giftProductManufacturer },
                    }" :title="item.productPolicy.giftProductManufacturer">
                      {{ item.productPolicy.giftProductManufacturer }}
                    </router-link>
                    <p>商品效期: {{ getTime(item.endDate)}}</p>
                  </li>
                  <li class="price" :style="updatePriceFlag ? 'width: 100px' : ''">
                    ￥ {{ item.productPolicy.sendNeedPrice ? item.productPolicy.sendNeedPrice : "--/--" }}
                  </li>
                  <li class="discountPrice" :style="updatePriceFlag ? 'width: 100px' : ''">
                    {{ item.productPolicy.discountPrice ? item.productPolicy.discountPrice : "暂无优惠" }}
                  </li>
                  <li class="amount">
                    <div class="amoutBox">
                      {{ item.productPolicy.sendNumber }}
                    </div>
                  </li>
                  <li class="subtotal">
                    ￥{{ item.productPolicy.sendNeedMoney ? item.productPolicy.sendNeedMoney : "--/--" }}
                  </li>
                  <li class="operation delAct">
                  </li>
                </ul>

                <!-- giftType:2 赠品为礼品展示 -->
                <ul v-else-if="item.policySendFlag == '1' && item.productPolicy && item.productPolicy.giftType == '2'">

                  <li class="checkbox checkItemId">
                    <input class="giftCheckBox" @change="removeSendGift(item.productPolicy.id)"
                      :checked="sendGiftIds.indexOf(item.productPolicy.id) > -1 ? true : false" type="checkbox">
                  </li>
                  <li class="name">

                    <a href="javascript:;" target="_blank">
                      <span class="tips tipzp">赠品</span>
                      {{ item.productPolicy.giftName }}
                    </a>
                  </li>
                  <li class="format" :style="updatePriceFlag ? 'width: 135px' : ''">{{ item.productPolicy.giftFormat ?
                    item.productPolicy.giftFormat : '暂无信息' }}
                  </li>
                  <li class="manufacturer" :style="updatePriceFlag ? 'width: 200px' : ''">
                    <router-link :to="{
                      path: '/productList',
                      query: { manufacturer: item.productPolicy.giftManufacturer },
                    }" :title="item.productPolicy.giftManufacturer">
                      {{ item.productPolicy.giftManufacturer }}
                    </router-link>
                    <p>商品效期: {{ getTime(item.endDate)}}</p>
                  </li>
                  <li class="price" :style="updatePriceFlag ? 'width: 100px' : ''">
                    ￥ {{ item.productPolicy.sendNeedPrice ? item.productPolicy.sendNeedPrice : "--/--" }}
                  </li>
                  <li class="discountPrice" :style="updatePriceFlag ? 'width: 100px' : ''">
                    {{ item.productPolicy.discountPrice ? item.productPolicy.discountPrice : "暂无优惠" }}
                  </li>
                  <li class="amount">
                    <div class="amoutBox">
                      {{ item.productPolicy.sendNumber }}
                    </div>
                  </li>
                  <li class="subtotal">
                    ￥{{ item.productPolicy.sendNeedMoney ? item.productPolicy.sendNeedMoney : "--/--" }}
                  </li>
                  <li class="operation delAct">
                  </li>
                </ul>
              </div>
              <!-- end活动产品数据 -->
            </div>
            <!--END 折扣商品 -->
            <!-- 失效商品列表 -->
            <div v-if="errorShoppingCartList.length > 0" class="listBox">
              <div class="ticketListContent">
                <p class="zhekouTitle">失效产品</p>
              </div>

              <div v-for="(item, index) of errorShoppingCartList" :key="'noActivity' + index" class="cartItemBox">
                <ul>
                  <li class="checkbox checkItemId">
                    <el-checkbox :label="item.id"></el-checkbox>
                  </li>
                  <li class="name">
                    <router-link :to="{
                      path: '/productDetail',
                      query: { productId: item.productId },
                    }" :title="item.productName" target="_blank">

                      <span v-if="item.productOfflineSaleFlag && item.productOfflineSaleFlag == '1'"
                        class="tips tip1">开票员订货</span>
                      <span v-else-if="item.inventoryTypeId && item.inventoryTypeId == 'YUSHOU'" @click.stop="gotoDhh"
                        class="tips tip1">{{ item.inventoryTypeName }}</span>
                      <span v-else-if="item.productActivityPriceStrategyId &&
                        item.productActivityPriceStrategyId !== ''
                        " class="tips tip1">秒杀</span>

                      <span v-else-if="item.newCustomerPriceFlag &&
                        item.newCustomerPriceFlag == 1
                        " class="tips tip3">特价</span>
                      <el-tooltip v-else-if="item.policyFlag == '1'" class="item" effect="dark"
                        :content="item.policyRemark" placement="right">
                        <span class="tips tip3">促销</span>
                      </el-tooltip>
                      <span v-else-if="item.nearDateFlag &&
                        item.nearDateFlag == 1
                        " class="tips tip4">近效期</span>
                      {{ item.productName }}
                    </router-link>
                  </li>
                  <li class="format" :style="updatePriceFlag ? 'width: 135px' : ''">{{ item.productFormat }}</li>
                  <li class="manufacturer" :style="updatePriceFlag ? 'width: 200px' : ''">
                    <router-link :to="{
                      path: '/productList',
                      query: { manufacturer: item.productManufacturer },
                    }" :title="item.productManufacturer">
                      {{ item.productManufacturer }}
                    </router-link>
                    <p>商品效期: {{ getTime(item.endDate)}}</p>
                  </li>
                  <li class="price" :style="updatePriceFlag ? 'width: 100px' : ''">
                    ￥ {{ item.originalPrice ? item.originalPrice : "--/--" }}
                  </li>
                  <li v-if="updatePriceFlag" class="sale-man-price">
                    {{ !item.giftFlag || item.giftFlag !== '1' ? '￥' : '￥' + item.originalPrice }} <input
                      v-if="!item.giftFlag || item.giftFlag !== '1'" type="text" @change="changeSalePrice(item, $event)"
                      oninput="value=value.replace(/[^\d^\.]+/g,'')" onkeyup="value=value.replace(/[^\d^\.]+/g,'')"
                      v-model="item.salePrice" class="sale-price-input">
                  </li>
                  <li class="discountPrice" :style="updatePriceFlag ? 'width: 100px' : ''">￥{{ item.discountPrice }}</li>
                  <li class="amount">

                    <div v-if="item.productActivityId && item.productActivityId !== ''" class="amoutBox">
                      <p title="活动产品不允许在购物车修改，请前往商品详情进行修改！" class="activityAmount">
                        {{ item.amount }}
                        <router-link :to="{
                          path: '/productDetail',
                          query: { productId: item.productId },
                        }">
                          [修改]
                        </router-link>
                      </p>
                    </div>
                    <div class="amoutBox">
                      <span @click="lessAmount(item)">&minus;</span>
                      <input @change="changeAmount(item, $event)" onkeyup="this.value=this.value.replace(/[^0-9-]+/,'');"
                        :value="item.amount" type="text" />
                      <span @click="addAmount(item)">&plus;</span>
                    </div>
                  </li>
                  <li class="subtotal">
                    ￥ {{ item.productMoney ? item.productMoney : "--/--" }}
                  </li>
                  <li class="operation delAct">
                    <a @click="removeProduct(item)" href="javascript:;"> 删除 </a>
                  </li>
                </ul>
                <!-- 赠品栏数据展示 -->
                <!-- giftType:1 赠品为产品展示 -->
                <ul v-if="item.policySendFlag == '1' && item.productPolicy && item.productPolicy.giftType == '1'">
                  <li class="checkbox checkItemId">
                    <input class="giftCheckBox" @change="removeSendGift(item.productPolicy.id)"
                      :checked="sendGiftIds.indexOf(item.productPolicy.id) > -1 ? true : false" type="checkbox">
                  </li>
                  <li class="name">
                    <router-link :to="{
                      path: '/productDetail',
                      query: { productId: item.productPolicy.giftId },
                    }" :title="item.productPolicy.giftProductName" target="_blank">
                      <span class="tips tipzp">赠品</span>
                      {{ item.productPolicy.giftProductName }}
                    </router-link>
                  </li>
                  <li class="format" :style="updatePriceFlag ? 'width: 135px' : ''">{{
                    item.productPolicy.giftProductFormat ? item.productPolicy.giftProductFormat :
                    '暂无信息'
                  }}
                  </li>
                  <li class="manufacturer" :style="updatePriceFlag ? 'width: 200px' : ''">
                    <router-link :to="{
                      path: '/productList',
                      query: { manufacturer: item.productPolicy.giftProductManufacturer },
                    }" :title="item.productPolicy.giftProductManufacturer">
                      {{ item.productPolicy.giftProductManufacturer }}
                    </router-link>
                    <p>商品效期: {{ getTime(item.endDate)}}</p>
                  </li>
                  <li class="price" :style="updatePriceFlag ? 'width: 100px' : ''">
                    ￥ {{ item.productPolicy.sendNeedPrice ? item.productPolicy.sendNeedPrice : "--/--" }}
                  </li>
                  <li class="discountPrice" :style="updatePriceFlag ? 'width: 100px' : ''">
                    {{ item.productPolicy.discountPrice ? item.productPolicy.discountPrice : "暂无优惠" }}
                  </li>
                  <li class="amount">
                    <div class="amoutBox">
                      {{ item.productPolicy.sendNumber }}
                    </div>
                  </li>
                  <li class="subtotal">
                    ￥{{ item.productPolicy.sendNeedMoney ? item.productPolicy.sendNeedMoney : "--/--" }}
                  </li>
                  <li class="operation delAct">
                  </li>
                </ul>

                <!-- giftType:2 赠品为礼品展示 -->
                <ul v-else-if="item.policySendFlag == '1' && item.productPolicy && item.productPolicy.giftType == '2'">

                  <li class="checkbox checkItemId">
                    <input class="giftCheckBox" @change="removeSendGift(item.productPolicy.id)"
                      :checked="sendGiftIds.indexOf(item.productPolicy.id) > -1 ? true : false" type="checkbox">
                  </li>
                  <li class="name">

                    <a href="javascript:;" target="_blank">
                      <span class="tips tipzp">赠品</span>
                      {{ item.productPolicy.giftName }}
                    </a>
                  </li>
                  <li class="format" :style="updatePriceFlag ? 'width: 135px' : ''">{{ item.productPolicy.giftFormat ?
                    item.productPolicy.giftFormat : '暂无信息' }}
                  </li>
                  <li class="manufacturer" :style="updatePriceFlag ? 'width: 200px' : ''">
                    <router-link :to="{
                      path: '/productList',
                      query: { manufacturer: item.productPolicy.giftManufacturer },
                    }" :title="item.productPolicy.giftManufacturer">
                      {{ item.productPolicy.giftManufacturer }}
                    </router-link>
                    <p>商品效期: {{ getTime(item.endDate)}}</p>
                  </li>
                  <li class="price" :style="updatePriceFlag ? 'width: 100px' : ''">
                    ￥ {{ item.productPolicy.sendNeedPrice ? item.productPolicy.sendNeedPrice : "--/--" }}
                  </li>
                  <li class="discountPrice" :style="updatePriceFlag ? 'width: 100px' : ''">
                    {{ item.productPolicy.discountPrice ? item.productPolicy.discountPrice : "暂无优惠" }}
                  </li>
                  <li class="amount">
                    <div class="amoutBox">
                      {{ item.productPolicy.sendNumber }}
                    </div>
                  </li>
                  <li class="subtotal">
                    ￥{{ item.productPolicy.sendNeedMoney ? item.productPolicy.sendNeedMoney : "--/--" }}
                  </li>
                  <li class="operation delAct">
                  </li>
                </ul>
              </div>
            </div>
            <!--END 失效商品列表 -->
          </el-checkbox-group>
        </div>
        <div class="cartBottom">
          <div class="actLeft">
            <a v-if="presaleFlag" @click="checkPresaleCart(1)" href="javascript:;">勾选预售产品</a>
            <a v-if="presaleFlag" @click="checkPresaleCart(0)" href="javascript:;">勾选非预售产品</a>
            <a @click="removeBatchCart(1)" href="javascript:;">删除选中的商品</a>
            <a @click="removeBatchCart(2)" href="javascript:;">删除失效产品</a>
          </div>
          <div class="payTypeBox">
            <el-radio v-if="feeIsOnline === 1" @change="changePayType" v-model="feeType" :label="2">在线支付
            </el-radio>
            <el-radio v-if="feeIsOffline === 1" @change="changePayType" v-model="feeType" :label="1">线下支付
            </el-radio>
          </div>
          <div class="cartData">
            <div class="dataLeft">
              <!-- 判断平台是否开启了订单大包装控制 -->
              <div v-if="saleOrderBigPkgNumberAstrictFlag == '1' && saleOrderBigPkgNumberAstrictOk == '0'"
                class="yunfeiText">
                当前购物车商品件数为<span>{{ saleOrderBigPkgNumber }}</span>件，不满足公司<span>{{
                  saleOrderBigPkgNumberAstrict
                }}</span>件起订要求，请补充数量后提交。
              </div>
              <div v-else-if="createOrderMoneyStrategyOk == '0' && createOrderMoneyStrategyMoney > 0" class="yunfeiText">
                当前购物车金额不满足<span>{{ createOrderMoneyStrategyMoney }}</span>元的起订要求，请补充商品后提交。
              </div>
              <div v-else-if="satisfyMoney > 0" class="yunfeiText">
                满{{ notLogisticsMoney }}元包邮，需支付运费<span>￥{{ logisticsMoney }}</span>
              </div>
              <div class="shopData">
                <span>已选商品<i>{{ checkedCart.length }}</i>件</span>
                <span v-if="JSON.stringify(actTicketData) !== {} && discountMoney > 0
                  ">已优惠: <i>￥{{ discountMoney }}</i></span>
                <span>支付金额: <i>￥{{ shopCartAllMoneyAll }}</i></span>
              </div>
            </div>
            <div
              v-if="(saleOrderBigPkgNumberAstrictOk == '1' && !createOrderStatus) || (saleOrderBigPkgNumberAstrictFlag == '1' && saleOrderBigPkgNumberAstrictOk == '1' && !createOrderStatus) || (createOrderMoneyStrategyOk == '1' && !createOrderStatus)"
              @click="confirmCreateOrder" class="createOrderBtn">
              创建订单
            </div>
            <div
              v-else-if="(saleOrderBigPkgNumberAstrictFlag == '1' && saleOrderBigPkgNumberAstrictOk == '0' && !createOrderStatus) || (createOrderMoneyStrategyOk == '0' && !createOrderStatus)"
              disabled="true" class="createOrderBtn disabledBtn">
              无法创建
            </div>
            <div class="createOrderBtn" disabled="true" v-else v-loading="createOrderStatus">
              正在创建
            </div>
          </div>
        </div>

        <div class="remarks-box">
          <label class="label" for="remark">
            订单备注信息：
          </label>
          <input type="text" class="remarkBox" name="remark" v-model="orderRemark" maxlength="250" id="remark" />
        </div>
      </div>
    </div>
    <div v-else-if="shopLoading === 2" class="dataNone container_box">
      <router-link to="/productList">
        <img src="../assets/images/common/shopNone.png" alt="" />
      </router-link>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="25%" height="15vh">
      <span>{{
        deleteType === 1 ? "确定批量删除购物车?" : "确定批量移除失效列表?"
      }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="hadleOk" @click="deleteShopCart">确定</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getAction, postAction, putAction } from "../api/manage";
import { accAdd, getUserInfo } from "../assets/js/common/common";
import { mapGetters } from "vuex";
export default {
  name: "ShoppingCart",
  data() {
    return {
      editAddStatus: false,
      selecedAddId: 0,
      addressShowState: false,
      ticketShowState: false,
      dialogVisible: false,
      deleteType: 0,
      shopLoading: 0,
      shopCartCheckAll: true,
      checkedCart: [],
      presaleFlag: false,
      presaleCheckedCart: [], // 预售产品购物车id
      nonPresaleCheckedCart: [], // 非预售产品购物车id
      shopCartCheckIds: [],
      isIndeterminate: false,
      canShoppingCartList: [],
      noActivityShoppingCart: [],
      activityShoppingCart: [],
      errorShoppingCartList: [],
      canUseDiscountTicketList: [],
      customerAddressList: [],
      actAddressListData: [],
      addressListOpen: false,
      feeType: 0,
      feeIsOnline: 1,
      feeIsOffline: 1,
      showTicketListStatus: false,
      createOrderStatus: false,
      actTicketData: {},
      selectTicketData: {},
      logisticsMoney: 0,
      notLogisticsMoney: 0,
      productNotCanUseDiscountTicketMoney: 0,
      productCanUseDiscountTicketMoney: 0,
      discountMoney: 0,
      selectTicketId: 0,
      shopCartAllMoney: 0,
      listOperatLoaing: false, // 列表加载动画 用于删除和清空数据操作
      orderStatus: null, // 订单配货定时器
      shopCardSendGiftIds: [], // 原始购物车所有的赠品id数组
      shopCardSendGiftItems: [], // 原始购物车所有的赠品数组
      sendGiftIds: [], // 赠品复选框id
      createOrderMoneyStrategyOk: '0',
      createOrderMoneyStrategyMoney: 0,
      createOrderAlertMessageFlag: null,
      createOrderAlertMessage: '',
      saleOrderBigPkgNumberAstrictFlag: '0',
      saleOrderBigPkgNumberAstrictOk: '0',
      saleOrderBigPkgNumberAstrict: 0,
      saleOrderBigPkgNumber: 0,
      orderRemark: '', // 订单备注信息
      updatePriceFlag: false, // 是否可以更改价格
      firstGetFlag: true
    };
  },

  created() {
    this.backTop();
    const userInfo = getUserInfo;
    if (!userInfo) {
      this.$alert("请登录后操作!", "提示信息：");
      this.$router.push("/login");
      return false;
    } else {
      this.renderUserPayWay();
    }
  },
  methods: {
    gotoDhh() {
      this.$router.push("/activityZone/orderPlacing")
    },
    getTime(timeStr) {
      if (!timeStr) {
        return '--'
      }
      const dateObject = new Date(timeStr);
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1;
      const day = dateObject.getDate();
      return `${year}-${month}-${day}`;
    },
    classifyLabelList(item) {
      let labelList = []
      if (item.productLabelList && item.productLabelList.length > 0) {
        let productLabelList = item.productLabelList
        labelList = productLabelList.filter(key => {
          return (key.id === 340 || key.id === 341 || key.id === 342)
        })
      }
      return labelList
    },
    // 设置业务员自定义价格
    changeSalePrice(item, e) {
      let reg = /^\d+$|^\d*\.\d+$/g;
      let val = item.salePrice
      if (reg.test(val)) {
        this.changeShopCartItemAount(item);
      } else {
        this.$message({
          message: "请填写正确的商品定价！",
          offset: 400,
          duration: 1000,
          type: "error",
        });
      }
    },
    // 添加订单备注信息
    addOrderRemark() {
      let _this = this;
      let orderRemark = this.orderRemark
      this.$prompt('', '订单备注信息：', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: orderRemark
      }).then(({ value }) => {
        console.log("您填写的订单备注信息为:+++", value);
        _this.orderRemark = value
      }).catch(() => {
      });
    },
    removeSendGift(id) {
      let sendGiftIds = this.sendGiftIds;
      let index = sendGiftIds.indexOf(id)
      if (index > -1) {
        sendGiftIds.splice(index, 1)
      } else {
        sendGiftIds.push(id)
      }
      this.sendGiftIds = sendGiftIds
    },
    changeSendGiftStatus(id) {
      console.log("修改礼品框的选中状态++++", id)
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    accMuls(arg1, arg2) {
      if (typeof arg1 === "undefined") {
        arg1 = 0;
      }
      if (typeof arg2 === "undefined") {
        arg2 = 0;
      }
      var m = 0;
      var s1 = arg1.toString();
      var s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) { }
      try {
        m += s2.split(".")[1].length;
      } catch (e) { }
      const numberMoney =
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m);
      return numberMoney.toFixed(2);
    },
    productImg(item) {
      if (item.productPicUrl && item.productPicUrl !== "") {
        let picUrlArr = item.productPicUrl.split(",");
        const imgsArr = picUrlArr[0];
        const imgSrc = this.$imgPath + imgsArr;
        return imgSrc;
      } else {
        const imgSrc = "../../assets/images/common/dataNone.png";
        return imgSrc;
      }
    },
    searchProductManufacturer(manufacturer) {
      this.$router.push({
        name: "productList",
        query: { searchVal: manufacturer },
      });
    },
    handleCheckAllChange(val) {
      this.checkedCart = val ? this.shopCartCheckIds : [];
      if (val == false) {
        this.sendGiftIds = []
      } else {
        let shopCardSendGiftIds = this.shopCardSendGiftIds
        this.sendGiftIds = shopCardSendGiftIds;
      }
      this.isIndeterminate = false;
      const choiceId = this.checkedCart;
      this.getShoppingCartList(choiceId);
    },
    handleCheckedCitiesChange(checkedIds) {
      console.log("修改的checkedIds+++++++++", checkedIds)
      const checkedCount = checkedIds.length;
      this.shopCartCheckAll = checkedCount === this.shopCartCheckIds.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.shopCartCheckIds.length;
      this.getShoppingCartList(checkedIds);
    },
    changePayType() {
      const choiceId = this.checkedCart;
      this.getShoppingCartList(choiceId);
    },
    renderUserPayWay() {
      const that = this;
      const url = "/customer/customerConfig/get";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          const resData = res.result;
          that.feeIsOnline = resData.feeIsOnline;
          that.feeIsOffline = resData.feeIsOffline;
          if (resData.feeIsOnline === 1 && resData.feeIsOffline === 0) {
            that.feeType = 2;
          } else if (resData.feeIsOnline === 0 && resData.feeIsOffline === 1) {
            that.feeType = 1;
          } else {
            that.feeType = resData.defaultPayType === 0 ? 1 : 2;
          }
          that.getShoppingCartList();
        }
      });
    },
    getShoppingCartList(choiceId) {
      const feeType = this.feeType;
      const data = {
        feeType: feeType,
      };
      if (choiceId) {
        data.choiceId = choiceId;
      }
      if (
        this.selectTicketData &&
        JSON.stringify(this.selectTicketData) !== "{}"
      ) {
        this.actTicketData = this.selectTicketData;
        const useDiscountTicketId = this.actTicketData.id;
        data.useDiscountTicketId = useDiscountTicketId;
      }
      const _this = this;
      const url = "/shopping/cart/getList";
      postAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          const resData = res.result;
          const checkedCart = this.checkedCart;
          const shopCartCheckIds = _this.shopCartCheckIds;
          if (
            typeof choiceId === "undefined" ||
            checkedCart === shopCartCheckIds
          ) {
            this.$store.commit("SHOPPING_CART_LIST", resData);
            _this.renderShopCartData(true);
          } else {
            this.$store.commit("SHOPPING_CART_LIST", resData);
            _this.renderShopCartData();
          }
        }
      });
    },
    renderShopCartData(checkStatus) {
      let resData = this.shoppingCartList;
      console.log("当前购物车列表返回的数据为123++++++", resData)
      if (resData.updatePriceFlag && resData.updatePriceFlag == '1') {
        this.updatePriceFlag = true
      } else {
        this.updatePriceFlag = false
      }
      this.listOperatLoaing = false;
      const _this = this;
      let shoppingCartCount = 0;
      if (
        (resData.shoppingCartList && resData.shoppingCartList.length > 0) ||
        (resData.errorShoppingCartList &&
          resData.errorShoppingCartList.length > 0)
      ) {
        _this.shopLoading = 1;
      } else {
        _this.shopLoading = 2;
        _this.$store.commit("SHOPPING_CART_COUNT", shoppingCartCount);
        return false;
      }
      if (resData.shoppingCartList && resData.shoppingCartList.length > 0) {
        _this.canShoppingCartList = resData.shoppingCartList;
        const shopCartCheckIds = [];
        const activityShoppingCart = [];
        const noActivityShoppingCart = [];
        const shopCardSendGiftIds = [];
        const presaleCheckedCart = [];
        const nonPresaleCheckedCart = [];
        _this.shopCardSendGiftItems = [];
        for (const key of resData.shoppingCartList) {
          shopCartCheckIds.push(key.id);
          if (key.policySendFlag == '1' && key.productPolicy) {
            shopCardSendGiftIds.push(key.productPolicy.id)
            _this.shopCardSendGiftItems.push(key.productPolicy)
          }
          if (key.canTicketFlag === 1) {
            activityShoppingCart.push(key);
          } else {
            noActivityShoppingCart.push(key);
          }
          // 给预售产品和非预售产品赋值
          if (key.inventoryTypeId == "YUSHOU") {
            presaleCheckedCart.push(key.id);
            _this.presaleFlag = true;
          } else {
            nonPresaleCheckedCart.push(key.id)
          }
        }

        _this.presaleCheckedCart = presaleCheckedCart;
        _this.nonPresaleCheckedCart = nonPresaleCheckedCart;
        _this.noActivityShoppingCart = noActivityShoppingCart;
        _this.activityShoppingCart = activityShoppingCart;
        _this.$set(_this, "noActivityShoppingCart", noActivityShoppingCart);
        _this.$set(_this, "activityShoppingCart", activityShoppingCart);
        _this.shopCartCheckIds = shopCartCheckIds;
        _this.shopCardSendGiftIds = shopCardSendGiftIds;
        console.log("购物车原始的赠品id数组为++++++", shopCardSendGiftIds)
        console.log("checkStatus为++++++", checkStatus)
        if (checkStatus) {
          let presaleFlag = _this.presaleFlag;
          let firstGetFlag = _this.firstGetFlag;
          // 应后端要求2023-07-27 9:00 初次请求判断购物车是否有预售品种，如果有勾选预售产品再次请求购物车选中品种操作
          if (presaleFlag && firstGetFlag) {
            _this.checkedCart = presaleCheckedCart;
            _this.firstGetFlag = false;
            _this.getShoppingCartList(presaleCheckedCart);
          } else {
            _this.checkedCart = shopCartCheckIds;
          }
          // _this.checkedCart = shopCartCheckIds;
        }
        _this.sendGiftIds = shopCardSendGiftIds;
        shoppingCartCount = shopCartCheckIds.length;
      }
      _this.$store.commit("SHOPPING_CART_COUNT", shoppingCartCount);
      if (
        resData.errorShoppingCartList &&
        resData.errorShoppingCartList.length >= 0
      ) {
        _this.errorShoppingCartList = resData.errorShoppingCartList;
      }
      if (
        resData.hasOwnProperty("customerAddressList") &&
        resData.customerAddressList
      ) {
        _this.renderAddressList(resData.customerAddressList);
        _this.customerAddressList = resData.customerAddressList;
      }
      _this.productCanUseDiscountTicketMoney =
        resData.productCanUseDiscountTicketMoney;
      _this.productNotCanUseDiscountTicketMoney =
        resData.productNotCanUseDiscountTicketMoney;
      if (
        resData.canUseDiscountTicketList &&
        resData.canUseDiscountTicketList.length > 0
      ) {
        _this.canUseDiscountTicketList = resData.canUseDiscountTicketList;
        if (resData.useDiscountTicket) {
          _this.actTicketData = resData.useDiscountTicket;
          _this.selectTicketData = resData.useDiscountTicket;
        } else {
          _this.actTicketData = resData.canUseDiscountTicketList[0];
          _this.selectTicketData = resData.canUseDiscountTicketList[0];
        }
      } else {
        _this.canUseDiscountTicketList = [];
        _this.actTicketData = {};
        _this.selectTicketData = {};
      }
      // 判断用户是否开启了大包装售卖
      if (resData.saleOrderBigPkgNumberAstrictFlag && resData.saleOrderBigPkgNumberAstrictFlag == '1') {
        _this.saleOrderBigPkgNumberAstrictFlag = resData.saleOrderBigPkgNumberAstrictFlag
        _this.saleOrderBigPkgNumberAstrict = resData.saleOrderBigPkgNumberAstrict
        _this.saleOrderBigPkgNumber = resData.saleOrderBigPkgNumber
        _this.saleOrderBigPkgNumberAstrictOk = resData.saleOrderBigPkgNumberAstrictOk
      }
      // 是否开启了订单金额控制
      if (resData.createOrderMoneyStrategyOk && resData.createOrderMoneyStrategyOk !== '') {
        _this.createOrderMoneyStrategyOk = resData.createOrderMoneyStrategyOk
      }
      if (resData.createOrderMoneyStrategyMoney && resData.createOrderMoneyStrategyMoney > 0) {
        _this.createOrderMoneyStrategyMoney = resData.createOrderMoneyStrategyMoney
      }
      // 判断是否存在订单创建提示设置
      if (resData.createOrderAlertMessageFlag && resData.createOrderAlertMessageFlag !== '') {
        _this.createOrderAlertMessageFlag = resData.createOrderAlertMessageFlag
      }
      if (resData.createOrderAlertMessage && resData.createOrderAlertMessage !== '') {
        _this.createOrderAlertMessage = resData.createOrderAlertMessage
      }

      _this.discountMoney = resData.discountMoney;
      _this.logisticsMoney = resData.logisticsMoney;
      _this.notLogisticsMoney = resData.notLogisticsMoney;
      _this.shopCartAllMoney = resData.payMoney;
      this.$forceUpdate();
    },
    renderAddressList(accountAddress) {
      const _this = this;
      const addList = accountAddress;
      let hasDefault = false;
      const actAddressListData = [];
      for (const key of addList) {
        if (key.isDefault === 1) {
          actAddressListData.push(key);
          _this.selecedAddId = key.id;
          hasDefault = true;
        }
      }
      if (!hasDefault) {
        for (const key of addList) {
          if (key.isAction && key.isAction === 1) {
            actAddressListData.push(key);
            _this.selecedAddId = key.id;
            hasDefault = true;
          }
        }
      }
      if (!hasDefault && accountAddress.length > 0) {
        actAddressListData.push(accountAddress[0]);
        _this.selecedAddId = accountAddress[0].id;
      }
      this.actAddressListData = actAddressListData;
      this.addressListOpen = false;
    },
    changeAddressListOpen(boolean) {
      if (boolean) {
        this.actAddressListData = this.customerAddressList;
        this.addressListOpen = true;
      } else {
        this.renderAddressList(this.customerAddressList);
        this.addressListOpen = false;
      }
    },
    actAddress(addId) {
      this.actAddressListData = [];
      this.selecedAddId = addId
      let customerAddressList = this.customerAddressList
      this.actAddressListData = []
      for (let key of customerAddressList) {
        if (addId == key.id) {
          this.actAddressListData.push(key);
        }
      }
      this.addressShowState = false;
    },
    setCustomerDefault(item) {
      const _this = this;
      const url = "/customer/customerAddress/edit";
      const data = {
        id: item.id,
        isDefault: 1,
      };
      putAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          _this.getCustomerAddressList();
        }
      });
    },
    getCustomerAddressList() {
      const _this = this;
      const url = "/customer/customerAddress/list";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          _this.customerAddressList = res.result;
          _this.renderAddressList(_this.customerAddressList);
        }
      });
    },
    removeProduct(item) {
      this.listOperatLoaing = true;
      const _this = this;
      const deleteUrl = "/shopping/cart/delete?getList=1";
      const feeType = this.feeType;
      const data = {
        id: item.id,
        getList: 1,
        feeType: feeType,
      };
      postAction(deleteUrl, data).then((res) => {
        this.listOperatLoaing = false;
        if (res.code === 200 && res.success) {
          const resData = res.result;
          _this.$store.commit("SHOPPING_CART_LIST", resData);
          // 删除成功后从全选数据里面移除删除的id
          let shopCartCheckIds = _this.shopCartCheckIds;
          if (shopCartCheckIds.indexOf(item.id) > -1) {
            let index = shopCartCheckIds.indexOf(item.id);
            shopCartCheckIds.splice(index, 1);
            _this.shopCartCheckIds = shopCartCheckIds;
          }
          const choiceId = _this.checkedCart;
          if (choiceId.indexOf(item.id) > -1) {
            let index = choiceId.indexOf(item.id);
            choiceId.splice(index, 1);
            _this.choiceId = choiceId;
          }
          _this.renderShopCartData();
        }
      });
    },
    lessAmount(item) {
      let inputNum = parseInt(item.amount);
      console.log("inputNum+++++++", inputNum);
      let step = 1;
      if (item.productStep && item.productStep > 0) {
        step = parseInt(item.productStep);
      }
      const stockNum = parseInt(item.inventory);
      if (
        (inputNum > 0 && inputNum <= step && inputNum < stockNum) ||
        inputNum <= 0
      ) {
        inputNum = step;
      } else if (inputNum > step && inputNum <= stockNum) {
        inputNum = inputNum - step;
        if (inputNum % step) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
        }
      }
      item.amount = inputNum;
      this.changeShopCartItemAount(item);
    },
    addAmount(item) {
      let inputNum = parseInt(item.amount);
      let step = 1;
      if (item.productStep && item.productStep > 0) {
        step = parseInt(item.productStep);
      }
      const stockNum = parseInt(item.inventory);
      if (
        (inputNum > stockNum - step && inputNum < stockNum) ||
        inputNum >= stockNum
      ) {
        this.$message({
          message: "该产品最大库存为：" + stockNum,
          offset: 400,
          duration: 1000,
          type: "error",
        });
        inputNum = stockNum;
      } else if (inputNum >= 0 && inputNum <= stockNum - step) {
        inputNum = inputNum + step;
        if (inputNum % step) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
        }
      }
      item.amount = inputNum;
      this.changeShopCartItemAount(item);
    },
    changeAmount(item, e) {
      let step = 1;
      if (item.productStep && item.productStep > 0) {
        step = parseInt(item.productStep);
      }
      let inputNum = parseInt(e.target.value);
      const inventory = parseInt(item.inventory);
      // 强制改变用户的输入数量为步进数量
      if (inputNum >= inventory) {
        if (inputNum > inventory) {
          this.$message({
            message: `该产品最大购买量为:${inventory}, 已按照最大购买量添加至购物车！`,
            offset: 400,
            duration: 2000,
            type: 'success'
          })
        }
        inputNum = inventory;
      } else if (inputNum <= 0 && inputNum >= step) {
        // 输入的小于 0并且库存大于步进数量, 按照默认步进来计算
        inputNum = step;
      } else {
        if (step !== 1) {
          if (inputNum % step > 0) {
            let mulNum = (inputNum - (inputNum % step)) / step + 1;
            inputNum = mulNum * step;
            if (inputNum > inventory) {
              inputNum = step * (mulNum - 1)
            }
            this.$message({
              message: `该产品不拆零销售, 修改购物车的数量为:${inputNum}`,
              offset: 400,
              duration: 2000,
              type: 'success'
            })
          }
        }
      }
      item.amount = inputNum;
      this.changeShopCartItemAount(item);
    },
    changeShopCartItemAount(item) {
      console.log("修改购物车产品的item数据为++++", item)
      const _this = this;
      const inputNum = item.amount;
      if (inputNum > 0) {
        const addPrice = item.addPrice;
        const amount = inputNum;
        const id = item.id;
        const feeType = this.feeType;
        const productPriceStrategyId = item.productPriceStrategyId;
        const uid = item.uid;
        const actTicketData = this.actTicketData;
        const addShopData = {
          addPrice: addPrice,
          amount: amount,
          feeType: feeType,
          uid: uid,
          id: id,
        };
        if (item.salePrice && item.salePrice !== '') {
          addShopData.salePrice = item.salePrice
        }
        if (productPriceStrategyId) {
          addShopData.productPriceStrategyId = productPriceStrategyId;
        }
        const productActivityPriceStrategyId = item.productActivityPriceStrategyId;
        if (productActivityPriceStrategyId) {
          addShopData.productActivityPriceStrategyId = productActivityPriceStrategyId;
        }
        if (actTicketData && JSON.stringify(actTicketData) !== "{}") {
          addShopData.useDiscountTicketId = actTicketData.id;
        }
        // 用户选中的购物车产品数据
        const checkedCart = this.checkedCart;
        if (checkedCart.length > 0) {
          addShopData.choiceId = checkedCart
        }
        const addShopUrl = "/shopping/cart/add?getList=1";
        postAction(addShopUrl, addShopData).then((res) => {
          if (res.success && res.code === 200) {
            const resData = res.result;
            _this.$store.commit("SHOPPING_CART_LIST", resData);
            _this.renderShopCartData();
          } else {
            // 判断当前产品为液体 报错时修改数量为原先购物车数量
            item.amount = res.result
            this.$alert(res.message, "提示信息", {
              type: "error",
              dangerouslyUseHTMLString: true,
            });
          }
        });
      } else {
        this.$message({
          message: "添加产品的数量不能小于1",
          type: "warning",
          duration: 1000,
          offset: 400,
        });
      }
    },
    checkPresaleCart(type) {
      if (type == 1) {
        this.checkedCart = this.presaleCheckedCart
      } else {
        this.checkedCart = this.nonPresaleCheckedCart
      }
      let checkedIds = this.checkedCart
      this.getShoppingCartList(checkedIds);
    },
    removeBatchCart(flag) {
      let errorShoppingCartList = this.errorShoppingCartList
      let checkedCart = this.checkedCart
      if (flag == 2 && errorShoppingCartList.length === 0) {
        this.$message({
          message: "购物车暂时没有失效产品哦！",
          type: "warning",
          duration: 1000,
          offset: 400,
        });
        return false;
      } else if (flag == 1 && checkedCart.length === 0) {
        this.$message({
          message: "请勾选需要删除的产品！",
          type: "warning",
          duration: 1000,
          offset: 400,
        });
        return false;
      }
      this.deleteType = parseInt(flag);
      this.dialogVisible = true;
    },
    // 删除购物车操作
    deleteShopCart() {
      this.listOperatLoaing = true;
      this.dialogVisible = false;
      const _this = this;
      const deleteType = this.deleteType;
      const checkedCart = this.checkedCart;
      const shopCartCheckIds = this.shopCartCheckIds;
      const actTicketData = this.actTicketData;
      const errorShoppingCartList = this.errorShoppingCartList;
      const feeType = this.feeType;
      const data = [];
      if (deleteType === 1) {
        for (const key of checkedCart) {
          const itemData = {
            id: parseInt(key),
            feeType: feeType,
          };
          if (actTicketData && JSON.stringify(actTicketData) !== "{}") {
            itemData.useDiscountTicketId = actTicketData.id;
          }
          data.push(itemData);
        }
      } else if (deleteType === 2) {
        for (const key of errorShoppingCartList) {
          const itemData = {
            id: parseInt(key.id),
            feeType: feeType,
          };
          if (actTicketData && JSON.stringify(actTicketData) !== "{}") {
            itemData.useDiscountTicketId = actTicketData.id;
          }
          data.push(itemData);
        }
      }
      const url = "/shopping/cart/deleteList?getList=1";
      postAction(url, data, true).then((res) => {
        if (res.code === 200 && res.success) {
          const resData = res.result;
          if (deleteType === 1) {
            _this.$message({
              message: "已批量删除购物车!",
              offset: 400,
              duration: 1000,
              type: "success",
            });
          } else if (deleteType === 2) {
            _this.$message({
              message: "已移除失效产品!",
              offset: 400,
              duration: 1000,
              type: "success",
            });
          }

          for (let key of data) {
            if (checkedCart.indexOf(key.id) > -1) {
              let index = checkedCart.indexOf(key.id);
              checkedCart.splice(index, 1);
            }
            if (shopCartCheckIds.indexOf(key.id) > -1) {
              let index = shopCartCheckIds.indexOf(key.id);
              shopCartCheckIds.splice(index, 1);
            }
          }
          _this.checkedCart = checkedCart;
          _this.shopCartCheckIds = shopCartCheckIds;
          _this.$store.commit("SHOPPING_CART_LIST", resData);
          _this.renderShopCartData();
        }
      });
    },
    // 创建订单前的弹窗提示判断
    confirmCreateOrder() {
      let that = this;
      let createOrderAlertMessageFlag = this.createOrderAlertMessageFlag;
      if (createOrderAlertMessageFlag && createOrderAlertMessageFlag == '1') {
        let createOrderAlertMessage = this.createOrderAlertMessage;
        this.$confirm(createOrderAlertMessage, "温馨提示:", {
          type: "warning",
          confirmButtonText: '继续提交',
          cancelButtonText: '返回购物车',
        })
          .then(() => {
            that.createOrder()
          })
          .catch(() => { });
      } else {
        this.createOrder()
      }
    },
    // 创建订单
    createOrder() {
      const _this = this;
      const shoppingCartId = this.checkedCart;
      const actAddressListData = this.actAddressListData;
      const feeType = this.feeType;
      const sourceType = "1";
      let orderRemark = this.orderRemark
      const shopData = {
        shoppingCartId: shoppingCartId,
        feeType: feeType,
        sourceType: parseInt(sourceType),
        remark: orderRemark
      };
      if (actAddressListData && actAddressListData.length > 0) {
        shopData.customerAddressId = actAddressListData[0].id;
      }
      const customerDiscountTicketId = null;
      if (this.actTicketData && JSON.stringify(this.actTicketData) !== "{}") {
        if (this.actTicketData.orderActivityFlag === 1) {
          shopData.orderActivityId = this.actTicketData.orderActivityId;
        } else {
          shopData.customerDiscountTicketId =
            this.actTicketData.customerDiscountTicketId;
        }
      }
      let sendGiftIds = this.sendGiftIds;
      if (sendGiftIds.length > 0) {
        shopData.policyChoiceId = sendGiftIds
      }
      if (shoppingCartId.length > 0) {
        this.createOrderStatus = true;
        const url = "/saleOrder/saleOrder/createSaleOrder";
        console.log("创建订单传输的数据为++++++", shopData);
        postAction(url, shopData).then((res) => {
          if (res.code === 200 && res.success) {
            console.log("创建订单返回的数据为++++++", res)
            const resData = res.result;
            _this.$store.commit("SHOPPING_CART_COUNT", 0);
            // 订单的下一节点地址跳转
            _this.orderNextFlowRouter(resData.saleOrder)
          } else {
            _this.createOrderStatus = false;
            _this.$alert(res.message, "提示信息:", { type: "error", dangerouslyUseHTMLString: true });
          }
        });
      } else {
        this.createOrderStatus = false;
      }
    },
    orderNextFlowRouter(saleOrder) {
      let _this = this;
      // 获取订单id，轮询请求订单配货状态
      // 判断订单下一节点状态
      let nextFlow = saleOrder.nextFlow;
      let saleOrderId = saleOrder.id;
      let payOrderId = saleOrder.payOrderId;
      // 判断订单审核状态
      if (nextFlow && nextFlow !== '') {
        if (nextFlow === 'audit') { // 去审核
          this.$router.push({
            path: '/orderList',
            query: { state: 2 }
          })
        } else if (nextFlow === 'allot') { // 获取配货状态
          _this.$router.push({
            path: "/createOrder",
            query: { saleOrderId: saleOrderId },
          });
        } else if (nextFlow === 'confirm') { // 去确认订单
          // 创建订单成功后跳转确认订单页面
          _this.$router.push({
            name: "confirmOrder",
            query: {
              saleOrderId: saleOrderId,
            },
          });
        } else if (nextFlow === 'pay') { // 去支付订单
          // 线上支付
          _this.$router.push({
            path: "/orderPay",
            query: { payOrderId: payOrderId, saleOrderId: saleOrderId },
          });
        }
      } else {
        this.$router.push({
          path: '/orderList',
          query: { state: 3 }
        })
      }
    },
    allMoney(list) {
      const trueIndex = this.checkedCart;
      let allMoney = 0;
      for (const key of trueIndex) {
        let productMoney = 0;
        for (const key2 of list) {
          if (key === key2.id) {
            productMoney = key2.productMoney;
          }
        }
        allMoney = accAdd(productMoney, allMoney);
      }
      return allMoney;
    },
    changeTicketListStatus(actId) {
      const _this = this;
      const canUseDiscountTicketList = this.canUseDiscountTicketList;
      for (const key of canUseDiscountTicketList) {
        if (key.id === actId) {
          _this.selectTicketData = key;
          const checkedCart = this.checkedCart;
          _this.getShoppingCartList(checkedCart);
        }
      }
    },
    cleanTime(time) {
      const timeArr = time.split(" ");
      return timeArr[0];
    },
    // 选择使用的优惠券的数据
    selectTicket(item) {
      this.selectTicketData = item;
      this.ticketShowState = false;
      this.showTicketListStatus = false;
      let checkedCart = this.checkedCart;
      this.getShoppingCartList(checkedCart);
    },
  },
  computed: {
    ...mapGetters(["shoppingCartList"]),
    // 计算勾选赠品的金额
    shopCartAllMoneyAll() {
      let shopCartAllMoney = this.shopCartAllMoney;
      let sendGiftIds = this.sendGiftIds;
      let shopCardSendGiftItems = this.shopCardSendGiftItems;
      let notCheckGiftIds = []
      let notGiftMoney = 0
      for (let key of shopCardSendGiftItems) {
        if (sendGiftIds.indexOf(key.id) < 0) {
          notCheckGiftIds.push(key.id)
          notGiftMoney = accAdd(notGiftMoney, key.sendNeedMoney)
        }
      }
      console.log("当前取消的礼品id为++++++", notCheckGiftIds)
      console.log("需要扣除的总金额++++++", notGiftMoney)
      console.log("shopCartAllMoney++++++", shopCartAllMoney)
      return accAdd(shopCartAllMoney, -notGiftMoney);
    },
    satisfyMoney() {
      const canShoppingCartList = this.canShoppingCartList;
      const allMoney = this.allMoney(canShoppingCartList);
      const notLogisticsMoney = this.notLogisticsMoney ? this.notLogisticsMoney : 0;
      return accAdd(notLogisticsMoney, -allMoney);
    },
  },
  unmounted() {
    clearInterval(this.orderStatus);
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/shoppingCart/shoppingCart";
</style>