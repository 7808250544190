// getters类似于计算属性，是对store中state的一些派生状态
const getters = {
  token: state => state.user.token, // 用户token
  userInfo: state => state.user.info, // 用户基础数据
  loginState: state => state.user.loginState, // 用户登录状态
  salemanToken: state => state.user.salemanToken, // 业务员token
  errorData: state => state.user.errorData, // 登录页面错误提示信息
  shoppingCartList: state => state.shoppingCart.shoppingCartList, // 客户购物车列表
  shoppingCartCount: state => state.shoppingCart.shoppingCartCount, // 客户购物车数量
  collectUploadStatus: state => state.shoppingCart.collectUploadStatus, // 客户修改收藏夹数量
  pendingOrderCount: state => state.order.pendingOrderCount, // 客户待确认订单熟练
  productCategoryList: state => state.common.productCategoryList, //产品分类的数据
  navMenuList: state => state.common.navMenuList, // 网站导航栏数据
  companyCredentialsList: state => state.common.companyCredentialsList, // 公司的资质信息列表
  companyNoticeList: state => state.common.companyNoticeList, // 公司的资质信息列表
  advertTopData: state => state.common.advertTopData, // 顶部广告位数据
  unreadMsgNumAll: state => state.common.unreadMsgNumAll, // 未读的消息数据
  productShowType: state => state.common.productShowType, // 默认的产品列表展示类型
  companyConfigData: state =>  state.common.companyConfigData, // 公司的基础配置数据
}

export default getters
