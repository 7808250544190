<template>
  <div class="messageContent">
    <div class="headerTitle">
      <div class="tabbarList">
        <div class="tabbarItem" v-for="(item, index) of typeList" :key="index">
          <p @click="selectMsgIndex(index)" :class="{ active: msgCategory == index + 2 }" class="tabbarText">
            {{ item }}
            <span class="noreadNum" v-if="unreadNumber(index) > 0">{{
                unreadNumber(index)
            }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="msgContent">
      <div v-if="messageList.length > 0" class="msgList">
        <div class="magItem" v-for="(item, index) of messageList" :key="'msgItem' + index">
          <div v-if="item.readFlag == 0" class="unreadMsg">
            <div class="detail">
              <span class="status unread">未读</span>
              <p class="msg">{{ item.titile }}</p>
              <span @click="lookDetail(item)" class="lookDetail">查看详情</span>
            </div>
          </div>
          <div v-else-if="item.readFlag == 1" class="readMsg">
            <div class="detail">
              <span class="status">已读</span>
              <p class="msg">{{ item.titile }}</p>
              <span @click="deleteMsg(item, index)" class="delete">删除</span>
            </div>
            <div class="adminBack">
              <p>{{ item.msgContent }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="dataNone">
        <div class="dataImg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { deleteAction, getAction } from '../api/manage';
export default {
  data() {
    return {
      typeList: ["系统消息", "求购信息", "反馈信息"],
      messageList: [],
      msgCategory: 2,
      pageNo: 1,
      pageSize: 20,
      pages: 0
    };
  },
  created() {
    this.getMessageList()
  },
  methods: {
    ...mapGetters(["unreadMsgNumAll"]),
    async getMessageList() {
      let url = "customer/customerAnnouncement/listByType"
      let msgCategory = this.msgCategory
      let pageNo = this.pageNo
      let pageSize = this.pageSize
      let data = {
        msgCategory: msgCategory,
        pageNo: pageNo,
        pageSize: pageSize,
      }
      let res = await getAction(url, data);
      if (res.code == 200 && res.success) {
        let resData = res.result;
        console.log("返回的消息列表为+++++++", resData)
        this.messageList = resData.records;
        this.pages = resData.pages

      }
      console.log("获取消息列表的数据为+++++", res)
    },
    unreadNumber(index) {
      let unreadMsgNumAll = this.unreadMsgNumAll()
      return unreadMsgNumAll[index + 2];
    },
    selectMsgIndex(index) {
      this.msgCategory = index + 2;
      this.pageNo = 1
      this.getMessageList();
    },
    async lookDetail(item) {
      let url = "customer/customerAnnouncement/readByid";
      let data = {
        id: item.id
      }
      let res = await getAction(url, data);
      if (res.code == 200 && res.success) {
        item.readFlag = 1;
        this.getCustomerAnnouncement()
      }
    },
    // 获取客户系统通知消息
    async getCustomerAnnouncement() {
      let url = "customer/customerAnnouncement/count"
      let res = await getAction(url);
      if (res.code == 200 && res.success) {
        this.$store.commit("UNREAD_MSG_NUM_ALL", res.result);
      }
    },
    async deleteMsg(item, index) {
      let url = "customer/customerAnnouncement/delete"
      let data = {
        id: item.id
      }
      let res = await deleteAction(url, data)
      if (res.code == 200 && res.success) {
        this.messageList.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/center/messageList";
</style>