<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getUserInfo } from '../assets/js/common/common';
export default {
  name: 'Groceries',
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  created() {
  }
}
</script>

<style>

</style>