<template>
  <div class="prize-content">
    <div class="prize-box">
      <div class="prize-title-box">
        <div class="left-img"></div>
        <span>活动规则</span>
        <div class="right-img"></div>
      </div>
      <div class="rule-content">
        <div class="act-time">活动时间：
          <span>{{ activityData.startTime }} <i>至</i> {{ activityData.endTime }}</span>
        </div>
        <div class="act-introduce">
          活动规则：<span>{{ activityData.introduce }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivityRules",
  props: {
    activityData: {
      type: Object
    }
  },
}
</script>

<style lang="scss" scoped>
.prize-content {
  width: 1190px;
  margin: 20px auto;
  margin-bottom: 0;
  text-align: center;

  .prize-box {
    max-width: 1190px;
    min-width: 600px;
    margin: 0 auto;
    display: inline-block;
    // min-height: 480px;
    padding-bottom: 50px;
    padding: 40px;
    padding-bottom: 60px;
    background: #FAE3DD;
    border-radius: 30px;

    .prize-title-box {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      span {
        font-size: 32px;
        letter-spacing: 3px;
        text-indent: 3px;
         font-family:  'SourceHanSansSC', 'Microsoft YaHei',sans-serif;
        font-weight: 500;
        color: #CA321A;
        line-height: 36px;
        margin: 0 15px;
        padding-bottom: 4px;
      }

      .left-img,
      .right-img {
        width: 55px;
        height: 44px;
        background: url("../../assets/images/activity/title-left-icon.png") no-repeat center;

        &.right-img {
          transform: rotateY(180deg);
        }
      }
    }

    .rule-content {
      padding-top: 50px;

      .act-time {
        text-align: left;
        color: #585858;
        font-size: 17px;
        font-weight: bold;

        span {
          font-size: 17px;
          color: #CA321A;
          i {
            font-style: normal;
          }
        }
      }

      .act-introduce {
        text-align: left;
        margin-top: 30px;
        font-size: 17px;
        color: #585858;
        font-weight: bold;

        span {
          font-size: 17px;
          color: #CA321A;
        }
      }
    }
  }
}</style>