var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"areaContainer",style:(_vm.brandDetail &&
    _vm.brandDetail.backgroundColor &&
    _vm.brandDetail.backgroundColor !== ''
    ? `background-color:${_vm.brandDetail.backgroundColor}`
    : '')},[(_vm.brandDetail && _vm.brandDetail.picUrl && _vm.brandDetail.picUrl !== '')?_c('div',{staticClass:"areaBanner container"},[_c('img',{attrs:{"src":_vm.brandDetail.picUrl,"alt":"brandDetail.name"}})]):_vm._e(),_c('div',{staticClass:"activityBox container_box"},[(_vm.ticketDataList.length > 0)?_c('div',{staticClass:"ticketListContent activityItem",attrs:{"id":"yhqNav"}},[_c('div',{staticClass:"ticketListMain"},_vm._l((_vm.ticketDataList),function(item,index){return _c('div',{key:'Rightcart' + index,staticClass:"ticketDataBox"},[_c('div',{staticClass:"ticketContent",class:{
            productTicket: item.astrictProductFlag ==
              1
          }},[_c('div',{staticClass:"ticketTop"},[(item.ticketType == 1)?_c('div',{staticClass:"price"},[_c('p',{staticClass:"typeText"},[_vm._v("￥")]),_c('p',{staticClass:"money"},[_vm._v(_vm._s(item.ticketDiscountMoney))])]):(item.ticketType == 2)?_c('div',{staticClass:"price"},[_c('p',{staticClass:"money"},[_vm._v(_vm._s(parseFloat((item.ticketDiscountMoney * 10).toPrecision(12))))]),_c('p',{staticClass:"typeText"},[_vm._v("折")])]):_vm._e(),_c('div',{staticClass:"ticketName"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.ticketName))])]),_c('div',{staticClass:"ticketTime"},[_c('p',[_vm._v("有效期限："+_vm._s(_vm.timeClear(item.startTime))+"-"+_vm._s(_vm.timeClear(item.endTime)))])])]),_c('div',{staticClass:"ticketBottom"},[_c('div',{staticClass:"ticketDetail"},[_c('p',{staticClass:"detail"},[_c('span',[_vm._v("适用范围:")]),_vm._v(_vm._s(item.ticketDetail))])]),_c('div',{staticClass:"ticketBtnBox"},[(item.ticketReceiveStatus == 1)?_c('a',{staticClass:"goUseBtn",class:{ goUseBtn2: item.astrictProductFlag !== '0' },attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.useTicket(item)}}},[_vm._v("去使用")]):(item.ticketReceiveStatus == 0)?_c('a',{staticClass:"ticketOverBtn",attrs:{"href":"javascript:;"}},[_vm._v("已领完")]):_c('a',{staticClass:"lingquBtn",class:{ lingquBtn2: item.astrictProductFlag !== '0' },attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.lingquTicket(item)}}},[_vm._v("领取")])])])])])}),0)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }