<template>
  <div class="OrderRightBox">
    <div class="orderList">
      <table v-loading="orderLoading" class="orderListTitle">
        <thead>
          <tr class="tableHeader">
            <th class="ddbh">订单编号</th>
            <th class="sqsj">申请时间</th>
            <th class="tklx">退款类型</th>
            <th class="tkje">退款金额</th>
            <th class="tkzt">退款状态</th>
            <th class="sqyy">申请原因</th>
            <th class="shbz">审核备注</th>
          </tr>
        </thead>
        <tbody v-if="orderList.length > 0">
          <tr class="tableList" v-for="(item, index) of orderList" :key="item.id + '_' + index">
            <td class="ddbh">
              <router-link :to="{
                name: 'applyReturn',
                query: {
                  refundId: item.id,
                  type: 2
                }
              }">
                {{ item.saleOrderId }}
              </router-link>
            </td>
            <td>
              <p class="sqsj">{{ item.createTime }}</p>
            </td>
            <td>
              <p class="tklx">{{ refundType(item.type) }}</p>
            </td>
            <td>
              <p class="tkje">￥{{ item.money }}</p>
            </td>
            <td>
              <p :class="auditStatusClass" class="tkzt">{{ auditStatus(item.auditStatus) }}</p>
            </td>
            <td>
              <p class="sqyy" :title="item.remark">{{ item.remark }}</p>
            </td>
            <td>
              <p class="shbz" :title="item.auditRemark">{{ item.auditRemark ? item.auditRemark : '' }}</p>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <div class="dataNone">
            <div class="dataImg orderNoneImg"></div>
          </div>
        </tbody>
      </table>
    </div>

    <div class="pageFooter">
      <el-pagination background :hide-on-single-page="true" v-if="totalCount > 0" layout="prev, pager, next, jumper"
        :current-page="pageNo" @current-change="currentChange" :page-size="pageSize" :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAction } from "../api/manage";

export default {
  name: "OrderRight",
  data() {
    return {
      pageNo: 1,
      pages: 20,
      pageSize: 10,
      totalCount: 100,
      searchVal: "",
      orderDate: "",
      orderState: 0,
      orderList: [],
      orderLoading: false,
    };
  },
  created() {
    this.getOrderListData();
  },
  methods: {
    refundType(type) {
      if (type === 2) {
        return '货品退款'
      } else if (type === 3) {
        return '运费退款'
      }
    },
    auditStatusClass(auditStatus) {
      if (auditStatus === 2) {
        return 'yitongguo'
      } else if (auditStatus === 3) {
        return 'thtd'
      }
    },
    auditStatus(auditStatus) {
      if (auditStatus === 1) {
        return '待审批'
      } else if (auditStatus === 2) {
        return '已通过'
      } else if (auditStatus === 3) {
        return '已驳回'
      }
    },
    changeOrderStatus(num) {
      this.$emit("changeOrderStatus", num);
    },
    searchOrder() {
      this.pageNo = 1;
      this.getOrderListData();
    },
    async getOrderListData() {
      this.orderLoading = true;
      let state = this.orderState;
      // 订单编号首尾去空格(防止用户复制粘贴多复制了空白);
      let searchVal = this.searchVal;
      searchVal = searchVal.replace(/(^\s*)|(\s*$)/g, "");
      // 分页数据，订单状态
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let orderStatus = parseInt(state) - 1;
      let data = {
        pageNo: pageNo,
        pageSize: pageSize,
        orderStatus: orderStatus,
        searchVal: searchVal,
      };
      let url = "saleOrder/saleReturnOrder/list";
      let res = await getAction(url, data);
      if (res.code == 200 && res.success) {
        this.orderLoading = false;
        let resData = res.result;
        console.log("返回的退货申请列表为++++++", resData)
        this.orderList = resData.records;
        this.pageNo = resData.current;
        this.totalCount = resData.total;
      }
    },
    // 切换页数
    currentChange(val) {
      this.pageNo = val;
      this.getOrderListData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/order/right";

.pageFooter {
  position: absolute;
  bottom: 0;
  right: 0;
}

.sqsj {
  width: 150px;
}

.tklx {
  width: 120px;
}

.tkje {
  width: 120px;
}

.tkzt {
  width: 120px;
}

.sqyy,
.shbz {
  width: 140px;
  @include ellipsis;
}

.dataNone {
  position: absolute;
  width: 930px;
}

.thtd {
  color: $act-color !important;
}

.tktd {
  color: $base-color !important;
}

.yitongguo {
  color: #2CA72C;
}
</style>
