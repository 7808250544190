<template>
  <div class="flash-sale-zone">
    <div class="banner">
      <img src="../../assets/images/activity/combination-sale/banner.png" alt="秒杀专区">
    </div>
    <div class="container">
      <div class="activity-header">
        <div class="flash-time-header clear">
          <div v-for="(item, index) of tabbarList" class="time-tab fl" :key="index">
            <p :class="{ act: actIndex === item.value }" @click="selectTab(item)">{{ item.label }}</p>
            <span v-if="index !== tabbarList.length - 1" class="hr"></span>
          </div>
        </div>
      </div>
      <div class="activity-goods-list">
        <div class="good-list-box">
          <promotion-goods v-if="actIndex === '1'"></promotion-goods>
          <combination-goods v-else-if="actIndex === '2'"></combination-goods>
          <zone-promotion-goods v-else-if="actIndex === '3'"></zone-promotion-goods>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CombinationGoods from '../../components/product/CombinationGoods.vue';
import ZonePromotionGoods from '../../components/product/ZonePromotionGoods.vue';
import PromotionGoods from '../../components/product/PromotionGoods.vue';
export default {
  components: { PromotionGoods, CombinationGoods, ZonePromotionGoods },
  name: 'CombinationSale',
  data() {
    return {
      tabbarList: [
        { label: '单品促销', value: '1' },
        { label: '组合销售', value: '2' },
        { label: '专区促销', value: '3' },
      ],
      actIndex: '2'
    }
  },
  methods: {
    selectTab(item) {
      this.actIndex = item.value;
    }
  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>
.flash-sale-zone {
  background-color: #fe5737;

  .banner {
    min-width: 1190px;

    // border-top: 3px solid #fe5737;
    img {
      min-width: 1190px;
      max-width: 100%;
      height: auto;
    }
  }

  .container {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;

    .activity-header {
      width: 1200px;
      height: 60px;
      margin: 10px auto;
      background: #ffffff;

      .flash-time-header {
        width: 1200px;
        float: left;
        height: 60px;
        overflow: hidden;

        .clear {
          &:after {
            display: block;
            content: '';
            clear: both;
          }
        }

        .time-tab {
          float: left;
          font-size: 17px;
          font-weight: bold;
          width: 400px;
          height: 60px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          cursor: pointer;
          position: relative;

          p {
            width: 398px;
            height: 100%;
            text-align: center;
            line-height: 60px;

            &:first-child,
            &:last-child {
              width: 400px;
            }

            &.act {
              background-color: #E10606;
              color: #ffffff;


              &:hover {
                background: #E1060660;
              }

              &::after {
                background-color: #E10606;
              }

              &.status {
                border: 1px solid #E10606;
                background-color: #ffffff;
                color: #E10606;
              }
            }
          }
        }

        &:last-child {}

        p {
          font-size: 18px;
        }

        .status {
          display: inline-block;
          height: 30px;
          line-height: 28px;
          border-radius: 10px;
          font-size: 17px;
          background-color: #ffffff;
          color: #585858;
          padding: 0 10px;
          border: 1px solid #DCDCDC;
        }

        .hr {
          display: inline-block;
          width: 2px;
          height: 40px;
          background: #DCDCDC;
        }
      }

      .more {
        float: left;
        width: 70px;
        height: 60px;
        line-height: 60px;
        color: #E10606;
        text-align: center;
        padding-left: 10px;
        box-shadow: -8px 0 8px #58585850;
        cursor: pointer;

        &:hover {
          opacity: .6;
        }

        p {
          font-size: 16px;

          .right-icon {
            margin-bottom: -3px;
            display: inline-block;
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-left: 8px solid #E10606;
          }
        }
      }
    }
  }
}</style>