<template>
  <div v-if="flashSaleList.length > 0 && flashSaleDetail.nowStatus" class="flash-floor-content floorNavClass">
    <div @click="linkFlashSale" class="count-down">
      <p class="tips">距离本场结束还剩</p>
      <div class="time">
        <div class="timebox">
          <span>{{ flashSaleDetail.suH ? flashSaleDetail.suH : '00' }}</span>
          <span>{{ flashSaleDetail.suF ? flashSaleDetail.suF : '00' }}</span>
          <span>{{ flashSaleDetail.suM ? flashSaleDetail.suM : '00' }}</span>
        </div>
      </div>
      <!-- <router-link :to="{ name: 'secshaZone', query: { id: flashSaleDetail.id } }" target="_blank" class="add-more">
        查看更多
      </router-link> -->
    </div>
    <el-carousel :interval="8000" indicator-position="none" trigger="click" width="990px" height="270px">
      <el-carousel-item v-for="(item, index) of sliceArrs(flashSaleList, 5)" :key="'xsqgItem' + index">
        <router-link class="goods-content" v-for="(itemAC, indexAC) of item" :key="'xsqgProduct' + itemAC.id + indexAC"
          target="_blank" :to="{ name: 'productDetail', query: { productId: itemAC.productId } }">
          <div class="pic-img-box">
            <!-- 查看专区产品遮罩图 -->
            <img v-if="flashSaleDetail.productBackgroundPic && flashSaleDetail.productBackgroundPic !== ''"
              :src="flashSaleDetail.productBackgroundPic" class="mask-img" alt="产品遮罩图" />
            <!-- 查看产品标签遮罩图 -->
            <img v-else-if="item.productLabelList && item.productLabelList.length > 0
              && prolabelmask - img(item.productLabelList) !== ''
              " :src="prolabelmask - img(item.productLabelList)" class="mask-img" alt="产品遮罩图" />

            <img v-lazy="productImg(itemAC)" alt="产品图片" />
          </div>
          <p class="goods-data">
            {{ itemAC.productName }}{{ itemAC.productFormat }}
          </p>
          <div class="price">
            <p class="msjg">￥<span>{{ itemAC.price }}</span></p>
            <p class="btn">抢购</p>
          </div>
        </router-link>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    flashSaleDetail: {
      type: Object,
      default: function () {
        return {}
      }
    },
    flashSaleList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    // 跳转秒杀专区
    linkFlashSale() {
      let flashSaleDetail = this.flashSaleDetail;
      let text = this.$router.resolve({ name: 'secshaZone', query: { id: flashSaleDetail.id } });
      window.open(text.href, '_blank');
    },
    // 显示产品图片
    productImg(item) {
      if (item.productPicUrl && item.productPicUrl !== "") {
        let imgsArr = item.productPicUrl.split(",");
        let imgSrc = imgsArr[0];
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },
    // 轮播产品楼层样式
    floorStyle(item) {
      let data = item.floorDetailList;
      if (data.length > 5) {
        let oldArrs = this.sliceArrs(data, 5);
        let oldL = oldArrs.length;
        let Fwidth = (oldL + 2) * 1190;
        let initialL = -1190;
        let style = {
          width: Fwidth + "px",
          left: initialL + "px",
        };
        return style;
      }
    },
    // 均分数组
    sliceArrs(array, size) {
      var result = [];
      for (var x = 0; x < Math.ceil(array.length / size); x++) {
        var start = x * size;
        var end = start + size;
        result.push(array.slice(start, end));
      }
      return result;
    },
  }
}
</script>

<style lang="scss" scoped>
.flash-floor-content {
  margin: 30px 0;
  @include clear;

  ::v-deep .el-carousel__arrow {
    width: 20px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #fe4105;
    border-radius: 0;
  }

  .count-down {
    position: relative;
    cursor: pointer;
    float: left;
    width: 190px;
    height: 270px;
    text-align: center;
    background: url("~@/assets/images/test/flash-kill-bg.png") no-repeat center;
    background-size: 100% 100%;

    .tips {
      font-size: 17px;
      font-weight: bold;
      color: #333333;
      width: 100%;
      text-align: center;
      margin-top: 160px;
      letter-spacing: 2px;
      text-indent: 2px;
    }

    .time {
      margin-top: 12px;
      margin-bottom: 10px;
      width: 100%;
      text-align: center;

      span {
        display: inline-block;
        width: 35px;
        height: 35px;
        font-size: 20px;
        line-height: 35px;
        font-weight: bold;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(90deg, #FE4203 0%, #FF1150 100%);
        color: #ffffff;
        margin: 0 10px;
        position: relative;

        &:after {
          position: absolute;
          right: -13px;
          top: 0;
          content: ":";
          display: block;
          font-weight: bold;
          color: #fd2c17;
        }

        &:last-child::after {
          display: none;
          content: "";
        }
      }
    }

    .add-more {
      display: inline-block;
      font-size: 13px;
      color: #ffffff;
      letter-spacing: 2px;
      text-indent: 2px;
    }
  }

  .goods-content {
    display: block;
    float: left;
    width: 200px;
    height: 270px;
    padding: 7px;
    margin-left: -1px;
    border: 1px solid $hr-color;

    &:first-child {
      margin-left: 0;
    }

    .pic-img-box {
      width: 185px;
      height: 185px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .mask-img {
        width: 185px;
        height: 185px;
        z-index: 2;
        padding: 0;
      }

      img {
        position: absolute;
        width: auto;
        height: auto;
        padding: 10px;
        max-width: 185px;
        max-height: 185px;
      }
    }

    .goods-data {
      width: 100%;
      @include ellipsis;
      font-size: 17px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      color: #585858;
      font-weight: 600;
      margin-top: 5px;

      padding: 0 5px;
    }

    .price {
      margin-top: 7px;
      color: #FE4105;
      display: flex;
      height: 30px;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px;

      p {
        float: left;
        width: 73px;
        height: 22px;
        line-height: 20px;
      }

      .msjg {
        font-size: 14px;
        color: #FE4105;

        span {
          font-size: 20px;
          font-weight: 600;
        }
      }

      .btn {
        width: 72px;
        height: 30px;
        background: linear-gradient(90deg, #FE4203 0%, #FF1150 100%);
        border-radius: 6px;
        font-size: 17px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
      }

      .spyj {
        color: $font-color;
        text-decoration: line-through;
      }
    }

  }
}
</style>