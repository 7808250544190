<template>
    <order-right></order-right>
</template>

<script>
import OrderRight from '../components/order/orderRight';
export default {
  name: "OrderList",
  components: { OrderRight },
  data () {
    return {
      selectIndex: 1
    }
  }
};
</script>

<style lang="scss" scoped>
</style>