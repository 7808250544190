<template>
  <div class="fastShoppingContent container jfShop clearfix">
    <div class="container_box">
      <div class="navList">
        <router-link to="/index">首页</router-link>
        <router-link class="active" to="/fastShopping">极速下单</router-link>
      </div>
      <div class="shoppingTable">
        <div class="searchTop">
          <div class="searchInputBox">
            <img class="searchIcon" src="../assets/images/fastShopping/searchIcon.png" alt="" />
            <input placeholder="请输入商品名称、首字母、生产厂家进行检索" class="searchVal" ref="searchVal" v-model="searchVal"
              @keyup="searchProduct" @keyup.enter="searchProduct" type="text" />

            <img v-show="searchVal !== ''" class="clearIcon" @click="keyupCancelOrder"
              src="../assets/images/fastShopping/clearIcon.png" alt="" />
            <div class="searchBtn">
              <el-select class="searchBox" v-model="searchType" placeholder="请选择">
                <el-option v-for="item in searchOptions" :key="item.value" style="text-align: center"
                  :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div v-show="showProductState && searchType == 1" class="searchProductBox">
              <div class="scrollBox">
                <table id="searchProductList">
                  <tr id="thtr" class="thtr">
                    <th class="fxk"></th>
                    <th class="name">商品名称</th>
                    <th class="format">规格</th>
                    <th class="unit">单位</th>
                    <th class="manufacturer">厂家</th>
                    <th class="endDate">有效期</th>
                    <th class="price">单价</th>
                    <th class="inventory">库存</th>
                    <th class="amount">数量</th>
                  </tr>
                  <tbody class="tbody">
                    <el-scrollbar ref="scrollbar" class="scrollTableBody">
                      <tr v-for="(item, index) of productList" :class="{ active: index == selectProIndex }"
                        :key="'tableItem' + index" @click="selectHandlePro(index)">
                        <td class="fxk">
                          <el-checkbox @change="selectHandlePro(index)" :value="
                            selectProIds.indexOf(item.id) > -1 ? true : false
                          "></el-checkbox>
                        </td>
                        <td class="name">{{ item.name }}</td>
                        <td class="format" :title="item.format ? item.format : '暂无规格信息'">{{ item.format }}</td>
                        <td class="unit">{{ item.unit }}</td>
                        <td class="manufacturer" :title="item.manufacturer">
                          {{ item.manufacturer }}
                        </td>
                        <td class="endDate">
                          {{
                            showProductEndDate(item)
                          }}
                        </td>

                        <td class="price" style="color: #fd2c17">
                          {{ showPrice(item) }}
                        </td>
                        <td class="inventory">
                          {{
                            item.inventoryNum > 0
                              ? item.inventoryNum > 999
                                ? "充裕"
                                : item.inventoryNum
                              : "无货"
                          }}
                        </td>
                        <td class="amount">
                          <div v-if="item.inventoryList && item.inventoryList.length > 0" class="changeInput">
                            <input :class="{
                              shoppingHas:
                                item.inventoryList[0].shoppingCartAmount &&
                                item.inventoryList[0].shoppingCartAmount > 0,
                            }" :value="
item.inventoryList[0].addCartQuantity" @change="
    changeNum(item.inventoryList[0], item, $event)
  " oninput="value=value.replace(/[^0-9.]/g,'')" ref="proInputNum" type="text" />
                          </div>
                        </td>
                      </tr>
                      <tr class="loadingBottom" v-if="loading">
                        加载中...
                      </tr>
                      <tr class="loadingBottom" v-if="noMore">
                        没有更多了
                      </tr>
                    </el-scrollbar>
                  </tbody>
                </table>
              </div>
              <div class="tableActionBox">
                <button @click="keyupCancelOrder" class="cancelBtn">
                  取消(ESC)
                </button>
                <button @click="keyupEnter" class="confirmBtn">
                  确认(Enter)
                </button>
              </div>
              <div class="searchButtom">
                <p>
                  简易操作说明：按方向键控制浏览
                  <span class="el-icon el-icon-top"></span>或
                  <span class="el-icon el-icon-bottom"></span>,
                  点击<span>S</span>选中商品，输入数量，点击<span>C</span>取消选中，点击<span>Enter</span>将选中的商品加入清单
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="screen clearfix">
          <div class="screenUrl">
            <span @click="changScreenType(1)" :class="{ active: screenType == 1 }">历史采购</span>
            <span @click="changScreenType(2)" :class="{ active: screenType == 2 }">收藏夹</span>
          </div>
          <div v-if="screenType == 1" class="screenBox">
            <div class="screenTime">
              <p>采购时间：</p>
              <div class="labelContent">
                <el-select size="mini" class="selectBox" @change="reloadListData" v-model="screenTimeType"
                  placeholder="请选择">
                  <el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value"
                    style="text-align: center">
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="screenContent">
              <p>排序方式：</p>
              <div class="labelContent">
                <el-select size="mini" class="selectBox" @change="reloadListData" v-model="screenStatusType"
                  placeholder="请选择">
                  <el-option v-for="item in itemOptions" :key="item.value" :label="item.label" :value="item.value"
                    style="text-align: center">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="scrollBox2">
          <table id="fastTableContent" v-loading="listOperatLoaing">
            <tr class="thtr">
              <th class="name">商品名称</th>
              <th class="format">规格</th>
              <th class="unit">单位</th>
              <th class="manufacturer th">厂家</th>
              <th class="endDate">有效期</th>
              <th class="cgzl">
                {{ screenType == 1 ? (screenStatusType == 1 ? "采购总量" : "采购频次") : "中/大包装" }}</th>
              <th class="endDate">
                {{ screenType == 1 ? "上次采购时间" : "预计到货时间" }}
              </th>
              <th class="price">单价</th>
              <th class="kucun">库存</th>
              <th class="action th">
                <div @click="scrollGotoTop" class="header">
                  <p>回到顶部</p>
                  <img src="../assets/images/fastShopping/gotopIcon.png" alt="" />
                </div>
              </th>
            </tr>

            <tbody class="tbody2">
              <el-scrollbar ref="scrollbar2" class="scrollTableBody2">
                <tr v-for="(item, index) of listData" :key="'tableItem' + index">
                  <td class="name listName">

                    <router-link v-if="screenType == 1" :to="{
                      path: '/productDetail',
                      query: { productId: item.productId },
                    }" :title="item.productName" target="_blank">
                      {{ item.productName }}
                    </router-link>
                    <router-link v-else :to="{
                      path: '/productDetail',
                      query: { productId: item.productId },
                    }" :title="item.productName" target="_blank">
                      {{ item.name }}
                    </router-link>
                  </td>
                  <td class="format" :title="item.format ? item.format : '暂无规格信息'">{{ item.format }}</td>
                  <td class="unit">{{ item.unit }}</td>
                  <td class="manufacturer" :title="item.manufacturer">{{ item.manufacturer }}</td>
                  <td v-if="screenType == 1" class="endDate">
                    {{
                      showProductEndDate(item.productVO)
                    }}
                  </td>
                  <td v-else-if="screenType == 2" class="endDate">
                    {{
                      showProductEndDate(item)
                    }}
                  </td>
                  <td v-if="screenType == 1 && screenStatusType == '1'" class="cgzl">
                    {{ item.totalPurchases ? item.totalPurchases : "0" }}
                  </td>

                  <td v-else-if="screenType == 1 && screenStatusType == '2'" class="cgzl">
                    {{ item.orderCount ? item.orderCount : "0" }}
                  </td>
                  <td v-else class="cgzl">
                    {{ item.mediumPkgSize }}/{{ item.bigPkgSize }}
                  </td>

                  <td v-if="screenType == 1" class="endDate">
                    {{
                      item.lastTime
                        ? item.lastTime.substring(0, 10)
                        : "2021-03-29"
                    }}
                  </td>
                  <td v-else-if="screenType == 2" class="endDate">
                    {{
                    (item.inventoryList && item.inventoryList.length > 0 && item.inventoryList[0].deliveryTime)
  ? item.inventoryList[0].deliveryTime
  : "24H"
                    }}
                  </td>
                  <td v-if="screenType == 1" class="price">
                    ￥ {{ item.price ? item.price : "--/--" }}
                  </td>
                  <td v-else-if="screenType == 2" class="price">
                    {{ showPrice(item) }}
                  </td>

                  <td v-if="screenType == 1" class="kucun">
                    {{
                      item.productVO && item.productVO.inventoryNum > 0
                        ? item.productVO.inventoryNum > 999
                          ? "充裕"
                          : item.productVO.inventoryNum
                        : "无货"
                    }}</td>
                  <td v-else-if="screenType == 2" class="kucun">
                    {{ showInventoryText(item.inventoryNum, item) }}
                  </td>
                  <td v-if="screenType == 2" class="action">
                    <div v-if="item.inventoryList && item.inventoryList.length > 0" class="listAction">
                      <div class="changeInput">
                        <span @click="lessNum(item.inventoryList[0], item)">-</span>
                        <input :class="{
                          shoppingHas:
                            item.inventoryList[0].shoppingCartAmount &&
                            item.inventoryList[0].shoppingCartAmount > 0,
                        }" :value="item.inventoryList[0].addCartQuantity" @focus="selectShopAmount($event)"
                          @keyup.enter="
                            jionShopCart(item.inventoryList[0], item, $event)
                          " @change="
  changeNum(item.inventoryList[0], item, $event)
" oninput="value=value.replace(/[^0-9.]/g,'')" type="text" />
                        <span @click="addNum(item.inventoryList[0], item)">+</span>
                      </div>
                      <img @click="
                        jionShopCart(item.inventoryList[0], item, $event)
                      " src="../assets/images/product/list/jionShop.png" alt="" />
                    </div>
                  </td>

                  <td v-if="screenType == 1" class="action">

                    <div v-if="item.productVO" class="listAction">
                      <div class="changeInput">
                        <span @click="lessNum(item.productVO.inventoryList[0], item.productVO)">-</span>
                        <input :class="{
                          shoppingHas:
                            item.productVO.inventoryList[0].shoppingCartAmount &&
                            item.productVO.inventoryList[0].shoppingCartAmount > 0,
                        }" :value="
item.productVO.inventoryList[0].addCartQuantity" @focus="selectShopAmount($event)" @keyup.enter="
    jionShopCart(item.productVO.inventoryList[0], item.productVO, $event)
  " @change="
  changeNum(item.productVO.inventoryList[0], item.productVO, $event)
" oninput="value=value.replace(/[^0-9.]/g,'')" type="text" />
                        <span @click="addNum(item.productVO.inventoryList[0], item.productVO)">+</span>
                      </div>
                      <img @click="
                        jionShopCart(item.productVO.inventoryList[0], item.productVO, $event)
                      " src="../assets/images/product/list/jionShop.png" alt="" />
                    </div>
                  </td>
                </tr>
                <tr class="loadingBottom" v-if="loading2">
                  加载中...
                </tr>
                <tr class="loadingBottom" v-if="noMore2">
                  没有更多了
                </tr>
              </el-scrollbar>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="25%" height="15vh">
      <span>确定批量删除购物车?</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="hadleOk" @click="deleteShopCart">确定</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getAction, postAction } from "../api/manage";
import { ProductMixin } from "@/mixins/ProductMixin";
export default {
  name: "BestShopping",
  mixins: [ProductMixin],
  data() {
    return {
      isIndeterminate: false,
      checkStatus: false,
      showProductState: false,
      createOrderStatus: false,
      productList: [],
      actTicketData: {},
      shopCartCheckAll: true, // 清单全选状态操作
      shoppingCartList: [], // 用户添加快速下单的产品列表数据
      checkedCart: [], // 选中创建订单的产品id数组
      shopCartCheckIds: [], // 清单列表所有产品id的数组
      selectProIds: [], // 选中的产品id数组
      selectProList: [], // 选中的产品数据数组
      selectProIndex: -1,
      dialogVisible: false,
      listOperatLoaing: true,
      searchVal: "",
      screenType: 1, // 筛选条件 1.历史采购  2是收藏夹
      productPage: {
        pageNo: 1, // 当前页数
        pages: 1, // 总页数
        pageSize: 2000, // 每页显示的条数
        total: 0,
      },
      screenTimeType: "1", // 时间段类型
      screenStatusType: "1", // 筛选状态类型
      timeOptions: [
        { label: "一个月内", value: "1" },
        { label: "二个月内", value: "2" },
        { label: "三个月内", value: "3" },
      ],
      itemOptions: [
        { label: "采购总量", value: "1" },
        { label: "采购频次", value: "2" },
      ],
      searchOptions: [
        { label: "全部商品", value: "1" },
        { label: "当前列表", value: "2" },
      ],
      options: {
        pageNo: 1,
        pageSize: 20,
        pages: 0,
        total: 0,
      },
      listData: [], // 列表数据
      url: {
        collectUrl: "product/getlistE",
        historyUrl: "historicalPurchase/list",
      },
      searchType: "1", // 搜索类型  1.搜全网  2.搜列表
      shopCartAllMoney: 0, // 订单的总金额
      loading: false,
      loading2: false,
      feeType: 0,
      orderStatus: false,
    };
  },
  computed: {
    noMore() {
      return this.productPage.pageNo >= this.productPage.pages;
    },
    noMore2() {
      return this.options.pageNo >= this.options.pages;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  created() {
    this.reloadListData();
  },
  mounted() {
    this.handleScroll();
    this.handleScroll2();
    this.handleKeyup();
  },
  methods: {
    // 展示产品的效期判断
    showProductEndDate(productData) {
      if (productData.inventoryList && productData.inventoryList.length > 1 && productData.inventoryList[0].endDate && productData.inventoryList[0].endDate !== "") {
        return productData.inventoryList[0].endDate
      } else {
        return ""
      }
    },
    changScreenType(type) {
      this.screenType = parseInt(type);
      this.reloadListData();
    },
    reloadListData() {
      this.options.pageNo = 1;
      this.listData = [];
      this.getListData();
    },
    // 获取极速采购列表
    async getListData() {
      let screenType = this.screenType;
      let searchType = this.searchType;
      let pageNo = this.options.pageNo;
      let pageSize = this.options.pageSize;
      let screenTimeType = this.screenTimeType;
      let screenStatusType = this.screenStatusType;
      let url = "";
      if (screenType == 1) {
        url = this.url.historyUrl;
      } else if (screenType == 2) {
        url = this.url.collectUrl;
      }
      let data = {
        pageNo: pageNo,
        pageSize: 20,
      };
      if (screenType == 2) {
        data.collectFlag = 1;
        data.pageSize = 20
      } else if (screenType == 1) {
        data.pageSize = 2000
        // 筛选方式
        if (screenStatusType == 1) {
          data.quantityFlag = 1;
        } else if (screenStatusType == 2) {
          data.orderCountFlag = 1;
        }
        // // 指定时间内
        let endTime = this.getStartDate(screenTimeType, 1);
        let startTime = this.getStartDate(screenTimeType, 2);
        data.startTime = startTime;
        data.endTime = endTime;
      }
      if (searchType == 2) {
        data.searchVal = this.searchVal;
      } else {
        data.pageSize = 20
      }
      let res = await getAction(url, data);
      this.listOperatLoaing = false;
      if (res.code == 200 && res.success) {
        let resData = res.result;
        let list = [];
        if (screenType == 1) {
          this.options.total = resData.total;
          this.options.pages = resData.pages;
          if (pageNo == 1) {
            list = resData.records;
          } else if (pageNo > 1 && pageNo <= resData.pages) {
            list = this.listData.concat(resData.records);
          }
        } else if (screenType == 2) {
          this.options.total = resData.pageData.total;
          this.options.pages = resData.pageData.pages;
          if (pageNo == 1) {
            list = resData.pageData.records;
          } else if (pageNo > 1 && pageNo <= resData.pageData.pages) {
            list = this.listData.concat(resData.pageData.records);
          }
        }
        this.listData = list;
      }
      console.log("请求极速采购列表返回的数据为++++++", res);
    },
    getStartDate(type, returnType) {
      var dateTime = null;
      var nowDate = new Date();
      if (returnType == 1) {
        dateTime = nowDate.getTime();
      } else if (returnType == 2) {
        dateTime =
          nowDate.getTime() - parseInt(type) * 30 * 24 * 60 * 60 * 1000;
      }
      console.log(dateTime);
      var date = new Date(dateTime);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var curDate = date.getDate();
      var curHours = date.getHours();
      var curMinutes = date.getMinutes();
      var curSeconds = date.getSeconds();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (curDate >= 0 && curDate <= 9) {
        curDate = "0" + curDate;
      }
      if (curSeconds >= 0 && curSeconds <= 9) {
        curSeconds = "0" + curSeconds;
      }
      return `${year}-${month}-${curDate} ${curHours}:${curMinutes}:${curSeconds}`;
    },
    // 返回顶部事件
    scrollGotoTop() {
      let _this = this;
      let scrollbarEl = _this.$refs.scrollbar2.wrap;
      scrollbarEl.scrollTop = 0;
    },

    // 获取快速下单产品列表数据
    getFastShoppingList(choiceId) {
      let _this = this;
      let feeType = this.feeType;
      let data = {
        feeType: feeType,
      };
      if (choiceId) {
        data.choiceId = choiceId;
      }
      let url = "/shopping/cart/getList";
      postAction(url, data).then((res) => {
        console.log("返回的快速下单产品列表", res);
        if (res.code === 200 && res.success) {
          let resData = res.result;
          // 执行渲染清单产品列表数据
          let checkedCart = this.checkedCart;
          let shopCartCheckIds = _this.shopCartCheckIds;
          if (
            typeof choiceId === "undefined" ||
            checkedCart === shopCartCheckIds
          ) {
            _this.renderShopCartData(resData, true);
          } else {
            _this.renderShopCartData(resData);
          }
        }
      });
    },
    // 点击指定产品选中操作
    selectHandlePro(index) {
      let selectProIndex = this.selectProIndex;
      if (selectProIndex == index) {
        this.keyupCancelPro();
        this.selectProIndex = -1;
      } else {
        this.selectProIndex = index;
        this.keyupSelectedPro();
      }
    },
    // 输入框快捷键操作
    handleKeyup() {
      let _this = this;
      window.onkeydown = function (e) {
        let showProductState = _this.showProductState;
        let selectProIndex = _this.selectProIndex;
        let productList = _this.productList;
        if (showProductState) {
          let keyCode = e.keyCode;
          if (keyCode == 38) {
            if (productList && productList.length > 0 && selectProIndex > 0) {
              e.preventDefault();
            }
            // 向上按键操作
            _this.$refs.searchVal.blur();
            console.log("向上按键操作");
            _this.keyupDrowUp();
          } else if (keyCode == 40) {
            if (
              productList &&
              productList.length > 0 &&
              selectProIndex < productList.length - 1
            ) {
              e.preventDefault();
            }
            _this.$refs.searchVal.blur();
            console.log("向下按键操作");
            // 向下按键操作
            _this.keyupDrownDown();
          } else if (keyCode == 67) {
            // 取消选中事件
            _this.keyupCancelPro();
            console.log("取消选中事件");
          }
        }
      };
      window.onkeyup = function (e) {
        let showProductState = _this.showProductState;
        if (showProductState) {
          let keyCode = e.keyCode;
          if (keyCode == 38) {
            let selectProIndex = _this.selectProIndex;
            if (selectProIndex == -1) {
              _this.$refs.searchVal.focus();
            }
          } else if (keyCode == 83) {
            // 选中产品事件
            console.log("选中产品事件");
            _this.keyupSelectedPro();
          } else if (keyCode == 27) {
            _this.keyupCancelOrder();
            console.log("取消下单操作事件");
          } else if (keyCode == 13) {
            console.log("添加到快速开单列表事件");
            let searchType = _this.searchType;
            if (searchType == 1) {
              _this.keyupEnter();
            }
          }
        }
      };
    },
    // 快捷选中产品操作
    keyupSelectedPro() {
      let _this = this;
      let selectProIndex = _this.selectProIndex;
      let productList = _this.productList;
      if (selectProIndex > -1) {
        let selectProData = productList[selectProIndex];
        let selectProId = selectProData.id;
        let selectProIds = _this.selectProIds;
        if (selectProIds.indexOf(selectProId) < 0) {
          // 将选中的产品id放入selectProIds数组中
          _this.selectProIds.push(selectProId);
          _this.selectProList.push(selectProData);
        }
        // 执行选中产品的输入框focus事件
        _this.$refs.proInputNum[selectProIndex].select();
      }
    },
    // 快捷键(取消选中产品操作)
    keyupCancelPro() {
      let _this = this;
      let selectProIndex = _this.selectProIndex;
      let productList = _this.productList;
      if (selectProIndex > -1) {
        _this.$refs.proInputNum[selectProIndex].blur();
        let selectProData = productList[selectProIndex];
        let selectProId = selectProData.id;
        let selectProIds = this.selectProIds;
        // 检查取消选中的产品id在数组中的index值
        let proIndex = selectProIds.indexOf(selectProId);
        if (proIndex > -1) {
          // 从选中的数组中去掉当前的产品id
          _this.selectProIds.splice(proIndex, 1);
          _this.selectProList.splice(proIndex, 1);
          // 将去掉的产品输入框清空
          _this.productList[
            selectProIndex
          ].inventoryList[0].addCartQuantity = 0;
        }
      }
    },
    // 快捷键(向上键)
    keyupDrowUp() {
      let _this = this;
      let selectProIndex = _this.selectProIndex;
      let scrollbarEl = _this.$refs.scrollbar.wrap;
      // 获取当前滚动栏的高度
      let scrollTop = scrollbarEl.scrollTop;
      // 按键操作后选中的产品数量输入框移除焦点事件
      if (selectProIndex > -1) {
        _this.$refs.proInputNum[selectProIndex].blur();
      }
      if (selectProIndex >= 0) {
        _this.selectProIndex--;
        // let selectProIndex = _this.selectProIndex;
        // if (selectProIndex == -1) {
        //   _this.$refs.searchVal.focus();
        // }
        let selectIndexTop = (selectProIndex + 1) * 50;
        // 当选中的产品高度小于滚动框上的高度定位到上一个产品的滚动条位置
        if (selectIndexTop <= scrollTop) {
          scrollbarEl.scrollTop = selectIndexTop - 50;
        }
      }
    },
    // 快捷键(向下键)
    keyupDrownDown() {
      let _this = this;
      let selectProIndex = _this.selectProIndex;
      let scrollbarEl = _this.$refs.scrollbar.wrap;
      let total = _this.productPage.total;
      // 获取当前滚动栏的高度
      let scrollTop = scrollbarEl.scrollTop;
      // 按键操作后选中的产品数量输入框移除焦点事件
      if (selectProIndex > -1) {
        _this.$refs.proInputNum[selectProIndex].blur();
      }
      if (_this.selectProIndex < total - 1) {
        _this.selectProIndex++;
        let selectProIndex = _this.selectProIndex;
        let selectIndexTop = (selectProIndex + 1) * 50;
        // 当选中的产品是最后一个的时候滚动条显示出(没有更多数据！)
        if (selectProIndex == total - 1) {
          scrollbarEl.scrollTop = selectIndexTop - 350;
        } else if (selectIndexTop > scrollTop + 400) {
          // 当当前选中的产品高度大于滚动条框外，定位到最后一个选中产品的滚动高度
          scrollbarEl.scrollTop = selectIndexTop - 400;
        }
      }
    },
    // 快捷键(ESC键)
    keyupCancelOrder() {
      // 首先清空productIds
      this.selectProIds = [];
      this.selectProList = [];
      this.selectProIndex = -1;
      this.searchVal = "";
      this.$refs.searchVal.focus();
      this.showProductState = false;
    },
    // 快捷键(Enter)
    keyupEnter() {
      let _this = this;
      // 判断用户是否选中的产品
      let selectProList = this.selectProList;
      let showProductState = this.showProductState;
      console.log("selectProList+++++", selectProList);
      if (selectProList.length > 0 && showProductState) {
        let jionProList = [];
        for (let key of selectProList) {
          if (
            key.inventoryList &&
            key.inventoryList.length > 0 &&
            key.inventoryList[0].addCartQuantity &&
            key.inventoryList[0].addCartQuantity !== ""
          ) {
            let data = {
              uid: key.inventoryList[0].uid,
              addPrice: key.showPrice,
              amount: key.inventoryList[0].addCartQuantity,
            };
            jionProList.push(data);
          }
        }
        console.log("添加产品列表返回的数据为+++++", jionProList);
        let url = "/shopping/cart/addList?getList=1";
        postAction(url, jionProList).then((res) => {
          if (res.code === 200 && res.success) {
            _this.$message({
              message: "商品已添加到购物车！",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            let shoppingCartCount = res.result.shoppingCartListcount
            _this.$store.commit('SHOPPING_CART_COUNT', shoppingCartCount)
            // 添加成功之后关闭搜索框区域
            _this.keyupCancelOrder();
            // 刷新产品列表
            _this.getFastShoppingList();
          } else {
            this.$alert(res.message, "提示信息", {
              type: "error",
            });
          }
        });
      }
    },

    // 监听滚动条滚动时间
    handleScroll() {
      let _this = this;
      let scrollbarEl = this.$refs.scrollbar.wrap;
      scrollbarEl.onscroll = () => {
        let scrollHeight = scrollbarEl.scrollHeight;
        let scrollTop = scrollbarEl.scrollTop;
        if (scrollTop >= scrollHeight - 400) {
          let pages = _this.productPage.pages;
          let pageNo = _this.productPage.pageNo;
          if (pageNo < pages) {
            _this.productPage.pageNo = _this.productPage.pageNo + 1;
            _this.loadProductData();
          }
        }
      };
    },
    // 监听列表滚动条滚动时间
    handleScroll2() {
      let _this = this;
      let scrollbarEl = this.$refs.scrollbar2.wrap;
      scrollbarEl.onscroll = () => {
        let scrollHeight = scrollbarEl.scrollHeight;
        let scrollTop = scrollbarEl.scrollTop;
        console.log(scrollTop >= scrollHeight - 600);
        if (scrollTop >= scrollHeight - 600) {
          let pages = _this.options.pages;
          let pageNo = _this.options.pageNo;
          if (pageNo < pages) {
            _this.options.pageNo = _this.options.pageNo + 1;
            _this.getListData();
          }
        }
      };
    },
    // 搜索产品操作
    searchProduct(e) {
      let str = "";
      let searchType = this.searchType;
      let searchVal = this.searchVal;
      str = searchVal.replace(/\ +/g, "");
      str = searchVal.replace(/[\r\n]/g, "");

      console.log("输入框回车搜索时间", searchType);
      if (searchType == 1) {
        if (str !== "") {
          this.showProductState = true;
          this.productPage.pageNo = 1;
          this.loadProductData();
        } else {
          this.showProductState = false;
        }
      } else if (searchType == 2) {
        this.listOperatLoaing = true;
        this.options.pageNo = 1;
        this.getListData();
      }
    },
    // 加载产品数据
    loadProductData() {
      let _this = this;
      let searchVal = this.searchVal;
      let pageNo = this.productPage.pageNo;
      let data = {
        searchVal: searchVal,
        pageNo: pageNo,
        pageSize: 20,
      };
      this.loading = true;
      let url = "/product/getlistE";
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          let pageData = resData.pageData;
          if (pageData.current == pageData.pages || pageData.total == 0) {
            _this.loading = false;
          }
          _this.productPage.pageNo = pageData.current;
          _this.productPage.pages = pageData.pages;
          _this.productPage.total = pageData.total;
          console.log("当前极速采购返回的产品数据为++++++", pageData.records)
          if (pageNo == 1) {
            _this.productList = pageData.records;
          } else if (pageNo > 1) {
            _this.productList = _this.productList.concat(pageData.records);
          }
          // if (pageData.records.length > 0) {
          //   if (pageData.current == pageData.pages) {
          //     _this.loading = false;
          //   }
          //   _this.productPage.pageNo = pageData.current;
          //   _this.productPage.pages = pageData.pages;
          //   _this.productPage.total = pageData.total;
          //   if (pageNo == 1) {
          //     _this.productList = pageData.records;
          //   } else if (pageNo > 1) {
          //     _this.productList = _this.productList.concat(pageData.records);
          //   }
          // }
        }
      });
    },
    // 移除产品数据
    removeProduct(item) {
      this.listOperatLoaing = true;
      let _this = this;
      let deleteUrl = "/shopping/cart/delete";
      let feeType = this.feeType;
      let data = {
        id: item.id,
        getList: 2,
        feeType: feeType,
      };
      postAction(deleteUrl, data).then((res) => {
        if (res.code === 200 && res.success) {
          // 删除成功后从全选数据里面移除删除的id
          let shopCartCheckIds = _this.shopCartCheckIds;
          if (shopCartCheckIds.indexOf(item.id) > -1) {
            let index = shopCartCheckIds.indexOf(item.id);
            shopCartCheckIds.splice(index, 1);
            _this.shopCartCheckIds = shopCartCheckIds;
          }
          let checkedCart = _this.checkedCart;
          if (checkedCart.indexOf(item.id) > -1) {
            let index = checkedCart.indexOf(item.id);
            checkedCart.splice(index, 1);
            _this.checkedCart = checkedCart;
          }
          _this.getFastShoppingList(checkedCart);
        }
      });
    },
    removeBatchCart() {
      this.dialogVisible = true;
    },
    // 批量删除购物车数据
    deleteShopCart() {
      let _this = this;
      this.listOperatLoaing = true;
      this.dialogVisible = false;
      let feeType = this.feeType;
      let checkedCart = this.checkedCart;
      let shopCartCheckIds = this.shopCartCheckIds;
      let actTicketData = this.actTicketData;
      let url = "/shopping/cart/deleteList?getList=1";
      let data = [];
      for (let key of checkedCart) {
        let itemData = {
          id: parseInt(key),
          feeType: feeType,
        };
        if (actTicketData && JSON.stringify(actTicketData) !== "{}") {
          itemData.useDiscountTicketId = actTicketData.id;
        }
        data.push(itemData);
      }
      postAction(url, data, true).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.$message({
            message: "已批量删除购物车!",
            offset: 400,
            duration: 1000,
            type: "success",
          });
          // 从选中的产品数组id中移除掉删掉的产品id
          for (let key of data) {
            if (checkedCart.indexOf(key.id) > -1) {
              let index = checkedCart.indexOf(key.id);
              checkedCart.splice(index, 1);
            }
            if (shopCartCheckIds.indexOf(key.id) > -1) {
              let index = shopCartCheckIds.indexOf(key.id);
              shopCartCheckIds.splice(index, 1);
            }
          }
          _this.checkedCart = checkedCart;
          _this.shopCartCheckIds = shopCartCheckIds;
          console.log("批量删除以后清单列表所有的产品id", shopCartCheckIds);
          console.log("批量删除以后清单列表勾选的产品id", checkedCart);
          _this.getFastShoppingList(checkedCart);
        }
      });
    },
    // 清单列表修改数量操作
    changeShopCartItemAount(item) {
      let _this = this;
      let inputNum = item.amount;
      if (inputNum > 0) {
        let addPrice = item.addPrice;
        let amount = inputNum;
        let id = item.id;
        let feeType = this.feeType;
        let productPriceStrategyId = item.productPriceStrategyId;
        let uid = item.uid;
        let addShopData = {
          addPrice: addPrice,
          amount: amount,
          feeType: feeType,
          uid: uid,
          id: id,
        };
        if (productPriceStrategyId) {
          addShopData.productPriceStrategyId = productPriceStrategyId;
        }
        let addShopUrl = "/shopping/cart/add";
        postAction(addShopUrl, addShopData).then((res) => {
          if (res.success && res.code === 200) {
            let resData = res.result;
            let choiceId = this.checkedCart;
            _this.getFastShoppingList(choiceId);
          } else {
            this.$alert(res.message, "提示信息", {
              type: "error",
              dangerouslyUseHTMLString: true,
            });
          }
        });
      } else {
        this.$message({
          message: "添加产品的数量不能小于1",
          type: "warning",
          duration: 1000,
          offset: 400,
        });
      }
    },
    // 清单列表产品数量减少数量
    lessAmount(item) {
      let inputNum = parseInt(item.amount);
      console.log("inputNum+++++++", inputNum);
      let step = 1;
      if (item.productStep && item.productStep > 0) {
        step = parseInt(item.productStep);
      }
      let stockNum = parseInt(item.inventory);
      if (
        (inputNum > 0 && inputNum <= step && inputNum < stockNum) ||
        inputNum <= 0
      ) {
        inputNum = step;
      } else if (inputNum >= stockNum) {
        inputNum = stockNum;
      } else if (inputNum > step && inputNum <= stockNum) {
        inputNum = inputNum - step;
        if (inputNum % step) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
        }
      }
      item.amount = inputNum;
      this.changeShopCartItemAount(item);
    },
    // 清单列表产品数量添加数量
    addAmount(item) {
      let inputNum = parseInt(item.amount);
      let step = 1;
      if (item.productStep && item.productStep > 0) {
        step = parseInt(item.productStep);
      }
      let stockNum = parseInt(item.inventory);
      if (
        (inputNum > stockNum - step && inputNum < stockNum) ||
        inputNum >= stockNum
      ) {
        inputNum = stockNum;
      } else if (inputNum >= 0 && inputNum <= stockNum - step) {
        inputNum = inputNum + step;
        if (inputNum % step) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
        }
      }
      item.amount = inputNum;
      this.changeShopCartItemAount(item);
    },
    // 清单列表产品数量修改输入框数量
    changeAmount(item, e) {
      let step = 1;
      if (item.productStep && item.productStep > 0) {
        step = parseInt(item.productStep);
      }
      let inputNum = parseInt(e.target.value);
      let inventory = parseInt(item.inventory);
      // 强制改变用户的输入数量为步进数量
      if (inputNum >= inventory) {
        if (inputNum > inventory) {
          this.$message({
            message: `该产品最大购买量为:${inventory}, 已按照最大购买量添加！`,
            offset: 400,
            duration: 2000,
            type: 'success'
          })
        }
        inputNum = inventory;

      } else if (inputNum <= 0 && inputNum >= step) {
        // 输入的小于 0并且库存大于步进数量, 按照默认步进来计算
        inputNum = step;
      } else {
        if (step !== 1) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
          if (inputNum > inventory) {
            inputNum = step * (mulNum - 1)
          }
          this.$message({
            message: `该产品不拆零销售, 修改的数量为:${inputNum}`,
            offset: 400,
            duration: 2000,
            type: 'success'
          })
        }
      }
      item.amount = inputNum;
      this.changeShopCartItemAount(item);
    },
    // 全选清单数据操作
    handleCheckAllChange(val) {
      this.checkedCart = val ? this.shopCartCheckIds : [];
      this.isIndeterminate = false;
      let choiceId = this.checkedCart;
      this.getFastShoppingList(choiceId);
    },
    // 清单列表复选框勾选操作
    handleCheckedCitiesChange(checkedIds) {
      let checkedCount = checkedIds.length;
      this.shopCartCheckAll = checkedCount === this.shopCartCheckIds.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.shopCartCheckIds.length;
      this.getFastShoppingList(checkedIds);
    },
    changePayType(e) {
      this.getFastShoppingList();
    },
    // 渲染列表数据
    renderShopCartData(resData, checkStatus) {
      let _this = this;
      // 暂停加载动画
      this.listOperatLoaing = false;
      // 渲染产品列表数据
      this.shoppingCartList = resData.shoppingCartList;
      // 检测用户是否有可用的优惠券,如果有存储起来默认创建订单的时候使用
      if (
        resData.canUseDiscountTicketList &&
        resData.canUseDiscountTicketList.length > 0
      ) {
        _this.actTicketData = resData.canUseDiscountTicketList[0];
      }
      // 默认全选购物车数据
      let shopCartCheckIds = [];
      for (let key of resData.shoppingCartList) {
        shopCartCheckIds.push(key.id);
      }
      _this.shopCartCheckIds = shopCartCheckIds;
      if (checkStatus) {
        _this.checkedCart = shopCartCheckIds;
      }
      // 展示的快速开单金额
      _this.shopCartAllMoney = resData.money;
      this.$forceUpdate();
    },
    // 创建订单操作
    createOrder() {
      const _this = this;
      const shoppingCartId = this.checkedCart;
      const feeType = this.feeType;
      const shopData = {
        shoppingCartId: shoppingCartId,
        feeType: feeType,
        sourceType: 1,
      };
      if (shoppingCartId.length > 0) {
        this.createOrderStatus = true;
        const url = "/saleOrder/saleOrder/createSaleOrder";
        console.log("创建订单传输的数据为++++++", shopData);
        postAction(url, shopData).then((res) => {
          if (res.code === 200 && res.success) {
            const resData = res.result;
            // 获取订单id，轮询请求订单配货状态
            let saleOrderId = resData.saleOrder.id;
            this.visitOrderStatus(saleOrderId);
          } else {
            _this.createOrderStatus = false;
            _this.$message({
              message: res.message,
              offset: 400,
              duration: 1000,
              type: "error",
            });
          }
        });
      } else {
        this.createOrderStatus = false;
      }
    },
    visitOrderStatus(id) {
      let _this = this;
      this.createOrderStatus = true;
      _this.querySaleOrderAllotStatus(id);
      this.orderStatus = setInterval(function () {
        _this.querySaleOrderAllotStatus(id);
      }, 2000);
    },
    querySaleOrderAllotStatus(id) {
      let _this = this;
      let url = "/saleOrder/saleOrder/querySaleOrderAllotStatus";
      let data = {
        saleOrderId: id,
      };
      getAction(url, data)
        .then((res) => {
          console.log("请求erp配货状态返回的数据为++++++", res);
          if (res.code === 200 && res.success) {
            if (res.result == "2") {
              _this.createOrderStatus = false;
              clearInterval(_this.orderStatus);
              // 创建订单成功后跳转确认订单页面
              _this.$router.push({
                name: "confirmOrder",
                query: {
                  saleOrderId: id,
                },
              });
            }
          } else {
            _this.createOrderStatus = false;
            _this.$alert(res.message, "创建订单失败：");
          }
        })
        .catch((error) => {
          _this.createOrderStatus = false;
          _this.$alert(error, "创建订单失败：");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/fastShopping/bestShopping";
</style>