import { getAction,postAction } from '@/api/manage'
import router from '@/router/index'

const shoppingCart = {
  state: {
    shoppingCartCount: 0,
    shoppingCartList: [],
    collectUploadStatus: false,
  },
  mutations: {
    // 购物车订单数量
    SHOPPING_CART_COUNT: (state, count) => {
      state.shoppingCartCount = count
    },
    COLLECT_UPLOAD_STATUS: (state, status) => {
      console.log("取消收藏的操作状态",status)
      state.collectUploadStatus = status
    },
    SHOPPING_CART_LIST: (state, count) => {
      state.shoppingCartList = count
    }
  },

  actions: {
    ClearShoppingCartCount({commit}, state){
        commit('SHOPPING_CART_COUNT', 0)
    }
  }
}

export default shoppingCart
