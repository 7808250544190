var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tableListBox"},[_c('div',{staticClass:"listBox"},_vm._l((_vm.productList),function(item,index){return _c('ul',{key:'productLi' + index,class:{ 'offline-sale-item': item.offlineSaleFlag && item.offlineSaleFlag == '1' }},[(item.inventoryList && item.inventoryList.length > 0)?_c('li',{staticClass:"activity"},[(
          item.productActivityPriceStrategyFlag === 1 &&
          item.productActivityPriceStrategyDetail &&
          item.productActivityPriceStrategyDetail.canSaleAmount
        )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"该产品为秒杀产品","placement":"right"}},[_c('p',{staticClass:"tip1"},[_vm._v("秒杀")])]):(
          item.inventoryList[0].newCustomerPriceFlag &&
          item.inventoryList[0].newCustomerPriceFlag == '1'
        )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"新用户特价产品","placement":"right"}},[_c('p',{staticClass:"tip3"},[_vm._v("特价")])]):(item.policyFlag && item.productPolicyList && item.productPolicyList.length > 0)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.productPolicyList[0].remark,"placement":"right"}},[_c('p',{staticClass:"tip3"},[_vm._v("促销")])]):(
          item.inventoryList[0].priceTitle &&
          item.inventoryList[0].priceTitle !== ''
        )?_c('p',{staticClass:"tip2"},[_vm._v(" "+_vm._s(item.inventoryList[0].priceTitle)+" ")]):(item.inventoryList[0].nearDateFlag)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"该产品为近效期产品","placement":"right"}},[_c('p',{staticClass:"tip4"},[_vm._v("近效期")])]):_vm._e()],1):_c('li',{staticClass:"activity"},[_c('p',{})]),_c('li',{staticClass:"name"},[(item.picUrl && item.picUrl !== '')?_c('img',{staticClass:"imgIcon",attrs:{"src":require("@/assets/images/product/list/proImg.png"),"alt":"产品图片"}}):_c('img',{staticClass:"imgIcon",attrs:{"src":require("@/assets/images/product/list/noProImg.png"),"alt":"产品图片"}}),_c('router-link',{attrs:{"to":{
          path: '/productDetail',
          query: { productId: item.productId },
        },"title":item.name,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.picUrl && item.picUrl !== '')?_c('div',{staticClass:"productImg"},[_c('span',{staticClass:"listImgBox"},[(_vm.specialAreaMaskImg && _vm.specialAreaMaskImg !== '')?_c('img',{staticClass:"maskImg",attrs:{"src":_vm.specialAreaMaskImg,"alt":"产品遮罩图"}}):(
              item.productLabelList && item.productLabelList.length > 0 && _vm.prolabelMaskImg(item.productLabelList) !== ''
            )?_c('img',{staticClass:"maskImg",attrs:{"src":_vm.prolabelMaskImg(item.productLabelList),"alt":"产品遮罩图"}}):_vm._e(),_c('img',{attrs:{"src":_vm.productImg(item)}})])]):_vm._e()],1),_c('li',{staticClass:"format",attrs:{"title":item.format}},[_vm._v(" "+_vm._s((item.format && item.format !== '') ? item.format : "暂无信息")+" ")]),_c('li',{staticClass:"unit"},[_vm._v(" "+_vm._s(item.unit ? item.unit : "无")+" ")]),_c('li',{staticClass:"manufacturer"},[_vm._v(_vm._s(_vm.testReturnData(item.manufacturer)))]),_c('li',{staticClass:"validity"},[(
          item.inventoryList &&
          item.inventoryList.length > 0 &&
          item.inventoryList[0].endDate &&
          item.inventoryList[0].endDate !== ''
        )?_c('div',[_vm._v(" "+_vm._s(item.inventoryList[0].endDate && item.inventoryList[0].endDate !== "" ? item.inventoryList[0].endDate : "")+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.loginState ? "----/--/--" : "会员可见")+" ")])]),_c('li',{staticClass:"package"},[_vm._v(_vm._s(item.mediumPkgSize)+"/"+_vm._s(item.bigPkgSize))]),_c('li',{staticClass:"price"},[_vm._v(" "+_vm._s(item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleShowPrice !== '1' ? '请联系开票员' : _vm.showPrice(item))+" ")]),(false)?_c('li',{staticClass:"discountPrice"},[_vm._v(" "+_vm._s(item.inventoryList && item.inventoryList.length > 0 && item.inventoryList[0].discountPrice && item.inventoryList[0].discountPrice !== "" ? "￥" + item.inventoryList[0].discountPrice : "暂无优惠")+" ")]):_vm._e(),(_vm.loginState)?_c('li',{staticClass:"discountPrice"},[(item.inventoryList && item.inventoryList.length > 0)?_c('div',{staticClass:"fhsj",style:(item.inventoryList[0].deliveryTime === '24'
            ? 'color:#009944'
            : 'color:#0f4c81')},[_c('span',{staticClass:"fhsjSpan"},[_vm._v(_vm._s(item.inventoryList[0].deliveryTime ? item.inventoryList[0].deliveryTime : "24H"))])]):_vm._e()]):_c('li',{staticClass:"discountPrice"},[_vm._m(0,true)]),(_vm.loginState)?_c('li',{staticClass:"inventory"},[_vm._v(" "+_vm._s(_vm.showInventoryText(item.inventoryNum, item))+" ")]):_c('li',{staticClass:"inventory"},[_vm._v(" 会员可见 ")]),(_vm.loginState)?_c('li',{staticClass:"amount"},[(item.inventoryList && item.inventoryList.length > 0 && item.productNotputSaleCreateOrder !== '0')?_c('div',{staticClass:"amoutBox"},[_c('span',{on:{"click":function($event){return _vm.lessNum(item.inventoryList[0], item)}}},[_vm._v("-")]),_c('input',{class:{
            shoppingHas:
              item.inventoryList[0].shoppingCartAmount &&
              item.inventoryList[0].shoppingCartAmount > 0,
          },attrs:{"oninput":"value=value.replace(/[^0-9.]/g,'')","type":"text"},domProps:{"value":item.inventoryList[0].addCartQuantity},on:{"focus":function($event){return _vm.selectShopAmount($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.jionShopCart(item.inventoryList[0], item, $event)},"change":function($event){return _vm.changeNum(item.inventoryList[0], item, $event)}}}),_c('span',{on:{"click":function($event){return _vm.addNum(item.inventoryList[0], item)}}},[_vm._v("+")])]):(item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleCreateOrder == '0')?_c('div',{staticClass:"amoutBox"},[_vm._v(" 请联系开票员订货 ")]):_c('div',{staticClass:"amoutBox"},[_vm._v(" 暂无库存 ")])]):_c('li',{staticClass:"amount"},[_vm._m(1,true)]),(_vm.loginState)?_c('li',{staticClass:"operation actioveBox"},[(
          item.inventoryList &&
          item.inventoryList.length > 0
        )?_c('a',{attrs:{"href":"javascript:;"}},[_c('img',{attrs:{"src":require("@/assets/images/product/list/jionShop.png"),"alt":""},on:{"click":function($event){return _vm.jionShopCart(item.inventoryList[0], item, $event)}}})]):_c('router-link',{staticClass:"qiugou",attrs:{"to":{ path: '/wantToBuyProduct', query: _vm.qiugouDenglu(item) }}},[_c('img',{attrs:{"src":require("@/assets/images/product/list/search.png"),"alt":""}}),_vm._v(" 找货 ")])],1):_c('li',{staticClass:"operation actioveBox"},[_c('router-link',{attrs:{"to":"/login"}},[_c('img',{attrs:{"src":require("@/assets/images/product/list/jionShop.png"),"alt":""}})])],1)])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fhsj",staticStyle:{"color":"#009944"}},[_c('span',{staticClass:"fhsjSpan"},[_vm._v("24H")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"amoutBox"},[_c('span',[_vm._v("-")]),_c('input',{attrs:{"oninput":"value=value.replace(/[^0-9.]/g,'')","type":"text"}}),_c('span',[_vm._v("+")])])
}]

export { render, staticRenderFns }