<template>
  <div class="productListApp">
    <div class="proTable">
      <div
        v-if="productList.length === 0 && !productLoading"
        class="dataNone container_box"
      >
        <div class="dataImg productNoneImg"></div>
      </div>
      <div v-else v-loading="productLoading" class="cartMain">
        <ul v-if="!listType" class="cartListHeader">
          <li class="name">商品名称</li>
          <li class="format">规格</li>
          <li class="manufacturer">生产厂家</li>
          <!-- <li class="validity">有效期</li> -->
          <li class="inventory">库存</li>
          <li class="discountPrice">预计到货</li>
          <li class="price">单价</li>
          <li class="amount">数量</li>
          <li class="operation"></li>
        </ul>
        <div class="productListBox">
          <table-pro-list2 specialAreaMaskImg="" @reloadProduct="getProductListData" v-if="!listType" :productList="productList">
          </table-pro-list2>
        </div>
      </div>
    </div>
    <div class="pageFooter">
      <el-pagination
        background
        v-if="totalCount > 0"
        :hide-on-single-page="true"
        layout="prev, pager, next, jumper"
        :current-page="pageNo"
        @current-change="currentChange"
        :page-size="pageSize"
        :total="totalCount"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import TableProList2 from "@/components/product/TableProList2.vue";
import { getAction } from "../api/manage";
export default {
  components: { TableProList2},
  props: ["searchName"],
  name: "ProductList",
  data() {
    return {
      listType: false,
      pageNo: 1,
      pages: 1,
      pageSize: 10,
      totalCount: 1,
      searchVal: "",
      collectFlag: "",
      productLoading: true,
      productList: [],
      manufacturer: "",
      hasStocket: false, // 只展示有库存的
      maxPrice: "",
      minPrice: "",
      showRangeStatus: false,
      screenList: ["默认排序", "销量", "价格", "人气"],
      screenType: 0, // 1:销量 2:价格 3:人气
      sortType: 1, // 1:升序 2:降序
      sortTypeText: "由低到高", // 排序提示
      productCategory: "", // 产品分类
      productLabelList: "", // 产品标签数据
    };
  },
  created() {
    let searchVal = this.$route.query.searchVal;
    let manufacturer = this.$route.query.manufacturer;
    let productCategory = this.$route.query.productCategory;
    let collectFlag = this.$route.query.collectFlag;
    let productLabelList = this.$route.query.productLabelList;
    this.manufacturer = manufacturer;
    this.productCategory = productCategory;
    this.productLabelList = productLabelList;
    this.searchVal = searchVal;
    this.collectFlag = collectFlag;
    this.getProductListData();
  },
  methods: {
    // 价格区间查询
    priceSearch() {
      this.showRangeStatus = false;
      this.getProductListData();
    },
    // 清空价格区间筛选
    clearPriceNum() {
      this.minPrice = "";
      this.maxPrice = "";
      this.showRangeStatus = false;
      this.getProductListData();
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    },
    // 选择商品筛选排序条件
    selectScreen(item, index) {
      let sortType = this.sortType;
      let screenType = this.screenType;
      if (screenType == index && index > 0) {
        if (sortType == 1) {
          this.sortTypeText = "由高到低";
          this.sortType = 2;
        } else {
          this.sortTypeText = "由低到高";
          this.sortType = 1;
        }
      } else if (screenType !== index && index > 0) {
        this.sortType = 1;
        this.sortTypeText = "由低到高";
      } else {
        this.sortType = 1;
        this.sortTypeText = "";
      }
      this.screenType = index;
      this.getProductListData();
    },
    // 获取产品列表数据
    getProductListData() {
      this.backTop();
      let _this = this;
      this.productLoading = true;
      let productCategory = this.productCategory;
      let productLabelList = this.productLabelList;
      let manufacturer = this.manufacturer;
      let searchVal = this.searchVal;
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let data = {
        searchVal: searchVal,
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (productCategory && productCategory !== "") {
        data.productCategory = productCategory;
      }
      if (manufacturer && manufacturer !== "") {
        data.manufacturer = manufacturer;
      }
      if (productLabelList && productLabelList !== "") {
        data.productLabelList = productLabelList;
      }

      data.collectFlag = 1;
      // 判断筛选条件
      // 1. 判断是否只显示有库存的产品数据
      let hasStocket = this.hasStocket;
      if (hasStocket) {
        data["productScreenBO.isInventory"] = 1;
      } else {
        data["productScreenBO.isInventory"] = 0;
      }
      // 2.判断价格区间
      let maxPrice = this.maxPrice;
      let minPrice = this.minPrice;
      if (maxPrice !== "") {
        data["productScreenBO.maxPrice"] = maxPrice;
      }
      if (minPrice !== "") {
        data["productScreenBO.minPrice"] = minPrice;
      }
      // 判断筛选条件和升降序
      let screenType = this.screenType;
      let sortType = this.sortType;
      // screenType: 1: 销量 2:价格 3.人气
      // sortType: 1: 升序  2.降序
      if (screenType !== "" && screenType > 0) {
        data["productScreenBO.screenType"] = screenType;
        data["productScreenBO.sortType"] = sortType;
      }
      let url = "/product/getlistE";
      getAction(url, data, true)
        .then((res) => {
          _this.productLoading = false;
          if (res.code === 200 && res.success) {
            let pageData = res.result.pageData;
            _this.productList = pageData.records;
            _this.pageNo = pageData.current;
            _this.pages = pageData.pages;
            _this.totalCount = pageData.total;
          } else {
            _this.productList = [];
            _this.productLoading = false;
          }
        })
        .catch((error) => {
          _this.productList = [];
          _this.productLoading = false;
        });
    },
    // 搜索产品的操作
    searchProduct(searchName) {
      this.clearSearchUrlData();
      this.searchVal = searchName;
      this.pageNo = 1;
      this.getProductListData();
    },
    // 搜索收藏夹产品的操作
    searchCollectProduct(searchName) {
      this.clearSearchUrlData();
      this.searchVal = searchName;
      this.collectFlag = 1;
      this.pageNo = 1;
      this.getProductListData();
    },
    searchProductCategory(productCategory) {
      this.clearSearchUrlData();
      this.productCategory = productCategory;
      this.getProductListData();
    },
    clearSearchUrlData() {
      this.productCategory = "";
      this.productLabelList = "";
      this.manufacturer = "";
      this.collectFlag = "";
      this.searchVal = "";
      this.pageNo = 1;
    },
    currentChange(val) {
      this.pageNo = val;
      this.getProductListData();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/product/product";
.productListApp {
  width: 930px;
   float: right;
}
.name,.manufacturer {
  width: 170px !important;
}
.operation {
  width: 135px !important;
}
.productImg {
  img {
    
  }
}
</style>