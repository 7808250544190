var AP = [{
    name: "山东省",
    id: "370000"
  },
  {
    name: "甘肃省",
    id: "620000"
  },
  {
    name: "江苏省",
    id: "320000"
  },
  {
    name: "北京市",
    id: "110000"
  },
  {
    name: "云南省",
    id: "530000"
  },
  {
    name: "海南省",
    id: "460000"
  },
  {
    name: "浙江省",
    id: "330000"
  },
  {
    name: "上海市",
    id: "310000"
  },
  {
    name: "天津市",
    id: "120000"
  },
  {
    name: "陕西省",
    id: "610000"
  },
  {
    name: "港澳",
    id: "910000"
  },
  {
    name: "新疆维吾尔自治区",
    id: "650000"
  },
  {
    name: "贵州省",
    id: "520000"
  },
  {
    name: "安徽省",
    id: "340000"
  },
  {
    name: "湖南省",
    id: "430000"
  },
  {
    name: "河北省",
    id: "130000"
  },
  {
    name: "辽宁省",
    id: "210000"
  },
  {
    name: "四川省",
    id: "510000"
  },
  {
    name: "宁夏回族自治区",
    id: "640000"
  },
  {
    name: "吉林省",
    id: "220000"
  },
  {
    name: "福建省",
    id: "350000"
  },
  {
    name: "湖北省",
    id: "420000"
  },
  {
    name: "广东省",
    id: "440000"
  },
  {
    name: "重庆市",
    id: "500000"
  },
  {
    name: "山西省",
    id: "140000"
  },
  {
    name: "江西省",
    id: "360000"
  },
  {
    name: "黑龙江省",
    id: "230000"
  },
  {
    name: "青海省",
    id: "630000"
  },
  {
    name: "河南省",
    id: "410000"
  },
  {
    name: "台湾省",
    id: "710000"
  },
  {
    name: "内蒙古自治区",
    id: "150000"
  },
  {
    name: "西藏自治区",
    id: "540000"
  },
  {
    name: "广西壮族自治区",
    id: "450000"
  }
];
var AC = [{
    name: "莱芜市",
    pid: "370000",
    id: "371200"
  },
  {
    name: "济南市",
    pid: "370000",
    id: "370100"
  },
  {
    name: "日照市",
    pid: "370000",
    id: "371100"
  },
  {
    name: "青岛市",
    pid: "370000",
    id: "370200"
  },
  {
    name: "德州市",
    pid: "370000",
    id: "371400"
  },
  {
    name: "淄博市",
    pid: "370000",
    id: "370300"
  },
  {
    name: "临沂市",
    pid: "370000",
    id: "371300"
  },
  {
    name: "威海市",
    pid: "370000",
    id: "371000"
  },
  {
    name: "济宁市",
    pid: "370000",
    id: "370800"
  },
  {
    name: "泰安市",
    pid: "370000",
    id: "370900"
  },
  {
    name: "枣庄市",
    pid: "370000",
    id: "370400"
  },
  {
    name: "滨州市",
    pid: "370000",
    id: "371600"
  },
  {
    name: "东营市",
    pid: "370000",
    id: "370500"
  },
  {
    name: "聊城市",
    pid: "370000",
    id: "371500"
  },
  {
    name: "烟台市",
    pid: "370000",
    id: "370600"
  },
  {
    name: "潍坊市",
    pid: "370000",
    id: "370700"
  },
  {
    name: "菏泽市",
    pid: "370000",
    id: "371700"
  },
  {
    name: "陇南市",
    pid: "620000",
    id: "621200"
  },
  {
    name: "兰州市",
    pid: "620000",
    id: "620100"
  },
  {
    name: "定西市",
    pid: "620000",
    id: "621100"
  },
  {
    name: "嘉峪关市",
    pid: "620000",
    id: "620200"
  },
  {
    name: "金昌市",
    pid: "620000",
    id: "620300"
  },
  {
    name: "庆阳市",
    pid: "620000",
    id: "621000"
  },
  {
    name: "甘南藏族自治州",
    pid: "620000",
    id: "623000"
  },
  {
    name: "平凉市",
    pid: "620000",
    id: "620800"
  },
  {
    name: "酒泉市",
    pid: "620000",
    id: "620900"
  },
  {
    name: "白银市",
    pid: "620000",
    id: "620400"
  },
  {
    name: "天水市",
    pid: "620000",
    id: "620500"
  },
  {
    name: "武威市",
    pid: "620000",
    id: "620600"
  },
  {
    name: "临夏回族自治州",
    pid: "620000",
    id: "622900"
  },
  {
    name: "张掖市",
    pid: "620000",
    id: "620700"
  },
  {
    name: "扬州市",
    pid: "320000",
    id: "321000"
  },
  {
    name: "镇江市",
    pid: "320000",
    id: "321100"
  },
  {
    name: "盐城市",
    pid: "320000",
    id: "320900"
  },
  {
    name: "连云港市",
    pid: "320000",
    id: "320700"
  },
  {
    name: "淮安市",
    pid: "320000",
    id: "320800"
  },
  {
    name: "苏州市",
    pid: "320000",
    id: "320500"
  },
  {
    name: "南通市",
    pid: "320000",
    id: "320600"
  },
  {
    name: "徐州市",
    pid: "320000",
    id: "320300"
  },
  {
    name: "常州市",
    pid: "320000",
    id: "320400"
  },
  {
    name: "南京市",
    pid: "320000",
    id: "320100"
  },
  {
    name: "泰州市",
    pid: "320000",
    id: "321200"
  },
  {
    name: "无锡市",
    pid: "320000",
    id: "320200"
  },
  {
    name: "宿迁市",
    pid: "320000",
    id: "321300"
  },
  {
    name: "市辖区",
    pid: "110000",
    id: "110100"
  },
  {
    name: "楚雄彝族自治州",
    pid: "530000",
    id: "532300"
  },
  {
    name: "迪庆藏族自治州",
    pid: "530000",
    id: "533400"
  },
  {
    name: "昆明市",
    pid: "530000",
    id: "530100"
  },
  {
    name: "怒江傈僳族自治州",
    pid: "530000",
    id: "533300"
  },
  {
    name: "德宏傣族景颇族自治州",
    pid: "530000",
    id: "533100"
  },
  {
    name: "普洱市",
    pid: "530000",
    id: "530800"
  },
  {
    name: "临沧市",
    pid: "530000",
    id: "530900"
  },
  {
    name: "昭通市",
    pid: "530000",
    id: "530600"
  },
  {
    name: "大理白族自治州",
    pid: "530000",
    id: "532900"
  },
  {
    name: "丽江市",
    pid: "530000",
    id: "530700"
  },
  {
    name: "西双版纳傣族自治州",
    pid: "530000",
    id: "532800"
  },
  {
    name: "玉溪市",
    pid: "530000",
    id: "530400"
  },
  {
    name: "保山市",
    pid: "530000",
    id: "530500"
  },
  {
    name: "文山壮族苗族自治州",
    pid: "530000",
    id: "532600"
  },
  {
    name: "红河哈尼族彝族自治州",
    pid: "530000",
    id: "532500"
  },
  {
    name: "曲靖市",
    pid: "530000",
    id: "530300"
  },
  {
    name: "海口市",
    pid: "460000",
    id: "460100"
  },
  {
    name: "儋州市",
    pid: "460000",
    id: "460400"
  },
  {
    name: "三沙市",
    pid: "460000",
    id: "460300"
  },
  {
    name: "三亚市",
    pid: "460000",
    id: "460200"
  },
  {
    name: "东方市",
    pid: "460000",
    id: "469007"
  },
  {
    name: "保亭黎族苗族自治县",
    pid: "460000",
    id: "469029"
  },
  {
    name: "万宁市",
    pid: "460000",
    id: "469006"
  },
  {
    name: "陵水黎族自治县",
    pid: "460000",
    id: "469028"
  },
  {
    name: "文昌市",
    pid: "460000",
    id: "469005"
  },
  {
    name: "乐东黎族自治县",
    pid: "460000",
    id: "469027"
  },
  {
    name: "昌江黎族自治县",
    pid: "460000",
    id: "469026"
  },
  {
    name: "白沙黎族自治县",
    pid: "460000",
    id: "469025"
  },
  {
    name: "琼海市",
    pid: "460000",
    id: "469002"
  },
  {
    name: "临高县",
    pid: "460000",
    id: "469024"
  },
  {
    name: "五指山市",
    pid: "460000",
    id: "469001"
  },
  {
    name: "澄迈县",
    pid: "460000",
    id: "469023"
  },
  {
    name: "屯昌县",
    pid: "460000",
    id: "469022"
  },
  {
    name: "定安县",
    pid: "460000",
    id: "469021"
  },
  {
    name: "琼中黎族苗族自治县",
    pid: "460000",
    id: "469030"
  },
  {
    name: "台州市",
    pid: "330000",
    id: "331000"
  },
  {
    name: "衢州市",
    pid: "330000",
    id: "330800"
  },
  {
    name: "舟山市",
    pid: "330000",
    id: "330900"
  },
  {
    name: "嘉兴市",
    pid: "330000",
    id: "330400"
  },
  {
    name: "湖州市",
    pid: "330000",
    id: "330500"
  },
  {
    name: "绍兴市",
    pid: "330000",
    id: "330600"
  },
  {
    name: "金华市",
    pid: "330000",
    id: "330700"
  },
  {
    name: "丽水市",
    pid: "330000",
    id: "331100"
  },
  {
    name: "杭州市",
    pid: "330000",
    id: "330100"
  },
  {
    name: "宁波市",
    pid: "330000",
    id: "330200"
  },
  {
    name: "温州市",
    pid: "330000",
    id: "330300"
  },
  {
    name: "市辖区",
    pid: "310000",
    id: "310100"
  },
  {
    name: "市辖区",
    pid: "120000",
    id: "120100"
  },
  {
    name: "宝鸡市",
    pid: "610000",
    id: "610300"
  },
  {
    name: "咸阳市",
    pid: "610000",
    id: "610400"
  },
  {
    name: "西安市",
    pid: "610000",
    id: "610100"
  },
  {
    name: "铜川市",
    pid: "610000",
    id: "610200"
  },
  {
    name: "商洛市",
    pid: "610000",
    id: "611000"
  },
  {
    name: "安康市",
    pid: "610000",
    id: "610900"
  },
  {
    name: "汉中市",
    pid: "610000",
    id: "610700"
  },
  {
    name: "榆林市",
    pid: "610000",
    id: "610800"
  },
  {
    name: "渭南市",
    pid: "610000",
    id: "610500"
  },
  {
    name: "延安市",
    pid: "610000",
    id: "610600"
  },
  {
    name: "香港特别行政区",
    pid: "910000",
    id: "810000"
  },
  {
    name: "澳门特别行政区",
    pid: "910000",
    id: "820000"
  },
  {
    name: "哈密市",
    pid: "650000",
    id: "650500"
  },
  {
    name: "吐鲁番市",
    pid: "650000",
    id: "650400"
  },
  {
    name: "克拉玛依市",
    pid: "650000",
    id: "650200"
  },
  {
    name: "乌鲁木齐市",
    pid: "650000",
    id: "650100"
  },
  {
    name: "昌吉回族自治州",
    pid: "650000",
    id: "652300"
  },
  {
    name: "博尔塔拉蒙古自治州",
    pid: "650000",
    id: "652700"
  },
  {
    name: "巴音郭楞蒙古自治州",
    pid: "650000",
    id: "652800"
  },
  {
    name: "阿克苏地区",
    pid: "650000",
    id: "652900"
  },
  {
    name: "五家渠市",
    pid: "650000",
    id: "659004"
  },
  {
    name: "图木舒克市",
    pid: "650000",
    id: "659003"
  },
  {
    name: "伊犁哈萨克自治州",
    pid: "650000",
    id: "654000"
  },
  {
    name: "阿拉尔市",
    pid: "650000",
    id: "659002"
  },
  {
    name: "克孜勒苏柯尔克孜自治州",
    pid: "650000",
    id: "653000"
  },
  {
    name: "喀什地区",
    pid: "650000",
    id: "653100"
  },
  {
    name: "塔城地区",
    pid: "650000",
    id: "654200"
  },
  {
    name: "和田地区",
    pid: "650000",
    id: "653200"
  },
  {
    name: "阿勒泰地区",
    pid: "650000",
    id: "654300"
  },
  {
    name: "铁门关市",
    pid: "650000",
    id: "659006"
  },
  {
    name: "石河子市",
    pid: "650000",
    id: "659001"
  },
  {
    name: "黔西南布依族苗族自治州",
    pid: "520000",
    id: "522300"
  },
  {
    name: "六盘水市",
    pid: "520000",
    id: "520200"
  },
  {
    name: "遵义市",
    pid: "520000",
    id: "520300"
  },
  {
    name: "贵阳市",
    pid: "520000",
    id: "520100"
  },
  {
    name: "安顺市",
    pid: "520000",
    id: "520400"
  },
  {
    name: "黔东南苗族侗族自治州",
    pid: "520000",
    id: "522600"
  },
  {
    name: "毕节市",
    pid: "520000",
    id: "520500"
  },
  {
    name: "黔南布依族苗族自治州",
    pid: "520000",
    id: "522700"
  },
  {
    name: "铜仁市",
    pid: "520000",
    id: "520600"
  },
  {
    name: "铜陵市",
    pid: "340000",
    id: "340700"
  },
  {
    name: "安庆市",
    pid: "340000",
    id: "340800"
  },
  {
    name: "宣城市",
    pid: "340000",
    id: "341800"
  },
  {
    name: "合肥市",
    pid: "340000",
    id: "340100"
  },
  {
    name: "阜阳市",
    pid: "340000",
    id: "341200"
  },
  {
    name: "芜湖市",
    pid: "340000",
    id: "340200"
  },
  {
    name: "宿州市",
    pid: "340000",
    id: "341300"
  },
  {
    name: "黄山市",
    pid: "340000",
    id: "341000"
  },
  {
    name: "滁州市",
    pid: "340000",
    id: "341100"
  },
  {
    name: "马鞍山市",
    pid: "340000",
    id: "340500"
  },
  {
    name: "池州市",
    pid: "340000",
    id: "341700"
  },
  {
    name: "淮北市",
    pid: "340000",
    id: "340600"
  },
  {
    name: "亳州市",
    pid: "340000",
    id: "341600"
  },
  {
    name: "蚌埠市",
    pid: "340000",
    id: "340300"
  },
  {
    name: "六安市",
    pid: "340000",
    id: "341500"
  },
  {
    name: "淮南市",
    pid: "340000",
    id: "340400"
  },
  {
    name: "株洲市",
    pid: "430000",
    id: "430200"
  },
  {
    name: "娄底市",
    pid: "430000",
    id: "431300"
  },
  {
    name: "长沙市",
    pid: "430000",
    id: "430100"
  },
  {
    name: "怀化市",
    pid: "430000",
    id: "431200"
  },
  {
    name: "衡阳市",
    pid: "430000",
    id: "430400"
  },
  {
    name: "湘潭市",
    pid: "430000",
    id: "430300"
  },
  {
    name: "岳阳市",
    pid: "430000",
    id: "430600"
  },
  {
    name: "邵阳市",
    pid: "430000",
    id: "430500"
  },
  {
    name: "张家界市",
    pid: "430000",
    id: "430800"
  },
  {
    name: "常德市",
    pid: "430000",
    id: "430700"
  },
  {
    name: "益阳市",
    pid: "430000",
    id: "430900"
  },
  {
    name: "永州市",
    pid: "430000",
    id: "431100"
  },
  {
    name: "郴州市",
    pid: "430000",
    id: "431000"
  },
  {
    name: "湘西土家族苗族自治州",
    pid: "430000",
    id: "433100"
  },
  {
    name: "石家庄市",
    pid: "130000",
    id: "130100"
  },
  {
    name: "衡水市",
    pid: "130000",
    id: "131100"
  },
  {
    name: "廊坊市",
    pid: "130000",
    id: "131000"
  },
  {
    name: "邯郸市",
    pid: "130000",
    id: "130400"
  },
  {
    name: "邢台市",
    pid: "130000",
    id: "130500"
  },
  {
    name: "唐山市",
    pid: "130000",
    id: "130200"
  },
  {
    name: "秦皇岛市",
    pid: "130000",
    id: "130300"
  },
  {
    name: "承德市",
    pid: "130000",
    id: "130800"
  },
  {
    name: "沧州市",
    pid: "130000",
    id: "130900"
  },
  {
    name: "保定市",
    pid: "130000",
    id: "130600"
  },
  {
    name: "张家口市",
    pid: "130000",
    id: "130700"
  },
  {
    name: "定州市",
    pid: "130000",
    id: "139001"
  },
  {
    name: "辛集市",
    pid: "130000",
    id: "139002"
  },
  {
    name: "盘锦市",
    pid: "210000",
    id: "211100"
  },
  {
    name: "辽阳市",
    pid: "210000",
    id: "211000"
  },
  {
    name: "阜新市",
    pid: "210000",
    id: "210900"
  },
  {
    name: "抚顺市",
    pid: "210000",
    id: "210400"
  },
  {
    name: "鞍山市",
    pid: "210000",
    id: "210300"
  },
  {
    name: "葫芦岛市",
    pid: "210000",
    id: "211400"
  },
  {
    name: "大连市",
    pid: "210000",
    id: "210200"
  },
  {
    name: "朝阳市",
    pid: "210000",
    id: "211300"
  },
  {
    name: "沈阳市",
    pid: "210000",
    id: "210100"
  },
  {
    name: "铁岭市",
    pid: "210000",
    id: "211200"
  },
  {
    name: "营口市",
    pid: "210000",
    id: "210800"
  },
  {
    name: "锦州市",
    pid: "210000",
    id: "210700"
  },
  {
    name: "丹东市",
    pid: "210000",
    id: "210600"
  },
  {
    name: "本溪市",
    pid: "210000",
    id: "210500"
  },
  {
    name: "资阳市",
    pid: "510000",
    id: "512000"
  },
  {
    name: "阿坝藏族羌族自治州",
    pid: "510000",
    id: "513200"
  },
  {
    name: "内江市",
    pid: "510000",
    id: "511000"
  },
  {
    name: "成都市",
    pid: "510000",
    id: "510100"
  },
  {
    name: "南充市",
    pid: "510000",
    id: "511300"
  },
  {
    name: "眉山市",
    pid: "510000",
    id: "511400"
  },
  {
    name: "自贡市",
    pid: "510000",
    id: "510300"
  },
  {
    name: "乐山市",
    pid: "510000",
    id: "511100"
  },
  {
    name: "凉山彝族自治州",
    pid: "510000",
    id: "513400"
  },
  {
    name: "甘孜藏族自治州",
    pid: "510000",
    id: "513300"
  },
  {
    name: "泸州市",
    pid: "510000",
    id: "510500"
  },
  {
    name: "达州市",
    pid: "510000",
    id: "511700"
  },
  {
    name: "攀枝花市",
    pid: "510000",
    id: "510400"
  },
  {
    name: "雅安市",
    pid: "510000",
    id: "511800"
  },
  {
    name: "绵阳市",
    pid: "510000",
    id: "510700"
  },
  {
    name: "宜宾市",
    pid: "510000",
    id: "511500"
  },
  {
    name: "德阳市",
    pid: "510000",
    id: "510600"
  },
  {
    name: "广安市",
    pid: "510000",
    id: "511600"
  },
  {
    name: "广元市",
    pid: "510000",
    id: "510800"
  },
  {
    name: "巴中市",
    pid: "510000",
    id: "511900"
  },
  {
    name: "遂宁市",
    pid: "510000",
    id: "510900"
  },
  {
    name: "银川市",
    pid: "640000",
    id: "640100"
  },
  {
    name: "吴忠市",
    pid: "640000",
    id: "640300"
  },
  {
    name: "石嘴山市",
    pid: "640000",
    id: "640200"
  },
  {
    name: "中卫市",
    pid: "640000",
    id: "640500"
  },
  {
    name: "固原市",
    pid: "640000",
    id: "640400"
  },
  {
    name: "白城市",
    pid: "220000",
    id: "220800"
  },
  {
    name: "长春市",
    pid: "220000",
    id: "220100"
  },
  {
    name: "延边朝鲜族自治州",
    pid: "220000",
    id: "222400"
  },
  {
    name: "四平市",
    pid: "220000",
    id: "220300"
  },
  {
    name: "吉林市",
    pid: "220000",
    id: "220200"
  },
  {
    name: "通化市",
    pid: "220000",
    id: "220500"
  },
  {
    name: "辽源市",
    pid: "220000",
    id: "220400"
  },
  {
    name: "松原市",
    pid: "220000",
    id: "220700"
  },
  {
    name: "白山市",
    pid: "220000",
    id: "220600"
  },
  {
    name: "漳州市",
    pid: "350000",
    id: "350600"
  },
  {
    name: "南平市",
    pid: "350000",
    id: "350700"
  },
  {
    name: "龙岩市",
    pid: "350000",
    id: "350800"
  },
  {
    name: "宁德市",
    pid: "350000",
    id: "350900"
  },
  {
    name: "福州市",
    pid: "350000",
    id: "350100"
  },
  {
    name: "厦门市",
    pid: "350000",
    id: "350200"
  },
  {
    name: "莆田市",
    pid: "350000",
    id: "350300"
  },
  {
    name: "三明市",
    pid: "350000",
    id: "350400"
  },
  {
    name: "泉州市",
    pid: "350000",
    id: "350500"
  },
  {
    name: "神农架林区",
    pid: "420000",
    id: "429021"
  },
  {
    name: "武汉市",
    pid: "420000",
    id: "420100"
  },
  {
    name: "咸宁市",
    pid: "420000",
    id: "421200"
  },
  {
    name: "天门市",
    pid: "420000",
    id: "429006"
  },
  {
    name: "黄冈市",
    pid: "420000",
    id: "421100"
  },
  {
    name: "潜江市",
    pid: "420000",
    id: "429005"
  },
  {
    name: "荆州市",
    pid: "420000",
    id: "421000"
  },
  {
    name: "仙桃市",
    pid: "420000",
    id: "429004"
  },
  {
    name: "宜昌市",
    pid: "420000",
    id: "420500"
  },
  {
    name: "恩施土家族苗族自治州",
    pid: "420000",
    id: "422800"
  },
  {
    name: "十堰市",
    pid: "420000",
    id: "420300"
  },
  {
    name: "黄石市",
    pid: "420000",
    id: "420200"
  },
  {
    name: "随州市",
    pid: "420000",
    id: "421300"
  },
  {
    name: "孝感市",
    pid: "420000",
    id: "420900"
  },
  {
    name: "荆门市",
    pid: "420000",
    id: "420800"
  },
  {
    name: "鄂州市",
    pid: "420000",
    id: "420700"
  },
  {
    name: "襄阳市",
    pid: "420000",
    id: "420600"
  },
  {
    name: "茂名市",
    pid: "440000",
    id: "440900"
  },
  {
    name: "湛江市",
    pid: "440000",
    id: "440800"
  },
  {
    name: "东莞市",
    pid: "440000",
    id: "441900"
  },
  {
    name: "江门市",
    pid: "440000",
    id: "440700"
  },
  {
    name: "清远市",
    pid: "440000",
    id: "441800"
  },
  {
    name: "佛山市",
    pid: "440000",
    id: "440600"
  },
  {
    name: "阳江市",
    pid: "440000",
    id: "441700"
  },
  {
    name: "汕头市",
    pid: "440000",
    id: "440500"
  },
  {
    name: "河源市",
    pid: "440000",
    id: "441600"
  },
  {
    name: "珠海市",
    pid: "440000",
    id: "440400"
  },
  {
    name: "汕尾市",
    pid: "440000",
    id: "441500"
  },
  {
    name: "深圳市",
    pid: "440000",
    id: "440300"
  },
  {
    name: "梅州市",
    pid: "440000",
    id: "441400"
  },
  {
    name: "揭阳市",
    pid: "440000",
    id: "445200"
  },
  {
    name: "韶关市",
    pid: "440000",
    id: "440200"
  },
  {
    name: "惠州市",
    pid: "440000",
    id: "441300"
  },
  {
    name: "潮州市",
    pid: "440000",
    id: "445100"
  },
  {
    name: "广州市",
    pid: "440000",
    id: "440100"
  },
  {
    name: "肇庆市",
    pid: "440000",
    id: "441200"
  },
  {
    name: "中山市",
    pid: "440000",
    id: "442000"
  },
  {
    name: "云浮市",
    pid: "440000",
    id: "445300"
  },
  {
    name: "梁平县",
    pid: "500000",
    id: "500228"
  },
  {
    name: "巫溪县",
    pid: "500000",
    id: "500238"
  },
  {
    name: "巫山县",
    pid: "500000",
    id: "500237"
  },
  {
    name: "奉节县",
    pid: "500000",
    id: "500236"
  },
  {
    name: "城口县",
    pid: "500000",
    id: "500229"
  },
  {
    name: "垫江县",
    pid: "500000",
    id: "500231"
  },
  {
    name: "酉阳土家族苗族自治县",
    pid: "500000",
    id: "500242"
  },
  {
    name: "丰都县",
    pid: "500000",
    id: "500230"
  },
  {
    name: "秀山土家族苗族自治县",
    pid: "500000",
    id: "500241"
  },
  {
    name: "石柱土家族自治县",
    pid: "500000",
    id: "500240"
  },
  {
    name: "云阳县",
    pid: "500000",
    id: "500235"
  },
  {
    name: "忠县",
    pid: "500000",
    id: "500233"
  },
  {
    name: "市辖区",
    pid: "500000",
    id: "500100"
  },
  {
    name: "武隆县",
    pid: "500000",
    id: "500232"
  },
  {
    name: "彭水苗族土家族自治县",
    pid: "500000",
    id: "500243"
  },
  {
    name: "吕梁市",
    pid: "140000",
    id: "141100"
  },
  {
    name: "临汾市",
    pid: "140000",
    id: "141000"
  },
  {
    name: "太原市",
    pid: "140000",
    id: "140100"
  },
  {
    name: "大同市",
    pid: "140000",
    id: "140200"
  },
  {
    name: "阳泉市",
    pid: "140000",
    id: "140300"
  },
  {
    name: "长治市",
    pid: "140000",
    id: "140400"
  },
  {
    name: "晋城市",
    pid: "140000",
    id: "140500"
  },
  {
    name: "朔州市",
    pid: "140000",
    id: "140600"
  },
  {
    name: "晋中市",
    pid: "140000",
    id: "140700"
  },
  {
    name: "运城市",
    pid: "140000",
    id: "140800"
  },
  {
    name: "忻州市",
    pid: "140000",
    id: "140900"
  },
  {
    name: "萍乡市",
    pid: "360000",
    id: "360300"
  },
  {
    name: "九江市",
    pid: "360000",
    id: "360400"
  },
  {
    name: "南昌市",
    pid: "360000",
    id: "360100"
  },
  {
    name: "景德镇市",
    pid: "360000",
    id: "360200"
  },
  {
    name: "上饶市",
    pid: "360000",
    id: "361100"
  },
  {
    name: "抚州市",
    pid: "360000",
    id: "361000"
  },
  {
    name: "宜春市",
    pid: "360000",
    id: "360900"
  },
  {
    name: "赣州市",
    pid: "360000",
    id: "360700"
  },
  {
    name: "吉安市",
    pid: "360000",
    id: "360800"
  },
  {
    name: "新余市",
    pid: "360000",
    id: "360500"
  },
  {
    name: "鹰潭市",
    pid: "360000",
    id: "360600"
  },
  {
    name: "大庆市",
    pid: "230000",
    id: "230600"
  },
  {
    name: "双鸭山市",
    pid: "230000",
    id: "230500"
  },
  {
    name: "鹤岗市",
    pid: "230000",
    id: "230400"
  },
  {
    name: "鸡西市",
    pid: "230000",
    id: "230300"
  },
  {
    name: "齐齐哈尔市",
    pid: "230000",
    id: "230200"
  },
  {
    name: "哈尔滨市",
    pid: "230000",
    id: "230100"
  },
  {
    name: "牡丹江市",
    pid: "230000",
    id: "231000"
  },
  {
    name: "黑河市",
    pid: "230000",
    id: "231100"
  },
  {
    name: "绥化市",
    pid: "230000",
    id: "231200"
  },
  {
    name: "七台河市",
    pid: "230000",
    id: "230900"
  },
  {
    name: "佳木斯市",
    pid: "230000",
    id: "230800"
  },
  {
    name: "大兴安岭地区",
    pid: "230000",
    id: "232700"
  },
  {
    name: "伊春市",
    pid: "230000",
    id: "230700"
  },
  {
    name: "西宁市",
    pid: "630000",
    id: "630100"
  },
  {
    name: "海东市",
    pid: "630000",
    id: "630200"
  },
  {
    name: "黄南藏族自治州",
    pid: "630000",
    id: "632300"
  },
  {
    name: "海北藏族自治州",
    pid: "630000",
    id: "632200"
  },
  {
    name: "海西蒙古族藏族自治州",
    pid: "630000",
    id: "632800"
  },
  {
    name: "玉树藏族自治州",
    pid: "630000",
    id: "632700"
  },
  {
    name: "果洛藏族自治州",
    pid: "630000",
    id: "632600"
  },
  {
    name: "海南藏族自治州",
    pid: "630000",
    id: "632500"
  },
  {
    name: "焦作市",
    pid: "410000",
    id: "410800"
  },
  {
    name: "新乡市",
    pid: "410000",
    id: "410700"
  },
  {
    name: "濮阳市",
    pid: "410000",
    id: "410900"
  },
  {
    name: "平顶山市",
    pid: "410000",
    id: "410400"
  },
  {
    name: "信阳市",
    pid: "410000",
    id: "411500"
  },
  {
    name: "洛阳市",
    pid: "410000",
    id: "410300"
  },
  {
    name: "商丘市",
    pid: "410000",
    id: "411400"
  },
  {
    name: "鹤壁市",
    pid: "410000",
    id: "410600"
  },
  {
    name: "驻马店市",
    pid: "410000",
    id: "411700"
  },
  {
    name: "安阳市",
    pid: "410000",
    id: "410500"
  },
  {
    name: "周口市",
    pid: "410000",
    id: "411600"
  },
  {
    name: "漯河市",
    pid: "410000",
    id: "411100"
  },
  {
    name: "许昌市",
    pid: "410000",
    id: "411000"
  },
  {
    name: "开封市",
    pid: "410000",
    id: "410200"
  },
  {
    name: "南阳市",
    pid: "410000",
    id: "411300"
  },
  {
    name: "郑州市",
    pid: "410000",
    id: "410100"
  },
  {
    name: "三门峡市",
    pid: "410000",
    id: "411200"
  },
  {
    name: "济源市",
    pid: "410000",
    id: "419001"
  },
  {
    name: "台湾省",
    pid: "710000",
    id: "710100"
  },
  {
    name: "巴彦淖尔市",
    pid: "150000",
    id: "150800"
  },
  {
    name: "乌兰察布市",
    pid: "150000",
    id: "150900"
  },
  {
    name: "鄂尔多斯市",
    pid: "150000",
    id: "150600"
  },
  {
    name: "阿拉善盟",
    pid: "150000",
    id: "152900"
  },
  {
    name: "呼伦贝尔市",
    pid: "150000",
    id: "150700"
  },
  {
    name: "赤峰市",
    pid: "150000",
    id: "150400"
  },
  {
    name: "通辽市",
    pid: "150000",
    id: "150500"
  },
  {
    name: "包头市",
    pid: "150000",
    id: "150200"
  },
  {
    name: "锡林郭勒盟",
    pid: "150000",
    id: "152500"
  },
  {
    name: "乌海市",
    pid: "150000",
    id: "150300"
  },
  {
    name: "呼和浩特市",
    pid: "150000",
    id: "150100"
  },
  {
    name: "兴安盟",
    pid: "150000",
    id: "152200"
  },
  {
    name: "山南市",
    pid: "540000",
    id: "540500"
  },
  {
    name: "拉萨市",
    pid: "540000",
    id: "540100"
  },
  {
    name: "那曲地区",
    pid: "540000",
    id: "542400"
  },
  {
    name: "日喀则市",
    pid: "540000",
    id: "540200"
  },
  {
    name: "昌都市",
    pid: "540000",
    id: "540300"
  },
  {
    name: "林芝市",
    pid: "540000",
    id: "540400"
  },
  {
    name: "阿里地区",
    pid: "540000",
    id: "542500"
  },
  {
    name: "贵港市",
    pid: "450000",
    id: "450800"
  },
  {
    name: "钦州市",
    pid: "450000",
    id: "450700"
  },
  {
    name: "玉林市",
    pid: "450000",
    id: "450900"
  },
  {
    name: "梧州市",
    pid: "450000",
    id: "450400"
  },
  {
    name: "桂林市",
    pid: "450000",
    id: "450300"
  },
  {
    name: "崇左市",
    pid: "450000",
    id: "451400"
  },
  {
    name: "防城港市",
    pid: "450000",
    id: "450600"
  },
  {
    name: "北海市",
    pid: "450000",
    id: "450500"
  },
  {
    name: "贺州市",
    pid: "450000",
    id: "451100"
  },
  {
    name: "百色市",
    pid: "450000",
    id: "451000"
  },
  {
    name: "柳州市",
    pid: "450000",
    id: "450200"
  },
  {
    name: "来宾市",
    pid: "450000",
    id: "451300"
  },
  {
    name: "南宁市",
    pid: "450000",
    id: "450100"
  },
  {
    name: "河池市",
    pid: "450000",
    id: "451200"
  }
];
var AD = [{
    name: "莱城区",
    pid: "370000",
    id: "371202",
    cid: "371200"
  },
  {
    name: "钢城区",
    pid: "370000",
    id: "371203",
    cid: "371200"
  },
  {
    name: "历城区",
    pid: "370000",
    id: "370112",
    cid: "370100"
  },
  {
    name: "历下区",
    pid: "370000",
    id: "370102",
    cid: "370100"
  },
  {
    name: "长清区",
    pid: "370000",
    id: "370113",
    cid: "370100"
  },
  {
    name: "平阴县",
    pid: "370000",
    id: "370124",
    cid: "370100"
  },
  {
    name: "章丘市",
    pid: "370000",
    id: "370181",
    cid: "370100"
  },
  {
    name: "市中区",
    pid: "370000",
    id: "370103",
    cid: "370100"
  },
  {
    name: "济阳县",
    pid: "370000",
    id: "370125",
    cid: "370100"
  },
  {
    name: "槐荫区",
    pid: "370000",
    id: "370104",
    cid: "370100"
  },
  {
    name: "商河县",
    pid: "370000",
    id: "370126",
    cid: "370100"
  },
  {
    name: "天桥区",
    pid: "370000",
    id: "370105",
    cid: "370100"
  },
  {
    name: "莒县",
    pid: "370000",
    id: "371122",
    cid: "371100"
  },
  {
    name: "岚山区",
    pid: "370000",
    id: "371103",
    cid: "371100"
  },
  {
    name: "东港区",
    pid: "370000",
    id: "371102",
    cid: "371100"
  },
  {
    name: "五莲县",
    pid: "370000",
    id: "371121",
    cid: "371100"
  },
  {
    name: "黄岛区",
    pid: "370000",
    id: "370211",
    cid: "370200"
  },
  {
    name: "崂山区",
    pid: "370000",
    id: "370212",
    cid: "370200"
  },
  {
    name: "即墨市",
    pid: "370000",
    id: "370282",
    cid: "370200"
  },
  {
    name: "平度市",
    pid: "370000",
    id: "370283",
    cid: "370200"
  },
  {
    name: "莱西市",
    pid: "370000",
    id: "370285",
    cid: "370200"
  },
  {
    name: "胶州市",
    pid: "370000",
    id: "370281",
    cid: "370200"
  },
  {
    name: "市南区",
    pid: "370000",
    id: "370202",
    cid: "370200"
  },
  {
    name: "李沧区",
    pid: "370000",
    id: "370213",
    cid: "370200"
  },
  {
    name: "市北区",
    pid: "370000",
    id: "370203",
    cid: "370200"
  },
  {
    name: "城阳区",
    pid: "370000",
    id: "370214",
    cid: "370200"
  },
  {
    name: "宁津县",
    pid: "370000",
    id: "371422",
    cid: "371400"
  },
  {
    name: "禹城市",
    pid: "370000",
    id: "371482",
    cid: "371400"
  },
  {
    name: "乐陵市",
    pid: "370000",
    id: "371481",
    cid: "371400"
  },
  {
    name: "武城县",
    pid: "370000",
    id: "371428",
    cid: "371400"
  },
  {
    name: "夏津县",
    pid: "370000",
    id: "371427",
    cid: "371400"
  },
  {
    name: "德城区",
    pid: "370000",
    id: "371402",
    cid: "371400"
  },
  {
    name: "临邑县",
    pid: "370000",
    id: "371424",
    cid: "371400"
  },
  {
    name: "庆云县",
    pid: "370000",
    id: "371423",
    cid: "371400"
  },
  {
    name: "平原县",
    pid: "370000",
    id: "371426",
    cid: "371400"
  },
  {
    name: "陵城区",
    pid: "370000",
    id: "371403",
    cid: "371400"
  },
  {
    name: "齐河县",
    pid: "370000",
    id: "371425",
    cid: "371400"
  },
  {
    name: "桓台县",
    pid: "370000",
    id: "370321",
    cid: "370300"
  },
  {
    name: "高青县",
    pid: "370000",
    id: "370322",
    cid: "370300"
  },
  {
    name: "临淄区",
    pid: "370000",
    id: "370305",
    cid: "370300"
  },
  {
    name: "周村区",
    pid: "370000",
    id: "370306",
    cid: "370300"
  },
  {
    name: "沂源县",
    pid: "370000",
    id: "370323",
    cid: "370300"
  },
  {
    name: "淄川区",
    pid: "370000",
    id: "370302",
    cid: "370300"
  },
  {
    name: "张店区",
    pid: "370000",
    id: "370303",
    cid: "370300"
  },
  {
    name: "博山区",
    pid: "370000",
    id: "370304",
    cid: "370300"
  },
  {
    name: "沂南县",
    pid: "370000",
    id: "371321",
    cid: "371300"
  },
  {
    name: "河东区",
    pid: "370000",
    id: "371312",
    cid: "371300"
  },
  {
    name: "沂水县",
    pid: "370000",
    id: "371323",
    cid: "371300"
  },
  {
    name: "罗庄区",
    pid: "370000",
    id: "371311",
    cid: "371300"
  },
  {
    name: "郯城县",
    pid: "370000",
    id: "371322",
    cid: "371300"
  },
  {
    name: "临沭县",
    pid: "370000",
    id: "371329",
    cid: "371300"
  },
  {
    name: "蒙阴县",
    pid: "370000",
    id: "371328",
    cid: "371300"
  },
  {
    name: "费县",
    pid: "370000",
    id: "371325",
    cid: "371300"
  },
  {
    name: "兰山区",
    pid: "370000",
    id: "371302",
    cid: "371300"
  },
  {
    name: "兰陵县",
    pid: "370000",
    id: "371324",
    cid: "371300"
  },
  {
    name: "莒南县",
    pid: "370000",
    id: "371327",
    cid: "371300"
  },
  {
    name: "平邑县",
    pid: "370000",
    id: "371326",
    cid: "371300"
  },
  {
    name: "环翠区",
    pid: "370000",
    id: "371002",
    cid: "371000"
  },
  {
    name: "文登区",
    pid: "370000",
    id: "371003",
    cid: "371000"
  },
  {
    name: "荣成市",
    pid: "370000",
    id: "371082",
    cid: "371000"
  },
  {
    name: "乳山市",
    pid: "370000",
    id: "371083",
    cid: "371000"
  },
  {
    name: "曲阜市",
    pid: "370000",
    id: "370881",
    cid: "370800"
  },
  {
    name: "邹城市",
    pid: "370000",
    id: "370883",
    cid: "370800"
  },
  {
    name: "微山县",
    pid: "370000",
    id: "370826",
    cid: "370800"
  },
  {
    name: "鱼台县",
    pid: "370000",
    id: "370827",
    cid: "370800"
  },
  {
    name: "金乡县",
    pid: "370000",
    id: "370828",
    cid: "370800"
  },
  {
    name: "嘉祥县",
    pid: "370000",
    id: "370829",
    cid: "370800"
  },
  {
    name: "任城区",
    pid: "370000",
    id: "370811",
    cid: "370800"
  },
  {
    name: "兖州区",
    pid: "370000",
    id: "370812",
    cid: "370800"
  },
  {
    name: "汶上县",
    pid: "370000",
    id: "370830",
    cid: "370800"
  },
  {
    name: "泗水县",
    pid: "370000",
    id: "370831",
    cid: "370800"
  },
  {
    name: "梁山县",
    pid: "370000",
    id: "370832",
    cid: "370800"
  },
  {
    name: "新泰市",
    pid: "370000",
    id: "370982",
    cid: "370900"
  },
  {
    name: "宁阳县",
    pid: "370000",
    id: "370921",
    cid: "370900"
  },
  {
    name: "岱岳区",
    pid: "370000",
    id: "370911",
    cid: "370900"
  },
  {
    name: "东平县",
    pid: "370000",
    id: "370923",
    cid: "370900"
  },
  {
    name: "泰山区",
    pid: "370000",
    id: "370902",
    cid: "370900"
  },
  {
    name: "肥城市",
    pid: "370000",
    id: "370983",
    cid: "370900"
  },
  {
    name: "滕州市",
    pid: "370000",
    id: "370481",
    cid: "370400"
  },
  {
    name: "峄城区",
    pid: "370000",
    id: "370404",
    cid: "370400"
  },
  {
    name: "台儿庄区",
    pid: "370000",
    id: "370405",
    cid: "370400"
  },
  {
    name: "山亭区",
    pid: "370000",
    id: "370406",
    cid: "370400"
  },
  {
    name: "市中区",
    pid: "370000",
    id: "370402",
    cid: "370400"
  },
  {
    name: "薛城区",
    pid: "370000",
    id: "370403",
    cid: "370400"
  },
  {
    name: "邹平县",
    pid: "370000",
    id: "371626",
    cid: "371600"
  },
  {
    name: "沾化区",
    pid: "370000",
    id: "371603",
    cid: "371600"
  },
  {
    name: "博兴县",
    pid: "370000",
    id: "371625",
    cid: "371600"
  },
  {
    name: "阳信县",
    pid: "370000",
    id: "371622",
    cid: "371600"
  },
  {
    name: "惠民县",
    pid: "370000",
    id: "371621",
    cid: "371600"
  },
  {
    name: "滨城区",
    pid: "370000",
    id: "371602",
    cid: "371600"
  },
  {
    name: "无棣县",
    pid: "370000",
    id: "371623",
    cid: "371600"
  },
  {
    name: "河口区",
    pid: "370000",
    id: "370503",
    cid: "370500"
  },
  {
    name: "垦利区",
    pid: "370000",
    id: "370505",
    cid: "370500"
  },
  {
    name: "利津县",
    pid: "370000",
    id: "370522",
    cid: "370500"
  },
  {
    name: "广饶县",
    pid: "370000",
    id: "370523",
    cid: "370500"
  },
  {
    name: "东营区",
    pid: "370000",
    id: "370502",
    cid: "370500"
  },
  {
    name: "阳谷县",
    pid: "370000",
    id: "371521",
    cid: "371500"
  },
  {
    name: "临清市",
    pid: "370000",
    id: "371581",
    cid: "371500"
  },
  {
    name: "高唐县",
    pid: "370000",
    id: "371526",
    cid: "371500"
  },
  {
    name: "茌平县",
    pid: "370000",
    id: "371523",
    cid: "371500"
  },
  {
    name: "莘县",
    pid: "370000",
    id: "371522",
    cid: "371500"
  },
  {
    name: "冠县",
    pid: "370000",
    id: "371525",
    cid: "371500"
  },
  {
    name: "东昌府区",
    pid: "370000",
    id: "371502",
    cid: "371500"
  },
  {
    name: "东阿县",
    pid: "370000",
    id: "371524",
    cid: "371500"
  },
  {
    name: "莱阳市",
    pid: "370000",
    id: "370682",
    cid: "370600"
  },
  {
    name: "莱州市",
    pid: "370000",
    id: "370683",
    cid: "370600"
  },
  {
    name: "蓬莱市",
    pid: "370000",
    id: "370684",
    cid: "370600"
  },
  {
    name: "招远市",
    pid: "370000",
    id: "370685",
    cid: "370600"
  },
  {
    name: "龙口市",
    pid: "370000",
    id: "370681",
    cid: "370600"
  },
  {
    name: "芝罘区",
    pid: "370000",
    id: "370602",
    cid: "370600"
  },
  {
    name: "莱山区",
    pid: "370000",
    id: "370613",
    cid: "370600"
  },
  {
    name: "栖霞市",
    pid: "370000",
    id: "370686",
    cid: "370600"
  },
  {
    name: "海阳市",
    pid: "370000",
    id: "370687",
    cid: "370600"
  },
  {
    name: "福山区",
    pid: "370000",
    id: "370611",
    cid: "370600"
  },
  {
    name: "牟平区",
    pid: "370000",
    id: "370612",
    cid: "370600"
  },
  {
    name: "长岛县",
    pid: "370000",
    id: "370634",
    cid: "370600"
  },
  {
    name: "青州市",
    pid: "370000",
    id: "370781",
    cid: "370700"
  },
  {
    name: "诸城市",
    pid: "370000",
    id: "370782",
    cid: "370700"
  },
  {
    name: "寿光市",
    pid: "370000",
    id: "370783",
    cid: "370700"
  },
  {
    name: "安丘市",
    pid: "370000",
    id: "370784",
    cid: "370700"
  },
  {
    name: "奎文区",
    pid: "370000",
    id: "370705",
    cid: "370700"
  },
  {
    name: "潍城区",
    pid: "370000",
    id: "370702",
    cid: "370700"
  },
  {
    name: "临朐县",
    pid: "370000",
    id: "370724",
    cid: "370700"
  },
  {
    name: "寒亭区",
    pid: "370000",
    id: "370703",
    cid: "370700"
  },
  {
    name: "昌乐县",
    pid: "370000",
    id: "370725",
    cid: "370700"
  },
  {
    name: "坊子区",
    pid: "370000",
    id: "370704",
    cid: "370700"
  },
  {
    name: "高密市",
    pid: "370000",
    id: "370785",
    cid: "370700"
  },
  {
    name: "昌邑市",
    pid: "370000",
    id: "370786",
    cid: "370700"
  },
  {
    name: "东明县",
    pid: "370000",
    id: "371728",
    cid: "371700"
  },
  {
    name: "定陶区",
    pid: "370000",
    id: "371703",
    cid: "371700"
  },
  {
    name: "郓城县",
    pid: "370000",
    id: "371725",
    cid: "371700"
  },
  {
    name: "牡丹区",
    pid: "370000",
    id: "371702",
    cid: "371700"
  },
  {
    name: "巨野县",
    pid: "370000",
    id: "371724",
    cid: "371700"
  },
  {
    name: "鄄城县",
    pid: "370000",
    id: "371726",
    cid: "371700"
  },
  {
    name: "曹县",
    pid: "370000",
    id: "371721",
    cid: "371700"
  },
  {
    name: "成武县",
    pid: "370000",
    id: "371723",
    cid: "371700"
  },
  {
    name: "单县",
    pid: "370000",
    id: "371722",
    cid: "371700"
  },
  {
    name: "文县",
    pid: "620000",
    id: "621222",
    cid: "621200"
  },
  {
    name: "成县",
    pid: "620000",
    id: "621221",
    cid: "621200"
  },
  {
    name: "武都区",
    pid: "620000",
    id: "621202",
    cid: "621200"
  },
  {
    name: "康县",
    pid: "620000",
    id: "621224",
    cid: "621200"
  },
  {
    name: "宕昌县",
    pid: "620000",
    id: "621223",
    cid: "621200"
  },
  {
    name: "礼县",
    pid: "620000",
    id: "621226",
    cid: "621200"
  },
  {
    name: "西和县",
    pid: "620000",
    id: "621225",
    cid: "621200"
  },
  {
    name: "两当县",
    pid: "620000",
    id: "621228",
    cid: "621200"
  },
  {
    name: "徽县",
    pid: "620000",
    id: "621227",
    cid: "621200"
  },
  {
    name: "永登县",
    pid: "620000",
    id: "620121",
    cid: "620100"
  },
  {
    name: "红古区",
    pid: "620000",
    id: "620111",
    cid: "620100"
  },
  {
    name: "皋兰县",
    pid: "620000",
    id: "620122",
    cid: "620100"
  },
  {
    name: "榆中县",
    pid: "620000",
    id: "620123",
    cid: "620100"
  },
  {
    name: "城关区",
    pid: "620000",
    id: "620102",
    cid: "620100"
  },
  {
    name: "七里河区",
    pid: "620000",
    id: "620103",
    cid: "620100"
  },
  {
    name: "西固区",
    pid: "620000",
    id: "620104",
    cid: "620100"
  },
  {
    name: "安宁区",
    pid: "620000",
    id: "620105",
    cid: "620100"
  },
  {
    name: "渭源县",
    pid: "620000",
    id: "621123",
    cid: "621100"
  },
  {
    name: "陇西县",
    pid: "620000",
    id: "621122",
    cid: "621100"
  },
  {
    name: "漳县",
    pid: "620000",
    id: "621125",
    cid: "621100"
  },
  {
    name: "安定区",
    pid: "620000",
    id: "621102",
    cid: "621100"
  },
  {
    name: "临洮县",
    pid: "620000",
    id: "621124",
    cid: "621100"
  },
  {
    name: "通渭县",
    pid: "620000",
    id: "621121",
    cid: "621100"
  },
  {
    name: "岷县",
    pid: "620000",
    id: "621126",
    cid: "621100"
  },
  {
    name: "永昌县",
    pid: "620000",
    id: "620321",
    cid: "620300"
  },
  {
    name: "金川区",
    pid: "620000",
    id: "620302",
    cid: "620300"
  },
  {
    name: "西峰区",
    pid: "620000",
    id: "621002",
    cid: "621000"
  },
  {
    name: "合水县",
    pid: "620000",
    id: "621024",
    cid: "621000"
  },
  {
    name: "华池县",
    pid: "620000",
    id: "621023",
    cid: "621000"
  },
  {
    name: "宁县",
    pid: "620000",
    id: "621026",
    cid: "621000"
  },
  {
    name: "正宁县",
    pid: "620000",
    id: "621025",
    cid: "621000"
  },
  {
    name: "环县",
    pid: "620000",
    id: "621022",
    cid: "621000"
  },
  {
    name: "庆城县",
    pid: "620000",
    id: "621021",
    cid: "621000"
  },
  {
    name: "镇原县",
    pid: "620000",
    id: "621027",
    cid: "621000"
  },
  {
    name: "夏河县",
    pid: "620000",
    id: "623027",
    cid: "623000"
  },
  {
    name: "玛曲县",
    pid: "620000",
    id: "623025",
    cid: "623000"
  },
  {
    name: "碌曲县",
    pid: "620000",
    id: "623026",
    cid: "623000"
  },
  {
    name: "合作市",
    pid: "620000",
    id: "623001",
    cid: "623000"
  },
  {
    name: "舟曲县",
    pid: "620000",
    id: "623023",
    cid: "623000"
  },
  {
    name: "迭部县",
    pid: "620000",
    id: "623024",
    cid: "623000"
  },
  {
    name: "临潭县",
    pid: "620000",
    id: "623021",
    cid: "623000"
  },
  {
    name: "卓尼县",
    pid: "620000",
    id: "623022",
    cid: "623000"
  },
  {
    name: "静宁县",
    pid: "620000",
    id: "620826",
    cid: "620800"
  },
  {
    name: "灵台县",
    pid: "620000",
    id: "620822",
    cid: "620800"
  },
  {
    name: "崇信县",
    pid: "620000",
    id: "620823",
    cid: "620800"
  },
  {
    name: "崆峒区",
    pid: "620000",
    id: "620802",
    cid: "620800"
  },
  {
    name: "华亭县",
    pid: "620000",
    id: "620824",
    cid: "620800"
  },
  {
    name: "庄浪县",
    pid: "620000",
    id: "620825",
    cid: "620800"
  },
  {
    name: "泾川县",
    pid: "620000",
    id: "620821",
    cid: "620800"
  },
  {
    name: "玉门市",
    pid: "620000",
    id: "620981",
    cid: "620900"
  },
  {
    name: "敦煌市",
    pid: "620000",
    id: "620982",
    cid: "620900"
  },
  {
    name: "金塔县",
    pid: "620000",
    id: "620921",
    cid: "620900"
  },
  {
    name: "瓜州县",
    pid: "620000",
    id: "620922",
    cid: "620900"
  },
  {
    name: "肃北蒙古族自治县",
    pid: "620000",
    id: "620923",
    cid: "620900"
  },
  {
    name: "肃州区",
    pid: "620000",
    id: "620902",
    cid: "620900"
  },
  {
    name: "阿克塞哈萨克族自治县",
    pid: "620000",
    id: "620924",
    cid: "620900"
  },
  {
    name: "靖远县",
    pid: "620000",
    id: "620421",
    cid: "620400"
  },
  {
    name: "会宁县",
    pid: "620000",
    id: "620422",
    cid: "620400"
  },
  {
    name: "景泰县",
    pid: "620000",
    id: "620423",
    cid: "620400"
  },
  {
    name: "白银区",
    pid: "620000",
    id: "620402",
    cid: "620400"
  },
  {
    name: "平川区",
    pid: "620000",
    id: "620403",
    cid: "620400"
  },
  {
    name: "麦积区",
    pid: "620000",
    id: "620503",
    cid: "620500"
  },
  {
    name: "张家川回族自治县",
    pid: "620000",
    id: "620525",
    cid: "620500"
  },
  {
    name: "清水县",
    pid: "620000",
    id: "620521",
    cid: "620500"
  },
  {
    name: "秦安县",
    pid: "620000",
    id: "620522",
    cid: "620500"
  },
  {
    name: "甘谷县",
    pid: "620000",
    id: "620523",
    cid: "620500"
  },
  {
    name: "秦州区",
    pid: "620000",
    id: "620502",
    cid: "620500"
  },
  {
    name: "武山县",
    pid: "620000",
    id: "620524",
    cid: "620500"
  },
  {
    name: "凉州区",
    pid: "620000",
    id: "620602",
    cid: "620600"
  },
  {
    name: "民勤县",
    pid: "620000",
    id: "620621",
    cid: "620600"
  },
  {
    name: "古浪县",
    pid: "620000",
    id: "620622",
    cid: "620600"
  },
  {
    name: "天祝藏族自治县",
    pid: "620000",
    id: "620623",
    cid: "620600"
  },
  {
    name: "积石山保安族东乡族撒拉族自治县",
    pid: "620000",
    id: "622927",
    cid: "622900"
  },
  {
    name: "广河县",
    pid: "620000",
    id: "622924",
    cid: "622900"
  },
  {
    name: "临夏市",
    pid: "620000",
    id: "622901",
    cid: "622900"
  },
  {
    name: "永靖县",
    pid: "620000",
    id: "622923",
    cid: "622900"
  },
  {
    name: "东乡族自治县",
    pid: "620000",
    id: "622926",
    cid: "622900"
  },
  {
    name: "和政县",
    pid: "620000",
    id: "622925",
    cid: "622900"
  },
  {
    name: "康乐县",
    pid: "620000",
    id: "622922",
    cid: "622900"
  },
  {
    name: "临夏县",
    pid: "620000",
    id: "622921",
    cid: "622900"
  },
  {
    name: "临泽县",
    pid: "620000",
    id: "620723",
    cid: "620700"
  },
  {
    name: "甘州区",
    pid: "620000",
    id: "620702",
    cid: "620700"
  },
  {
    name: "高台县",
    pid: "620000",
    id: "620724",
    cid: "620700"
  },
  {
    name: "山丹县",
    pid: "620000",
    id: "620725",
    cid: "620700"
  },
  {
    name: "肃南裕固族自治县",
    pid: "620000",
    id: "620721",
    cid: "620700"
  },
  {
    name: "民乐县",
    pid: "620000",
    id: "620722",
    cid: "620700"
  },
  {
    name: "江都区",
    pid: "320000",
    id: "321012",
    cid: "321000"
  },
  {
    name: "宝应县",
    pid: "320000",
    id: "321023",
    cid: "321000"
  },
  {
    name: "高邮市",
    pid: "320000",
    id: "321084",
    cid: "321000"
  },
  {
    name: "仪征市",
    pid: "320000",
    id: "321081",
    cid: "321000"
  },
  {
    name: "广陵区",
    pid: "320000",
    id: "321002",
    cid: "321000"
  },
  {
    name: "邗江区",
    pid: "320000",
    id: "321003",
    cid: "321000"
  },
  {
    name: "润州区",
    pid: "320000",
    id: "321111",
    cid: "321100"
  },
  {
    name: "句容市",
    pid: "320000",
    id: "321183",
    cid: "321100"
  },
  {
    name: "丹阳市",
    pid: "320000",
    id: "321181",
    cid: "321100"
  },
  {
    name: "扬中市",
    pid: "320000",
    id: "321182",
    cid: "321100"
  },
  {
    name: "丹徒区",
    pid: "320000",
    id: "321112",
    cid: "321100"
  },
  {
    name: "京口区",
    pid: "320000",
    id: "321102",
    cid: "321100"
  },
  {
    name: "大丰区",
    pid: "320000",
    id: "320904",
    cid: "320900"
  },
  {
    name: "亭湖区",
    pid: "320000",
    id: "320902",
    cid: "320900"
  },
  {
    name: "射阳县",
    pid: "320000",
    id: "320924",
    cid: "320900"
  },
  {
    name: "盐都区",
    pid: "320000",
    id: "320903",
    cid: "320900"
  },
  {
    name: "建湖县",
    pid: "320000",
    id: "320925",
    cid: "320900"
  },
  {
    name: "滨海县",
    pid: "320000",
    id: "320922",
    cid: "320900"
  },
  {
    name: "阜宁县",
    pid: "320000",
    id: "320923",
    cid: "320900"
  },
  {
    name: "响水县",
    pid: "320000",
    id: "320921",
    cid: "320900"
  },
  {
    name: "东台市",
    pid: "320000",
    id: "320981",
    cid: "320900"
  },
  {
    name: "海州区",
    pid: "320000",
    id: "320706",
    cid: "320700"
  },
  {
    name: "赣榆区",
    pid: "320000",
    id: "320707",
    cid: "320700"
  },
  {
    name: "灌南县",
    pid: "320000",
    id: "320724",
    cid: "320700"
  },
  {
    name: "连云区",
    pid: "320000",
    id: "320703",
    cid: "320700"
  },
  {
    name: "东海县",
    pid: "320000",
    id: "320722",
    cid: "320700"
  },
  {
    name: "灌云县",
    pid: "320000",
    id: "320723",
    cid: "320700"
  },
  {
    name: "淮安区",
    pid: "320000",
    id: "320803",
    cid: "320800"
  },
  {
    name: "淮阴区",
    pid: "320000",
    id: "320804",
    cid: "320800"
  },
  {
    name: "涟水县",
    pid: "320000",
    id: "320826",
    cid: "320800"
  },
  {
    name: "清江浦区",
    pid: "320000",
    id: "320812",
    cid: "320800"
  },
  {
    name: "洪泽区",
    pid: "320000",
    id: "320813",
    cid: "320800"
  },
  {
    name: "盱眙县",
    pid: "320000",
    id: "320830",
    cid: "320800"
  },
  {
    name: "金湖县",
    pid: "320000",
    id: "320831",
    cid: "320800"
  },
  {
    name: "张家港市",
    pid: "320000",
    id: "320582",
    cid: "320500"
  },
  {
    name: "昆山市",
    pid: "320000",
    id: "320583",
    cid: "320500"
  },
  {
    name: "姑苏区",
    pid: "320000",
    id: "320508",
    cid: "320500"
  },
  {
    name: "吴江区",
    pid: "320000",
    id: "320509",
    cid: "320500"
  },
  {
    name: "常熟市",
    pid: "320000",
    id: "320581",
    cid: "320500"
  },
  {
    name: "吴中区",
    pid: "320000",
    id: "320506",
    cid: "320500"
  },
  {
    name: "相城区",
    pid: "320000",
    id: "320507",
    cid: "320500"
  },
  {
    name: "虎丘区",
    pid: "320000",
    id: "320505",
    cid: "320500"
  },
  {
    name: "太仓市",
    pid: "320000",
    id: "320585",
    cid: "320500"
  },
  {
    name: "启东市",
    pid: "320000",
    id: "320681",
    cid: "320600"
  },
  {
    name: "如皋市",
    pid: "320000",
    id: "320682",
    cid: "320600"
  },
  {
    name: "通州区",
    pid: "320000",
    id: "320612",
    cid: "320600"
  },
  {
    name: "如东县",
    pid: "320000",
    id: "320623",
    cid: "320600"
  },
  {
    name: "崇川区",
    pid: "320000",
    id: "320602",
    cid: "320600"
  },
  {
    name: "海安县",
    pid: "320000",
    id: "320621",
    cid: "320600"
  },
  {
    name: "港闸区",
    pid: "320000",
    id: "320611",
    cid: "320600"
  },
  {
    name: "海门市",
    pid: "320000",
    id: "320684",
    cid: "320600"
  },
  {
    name: "邳州市",
    pid: "320000",
    id: "320382",
    cid: "320300"
  },
  {
    name: "新沂市",
    pid: "320000",
    id: "320381",
    cid: "320300"
  },
  {
    name: "贾汪区",
    pid: "320000",
    id: "320305",
    cid: "320300"
  },
  {
    name: "鼓楼区",
    pid: "320000",
    id: "320302",
    cid: "320300"
  },
  {
    name: "睢宁县",
    pid: "320000",
    id: "320324",
    cid: "320300"
  },
  {
    name: "云龙区",
    pid: "320000",
    id: "320303",
    cid: "320300"
  },
  {
    name: "泉山区",
    pid: "320000",
    id: "320311",
    cid: "320300"
  },
  {
    name: "沛县",
    pid: "320000",
    id: "320322",
    cid: "320300"
  },
  {
    name: "铜山区",
    pid: "320000",
    id: "320312",
    cid: "320300"
  },
  {
    name: "丰县",
    pid: "320000",
    id: "320321",
    cid: "320300"
  },
  {
    name: "溧阳市",
    pid: "320000",
    id: "320481",
    cid: "320400"
  },
  {
    name: "钟楼区",
    pid: "320000",
    id: "320404",
    cid: "320400"
  },
  {
    name: "武进区",
    pid: "320000",
    id: "320412",
    cid: "320400"
  },
  {
    name: "天宁区",
    pid: "320000",
    id: "320402",
    cid: "320400"
  },
  {
    name: "金坛区",
    pid: "320000",
    id: "320413",
    cid: "320400"
  },
  {
    name: "新北区",
    pid: "320000",
    id: "320411",
    cid: "320400"
  },
  {
    name: "鼓楼区",
    pid: "320000",
    id: "320106",
    cid: "320100"
  },
  {
    name: "溧水区",
    pid: "320000",
    id: "320117",
    cid: "320100"
  },
  {
    name: "高淳区",
    pid: "320000",
    id: "320118",
    cid: "320100"
  },
  {
    name: "秦淮区",
    pid: "320000",
    id: "320104",
    cid: "320100"
  },
  {
    name: "江宁区",
    pid: "320000",
    id: "320115",
    cid: "320100"
  },
  {
    name: "建邺区",
    pid: "320000",
    id: "320105",
    cid: "320100"
  },
  {
    name: "六合区",
    pid: "320000",
    id: "320116",
    cid: "320100"
  },
  {
    name: "玄武区",
    pid: "320000",
    id: "320102",
    cid: "320100"
  },
  {
    name: "栖霞区",
    pid: "320000",
    id: "320113",
    cid: "320100"
  },
  {
    name: "雨花台区",
    pid: "320000",
    id: "320114",
    cid: "320100"
  },
  {
    name: "浦口区",
    pid: "320000",
    id: "320111",
    cid: "320100"
  },
  {
    name: "靖江市",
    pid: "320000",
    id: "321282",
    cid: "321200"
  },
  {
    name: "泰兴市",
    pid: "320000",
    id: "321283",
    cid: "321200"
  },
  {
    name: "兴化市",
    pid: "320000",
    id: "321281",
    cid: "321200"
  },
  {
    name: "姜堰区",
    pid: "320000",
    id: "321204",
    cid: "321200"
  },
  {
    name: "海陵区",
    pid: "320000",
    id: "321202",
    cid: "321200"
  },
  {
    name: "高港区",
    pid: "320000",
    id: "321203",
    cid: "321200"
  },
  {
    name: "江阴市",
    pid: "320000",
    id: "320281",
    cid: "320200"
  },
  {
    name: "宜兴市",
    pid: "320000",
    id: "320282",
    cid: "320200"
  },
  {
    name: "锡山区",
    pid: "320000",
    id: "320205",
    cid: "320200"
  },
  {
    name: "惠山区",
    pid: "320000",
    id: "320206",
    cid: "320200"
  },
  {
    name: "新吴区",
    pid: "320000",
    id: "320214",
    cid: "320200"
  },
  {
    name: "梁溪区",
    pid: "320000",
    id: "320213",
    cid: "320200"
  },
  {
    name: "滨湖区",
    pid: "320000",
    id: "320211",
    cid: "320200"
  },
  {
    name: "泗阳县",
    pid: "320000",
    id: "321323",
    cid: "321300"
  },
  {
    name: "宿城区",
    pid: "320000",
    id: "321302",
    cid: "321300"
  },
  {
    name: "泗洪县",
    pid: "320000",
    id: "321324",
    cid: "321300"
  },
  {
    name: "宿豫区",
    pid: "320000",
    id: "321311",
    cid: "321300"
  },
  {
    name: "沭阳县",
    pid: "320000",
    id: "321322",
    cid: "321300"
  },
  {
    name: "朝阳区",
    pid: "110000",
    id: "110105",
    cid: "110100"
  },
  {
    name: "怀柔区",
    pid: "110000",
    id: "110116",
    cid: "110100"
  },
  {
    name: "丰台区",
    pid: "110000",
    id: "110106",
    cid: "110100"
  },
  {
    name: "平谷区",
    pid: "110000",
    id: "110117",
    cid: "110100"
  },
  {
    name: "昌平区",
    pid: "110000",
    id: "110114",
    cid: "110100"
  },
  {
    name: "大兴区",
    pid: "110000",
    id: "110115",
    cid: "110100"
  },
  {
    name: "东城区",
    pid: "110000",
    id: "110101",
    cid: "110100"
  },
  {
    name: "通州区",
    pid: "110000",
    id: "110112",
    cid: "110100"
  },
  {
    name: "西城区",
    pid: "110000",
    id: "110102",
    cid: "110100"
  },
  {
    name: "顺义区",
    pid: "110000",
    id: "110113",
    cid: "110100"
  },
  {
    name: "房山区",
    pid: "110000",
    id: "110111",
    cid: "110100"
  },
  {
    name: "门头沟区",
    pid: "110000",
    id: "110109",
    cid: "110100"
  },
  {
    name: "石景山区",
    pid: "110000",
    id: "110107",
    cid: "110100"
  },
  {
    name: "密云区",
    pid: "110000",
    id: "110118",
    cid: "110100"
  },
  {
    name: "海淀区",
    pid: "110000",
    id: "110108",
    cid: "110100"
  },
  {
    name: "延庆区",
    pid: "110000",
    id: "110119",
    cid: "110100"
  },
  {
    name: "武定县",
    pid: "530000",
    id: "532329",
    cid: "532300"
  },
  {
    name: "元谋县",
    pid: "530000",
    id: "532328",
    cid: "532300"
  },
  {
    name: "永仁县",
    pid: "530000",
    id: "532327",
    cid: "532300"
  },
  {
    name: "大姚县",
    pid: "530000",
    id: "532326",
    cid: "532300"
  },
  {
    name: "姚安县",
    pid: "530000",
    id: "532325",
    cid: "532300"
  },
  {
    name: "南华县",
    pid: "530000",
    id: "532324",
    cid: "532300"
  },
  {
    name: "楚雄市",
    pid: "530000",
    id: "532301",
    cid: "532300"
  },
  {
    name: "牟定县",
    pid: "530000",
    id: "532323",
    cid: "532300"
  },
  {
    name: "双柏县",
    pid: "530000",
    id: "532322",
    cid: "532300"
  },
  {
    name: "禄丰县",
    pid: "530000",
    id: "532331",
    cid: "532300"
  },
  {
    name: "香格里拉市",
    pid: "530000",
    id: "533401",
    cid: "533400"
  },
  {
    name: "维西傈僳族自治县",
    pid: "530000",
    id: "533423",
    cid: "533400"
  },
  {
    name: "德钦县",
    pid: "530000",
    id: "533422",
    cid: "533400"
  },
  {
    name: "官渡区",
    pid: "530000",
    id: "530111",
    cid: "530100"
  },
  {
    name: "晋宁县",
    pid: "530000",
    id: "530122",
    cid: "530100"
  },
  {
    name: "安宁市",
    pid: "530000",
    id: "530181",
    cid: "530100"
  },
  {
    name: "寻甸回族彝族自治县",
    pid: "530000",
    id: "530129",
    cid: "530100"
  },
  {
    name: "嵩明县",
    pid: "530000",
    id: "530127",
    cid: "530100"
  },
  {
    name: "禄劝彝族苗族自治县",
    pid: "530000",
    id: "530128",
    cid: "530100"
  },
  {
    name: "盘龙区",
    pid: "530000",
    id: "530103",
    cid: "530100"
  },
  {
    name: "呈贡区",
    pid: "530000",
    id: "530114",
    cid: "530100"
  },
  {
    name: "宜良县",
    pid: "530000",
    id: "530125",
    cid: "530100"
  },
  {
    name: "石林彝族自治县",
    pid: "530000",
    id: "530126",
    cid: "530100"
  },
  {
    name: "西山区",
    pid: "530000",
    id: "530112",
    cid: "530100"
  },
  {
    name: "五华区",
    pid: "530000",
    id: "530102",
    cid: "530100"
  },
  {
    name: "东川区",
    pid: "530000",
    id: "530113",
    cid: "530100"
  },
  {
    name: "富民县",
    pid: "530000",
    id: "530124",
    cid: "530100"
  },
  {
    name: "兰坪白族普米族自治县",
    pid: "530000",
    id: "533325",
    cid: "533300"
  },
  {
    name: "贡山独龙族怒族自治县",
    pid: "530000",
    id: "533324",
    cid: "533300"
  },
  {
    name: "泸水市",
    pid: "530000",
    id: "533301",
    cid: "533300"
  },
  {
    name: "福贡县",
    pid: "530000",
    id: "533323",
    cid: "533300"
  },
  {
    name: "芒市",
    pid: "530000",
    id: "533103",
    cid: "533100"
  },
  {
    name: "瑞丽市",
    pid: "530000",
    id: "533102",
    cid: "533100"
  },
  {
    name: "陇川县",
    pid: "530000",
    id: "533124",
    cid: "533100"
  },
  {
    name: "盈江县",
    pid: "530000",
    id: "533123",
    cid: "533100"
  },
  {
    name: "梁河县",
    pid: "530000",
    id: "533122",
    cid: "533100"
  },
  {
    name: "墨江哈尼族自治县",
    pid: "530000",
    id: "530822",
    cid: "530800"
  },
  {
    name: "景东彝族自治县",
    pid: "530000",
    id: "530823",
    cid: "530800"
  },
  {
    name: "宁洱哈尼族彝族自治县",
    pid: "530000",
    id: "530821",
    cid: "530800"
  },
  {
    name: "澜沧拉祜族自治县",
    pid: "530000",
    id: "530828",
    cid: "530800"
  },
  {
    name: "西盟佤族自治县",
    pid: "530000",
    id: "530829",
    cid: "530800"
  },
  {
    name: "江城哈尼族彝族自治县",
    pid: "530000",
    id: "530826",
    cid: "530800"
  },
  {
    name: "孟连傣族拉祜族佤族自治县",
    pid: "530000",
    id: "530827",
    cid: "530800"
  },
  {
    name: "思茅区",
    pid: "530000",
    id: "530802",
    cid: "530800"
  },
  {
    name: "景谷傣族彝族自治县",
    pid: "530000",
    id: "530824",
    cid: "530800"
  },
  {
    name: "镇沅彝族哈尼族拉祜族自治县",
    pid: "530000",
    id: "530825",
    cid: "530800"
  },
  {
    name: "凤庆县",
    pid: "530000",
    id: "530921",
    cid: "530900"
  },
  {
    name: "云县",
    pid: "530000",
    id: "530922",
    cid: "530900"
  },
  {
    name: "沧源佤族自治县",
    pid: "530000",
    id: "530927",
    cid: "530900"
  },
  {
    name: "双江拉祜族佤族布朗族傣族自治县",
    pid: "530000",
    id: "530925",
    cid: "530900"
  },
  {
    name: "耿马傣族佤族自治县",
    pid: "530000",
    id: "530926",
    cid: "530900"
  },
  {
    name: "永德县",
    pid: "530000",
    id: "530923",
    cid: "530900"
  },
  {
    name: "临翔区",
    pid: "530000",
    id: "530902",
    cid: "530900"
  },
  {
    name: "镇康县",
    pid: "530000",
    id: "530924",
    cid: "530900"
  },
  {
    name: "昭阳区",
    pid: "530000",
    id: "530602",
    cid: "530600"
  },
  {
    name: "大关县",
    pid: "530000",
    id: "530624",
    cid: "530600"
  },
  {
    name: "永善县",
    pid: "530000",
    id: "530625",
    cid: "530600"
  },
  {
    name: "巧家县",
    pid: "530000",
    id: "530622",
    cid: "530600"
  },
  {
    name: "盐津县",
    pid: "530000",
    id: "530623",
    cid: "530600"
  },
  {
    name: "鲁甸县",
    pid: "530000",
    id: "530621",
    cid: "530600"
  },
  {
    name: "水富县",
    pid: "530000",
    id: "530630",
    cid: "530600"
  },
  {
    name: "彝良县",
    pid: "530000",
    id: "530628",
    cid: "530600"
  },
  {
    name: "威信县",
    pid: "530000",
    id: "530629",
    cid: "530600"
  },
  {
    name: "绥江县",
    pid: "530000",
    id: "530626",
    cid: "530600"
  },
  {
    name: "镇雄县",
    pid: "530000",
    id: "530627",
    cid: "530600"
  },
  {
    name: "宾川县",
    pid: "530000",
    id: "532924",
    cid: "532900"
  },
  {
    name: "大理市",
    pid: "530000",
    id: "532901",
    cid: "532900"
  },
  {
    name: "祥云县",
    pid: "530000",
    id: "532923",
    cid: "532900"
  },
  {
    name: "漾濞彝族自治县",
    pid: "530000",
    id: "532922",
    cid: "532900"
  },
  {
    name: "鹤庆县",
    pid: "530000",
    id: "532932",
    cid: "532900"
  },
  {
    name: "剑川县",
    pid: "530000",
    id: "532931",
    cid: "532900"
  },
  {
    name: "洱源县",
    pid: "530000",
    id: "532930",
    cid: "532900"
  },
  {
    name: "云龙县",
    pid: "530000",
    id: "532929",
    cid: "532900"
  },
  {
    name: "永平县",
    pid: "530000",
    id: "532928",
    cid: "532900"
  },
  {
    name: "巍山彝族回族自治县",
    pid: "530000",
    id: "532927",
    cid: "532900"
  },
  {
    name: "南涧彝族自治县",
    pid: "530000",
    id: "532926",
    cid: "532900"
  },
  {
    name: "弥渡县",
    pid: "530000",
    id: "532925",
    cid: "532900"
  },
  {
    name: "华坪县",
    pid: "530000",
    id: "530723",
    cid: "530700"
  },
  {
    name: "古城区",
    pid: "530000",
    id: "530702",
    cid: "530700"
  },
  {
    name: "宁蒗彝族自治县",
    pid: "530000",
    id: "530724",
    cid: "530700"
  },
  {
    name: "玉龙纳西族自治县",
    pid: "530000",
    id: "530721",
    cid: "530700"
  },
  {
    name: "永胜县",
    pid: "530000",
    id: "530722",
    cid: "530700"
  },
  {
    name: "景洪市",
    pid: "530000",
    id: "532801",
    cid: "532800"
  },
  {
    name: "勐腊县",
    pid: "530000",
    id: "532823",
    cid: "532800"
  },
  {
    name: "勐海县",
    pid: "530000",
    id: "532822",
    cid: "532800"
  },
  {
    name: "峨山彝族自治县",
    pid: "530000",
    id: "530426",
    cid: "530400"
  },
  {
    name: "新平彝族傣族自治县",
    pid: "530000",
    id: "530427",
    cid: "530400"
  },
  {
    name: "红塔区",
    pid: "530000",
    id: "530402",
    cid: "530400"
  },
  {
    name: "华宁县",
    pid: "530000",
    id: "530424",
    cid: "530400"
  },
  {
    name: "江川区",
    pid: "530000",
    id: "530403",
    cid: "530400"
  },
  {
    name: "易门县",
    pid: "530000",
    id: "530425",
    cid: "530400"
  },
  {
    name: "澄江县",
    pid: "530000",
    id: "530422",
    cid: "530400"
  },
  {
    name: "通海县",
    pid: "530000",
    id: "530423",
    cid: "530400"
  },
  {
    name: "元江哈尼族彝族傣族自治县",
    pid: "530000",
    id: "530428",
    cid: "530400"
  },
  {
    name: "龙陵县",
    pid: "530000",
    id: "530523",
    cid: "530500"
  },
  {
    name: "隆阳区",
    pid: "530000",
    id: "530502",
    cid: "530500"
  },
  {
    name: "昌宁县",
    pid: "530000",
    id: "530524",
    cid: "530500"
  },
  {
    name: "施甸县",
    pid: "530000",
    id: "530521",
    cid: "530500"
  },
  {
    name: "腾冲市",
    pid: "530000",
    id: "530581",
    cid: "530500"
  },
  {
    name: "广南县",
    pid: "530000",
    id: "532627",
    cid: "532600"
  },
  {
    name: "丘北县",
    pid: "530000",
    id: "532626",
    cid: "532600"
  },
  {
    name: "马关县",
    pid: "530000",
    id: "532625",
    cid: "532600"
  },
  {
    name: "麻栗坡县",
    pid: "530000",
    id: "532624",
    cid: "532600"
  },
  {
    name: "文山市",
    pid: "530000",
    id: "532601",
    cid: "532600"
  },
  {
    name: "西畴县",
    pid: "530000",
    id: "532623",
    cid: "532600"
  },
  {
    name: "砚山县",
    pid: "530000",
    id: "532622",
    cid: "532600"
  },
  {
    name: "富宁县",
    pid: "530000",
    id: "532628",
    cid: "532600"
  },
  {
    name: "绿春县",
    pid: "530000",
    id: "532531",
    cid: "532500"
  },
  {
    name: "金平苗族瑶族傣族自治县",
    pid: "530000",
    id: "532530",
    cid: "532500"
  },
  {
    name: "元阳县",
    pid: "530000",
    id: "532528",
    cid: "532500"
  },
  {
    name: "泸西县",
    pid: "530000",
    id: "532527",
    cid: "532500"
  },
  {
    name: "弥勒市",
    pid: "530000",
    id: "532504",
    cid: "532500"
  },
  {
    name: "蒙自市",
    pid: "530000",
    id: "532503",
    cid: "532500"
  },
  {
    name: "石屏县",
    pid: "530000",
    id: "532525",
    cid: "532500"
  },
  {
    name: "开远市",
    pid: "530000",
    id: "532502",
    cid: "532500"
  },
  {
    name: "建水县",
    pid: "530000",
    id: "532524",
    cid: "532500"
  },
  {
    name: "个旧市",
    pid: "530000",
    id: "532501",
    cid: "532500"
  },
  {
    name: "屏边苗族自治县",
    pid: "530000",
    id: "532523",
    cid: "532500"
  },
  {
    name: "河口瑶族自治县",
    pid: "530000",
    id: "532532",
    cid: "532500"
  },
  {
    name: "红河县",
    pid: "530000",
    id: "532529",
    cid: "532500"
  },
  {
    name: "沾益区",
    pid: "530000",
    id: "530303",
    cid: "530300"
  },
  {
    name: "富源县",
    pid: "530000",
    id: "530325",
    cid: "530300"
  },
  {
    name: "会泽县",
    pid: "530000",
    id: "530326",
    cid: "530300"
  },
  {
    name: "师宗县",
    pid: "530000",
    id: "530323",
    cid: "530300"
  },
  {
    name: "麒麟区",
    pid: "530000",
    id: "530302",
    cid: "530300"
  },
  {
    name: "罗平县",
    pid: "530000",
    id: "530324",
    cid: "530300"
  },
  {
    name: "马龙县",
    pid: "530000",
    id: "530321",
    cid: "530300"
  },
  {
    name: "陆良县",
    pid: "530000",
    id: "530322",
    cid: "530300"
  },
  {
    name: "宣威市",
    pid: "530000",
    id: "530381",
    cid: "530300"
  },
  {
    name: "美兰区",
    pid: "460000",
    id: "460108",
    cid: "460100"
  },
  {
    name: "琼山区",
    pid: "460000",
    id: "460107",
    cid: "460100"
  },
  {
    name: "龙华区",
    pid: "460000",
    id: "460106",
    cid: "460100"
  },
  {
    name: "秀英区",
    pid: "460000",
    id: "460105",
    cid: "460100"
  },
  {
    name: "王五镇",
    pid: "460000",
    id: "460400111",
    cid: "460400"
  },
  {
    name: "和庆镇",
    pid: "460000",
    id: "460400101",
    cid: "460400"
  },
  {
    name: "白马井镇",
    pid: "460000",
    id: "460400112",
    cid: "460400"
  },
  {
    name: "南丰镇",
    pid: "460000",
    id: "460400102",
    cid: "460400"
  },
  {
    name: "中和镇",
    pid: "460000",
    id: "460400113",
    cid: "460400"
  },
  {
    name: "大成镇",
    pid: "460000",
    id: "460400103",
    cid: "460400"
  },
  {
    name: "排浦镇",
    pid: "460000",
    id: "460400114",
    cid: "460400"
  },
  {
    name: "洋浦经济开发区",
    pid: "460000",
    id: "460400499",
    cid: "460400"
  },
  {
    name: "雅星镇",
    pid: "460000",
    id: "460400104",
    cid: "460400"
  },
  {
    name: "东成镇",
    pid: "460000",
    id: "460400115",
    cid: "460400"
  },
  {
    name: "国营西培农场",
    pid: "460000",
    id: "4604004",
    cid: "460400"
  },
  {
    name: "华南热作学院",
    pid: "460000",
    id: "4604005",
    cid: "460400"
  },
  {
    name: "那大镇",
    pid: "460000",
    id: "4604001",
    cid: "460400"
  },
  {
    name: "三都镇",
    pid: "460000",
    id: "46040011",
    cid: "460400"
  },
  {
    name: "兰洋镇",
    pid: "460000",
    id: "460400105",
    cid: "460400"
  },
  {
    name: "新州镇",
    pid: "460000",
    id: "460400116",
    cid: "460400"
  },
  {
    name: "光村镇",
    pid: "460000",
    id: "460400106",
    cid: "460400"
  },
  {
    name: "木棠镇",
    pid: "460000",
    id: "460400107",
    cid: "460400"
  },
  {
    name: "国营西联农场",
    pid: "460000",
    id: "460400404",
    cid: "460400"
  },
  {
    name: "海头镇",
    pid: "460000",
    id: "460400108",
    cid: "460400"
  },
  {
    name: "国营蓝洋农场",
    pid: "460000",
    id: "460400405",
    cid: "460400"
  },
  {
    name: "峨蔓镇",
    pid: "460000",
    id: "460400109",
    cid: "460400"
  },
  {
    name: "国营八一农场",
    pid: "460000",
    id: "460400407",
    cid: "460400"
  },
  {
    name: "中沙群岛的岛礁及其海域",
    pid: "460000",
    id: "460323",
    cid: "460300"
  },
  {
    name: "南沙群岛",
    pid: "460000",
    id: "460322",
    cid: "460300"
  },
  {
    name: "西沙群岛",
    pid: "460000",
    id: "460321",
    cid: "460300"
  },
  {
    name: "崖州区",
    pid: "460000",
    id: "460205",
    cid: "460200"
  },
  {
    name: "天涯区",
    pid: "460000",
    id: "460204",
    cid: "460200"
  },
  {
    name: "吉阳区",
    pid: "460000",
    id: "460203",
    cid: "460200"
  },
  {
    name: "海棠区",
    pid: "460000",
    id: "460202",
    cid: "460200"
  },
  {
    name: "天安乡",
    pid: "460000",
    id: "4690072",
    cid: "469007"
  },
  {
    name: "八所镇",
    pid: "460000",
    id: "4690071",
    cid: "469007"
  },
  {
    name: "东方华侨农场",
    pid: "460000",
    id: "4690075",
    cid: "469007"
  },
  {
    name: "国营广坝农场",
    pid: "460000",
    id: "4690074",
    cid: "469007"
  },
  {
    name: "新龙镇",
    pid: "460000",
    id: "469007107",
    cid: "469007"
  },
  {
    name: "四更镇",
    pid: "460000",
    id: "469007106",
    cid: "469007"
  },
  {
    name: "三家镇",
    pid: "460000",
    id: "469007105",
    cid: "469007"
  },
  {
    name: "板桥镇",
    pid: "460000",
    id: "469007104",
    cid: "469007"
  },
  {
    name: "感城镇",
    pid: "460000",
    id: "469007103",
    cid: "469007"
  },
  {
    name: "大田镇",
    pid: "460000",
    id: "469007102",
    cid: "469007"
  },
  {
    name: "江边乡",
    pid: "460000",
    id: "469007201",
    cid: "469007"
  },
  {
    name: "东河镇",
    pid: "460000",
    id: "469007101",
    cid: "469007"
  },
  {
    name: "六弓乡",
    pid: "460000",
    id: "4690292",
    cid: "469029"
  },
  {
    name: "保城镇",
    pid: "460000",
    id: "4690291",
    cid: "469029"
  },
  {
    name: "国营三道农场",
    pid: "460000",
    id: "469029405",
    cid: "469029"
  },
  {
    name: "新政镇",
    pid: "460000",
    id: "469029104",
    cid: "469029"
  },
  {
    name: "国营新星农场",
    pid: "460000",
    id: "469029401",
    cid: "469029"
  },
  {
    name: "响水镇",
    pid: "460000",
    id: "469029103",
    cid: "469029"
  },
  {
    name: "毛感乡",
    pid: "460000",
    id: "469029202",
    cid: "469029"
  },
  {
    name: "国营金江农场",
    pid: "460000",
    id: "469029403",
    cid: "469029"
  },
  {
    name: "三道镇",
    pid: "460000",
    id: "469029105",
    cid: "469029"
  },
  {
    name: "海南保亭热带作物研究所",
    pid: "460000",
    id: "469029402",
    cid: "469029"
  },
  {
    name: "加茂镇",
    pid: "460000",
    id: "469029102",
    cid: "469029"
  },
  {
    name: "南林乡",
    pid: "460000",
    id: "469029201",
    cid: "469029"
  },
  {
    name: "什玲镇",
    pid: "460000",
    id: "469029101",
    cid: "469029"
  },
  {
    name: "万城镇",
    pid: "460000",
    id: "4690061",
    cid: "469006"
  },
  {
    name: "南桥镇",
    pid: "460000",
    id: "46900611",
    cid: "469006"
  },
  {
    name: "北大镇",
    pid: "460000",
    id: "469006109",
    cid: "469006"
  },
  {
    name: "兴隆华侨农场",
    pid: "460000",
    id: "4690065",
    cid: "469006"
  },
  {
    name: "山根镇",
    pid: "460000",
    id: "469006108",
    cid: "469006"
  },
  {
    name: "国营东兴农场",
    pid: "460000",
    id: "4690064",
    cid: "469006"
  },
  {
    name: "长丰镇",
    pid: "460000",
    id: "469006107",
    cid: "469006"
  },
  {
    name: "国营新中农场",
    pid: "460000",
    id: "469006404",
    cid: "469006"
  },
  {
    name: "礼纪镇",
    pid: "460000",
    id: "469006106",
    cid: "469006"
  },
  {
    name: "东澳镇",
    pid: "460000",
    id: "469006105",
    cid: "469006"
  },
  {
    name: "地方国营六连林场",
    pid: "460000",
    id: "469006501",
    cid: "469006"
  },
  {
    name: "大茂镇",
    pid: "460000",
    id: "469006104",
    cid: "469006"
  },
  {
    name: "国营东和农场",
    pid: "460000",
    id: "469006401",
    cid: "469006"
  },
  {
    name: "后安镇",
    pid: "460000",
    id: "469006103",
    cid: "469006"
  },
  {
    name: "和乐镇",
    pid: "460000",
    id: "469006102",
    cid: "469006"
  },
  {
    name: "龙滚镇",
    pid: "460000",
    id: "469006101",
    cid: "469006"
  },
  {
    name: "三更罗镇",
    pid: "460000",
    id: "469006111",
    cid: "469006"
  },
  {
    name: "提蒙乡",
    pid: "460000",
    id: "4690282",
    cid: "469028"
  },
  {
    name: "椰林镇",
    pid: "460000",
    id: "4690281",
    cid: "469028"
  },
  {
    name: "本号镇",
    pid: "460000",
    id: "469028106",
    cid: "469028"
  },
  {
    name: "国营吊罗山林业公司",
    pid: "460000",
    id: "4690285",
    cid: "469028"
  },
  {
    name: "新村镇",
    pid: "460000",
    id: "469028107",
    cid: "469028"
  },
  {
    name: "国营岭门农场",
    pid: "460000",
    id: "4690284",
    cid: "469028"
  },
  {
    name: "黎安镇",
    pid: "460000",
    id: "469028108",
    cid: "469028"
  },
  {
    name: "三才镇",
    pid: "460000",
    id: "469028102",
    cid: "469028"
  },
  {
    name: "群英乡",
    pid: "460000",
    id: "469028201",
    cid: "469028"
  },
  {
    name: "英州镇",
    pid: "460000",
    id: "469028103",
    cid: "469028"
  },
  {
    name: "隆广镇",
    pid: "460000",
    id: "469028104",
    cid: "469028"
  },
  {
    name: "国营南平农场",
    pid: "460000",
    id: "469028401",
    cid: "469028"
  },
  {
    name: "文罗镇",
    pid: "460000",
    id: "469028105",
    cid: "469028"
  },
  {
    name: "光坡镇",
    pid: "460000",
    id: "469028101",
    cid: "469028"
  },
  {
    name: "文城镇",
    pid: "460000",
    id: "4690051",
    cid: "469005"
  },
  {
    name: "昌洒镇",
    pid: "460000",
    id: "46900511",
    cid: "469005"
  },
  {
    name: "龙楼镇",
    pid: "460000",
    id: "469005109",
    cid: "469005"
  },
  {
    name: "东郊镇",
    pid: "460000",
    id: "469005108",
    cid: "469005"
  },
  {
    name: "国营东路农场",
    pid: "460000",
    id: "4690054",
    cid: "469005"
  },
  {
    name: "文教镇",
    pid: "460000",
    id: "469005107",
    cid: "469005"
  },
  {
    name: "东阁镇",
    pid: "460000",
    id: "469005106",
    cid: "469005"
  },
  {
    name: "潭牛镇",
    pid: "460000",
    id: "469005105",
    cid: "469005"
  },
  {
    name: "公坡镇",
    pid: "460000",
    id: "469005116",
    cid: "469005"
  },
  {
    name: "国营罗豆农场",
    pid: "460000",
    id: "469005402",
    cid: "469005"
  },
  {
    name: "东路镇",
    pid: "460000",
    id: "469005104",
    cid: "469005"
  },
  {
    name: "铺前镇",
    pid: "460000",
    id: "469005115",
    cid: "469005"
  },
  {
    name: "国营南阳农场",
    pid: "460000",
    id: "469005401",
    cid: "469005"
  },
  {
    name: "会文镇",
    pid: "460000",
    id: "469005103",
    cid: "469005"
  },
  {
    name: "锦山镇",
    pid: "460000",
    id: "469005114",
    cid: "469005"
  },
  {
    name: "蓬莱镇",
    pid: "460000",
    id: "469005102",
    cid: "469005"
  },
  {
    name: "冯坡镇",
    pid: "460000",
    id: "469005113",
    cid: "469005"
  },
  {
    name: "重兴镇",
    pid: "460000",
    id: "469005101",
    cid: "469005"
  },
  {
    name: "抱罗镇",
    pid: "460000",
    id: "469005112",
    cid: "469005"
  },
  {
    name: "翁田镇",
    pid: "460000",
    id: "469005111",
    cid: "469005"
  },
  {
    name: "抱由镇",
    pid: "460000",
    id: "4690271",
    cid: "469027"
  },
  {
    name: "尖峰镇",
    pid: "460000",
    id: "469027109",
    cid: "469027"
  },
  {
    name: "莺歌海镇",
    pid: "460000",
    id: "46902711",
    cid: "469027"
  },
  {
    name: "国营尖峰岭林业公司",
    pid: "460000",
    id: "4690275",
    cid: "469027"
  },
  {
    name: "九所镇",
    pid: "460000",
    id: "469027105",
    cid: "469027"
  },
  {
    name: "国营乐光农场",
    pid: "460000",
    id: "469027402",
    cid: "469027"
  },
  {
    name: "国营莺歌海盐场",
    pid: "460000",
    id: "469027501",
    cid: "469027"
  },
  {
    name: "利国镇",
    pid: "460000",
    id: "469027106",
    cid: "469027"
  },
  {
    name: "黄流镇",
    pid: "460000",
    id: "469027107",
    cid: "469027"
  },
  {
    name: "佛罗镇",
    pid: "460000",
    id: "469027108",
    cid: "469027"
  },
  {
    name: "国营保国农场",
    pid: "460000",
    id: "469027405",
    cid: "469027"
  },
  {
    name: "万冲镇",
    pid: "460000",
    id: "469027101",
    cid: "469027"
  },
  {
    name: "大安镇",
    pid: "460000",
    id: "469027102",
    cid: "469027"
  },
  {
    name: "志仲镇",
    pid: "460000",
    id: "469027103",
    cid: "469027"
  },
  {
    name: "千家镇",
    pid: "460000",
    id: "469027104",
    cid: "469027"
  },
  {
    name: "国营山荣农场",
    pid: "460000",
    id: "469027401",
    cid: "469027"
  },
  {
    name: "七叉镇",
    pid: "460000",
    id: "469026106",
    cid: "469026"
  },
  {
    name: "海尾镇",
    pid: "460000",
    id: "469026105",
    cid: "469026"
  },
  {
    name: "海南矿业联合有限公司",
    pid: "460000",
    id: "469026501",
    cid: "469026"
  },
  {
    name: "昌化镇",
    pid: "460000",
    id: "469026104",
    cid: "469026"
  },
  {
    name: "国营红林农场",
    pid: "460000",
    id: "469026401",
    cid: "469026"
  },
  {
    name: "王下乡",
    pid: "460000",
    id: "4690262",
    cid: "469026"
  },
  {
    name: "石碌镇",
    pid: "460000",
    id: "4690261",
    cid: "469026"
  },
  {
    name: "国营霸王岭林场",
    pid: "460000",
    id: "4690265",
    cid: "469026"
  },
  {
    name: "乌烈镇",
    pid: "460000",
    id: "469026103",
    cid: "469026"
  },
  {
    name: "十月田镇",
    pid: "460000",
    id: "469026102",
    cid: "469026"
  },
  {
    name: "叉河镇",
    pid: "460000",
    id: "469026101",
    cid: "469026"
  },
  {
    name: "国营邦溪农场",
    pid: "460000",
    id: "469025408",
    cid: "469025"
  },
  {
    name: "金波乡",
    pid: "460000",
    id: "469025205",
    cid: "469025"
  },
  {
    name: "青松乡",
    pid: "460000",
    id: "469025204",
    cid: "469025"
  },
  {
    name: "阜龙乡",
    pid: "460000",
    id: "469025203",
    cid: "469025"
  },
  {
    name: "国营白沙农场",
    pid: "460000",
    id: "469025401",
    cid: "469025"
  },
  {
    name: "打安镇",
    pid: "460000",
    id: "469025103",
    cid: "469025"
  },
  {
    name: "南开乡",
    pid: "460000",
    id: "469025202",
    cid: "469025"
  },
  {
    name: "细水乡",
    pid: "460000",
    id: "4690252",
    cid: "469025"
  },
  {
    name: "牙叉镇",
    pid: "460000",
    id: "4690251",
    cid: "469025"
  },
  {
    name: "荣邦乡",
    pid: "460000",
    id: "469025206",
    cid: "469025"
  },
  {
    name: "国营龙江农场",
    pid: "460000",
    id: "469025404",
    cid: "469025"
  },
  {
    name: "邦溪镇",
    pid: "460000",
    id: "469025102",
    cid: "469025"
  },
  {
    name: "元门乡",
    pid: "460000",
    id: "469025201",
    cid: "469025"
  },
  {
    name: "七坊镇",
    pid: "460000",
    id: "469025101",
    cid: "469025"
  },
  {
    name: "潭门镇",
    pid: "460000",
    id: "469002107",
    cid: "469002"
  },
  {
    name: "龙江镇",
    pid: "460000",
    id: "469002106",
    cid: "469002"
  },
  {
    name: "国营东升农场",
    pid: "460000",
    id: "469002403",
    cid: "469002"
  },
  {
    name: "长坡镇",
    pid: "460000",
    id: "469002109",
    cid: "469002"
  },
  {
    name: "塔洋镇",
    pid: "460000",
    id: "469002108",
    cid: "469002"
  },
  {
    name: "中原镇",
    pid: "460000",
    id: "469002103",
    cid: "469002"
  },
  {
    name: "嘉积镇",
    pid: "460000",
    id: "4690021",
    cid: "469002"
  },
  {
    name: "石壁镇",
    pid: "460000",
    id: "469002102",
    cid: "469002"
  },
  {
    name: "阳江镇",
    pid: "460000",
    id: "469002105",
    cid: "469002"
  },
  {
    name: "国营东红农场",
    pid: "460000",
    id: "469002402",
    cid: "469002"
  },
  {
    name: "大路镇",
    pid: "460000",
    id: "46900211",
    cid: "469002"
  },
  {
    name: "博鳌镇",
    pid: "460000",
    id: "469002104",
    cid: "469002"
  },
  {
    name: "彬村山华侨农场",
    pid: "460000",
    id: "4690025",
    cid: "469002"
  },
  {
    name: "国营东太农场",
    pid: "460000",
    id: "4690024",
    cid: "469002"
  },
  {
    name: "万泉镇",
    pid: "460000",
    id: "469002101",
    cid: "469002"
  },
  {
    name: "会山镇",
    pid: "460000",
    id: "469002111",
    cid: "469002"
  },
  {
    name: "皇桐镇",
    pid: "460000",
    id: "469024104",
    cid: "469024"
  },
  {
    name: "国营加来农场",
    pid: "460000",
    id: "469024401",
    cid: "469024"
  },
  {
    name: "多文镇",
    pid: "460000",
    id: "469024105",
    cid: "469024"
  },
  {
    name: "东英镇",
    pid: "460000",
    id: "469024102",
    cid: "469024"
  },
  {
    name: "博厚镇",
    pid: "460000",
    id: "469024103",
    cid: "469024"
  },
  {
    name: "新盈镇",
    pid: "460000",
    id: "469024108",
    cid: "469024"
  },
  {
    name: "临城镇",
    pid: "460000",
    id: "4690241",
    cid: "469024"
  },
  {
    name: "调楼镇",
    pid: "460000",
    id: "469024109",
    cid: "469024"
  },
  {
    name: "和舍镇",
    pid: "460000",
    id: "469024106",
    cid: "469024"
  },
  {
    name: "南宝镇",
    pid: "460000",
    id: "469024107",
    cid: "469024"
  },
  {
    name: "国营红华农场",
    pid: "460000",
    id: "4690244",
    cid: "469024"
  },
  {
    name: "波莲镇",
    pid: "460000",
    id: "469024101",
    cid: "469024"
  },
  {
    name: "通什镇",
    pid: "460000",
    id: "4690011",
    cid: "469001"
  },
  {
    name: "毛阳镇",
    pid: "460000",
    id: "469001102",
    cid: "469001"
  },
  {
    name: "毛道乡",
    pid: "460000",
    id: "469001201",
    cid: "469001"
  },
  {
    name: "南圣镇",
    pid: "460000",
    id: "469001101",
    cid: "469001"
  },
  {
    name: "番阳镇",
    pid: "460000",
    id: "469001103",
    cid: "469001"
  },
  {
    name: "水满乡",
    pid: "460000",
    id: "469001202",
    cid: "469001"
  },
  {
    name: "畅好农场",
    pid: "460000",
    id: "4690014",
    cid: "469001"
  },
  {
    name: "畅好乡",
    pid: "460000",
    id: "4690012",
    cid: "469001"
  },
  {
    name: "桥头镇",
    pid: "460000",
    id: "469023109",
    cid: "469023"
  },
  {
    name: "永发镇",
    pid: "460000",
    id: "469023103",
    cid: "469023"
  },
  {
    name: "加乐镇",
    pid: "460000",
    id: "469023104",
    cid: "469023"
  },
  {
    name: "老城镇",
    pid: "460000",
    id: "469023101",
    cid: "469023"
  },
  {
    name: "瑞溪镇",
    pid: "460000",
    id: "469023102",
    cid: "469023"
  },
  {
    name: "金江镇",
    pid: "460000",
    id: "4690231",
    cid: "469023"
  },
  {
    name: "仁兴镇",
    pid: "460000",
    id: "469023107",
    cid: "469023"
  },
  {
    name: "福山镇",
    pid: "460000",
    id: "469023108",
    cid: "469023"
  },
  {
    name: "国营金安农场",
    pid: "460000",
    id: "469023405",
    cid: "469023"
  },
  {
    name: "文儒镇",
    pid: "460000",
    id: "469023105",
    cid: "469023"
  },
  {
    name: "国营西达农场",
    pid: "460000",
    id: "469023402",
    cid: "469023"
  },
  {
    name: "大丰镇",
    pid: "460000",
    id: "46902311",
    cid: "469023"
  },
  {
    name: "中兴镇",
    pid: "460000",
    id: "469023106",
    cid: "469023"
  },
  {
    name: "国营红光农场",
    pid: "460000",
    id: "4690234",
    cid: "469023"
  },
  {
    name: "新兴镇",
    pid: "460000",
    id: "469022101",
    cid: "469022"
  },
  {
    name: "乌坡镇",
    pid: "460000",
    id: "469022103",
    cid: "469022"
  },
  {
    name: "枫木镇",
    pid: "460000",
    id: "469022102",
    cid: "469022"
  },
  {
    name: "南坤镇",
    pid: "460000",
    id: "469022105",
    cid: "469022"
  },
  {
    name: "南吕镇",
    pid: "460000",
    id: "469022104",
    cid: "469022"
  },
  {
    name: "国营中坤农场",
    pid: "460000",
    id: "469022401",
    cid: "469022"
  },
  {
    name: "西昌镇",
    pid: "460000",
    id: "469022107",
    cid: "469022"
  },
  {
    name: "坡心镇",
    pid: "460000",
    id: "469022106",
    cid: "469022"
  },
  {
    name: "国营中建农场",
    pid: "460000",
    id: "4690224",
    cid: "469022"
  },
  {
    name: "屯城镇",
    pid: "460000",
    id: "4690221",
    cid: "469022"
  },
  {
    name: "翰林镇",
    pid: "460000",
    id: "469021108",
    cid: "469021"
  },
  {
    name: "岭口镇",
    pid: "460000",
    id: "469021107",
    cid: "469021"
  },
  {
    name: "富文镇",
    pid: "460000",
    id: "469021109",
    cid: "469021"
  },
  {
    name: "龙湖镇",
    pid: "460000",
    id: "469021102",
    cid: "469021"
  },
  {
    name: "新竹镇",
    pid: "460000",
    id: "469021101",
    cid: "469021"
  },
  {
    name: "雷鸣镇",
    pid: "460000",
    id: "469021104",
    cid: "469021"
  },
  {
    name: "国营南海农场",
    pid: "460000",
    id: "469021401",
    cid: "469021"
  },
  {
    name: "黄竹镇",
    pid: "460000",
    id: "469021103",
    cid: "469021"
  },
  {
    name: "龙河镇",
    pid: "460000",
    id: "469021106",
    cid: "469021"
  },
  {
    name: "龙门镇",
    pid: "460000",
    id: "469021105",
    cid: "469021"
  },
  {
    name: "国营金鸡岭农场",
    pid: "460000",
    id: "469021402",
    cid: "469021"
  },
  {
    name: "定城镇",
    pid: "460000",
    id: "4690211",
    cid: "469021"
  },
  {
    name: "国营中瑞农场",
    pid: "460000",
    id: "4690214",
    cid: "469021"
  },
  {
    name: "长征镇",
    pid: "460000",
    id: "469030104",
    cid: "469030"
  },
  {
    name: "和平镇",
    pid: "460000",
    id: "469030103",
    cid: "469030"
  },
  {
    name: "什运乡",
    pid: "460000",
    id: "469030202",
    cid: "469030"
  },
  {
    name: "黎母山镇",
    pid: "460000",
    id: "469030102",
    cid: "469030"
  },
  {
    name: "上安乡",
    pid: "460000",
    id: "469030201",
    cid: "469030"
  },
  {
    name: "湾岭镇",
    pid: "460000",
    id: "469030101",
    cid: "469030"
  },
  {
    name: "国营长征农场",
    pid: "460000",
    id: "469030407",
    cid: "469030"
  },
  {
    name: "国营加钗农场",
    pid: "460000",
    id: "469030406",
    cid: "469030"
  },
  {
    name: "中平镇",
    pid: "460000",
    id: "469030106",
    cid: "469030"
  },
  {
    name: "国营乌石农场",
    pid: "460000",
    id: "469030403",
    cid: "469030"
  },
  {
    name: "红毛镇",
    pid: "460000",
    id: "469030105",
    cid: "469030"
  },
  {
    name: "国营阳江农场",
    pid: "460000",
    id: "469030402",
    cid: "469030"
  },
  {
    name: "营根镇",
    pid: "460000",
    id: "4690301",
    cid: "469030"
  },
  {
    name: "海南黎母山省级自然保护区管理站",
    pid: "460000",
    id: "4690305",
    cid: "469030"
  },
  {
    name: "吊罗山乡",
    pid: "460000",
    id: "4690302",
    cid: "469030"
  },
  {
    name: "玉环县",
    pid: "330000",
    id: "331021",
    cid: "331000"
  },
  {
    name: "三门县",
    pid: "330000",
    id: "331022",
    cid: "331000"
  },
  {
    name: "温岭市",
    pid: "330000",
    id: "331081",
    cid: "331000"
  },
  {
    name: "临海市",
    pid: "330000",
    id: "331082",
    cid: "331000"
  },
  {
    name: "天台县",
    pid: "330000",
    id: "331023",
    cid: "331000"
  },
  {
    name: "椒江区",
    pid: "330000",
    id: "331002",
    cid: "331000"
  },
  {
    name: "仙居县",
    pid: "330000",
    id: "331024",
    cid: "331000"
  },
  {
    name: "黄岩区",
    pid: "330000",
    id: "331003",
    cid: "331000"
  },
  {
    name: "路桥区",
    pid: "330000",
    id: "331004",
    cid: "331000"
  },
  {
    name: "常山县",
    pid: "330000",
    id: "330822",
    cid: "330800"
  },
  {
    name: "柯城区",
    pid: "330000",
    id: "330802",
    cid: "330800"
  },
  {
    name: "开化县",
    pid: "330000",
    id: "330824",
    cid: "330800"
  },
  {
    name: "衢江区",
    pid: "330000",
    id: "330803",
    cid: "330800"
  },
  {
    name: "龙游县",
    pid: "330000",
    id: "330825",
    cid: "330800"
  },
  {
    name: "江山市",
    pid: "330000",
    id: "330881",
    cid: "330800"
  },
  {
    name: "普陀区",
    pid: "330000",
    id: "330903",
    cid: "330900"
  },
  {
    name: "岱山县",
    pid: "330000",
    id: "330921",
    cid: "330900"
  },
  {
    name: "嵊泗县",
    pid: "330000",
    id: "330922",
    cid: "330900"
  },
  {
    name: "定海区",
    pid: "330000",
    id: "330902",
    cid: "330900"
  },
  {
    name: "海宁市",
    pid: "330000",
    id: "330481",
    cid: "330400"
  },
  {
    name: "平湖市",
    pid: "330000",
    id: "330482",
    cid: "330400"
  },
  {
    name: "桐乡市",
    pid: "330000",
    id: "330483",
    cid: "330400"
  },
  {
    name: "秀洲区",
    pid: "330000",
    id: "330411",
    cid: "330400"
  },
  {
    name: "南湖区",
    pid: "330000",
    id: "330402",
    cid: "330400"
  },
  {
    name: "海盐县",
    pid: "330000",
    id: "330424",
    cid: "330400"
  },
  {
    name: "嘉善县",
    pid: "330000",
    id: "330421",
    cid: "330400"
  },
  {
    name: "南浔区",
    pid: "330000",
    id: "330503",
    cid: "330500"
  },
  {
    name: "德清县",
    pid: "330000",
    id: "330521",
    cid: "330500"
  },
  {
    name: "长兴县",
    pid: "330000",
    id: "330522",
    cid: "330500"
  },
  {
    name: "安吉县",
    pid: "330000",
    id: "330523",
    cid: "330500"
  },
  {
    name: "吴兴区",
    pid: "330000",
    id: "330502",
    cid: "330500"
  },
  {
    name: "诸暨市",
    pid: "330000",
    id: "330681",
    cid: "330600"
  },
  {
    name: "越城区",
    pid: "330000",
    id: "330602",
    cid: "330600"
  },
  {
    name: "新昌县",
    pid: "330000",
    id: "330624",
    cid: "330600"
  },
  {
    name: "柯桥区",
    pid: "330000",
    id: "330603",
    cid: "330600"
  },
  {
    name: "上虞区",
    pid: "330000",
    id: "330604",
    cid: "330600"
  },
  {
    name: "嵊州市",
    pid: "330000",
    id: "330683",
    cid: "330600"
  },
  {
    name: "磐安县",
    pid: "330000",
    id: "330727",
    cid: "330700"
  },
  {
    name: "武义县",
    pid: "330000",
    id: "330723",
    cid: "330700"
  },
  {
    name: "婺城区",
    pid: "330000",
    id: "330702",
    cid: "330700"
  },
  {
    name: "金东区",
    pid: "330000",
    id: "330703",
    cid: "330700"
  },
  {
    name: "浦江县",
    pid: "330000",
    id: "330726",
    cid: "330700"
  },
  {
    name: "兰溪市",
    pid: "330000",
    id: "330781",
    cid: "330700"
  },
  {
    name: "义乌市",
    pid: "330000",
    id: "330782",
    cid: "330700"
  },
  {
    name: "东阳市",
    pid: "330000",
    id: "330783",
    cid: "330700"
  },
  {
    name: "永康市",
    pid: "330000",
    id: "330784",
    cid: "330700"
  },
  {
    name: "青田县",
    pid: "330000",
    id: "331121",
    cid: "331100"
  },
  {
    name: "龙泉市",
    pid: "330000",
    id: "331181",
    cid: "331100"
  },
  {
    name: "庆元县",
    pid: "330000",
    id: "331126",
    cid: "331100"
  },
  {
    name: "景宁畲族自治县",
    pid: "330000",
    id: "331127",
    cid: "331100"
  },
  {
    name: "缙云县",
    pid: "330000",
    id: "331122",
    cid: "331100"
  },
  {
    name: "遂昌县",
    pid: "330000",
    id: "331123",
    cid: "331100"
  },
  {
    name: "莲都区",
    pid: "330000",
    id: "331102",
    cid: "331100"
  },
  {
    name: "松阳县",
    pid: "330000",
    id: "331124",
    cid: "331100"
  },
  {
    name: "云和县",
    pid: "330000",
    id: "331125",
    cid: "331100"
  },
  {
    name: "滨江区",
    pid: "330000",
    id: "330108",
    cid: "330100"
  },
  {
    name: "萧山区",
    pid: "330000",
    id: "330109",
    cid: "330100"
  },
  {
    name: "下城区",
    pid: "330000",
    id: "330103",
    cid: "330100"
  },
  {
    name: "江干区",
    pid: "330000",
    id: "330104",
    cid: "330100"
  },
  {
    name: "拱墅区",
    pid: "330000",
    id: "330105",
    cid: "330100"
  },
  {
    name: "淳安县",
    pid: "330000",
    id: "330127",
    cid: "330100"
  },
  {
    name: "西湖区",
    pid: "330000",
    id: "330106",
    cid: "330100"
  },
  {
    name: "余杭区",
    pid: "330000",
    id: "330110",
    cid: "330100"
  },
  {
    name: "富阳区",
    pid: "330000",
    id: "330111",
    cid: "330100"
  },
  {
    name: "桐庐县",
    pid: "330000",
    id: "330122",
    cid: "330100"
  },
  {
    name: "上城区",
    pid: "330000",
    id: "330102",
    cid: "330100"
  },
  {
    name: "临安市",
    pid: "330000",
    id: "330185",
    cid: "330100"
  },
  {
    name: "建德市",
    pid: "330000",
    id: "330182",
    cid: "330100"
  },
  {
    name: "慈溪市",
    pid: "330000",
    id: "330282",
    cid: "330200"
  },
  {
    name: "奉化市",
    pid: "330000",
    id: "330283",
    cid: "330200"
  },
  {
    name: "北仑区",
    pid: "330000",
    id: "330206",
    cid: "330200"
  },
  {
    name: "余姚市",
    pid: "330000",
    id: "330281",
    cid: "330200"
  },
  {
    name: "海曙区",
    pid: "330000",
    id: "330203",
    cid: "330200"
  },
  {
    name: "象山县",
    pid: "330000",
    id: "330225",
    cid: "330200"
  },
  {
    name: "江东区",
    pid: "330000",
    id: "330204",
    cid: "330200"
  },
  {
    name: "宁海县",
    pid: "330000",
    id: "330226",
    cid: "330200"
  },
  {
    name: "江北区",
    pid: "330000",
    id: "330205",
    cid: "330200"
  },
  {
    name: "镇海区",
    pid: "330000",
    id: "330211",
    cid: "330200"
  },
  {
    name: "鄞州区",
    pid: "330000",
    id: "330212",
    cid: "330200"
  },
  {
    name: "瑞安市",
    pid: "330000",
    id: "330381",
    cid: "330300"
  },
  {
    name: "乐清市",
    pid: "330000",
    id: "330382",
    cid: "330300"
  },
  {
    name: "洞头区",
    pid: "330000",
    id: "330305",
    cid: "330300"
  },
  {
    name: "苍南县",
    pid: "330000",
    id: "330327",
    cid: "330300"
  },
  {
    name: "文成县",
    pid: "330000",
    id: "330328",
    cid: "330300"
  },
  {
    name: "泰顺县",
    pid: "330000",
    id: "330329",
    cid: "330300"
  },
  {
    name: "鹿城区",
    pid: "330000",
    id: "330302",
    cid: "330300"
  },
  {
    name: "永嘉县",
    pid: "330000",
    id: "330324",
    cid: "330300"
  },
  {
    name: "龙湾区",
    pid: "330000",
    id: "330303",
    cid: "330300"
  },
  {
    name: "瓯海区",
    pid: "330000",
    id: "330304",
    cid: "330300"
  },
  {
    name: "平阳县",
    pid: "330000",
    id: "330326",
    cid: "330300"
  },
  {
    name: "崇明区",
    pid: "310000",
    id: "310151",
    cid: "310100"
  },
  {
    name: "奉贤区",
    pid: "310000",
    id: "310120",
    cid: "310100"
  },
  {
    name: "杨浦区",
    pid: "310000",
    id: "310110",
    cid: "310100"
  },
  {
    name: "黄浦区",
    pid: "310000",
    id: "310101",
    cid: "310100"
  },
  {
    name: "闵行区",
    pid: "310000",
    id: "310112",
    cid: "310100"
  },
  {
    name: "宝山区",
    pid: "310000",
    id: "310113",
    cid: "310100"
  },
  {
    name: "嘉定区",
    pid: "310000",
    id: "310114",
    cid: "310100"
  },
  {
    name: "徐汇区",
    pid: "310000",
    id: "310104",
    cid: "310100"
  },
  {
    name: "浦东新区",
    pid: "310000",
    id: "310115",
    cid: "310100"
  },
  {
    name: "长宁区",
    pid: "310000",
    id: "310105",
    cid: "310100"
  },
  {
    name: "金山区",
    pid: "310000",
    id: "310116",
    cid: "310100"
  },
  {
    name: "静安区",
    pid: "310000",
    id: "310106",
    cid: "310100"
  },
  {
    name: "松江区",
    pid: "310000",
    id: "310117",
    cid: "310100"
  },
  {
    name: "普陀区",
    pid: "310000",
    id: "310107",
    cid: "310100"
  },
  {
    name: "青浦区",
    pid: "310000",
    id: "310118",
    cid: "310100"
  },
  {
    name: "虹口区",
    pid: "310000",
    id: "310109",
    cid: "310100"
  },
  {
    name: "河东区",
    pid: "120000",
    id: "120102",
    cid: "120100"
  },
  {
    name: "北辰区",
    pid: "120000",
    id: "120113",
    cid: "120100"
  },
  {
    name: "河西区",
    pid: "120000",
    id: "120103",
    cid: "120100"
  },
  {
    name: "武清区",
    pid: "120000",
    id: "120114",
    cid: "120100"
  },
  {
    name: "南开区",
    pid: "120000",
    id: "120104",
    cid: "120100"
  },
  {
    name: "宝坻区",
    pid: "120000",
    id: "120115",
    cid: "120100"
  },
  {
    name: "河北区",
    pid: "120000",
    id: "120105",
    cid: "120100"
  },
  {
    name: "滨海新区",
    pid: "120000",
    id: "120116",
    cid: "120100"
  },
  {
    name: "东丽区",
    pid: "120000",
    id: "120110",
    cid: "120100"
  },
  {
    name: "西青区",
    pid: "120000",
    id: "120111",
    cid: "120100"
  },
  {
    name: "和平区",
    pid: "120000",
    id: "120101",
    cid: "120100"
  },
  {
    name: "津南区",
    pid: "120000",
    id: "120112",
    cid: "120100"
  },
  {
    name: "红桥区",
    pid: "120000",
    id: "120106",
    cid: "120100"
  },
  {
    name: "宁河区",
    pid: "120000",
    id: "120117",
    cid: "120100"
  },
  {
    name: "静海区",
    pid: "120000",
    id: "120118",
    cid: "120100"
  },
  {
    name: "蓟州区",
    pid: "120000",
    id: "120119",
    cid: "120100"
  },
  {
    name: "凤翔县",
    pid: "610000",
    id: "610322",
    cid: "610300"
  },
  {
    name: "岐山县",
    pid: "610000",
    id: "610323",
    cid: "610300"
  },
  {
    name: "太白县",
    pid: "610000",
    id: "610331",
    cid: "610300"
  },
  {
    name: "凤县",
    pid: "610000",
    id: "610330",
    cid: "610300"
  },
  {
    name: "千阳县",
    pid: "610000",
    id: "610328",
    cid: "610300"
  },
  {
    name: "麟游县",
    pid: "610000",
    id: "610329",
    cid: "610300"
  },
  {
    name: "陈仓区",
    pid: "610000",
    id: "610304",
    cid: "610300"
  },
  {
    name: "眉县",
    pid: "610000",
    id: "610326",
    cid: "610300"
  },
  {
    name: "陇县",
    pid: "610000",
    id: "610327",
    cid: "610300"
  },
  {
    name: "渭滨区",
    pid: "610000",
    id: "610302",
    cid: "610300"
  },
  {
    name: "扶风县",
    pid: "610000",
    id: "610324",
    cid: "610300"
  },
  {
    name: "金台区",
    pid: "610000",
    id: "610303",
    cid: "610300"
  },
  {
    name: "旬邑县",
    pid: "610000",
    id: "610429",
    cid: "610400"
  },
  {
    name: "彬县",
    pid: "610000",
    id: "610427",
    cid: "610400"
  },
  {
    name: "长武县",
    pid: "610000",
    id: "610428",
    cid: "610400"
  },
  {
    name: "杨陵区",
    pid: "610000",
    id: "610403",
    cid: "610400"
  },
  {
    name: "礼泉县",
    pid: "610000",
    id: "610425",
    cid: "610400"
  },
  {
    name: "渭城区",
    pid: "610000",
    id: "610404",
    cid: "610400"
  },
  {
    name: "永寿县",
    pid: "610000",
    id: "610426",
    cid: "610400"
  },
  {
    name: "泾阳县",
    pid: "610000",
    id: "610423",
    cid: "610400"
  },
  {
    name: "秦都区",
    pid: "610000",
    id: "610402",
    cid: "610400"
  },
  {
    name: "乾县",
    pid: "610000",
    id: "610424",
    cid: "610400"
  },
  {
    name: "三原县",
    pid: "610000",
    id: "610422",
    cid: "610400"
  },
  {
    name: "淳化县",
    pid: "610000",
    id: "610430",
    cid: "610400"
  },
  {
    name: "武功县",
    pid: "610000",
    id: "610431",
    cid: "610400"
  },
  {
    name: "兴平市",
    pid: "610000",
    id: "610481",
    cid: "610400"
  },
  {
    name: "高陵区",
    pid: "610000",
    id: "610117",
    cid: "610100"
  },
  {
    name: "莲湖区",
    pid: "610000",
    id: "610104",
    cid: "610100"
  },
  {
    name: "临潼区",
    pid: "610000",
    id: "610115",
    cid: "610100"
  },
  {
    name: "长安区",
    pid: "610000",
    id: "610116",
    cid: "610100"
  },
  {
    name: "新城区",
    pid: "610000",
    id: "610102",
    cid: "610100"
  },
  {
    name: "雁塔区",
    pid: "610000",
    id: "610113",
    cid: "610100"
  },
  {
    name: "周至县",
    pid: "610000",
    id: "610124",
    cid: "610100"
  },
  {
    name: "碑林区",
    pid: "610000",
    id: "610103",
    cid: "610100"
  },
  {
    name: "阎良区",
    pid: "610000",
    id: "610114",
    cid: "610100"
  },
  {
    name: "户县",
    pid: "610000",
    id: "610125",
    cid: "610100"
  },
  {
    name: "灞桥区",
    pid: "610000",
    id: "610111",
    cid: "610100"
  },
  {
    name: "蓝田县",
    pid: "610000",
    id: "610122",
    cid: "610100"
  },
  {
    name: "未央区",
    pid: "610000",
    id: "610112",
    cid: "610100"
  },
  {
    name: "王益区",
    pid: "610000",
    id: "610202",
    cid: "610200"
  },
  {
    name: "宜君县",
    pid: "610000",
    id: "610222",
    cid: "610200"
  },
  {
    name: "印台区",
    pid: "610000",
    id: "610203",
    cid: "610200"
  },
  {
    name: "耀州区",
    pid: "610000",
    id: "610204",
    cid: "610200"
  },
  {
    name: "柞水县",
    pid: "610000",
    id: "611026",
    cid: "611000"
  },
  {
    name: "镇安县",
    pid: "610000",
    id: "611025",
    cid: "611000"
  },
  {
    name: "商州区",
    pid: "610000",
    id: "611002",
    cid: "611000"
  },
  {
    name: "山阳县",
    pid: "610000",
    id: "611024",
    cid: "611000"
  },
  {
    name: "商南县",
    pid: "610000",
    id: "611023",
    cid: "611000"
  },
  {
    name: "丹凤县",
    pid: "610000",
    id: "611022",
    cid: "611000"
  },
  {
    name: "洛南县",
    pid: "610000",
    id: "611021",
    cid: "611000"
  },
  {
    name: "旬阳县",
    pid: "610000",
    id: "610928",
    cid: "610900"
  },
  {
    name: "白河县",
    pid: "610000",
    id: "610929",
    cid: "610900"
  },
  {
    name: "平利县",
    pid: "610000",
    id: "610926",
    cid: "610900"
  },
  {
    name: "镇坪县",
    pid: "610000",
    id: "610927",
    cid: "610900"
  },
  {
    name: "汉滨区",
    pid: "610000",
    id: "610902",
    cid: "610900"
  },
  {
    name: "紫阳县",
    pid: "610000",
    id: "610924",
    cid: "610900"
  },
  {
    name: "岚皋县",
    pid: "610000",
    id: "610925",
    cid: "610900"
  },
  {
    name: "石泉县",
    pid: "610000",
    id: "610922",
    cid: "610900"
  },
  {
    name: "宁陕县",
    pid: "610000",
    id: "610923",
    cid: "610900"
  },
  {
    name: "汉阴县",
    pid: "610000",
    id: "610921",
    cid: "610900"
  },
  {
    name: "佛坪县",
    pid: "610000",
    id: "610730",
    cid: "610700"
  },
  {
    name: "镇巴县",
    pid: "610000",
    id: "610728",
    cid: "610700"
  },
  {
    name: "留坝县",
    pid: "610000",
    id: "610729",
    cid: "610700"
  },
  {
    name: "宁强县",
    pid: "610000",
    id: "610726",
    cid: "610700"
  },
  {
    name: "略阳县",
    pid: "610000",
    id: "610727",
    cid: "610700"
  },
  {
    name: "汉台区",
    pid: "610000",
    id: "610702",
    cid: "610700"
  },
  {
    name: "西乡县",
    pid: "610000",
    id: "610724",
    cid: "610700"
  },
  {
    name: "勉县",
    pid: "610000",
    id: "610725",
    cid: "610700"
  },
  {
    name: "城固县",
    pid: "610000",
    id: "610722",
    cid: "610700"
  },
  {
    name: "洋县",
    pid: "610000",
    id: "610723",
    cid: "610700"
  },
  {
    name: "南郑县",
    pid: "610000",
    id: "610721",
    cid: "610700"
  },
  {
    name: "吴堡县",
    pid: "610000",
    id: "610829",
    cid: "610800"
  },
  {
    name: "米脂县",
    pid: "610000",
    id: "610827",
    cid: "610800"
  },
  {
    name: "佳县",
    pid: "610000",
    id: "610828",
    cid: "610800"
  },
  {
    name: "横山区",
    pid: "610000",
    id: "610803",
    cid: "610800"
  },
  {
    name: "定边县",
    pid: "610000",
    id: "610825",
    cid: "610800"
  },
  {
    name: "绥德县",
    pid: "610000",
    id: "610826",
    cid: "610800"
  },
  {
    name: "榆阳区",
    pid: "610000",
    id: "610802",
    cid: "610800"
  },
  {
    name: "靖边县",
    pid: "610000",
    id: "610824",
    cid: "610800"
  },
  {
    name: "神木县",
    pid: "610000",
    id: "610821",
    cid: "610800"
  },
  {
    name: "府谷县",
    pid: "610000",
    id: "610822",
    cid: "610800"
  },
  {
    name: "清涧县",
    pid: "610000",
    id: "610830",
    cid: "610800"
  },
  {
    name: "子洲县",
    pid: "610000",
    id: "610831",
    cid: "610800"
  },
  {
    name: "华阴市",
    pid: "610000",
    id: "610582",
    cid: "610500"
  },
  {
    name: "韩城市",
    pid: "610000",
    id: "610581",
    cid: "610500"
  },
  {
    name: "富平县",
    pid: "610000",
    id: "610528",
    cid: "610500"
  },
  {
    name: "蒲城县",
    pid: "610000",
    id: "610526",
    cid: "610500"
  },
  {
    name: "白水县",
    pid: "610000",
    id: "610527",
    cid: "610500"
  },
  {
    name: "临渭区",
    pid: "610000",
    id: "610502",
    cid: "610500"
  },
  {
    name: "合阳县",
    pid: "610000",
    id: "610524",
    cid: "610500"
  },
  {
    name: "华州区",
    pid: "610000",
    id: "610503",
    cid: "610500"
  },
  {
    name: "澄城县",
    pid: "610000",
    id: "610525",
    cid: "610500"
  },
  {
    name: "潼关县",
    pid: "610000",
    id: "610522",
    cid: "610500"
  },
  {
    name: "大荔县",
    pid: "610000",
    id: "610523",
    cid: "610500"
  },
  {
    name: "洛川县",
    pid: "610000",
    id: "610629",
    cid: "610600"
  },
  {
    name: "甘泉县",
    pid: "610000",
    id: "610627",
    cid: "610600"
  },
  {
    name: "富县",
    pid: "610000",
    id: "610628",
    cid: "610600"
  },
  {
    name: "安塞区",
    pid: "610000",
    id: "610603",
    cid: "610600"
  },
  {
    name: "志丹县",
    pid: "610000",
    id: "610625",
    cid: "610600"
  },
  {
    name: "吴起县",
    pid: "610000",
    id: "610626",
    cid: "610600"
  },
  {
    name: "子长县",
    pid: "610000",
    id: "610623",
    cid: "610600"
  },
  {
    name: "宝塔区",
    pid: "610000",
    id: "610602",
    cid: "610600"
  },
  {
    name: "延长县",
    pid: "610000",
    id: "610621",
    cid: "610600"
  },
  {
    name: "黄陵县",
    pid: "610000",
    id: "610632",
    cid: "610600"
  },
  {
    name: "延川县",
    pid: "610000",
    id: "610622",
    cid: "610600"
  },
  {
    name: "宜川县",
    pid: "610000",
    id: "610630",
    cid: "610600"
  },
  {
    name: "黄龙县",
    pid: "610000",
    id: "610631",
    cid: "610600"
  },
  {
    name: "新界",
    pid: "910000",
    id: "810120",
    cid: "810000"
  },
  {
    name: "葵青区",
    pid: "910000",
    id: "810110",
    cid: "810000"
  },
  {
    name: "北区",
    pid: "910000",
    id: "810111",
    cid: "810000"
  },
  {
    name: "中西区",
    pid: "910000",
    id: "810101",
    cid: "810000"
  },
  {
    name: "西贡区",
    pid: "910000",
    id: "810112",
    cid: "810000"
  },
  {
    name: "东区",
    pid: "910000",
    id: "810102",
    cid: "810000"
  },
  {
    name: "沙田区",
    pid: "910000",
    id: "810113",
    cid: "810000"
  },
  {
    name: "九龙城区",
    pid: "910000",
    id: "810103",
    cid: "810000"
  },
  {
    name: "屯门区",
    pid: "910000",
    id: "810114",
    cid: "810000"
  },
  {
    name: "观塘区",
    pid: "910000",
    id: "810104",
    cid: "810000"
  },
  {
    name: "大埔区",
    pid: "910000",
    id: "810115",
    cid: "810000"
  },
  {
    name: "深水埗区",
    pid: "910000",
    id: "810105",
    cid: "810000"
  },
  {
    name: "荃湾区",
    pid: "910000",
    id: "810116",
    cid: "810000"
  },
  {
    name: "湾仔区",
    pid: "910000",
    id: "810106",
    cid: "810000"
  },
  {
    name: "元朗区",
    pid: "910000",
    id: "810117",
    cid: "810000"
  },
  {
    name: "黄大仙区",
    pid: "910000",
    id: "810107",
    cid: "810000"
  },
  {
    name: "香港",
    pid: "910000",
    id: "810118",
    cid: "810000"
  },
  {
    name: "油尖旺区",
    pid: "910000",
    id: "810108",
    cid: "810000"
  },
  {
    name: "九龙",
    pid: "910000",
    id: "810119",
    cid: "810000"
  },
  {
    name: "离岛区",
    pid: "910000",
    id: "810109",
    cid: "810000"
  },
  {
    name: "路环",
    pid: "910000",
    id: "820105",
    cid: "820000"
  },
  {
    name: "凼仔",
    pid: "910000",
    id: "820103",
    cid: "820000"
  },
  {
    name: "路凼城",
    pid: "910000",
    id: "820104",
    cid: "820000"
  },
  {
    name: "离岛",
    pid: "910000",
    id: "820101",
    cid: "820000"
  },
  {
    name: "澳门半岛",
    pid: "910000",
    id: "820102",
    cid: "820000"
  },
  {
    name: "伊州区",
    pid: "650000",
    id: "650502",
    cid: "650500"
  },
  {
    name: "伊吾县",
    pid: "650000",
    id: "650522",
    cid: "650500"
  },
  {
    name: "巴里坤哈萨克自治县",
    pid: "650000",
    id: "650521",
    cid: "650500"
  },
  {
    name: "高昌区",
    pid: "650000",
    id: "650402",
    cid: "650400"
  },
  {
    name: "托克逊县",
    pid: "650000",
    id: "650422",
    cid: "650400"
  },
  {
    name: "鄯善县",
    pid: "650000",
    id: "650421",
    cid: "650400"
  },
  {
    name: "乌尔禾区",
    pid: "650000",
    id: "650205",
    cid: "650200"
  },
  {
    name: "白碱滩区",
    pid: "650000",
    id: "650204",
    cid: "650200"
  },
  {
    name: "克拉玛依区",
    pid: "650000",
    id: "650203",
    cid: "650200"
  },
  {
    name: "独山子区",
    pid: "650000",
    id: "650202",
    cid: "650200"
  },
  {
    name: "达坂城区",
    pid: "650000",
    id: "650107",
    cid: "650100"
  },
  {
    name: "头屯河区",
    pid: "650000",
    id: "650106",
    cid: "650100"
  },
  {
    name: "水磨沟区",
    pid: "650000",
    id: "650105",
    cid: "650100"
  },
  {
    name: "新市区",
    pid: "650000",
    id: "650104",
    cid: "650100"
  },
  {
    name: "沙依巴克区",
    pid: "650000",
    id: "650103",
    cid: "650100"
  },
  {
    name: "天山区",
    pid: "650000",
    id: "650102",
    cid: "650100"
  },
  {
    name: "乌鲁木齐县",
    pid: "650000",
    id: "650121",
    cid: "650100"
  },
  {
    name: "米东区",
    pid: "650000",
    id: "650109",
    cid: "650100"
  },
  {
    name: "昌吉市",
    pid: "650000",
    id: "652301",
    cid: "652300"
  },
  {
    name: "呼图壁县",
    pid: "650000",
    id: "652323",
    cid: "652300"
  },
  {
    name: "阜康市",
    pid: "650000",
    id: "652302",
    cid: "652300"
  },
  {
    name: "玛纳斯县",
    pid: "650000",
    id: "652324",
    cid: "652300"
  },
  {
    name: "奇台县",
    pid: "650000",
    id: "652325",
    cid: "652300"
  },
  {
    name: "吉木萨尔县",
    pid: "650000",
    id: "652327",
    cid: "652300"
  },
  {
    name: "木垒哈萨克自治县",
    pid: "650000",
    id: "652328",
    cid: "652300"
  },
  {
    name: "精河县",
    pid: "650000",
    id: "652722",
    cid: "652700"
  },
  {
    name: "博乐市",
    pid: "650000",
    id: "652701",
    cid: "652700"
  },
  {
    name: "温泉县",
    pid: "650000",
    id: "652723",
    cid: "652700"
  },
  {
    name: "阿拉山口市",
    pid: "650000",
    id: "652702",
    cid: "652700"
  },
  {
    name: "且末县",
    pid: "650000",
    id: "652825",
    cid: "652800"
  },
  {
    name: "焉耆回族自治县",
    pid: "650000",
    id: "652826",
    cid: "652800"
  },
  {
    name: "和静县",
    pid: "650000",
    id: "652827",
    cid: "652800"
  },
  {
    name: "和硕县",
    pid: "650000",
    id: "652828",
    cid: "652800"
  },
  {
    name: "博湖县",
    pid: "650000",
    id: "652829",
    cid: "652800"
  },
  {
    name: "轮台县",
    pid: "650000",
    id: "652822",
    cid: "652800"
  },
  {
    name: "库尔勒市",
    pid: "650000",
    id: "652801",
    cid: "652800"
  },
  {
    name: "尉犁县",
    pid: "650000",
    id: "652823",
    cid: "652800"
  },
  {
    name: "若羌县",
    pid: "650000",
    id: "652824",
    cid: "652800"
  },
  {
    name: "沙雅县",
    pid: "650000",
    id: "652924",
    cid: "652900"
  },
  {
    name: "新和县",
    pid: "650000",
    id: "652925",
    cid: "652900"
  },
  {
    name: "拜城县",
    pid: "650000",
    id: "652926",
    cid: "652900"
  },
  {
    name: "乌什县",
    pid: "650000",
    id: "652927",
    cid: "652900"
  },
  {
    name: "阿瓦提县",
    pid: "650000",
    id: "652928",
    cid: "652900"
  },
  {
    name: "柯坪县",
    pid: "650000",
    id: "652929",
    cid: "652900"
  },
  {
    name: "温宿县",
    pid: "650000",
    id: "652922",
    cid: "652900"
  },
  {
    name: "阿克苏市",
    pid: "650000",
    id: "652901",
    cid: "652900"
  },
  {
    name: "库车县",
    pid: "650000",
    id: "652923",
    cid: "652900"
  },
  {
    name: "兵团一零一团",
    pid: "650000",
    id: "6590045",
    cid: "659004"
  },
  {
    name: "军垦路街道",
    pid: "650000",
    id: "659004001",
    cid: "659004"
  },
  {
    name: "兵团一零二团",
    pid: "650000",
    id: "659004501",
    cid: "659004"
  },
  {
    name: "兵团一零三团",
    pid: "650000",
    id: "659004502",
    cid: "659004"
  },
  {
    name: "青湖路街道",
    pid: "650000",
    id: "659004002",
    cid: "659004"
  },
  {
    name: "人民路街道",
    pid: "650000",
    id: "659004003",
    cid: "659004"
  },
  {
    name: "兵团四十九团",
    pid: "650000",
    id: "659003509",
    cid: "659003"
  },
  {
    name: "兵团四十四团",
    pid: "650000",
    id: "659003504",
    cid: "659003"
  },
  {
    name: "兵团五十一团",
    pid: "650000",
    id: "659003511",
    cid: "659003"
  },
  {
    name: "兵团图木舒克市喀拉拜勒镇",
    pid: "650000",
    id: "659003514",
    cid: "659003"
  },
  {
    name: "兵团五十三团",
    pid: "650000",
    id: "659003513",
    cid: "659003"
  },
  {
    name: "前海街道",
    pid: "650000",
    id: "659003002",
    cid: "659003"
  },
  {
    name: "兵团五十团",
    pid: "650000",
    id: "65900351",
    cid: "659003"
  },
  {
    name: "齐干却勒街道",
    pid: "650000",
    id: "659003001",
    cid: "659003"
  },
  {
    name: "永安坝街道",
    pid: "650000",
    id: "659003003",
    cid: "659003"
  },
  {
    name: "伊宁县",
    pid: "650000",
    id: "654021",
    cid: "654000"
  },
  {
    name: "察布查尔锡伯自治县",
    pid: "650000",
    id: "654022",
    cid: "654000"
  },
  {
    name: "霍城县",
    pid: "650000",
    id: "654023",
    cid: "654000"
  },
  {
    name: "伊宁市",
    pid: "650000",
    id: "654002",
    cid: "654000"
  },
  {
    name: "巩留县",
    pid: "650000",
    id: "654024",
    cid: "654000"
  },
  {
    name: "奎屯市",
    pid: "650000",
    id: "654003",
    cid: "654000"
  },
  {
    name: "新源县",
    pid: "650000",
    id: "654025",
    cid: "654000"
  },
  {
    name: "霍尔果斯市",
    pid: "650000",
    id: "654004",
    cid: "654000"
  },
  {
    name: "昭苏县",
    pid: "650000",
    id: "654026",
    cid: "654000"
  },
  {
    name: "特克斯县",
    pid: "650000",
    id: "654027",
    cid: "654000"
  },
  {
    name: "尼勒克县",
    pid: "650000",
    id: "654028",
    cid: "654000"
  },
  {
    name: "托喀依乡",
    pid: "650000",
    id: "6590022",
    cid: "659002"
  },
  {
    name: "兵团七团",
    pid: "650000",
    id: "6590025",
    cid: "659002"
  },
  {
    name: "兵团第一师水利水电工程处",
    pid: "650000",
    id: "659002511",
    cid: "659002"
  },
  {
    name: "南口街道",
    pid: "650000",
    id: "659002004",
    cid: "659002"
  },
  {
    name: "阿拉尔农场",
    pid: "650000",
    id: "659002513",
    cid: "659002"
  },
  {
    name: "工业园区",
    pid: "650000",
    id: "659002402",
    cid: "659002"
  },
  {
    name: "兵团八团",
    pid: "650000",
    id: "659002501",
    cid: "659002"
  },
  {
    name: "兵团第一师塔里木灌区水利管理处",
    pid: "650000",
    id: "659002512",
    cid: "659002"
  },
  {
    name: "金银川路街道",
    pid: "650000",
    id: "659002001",
    cid: "659002"
  },
  {
    name: "青松路街道",
    pid: "650000",
    id: "659002003",
    cid: "659002"
  },
  {
    name: "幸福路街道",
    pid: "650000",
    id: "659002002",
    cid: "659002"
  },
  {
    name: "兵团二团",
    pid: "650000",
    id: "659002519",
    cid: "659002"
  },
  {
    name: "兵团十四团",
    pid: "650000",
    id: "659002507",
    cid: "659002"
  },
  {
    name: "西工业园区管理委员会",
    pid: "650000",
    id: "659002518",
    cid: "659002"
  },
  {
    name: "兵团十六团",
    pid: "650000",
    id: "659002509",
    cid: "659002"
  },
  {
    name: "兵团十一团",
    pid: "650000",
    id: "659002504",
    cid: "659002"
  },
  {
    name: "中心监狱",
    pid: "650000",
    id: "659002515",
    cid: "659002"
  },
  {
    name: "兵团十团",
    pid: "650000",
    id: "659002503",
    cid: "659002"
  },
  {
    name: "兵团第一师幸福农场",
    pid: "650000",
    id: "659002514",
    cid: "659002"
  },
  {
    name: "兵团十三团",
    pid: "650000",
    id: "659002506",
    cid: "659002"
  },
  {
    name: "兵团农一师沙井子水利管理处",
    pid: "650000",
    id: "659002517",
    cid: "659002"
  },
  {
    name: "兵团十二团",
    pid: "650000",
    id: "659002505",
    cid: "659002"
  },
  {
    name: "兵团一团",
    pid: "650000",
    id: "659002516",
    cid: "659002"
  },
  {
    name: "兵团三团",
    pid: "650000",
    id: "65900252",
    cid: "659002"
  },
  {
    name: "阿克陶县",
    pid: "650000",
    id: "653022",
    cid: "653000"
  },
  {
    name: "阿图什市",
    pid: "650000",
    id: "653001",
    cid: "653000"
  },
  {
    name: "阿合奇县",
    pid: "650000",
    id: "653023",
    cid: "653000"
  },
  {
    name: "乌恰县",
    pid: "650000",
    id: "653024",
    cid: "653000"
  },
  {
    name: "巴楚县",
    pid: "650000",
    id: "653130",
    cid: "653100"
  },
  {
    name: "塔什库尔干塔吉克自治县",
    pid: "650000",
    id: "653131",
    cid: "653100"
  },
  {
    name: "疏附县",
    pid: "650000",
    id: "653121",
    cid: "653100"
  },
  {
    name: "疏勒县",
    pid: "650000",
    id: "653122",
    cid: "653100"
  },
  {
    name: "喀什市",
    pid: "650000",
    id: "653101",
    cid: "653100"
  },
  {
    name: "英吉沙县",
    pid: "650000",
    id: "653123",
    cid: "653100"
  },
  {
    name: "泽普县",
    pid: "650000",
    id: "653124",
    cid: "653100"
  },
  {
    name: "莎车县",
    pid: "650000",
    id: "653125",
    cid: "653100"
  },
  {
    name: "叶城县",
    pid: "650000",
    id: "653126",
    cid: "653100"
  },
  {
    name: "麦盖提县",
    pid: "650000",
    id: "653127",
    cid: "653100"
  },
  {
    name: "岳普湖县",
    pid: "650000",
    id: "653128",
    cid: "653100"
  },
  {
    name: "伽师县",
    pid: "650000",
    id: "653129",
    cid: "653100"
  },
  {
    name: "额敏县",
    pid: "650000",
    id: "654221",
    cid: "654200"
  },
  {
    name: "塔城市",
    pid: "650000",
    id: "654201",
    cid: "654200"
  },
  {
    name: "沙湾县",
    pid: "650000",
    id: "654223",
    cid: "654200"
  },
  {
    name: "乌苏市",
    pid: "650000",
    id: "654202",
    cid: "654200"
  },
  {
    name: "托里县",
    pid: "650000",
    id: "654224",
    cid: "654200"
  },
  {
    name: "裕民县",
    pid: "650000",
    id: "654225",
    cid: "654200"
  },
  {
    name: "和布克赛尔蒙古自治县",
    pid: "650000",
    id: "654226",
    cid: "654200"
  },
  {
    name: "和田县",
    pid: "650000",
    id: "653221",
    cid: "653200"
  },
  {
    name: "墨玉县",
    pid: "650000",
    id: "653222",
    cid: "653200"
  },
  {
    name: "和田市",
    pid: "650000",
    id: "653201",
    cid: "653200"
  },
  {
    name: "皮山县",
    pid: "650000",
    id: "653223",
    cid: "653200"
  },
  {
    name: "洛浦县",
    pid: "650000",
    id: "653224",
    cid: "653200"
  },
  {
    name: "策勒县",
    pid: "650000",
    id: "653225",
    cid: "653200"
  },
  {
    name: "于田县",
    pid: "650000",
    id: "653226",
    cid: "653200"
  },
  {
    name: "民丰县",
    pid: "650000",
    id: "653227",
    cid: "653200"
  },
  {
    name: "布尔津县",
    pid: "650000",
    id: "654321",
    cid: "654300"
  },
  {
    name: "富蕴县",
    pid: "650000",
    id: "654322",
    cid: "654300"
  },
  {
    name: "阿勒泰市",
    pid: "650000",
    id: "654301",
    cid: "654300"
  },
  {
    name: "福海县",
    pid: "650000",
    id: "654323",
    cid: "654300"
  },
  {
    name: "哈巴河县",
    pid: "650000",
    id: "654324",
    cid: "654300"
  },
  {
    name: "青河县",
    pid: "650000",
    id: "654325",
    cid: "654300"
  },
  {
    name: "吉木乃县",
    pid: "650000",
    id: "654326",
    cid: "654300"
  },
  {
    name: "双丰镇",
    pid: "650000",
    id: "659006101",
    cid: "659006"
  },
  {
    name: "博古其镇",
    pid: "650000",
    id: "6590061",
    cid: "659006"
  },
  {
    name: "北泉镇",
    pid: "650000",
    id: "6590011",
    cid: "659001"
  },
  {
    name: "兵团一五二团",
    pid: "650000",
    id: "6590015",
    cid: "659001"
  },
  {
    name: "红山街道",
    pid: "650000",
    id: "659001003",
    cid: "659001"
  },
  {
    name: "老街街道",
    pid: "650000",
    id: "659001004",
    cid: "659001"
  },
  {
    name: "东城街道",
    pid: "650000",
    id: "659001005",
    cid: "659001"
  },
  {
    name: "新城街道",
    pid: "650000",
    id: "659001001",
    cid: "659001"
  },
  {
    name: "向阳街道",
    pid: "650000",
    id: "659001002",
    cid: "659001"
  },
  {
    name: "石河子镇",
    pid: "650000",
    id: "659001101",
    cid: "659001"
  },
  {
    name: "兴仁县",
    pid: "520000",
    id: "522322",
    cid: "522300"
  },
  {
    name: "兴义市",
    pid: "520000",
    id: "522301",
    cid: "522300"
  },
  {
    name: "普安县",
    pid: "520000",
    id: "522323",
    cid: "522300"
  },
  {
    name: "晴隆县",
    pid: "520000",
    id: "522324",
    cid: "522300"
  },
  {
    name: "贞丰县",
    pid: "520000",
    id: "522325",
    cid: "522300"
  },
  {
    name: "望谟县",
    pid: "520000",
    id: "522326",
    cid: "522300"
  },
  {
    name: "册亨县",
    pid: "520000",
    id: "522327",
    cid: "522300"
  },
  {
    name: "安龙县",
    pid: "520000",
    id: "522328",
    cid: "522300"
  },
  {
    name: "水城县",
    pid: "520000",
    id: "520221",
    cid: "520200"
  },
  {
    name: "盘县",
    pid: "520000",
    id: "520222",
    cid: "520200"
  },
  {
    name: "钟山区",
    pid: "520000",
    id: "520201",
    cid: "520200"
  },
  {
    name: "六枝特区",
    pid: "520000",
    id: "520203",
    cid: "520200"
  },
  {
    name: "桐梓县",
    pid: "520000",
    id: "520322",
    cid: "520300"
  },
  {
    name: "绥阳县",
    pid: "520000",
    id: "520323",
    cid: "520300"
  },
  {
    name: "红花岗区",
    pid: "520000",
    id: "520302",
    cid: "520300"
  },
  {
    name: "正安县",
    pid: "520000",
    id: "520324",
    cid: "520300"
  },
  {
    name: "汇川区",
    pid: "520000",
    id: "520303",
    cid: "520300"
  },
  {
    name: "道真仡佬族苗族自治县",
    pid: "520000",
    id: "520325",
    cid: "520300"
  },
  {
    name: "播州区",
    pid: "520000",
    id: "520304",
    cid: "520300"
  },
  {
    name: "务川仡佬族苗族自治县",
    pid: "520000",
    id: "520326",
    cid: "520300"
  },
  {
    name: "凤冈县",
    pid: "520000",
    id: "520327",
    cid: "520300"
  },
  {
    name: "湄潭县",
    pid: "520000",
    id: "520328",
    cid: "520300"
  },
  {
    name: "余庆县",
    pid: "520000",
    id: "520329",
    cid: "520300"
  },
  {
    name: "赤水市",
    pid: "520000",
    id: "520381",
    cid: "520300"
  },
  {
    name: "仁怀市",
    pid: "520000",
    id: "520382",
    cid: "520300"
  },
  {
    name: "习水县",
    pid: "520000",
    id: "520330",
    cid: "520300"
  },
  {
    name: "开阳县",
    pid: "520000",
    id: "520121",
    cid: "520100"
  },
  {
    name: "云岩区",
    pid: "520000",
    id: "520103",
    cid: "520100"
  },
  {
    name: "息烽县",
    pid: "520000",
    id: "520122",
    cid: "520100"
  },
  {
    name: "南明区",
    pid: "520000",
    id: "520102",
    cid: "520100"
  },
  {
    name: "修文县",
    pid: "520000",
    id: "520123",
    cid: "520100"
  },
  {
    name: "乌当区",
    pid: "520000",
    id: "520112",
    cid: "520100"
  },
  {
    name: "白云区",
    pid: "520000",
    id: "520113",
    cid: "520100"
  },
  {
    name: "花溪区",
    pid: "520000",
    id: "520111",
    cid: "520100"
  },
  {
    name: "观山湖区",
    pid: "520000",
    id: "520115",
    cid: "520100"
  },
  {
    name: "清镇市",
    pid: "520000",
    id: "520181",
    cid: "520100"
  },
  {
    name: "普定县",
    pid: "520000",
    id: "520422",
    cid: "520400"
  },
  {
    name: "镇宁布依族苗族自治县",
    pid: "520000",
    id: "520423",
    cid: "520400"
  },
  {
    name: "西秀区",
    pid: "520000",
    id: "520402",
    cid: "520400"
  },
  {
    name: "关岭布依族苗族自治县",
    pid: "520000",
    id: "520424",
    cid: "520400"
  },
  {
    name: "平坝区",
    pid: "520000",
    id: "520403",
    cid: "520400"
  },
  {
    name: "紫云苗族布依族自治县",
    pid: "520000",
    id: "520425",
    cid: "520400"
  },
  {
    name: "剑河县",
    pid: "520000",
    id: "522629",
    cid: "522600"
  },
  {
    name: "榕江县",
    pid: "520000",
    id: "522632",
    cid: "522600"
  },
  {
    name: "黄平县",
    pid: "520000",
    id: "522622",
    cid: "522600"
  },
  {
    name: "从江县",
    pid: "520000",
    id: "522633",
    cid: "522600"
  },
  {
    name: "凯里市",
    pid: "520000",
    id: "522601",
    cid: "522600"
  },
  {
    name: "施秉县",
    pid: "520000",
    id: "522623",
    cid: "522600"
  },
  {
    name: "雷山县",
    pid: "520000",
    id: "522634",
    cid: "522600"
  },
  {
    name: "三穗县",
    pid: "520000",
    id: "522624",
    cid: "522600"
  },
  {
    name: "麻江县",
    pid: "520000",
    id: "522635",
    cid: "522600"
  },
  {
    name: "镇远县",
    pid: "520000",
    id: "522625",
    cid: "522600"
  },
  {
    name: "丹寨县",
    pid: "520000",
    id: "522636",
    cid: "522600"
  },
  {
    name: "岑巩县",
    pid: "520000",
    id: "522626",
    cid: "522600"
  },
  {
    name: "天柱县",
    pid: "520000",
    id: "522627",
    cid: "522600"
  },
  {
    name: "锦屏县",
    pid: "520000",
    id: "522628",
    cid: "522600"
  },
  {
    name: "台江县",
    pid: "520000",
    id: "522630",
    cid: "522600"
  },
  {
    name: "黎平县",
    pid: "520000",
    id: "522631",
    cid: "522600"
  },
  {
    name: "大方县",
    pid: "520000",
    id: "520521",
    cid: "520500"
  },
  {
    name: "黔西县",
    pid: "520000",
    id: "520522",
    cid: "520500"
  },
  {
    name: "金沙县",
    pid: "520000",
    id: "520523",
    cid: "520500"
  },
  {
    name: "七星关区",
    pid: "520000",
    id: "520502",
    cid: "520500"
  },
  {
    name: "织金县",
    pid: "520000",
    id: "520524",
    cid: "520500"
  },
  {
    name: "纳雍县",
    pid: "520000",
    id: "520525",
    cid: "520500"
  },
  {
    name: "威宁彝族回族苗族自治县",
    pid: "520000",
    id: "520526",
    cid: "520500"
  },
  {
    name: "赫章县",
    pid: "520000",
    id: "520527",
    cid: "520500"
  },
  {
    name: "罗甸县",
    pid: "520000",
    id: "522728",
    cid: "522700"
  },
  {
    name: "长顺县",
    pid: "520000",
    id: "522729",
    cid: "522700"
  },
  {
    name: "龙里县",
    pid: "520000",
    id: "522730",
    cid: "522700"
  },
  {
    name: "惠水县",
    pid: "520000",
    id: "522731",
    cid: "522700"
  },
  {
    name: "三都水族自治县",
    pid: "520000",
    id: "522732",
    cid: "522700"
  },
  {
    name: "荔波县",
    pid: "520000",
    id: "522722",
    cid: "522700"
  },
  {
    name: "都匀市",
    pid: "520000",
    id: "522701",
    cid: "522700"
  },
  {
    name: "贵定县",
    pid: "520000",
    id: "522723",
    cid: "522700"
  },
  {
    name: "福泉市",
    pid: "520000",
    id: "522702",
    cid: "522700"
  },
  {
    name: "瓮安县",
    pid: "520000",
    id: "522725",
    cid: "522700"
  },
  {
    name: "独山县",
    pid: "520000",
    id: "522726",
    cid: "522700"
  },
  {
    name: "平塘县",
    pid: "520000",
    id: "522727",
    cid: "522700"
  },
  {
    name: "沿河土家族自治县",
    pid: "520000",
    id: "520627",
    cid: "520600"
  },
  {
    name: "松桃苗族自治县",
    pid: "520000",
    id: "520628",
    cid: "520600"
  },
  {
    name: "江口县",
    pid: "520000",
    id: "520621",
    cid: "520600"
  },
  {
    name: "玉屏侗族自治县",
    pid: "520000",
    id: "520622",
    cid: "520600"
  },
  {
    name: "石阡县",
    pid: "520000",
    id: "520623",
    cid: "520600"
  },
  {
    name: "碧江区",
    pid: "520000",
    id: "520602",
    cid: "520600"
  },
  {
    name: "思南县",
    pid: "520000",
    id: "520624",
    cid: "520600"
  },
  {
    name: "万山区",
    pid: "520000",
    id: "520603",
    cid: "520600"
  },
  {
    name: "印江土家族苗族自治县",
    pid: "520000",
    id: "520625",
    cid: "520600"
  },
  {
    name: "德江县",
    pid: "520000",
    id: "520626",
    cid: "520600"
  },
  {
    name: "郊区",
    pid: "340000",
    id: "340711",
    cid: "340700"
  },
  {
    name: "枞阳县",
    pid: "340000",
    id: "340722",
    cid: "340700"
  },
  {
    name: "义安区",
    pid: "340000",
    id: "340706",
    cid: "340700"
  },
  {
    name: "铜官区",
    pid: "340000",
    id: "340705",
    cid: "340700"
  },
  {
    name: "迎江区",
    pid: "340000",
    id: "340802",
    cid: "340800"
  },
  {
    name: "潜山县",
    pid: "340000",
    id: "340824",
    cid: "340800"
  },
  {
    name: "宜秀区",
    pid: "340000",
    id: "340811",
    cid: "340800"
  },
  {
    name: "怀宁县",
    pid: "340000",
    id: "340822",
    cid: "340800"
  },
  {
    name: "望江县",
    pid: "340000",
    id: "340827",
    cid: "340800"
  },
  {
    name: "岳西县",
    pid: "340000",
    id: "340828",
    cid: "340800"
  },
  {
    name: "大观区",
    pid: "340000",
    id: "340803",
    cid: "340800"
  },
  {
    name: "太湖县",
    pid: "340000",
    id: "340825",
    cid: "340800"
  },
  {
    name: "宿松县",
    pid: "340000",
    id: "340826",
    cid: "340800"
  },
  {
    name: "桐城市",
    pid: "340000",
    id: "340881",
    cid: "340800"
  },
  {
    name: "旌德县",
    pid: "340000",
    id: "341825",
    cid: "341800"
  },
  {
    name: "宣州区",
    pid: "340000",
    id: "341802",
    cid: "341800"
  },
  {
    name: "绩溪县",
    pid: "340000",
    id: "341824",
    cid: "341800"
  },
  {
    name: "泾县",
    pid: "340000",
    id: "341823",
    cid: "341800"
  },
  {
    name: "广德县",
    pid: "340000",
    id: "341822",
    cid: "341800"
  },
  {
    name: "宁国市",
    pid: "340000",
    id: "341881",
    cid: "341800"
  },
  {
    name: "郎溪县",
    pid: "340000",
    id: "341821",
    cid: "341800"
  },
  {
    name: "巢湖市",
    pid: "340000",
    id: "340181",
    cid: "340100"
  },
  {
    name: "包河区",
    pid: "340000",
    id: "340111",
    cid: "340100"
  },
  {
    name: "肥东县",
    pid: "340000",
    id: "340122",
    cid: "340100"
  },
  {
    name: "肥西县",
    pid: "340000",
    id: "340123",
    cid: "340100"
  },
  {
    name: "长丰县",
    pid: "340000",
    id: "340121",
    cid: "340100"
  },
  {
    name: "蜀山区",
    pid: "340000",
    id: "340104",
    cid: "340100"
  },
  {
    name: "瑶海区",
    pid: "340000",
    id: "340102",
    cid: "340100"
  },
  {
    name: "庐江县",
    pid: "340000",
    id: "340124",
    cid: "340100"
  },
  {
    name: "庐阳区",
    pid: "340000",
    id: "340103",
    cid: "340100"
  },
  {
    name: "界首市",
    pid: "340000",
    id: "341282",
    cid: "341200"
  },
  {
    name: "太和县",
    pid: "340000",
    id: "341222",
    cid: "341200"
  },
  {
    name: "临泉县",
    pid: "340000",
    id: "341221",
    cid: "341200"
  },
  {
    name: "颍泉区",
    pid: "340000",
    id: "341204",
    cid: "341200"
  },
  {
    name: "颍上县",
    pid: "340000",
    id: "341226",
    cid: "341200"
  },
  {
    name: "颍州区",
    pid: "340000",
    id: "341202",
    cid: "341200"
  },
  {
    name: "颍东区",
    pid: "340000",
    id: "341203",
    cid: "341200"
  },
  {
    name: "阜南县",
    pid: "340000",
    id: "341225",
    cid: "341200"
  },
  {
    name: "鸠江区",
    pid: "340000",
    id: "340207",
    cid: "340200"
  },
  {
    name: "三山区",
    pid: "340000",
    id: "340208",
    cid: "340200"
  },
  {
    name: "芜湖县",
    pid: "340000",
    id: "340221",
    cid: "340200"
  },
  {
    name: "繁昌县",
    pid: "340000",
    id: "340222",
    cid: "340200"
  },
  {
    name: "弋江区",
    pid: "340000",
    id: "340203",
    cid: "340200"
  },
  {
    name: "无为县",
    pid: "340000",
    id: "340225",
    cid: "340200"
  },
  {
    name: "南陵县",
    pid: "340000",
    id: "340223",
    cid: "340200"
  },
  {
    name: "镜湖区",
    pid: "340000",
    id: "340202",
    cid: "340200"
  },
  {
    name: "砀山县",
    pid: "340000",
    id: "341321",
    cid: "341300"
  },
  {
    name: "萧县",
    pid: "340000",
    id: "341322",
    cid: "341300"
  },
  {
    name: "灵璧县",
    pid: "340000",
    id: "341323",
    cid: "341300"
  },
  {
    name: "埇桥区",
    pid: "340000",
    id: "341302",
    cid: "341300"
  },
  {
    name: "泗县",
    pid: "340000",
    id: "341324",
    cid: "341300"
  },
  {
    name: "歙县",
    pid: "340000",
    id: "341021",
    cid: "341000"
  },
  {
    name: "屯溪区",
    pid: "340000",
    id: "341002",
    cid: "341000"
  },
  {
    name: "祁门县",
    pid: "340000",
    id: "341024",
    cid: "341000"
  },
  {
    name: "黄山区",
    pid: "340000",
    id: "341003",
    cid: "341000"
  },
  {
    name: "休宁县",
    pid: "340000",
    id: "341022",
    cid: "341000"
  },
  {
    name: "黟县",
    pid: "340000",
    id: "341023",
    cid: "341000"
  },
  {
    name: "徽州区",
    pid: "340000",
    id: "341004",
    cid: "341000"
  },
  {
    name: "天长市",
    pid: "340000",
    id: "341181",
    cid: "341100"
  },
  {
    name: "明光市",
    pid: "340000",
    id: "341182",
    cid: "341100"
  },
  {
    name: "琅琊区",
    pid: "340000",
    id: "341102",
    cid: "341100"
  },
  {
    name: "全椒县",
    pid: "340000",
    id: "341124",
    cid: "341100"
  },
  {
    name: "来安县",
    pid: "340000",
    id: "341122",
    cid: "341100"
  },
  {
    name: "南谯区",
    pid: "340000",
    id: "341103",
    cid: "341100"
  },
  {
    name: "定远县",
    pid: "340000",
    id: "341125",
    cid: "341100"
  },
  {
    name: "凤阳县",
    pid: "340000",
    id: "341126",
    cid: "341100"
  },
  {
    name: "雨山区",
    pid: "340000",
    id: "340504",
    cid: "340500"
  },
  {
    name: "花山区",
    pid: "340000",
    id: "340503",
    cid: "340500"
  },
  {
    name: "博望区",
    pid: "340000",
    id: "340506",
    cid: "340500"
  },
  {
    name: "含山县",
    pid: "340000",
    id: "340522",
    cid: "340500"
  },
  {
    name: "和县",
    pid: "340000",
    id: "340523",
    cid: "340500"
  },
  {
    name: "当涂县",
    pid: "340000",
    id: "340521",
    cid: "340500"
  },
  {
    name: "贵池区",
    pid: "340000",
    id: "341702",
    cid: "341700"
  },
  {
    name: "青阳县",
    pid: "340000",
    id: "341723",
    cid: "341700"
  },
  {
    name: "石台县",
    pid: "340000",
    id: "341722",
    cid: "341700"
  },
  {
    name: "东至县",
    pid: "340000",
    id: "341721",
    cid: "341700"
  },
  {
    name: "相山区",
    pid: "340000",
    id: "340603",
    cid: "340600"
  },
  {
    name: "烈山区",
    pid: "340000",
    id: "340604",
    cid: "340600"
  },
  {
    name: "杜集区",
    pid: "340000",
    id: "340602",
    cid: "340600"
  },
  {
    name: "濉溪县",
    pid: "340000",
    id: "340621",
    cid: "340600"
  },
  {
    name: "谯城区",
    pid: "340000",
    id: "341602",
    cid: "341600"
  },
  {
    name: "利辛县",
    pid: "340000",
    id: "341623",
    cid: "341600"
  },
  {
    name: "蒙城县",
    pid: "340000",
    id: "341622",
    cid: "341600"
  },
  {
    name: "涡阳县",
    pid: "340000",
    id: "341621",
    cid: "341600"
  },
  {
    name: "禹会区",
    pid: "340000",
    id: "340304",
    cid: "340300"
  },
  {
    name: "怀远县",
    pid: "340000",
    id: "340321",
    cid: "340300"
  },
  {
    name: "龙子湖区",
    pid: "340000",
    id: "340302",
    cid: "340300"
  },
  {
    name: "蚌山区",
    pid: "340000",
    id: "340303",
    cid: "340300"
  },
  {
    name: "淮上区",
    pid: "340000",
    id: "340311",
    cid: "340300"
  },
  {
    name: "五河县",
    pid: "340000",
    id: "340322",
    cid: "340300"
  },
  {
    name: "固镇县",
    pid: "340000",
    id: "340323",
    cid: "340300"
  },
  {
    name: "叶集区",
    pid: "340000",
    id: "341504",
    cid: "341500"
  },
  {
    name: "裕安区",
    pid: "340000",
    id: "341503",
    cid: "341500"
  },
  {
    name: "霍山县",
    pid: "340000",
    id: "341525",
    cid: "341500"
  },
  {
    name: "金安区",
    pid: "340000",
    id: "341502",
    cid: "341500"
  },
  {
    name: "金寨县",
    pid: "340000",
    id: "341524",
    cid: "341500"
  },
  {
    name: "舒城县",
    pid: "340000",
    id: "341523",
    cid: "341500"
  },
  {
    name: "霍邱县",
    pid: "340000",
    id: "341522",
    cid: "341500"
  },
  {
    name: "八公山区",
    pid: "340000",
    id: "340405",
    cid: "340400"
  },
  {
    name: "潘集区",
    pid: "340000",
    id: "340406",
    cid: "340400"
  },
  {
    name: "田家庵区",
    pid: "340000",
    id: "340403",
    cid: "340400"
  },
  {
    name: "谢家集区",
    pid: "340000",
    id: "340404",
    cid: "340400"
  },
  {
    name: "大通区",
    pid: "340000",
    id: "340402",
    cid: "340400"
  },
  {
    name: "凤台县",
    pid: "340000",
    id: "340421",
    cid: "340400"
  },
  {
    name: "寿县",
    pid: "340000",
    id: "340422",
    cid: "340400"
  },
  {
    name: "天元区",
    pid: "430000",
    id: "430211",
    cid: "430200"
  },
  {
    name: "株洲县",
    pid: "430000",
    id: "430221",
    cid: "430200"
  },
  {
    name: "荷塘区",
    pid: "430000",
    id: "430202",
    cid: "430200"
  },
  {
    name: "茶陵县",
    pid: "430000",
    id: "430224",
    cid: "430200"
  },
  {
    name: "攸县",
    pid: "430000",
    id: "430223",
    cid: "430200"
  },
  {
    name: "石峰区",
    pid: "430000",
    id: "430204",
    cid: "430200"
  },
  {
    name: "芦淞区",
    pid: "430000",
    id: "430203",
    cid: "430200"
  },
  {
    name: "炎陵县",
    pid: "430000",
    id: "430225",
    cid: "430200"
  },
  {
    name: "醴陵市",
    pid: "430000",
    id: "430281",
    cid: "430200"
  },
  {
    name: "新化县",
    pid: "430000",
    id: "431322",
    cid: "431300"
  },
  {
    name: "双峰县",
    pid: "430000",
    id: "431321",
    cid: "431300"
  },
  {
    name: "娄星区",
    pid: "430000",
    id: "431302",
    cid: "431300"
  },
  {
    name: "涟源市",
    pid: "430000",
    id: "431382",
    cid: "431300"
  },
  {
    name: "冷水江市",
    pid: "430000",
    id: "431381",
    cid: "431300"
  },
  {
    name: "望城区",
    pid: "430000",
    id: "430112",
    cid: "430100"
  },
  {
    name: "雨花区",
    pid: "430000",
    id: "430111",
    cid: "430100"
  },
  {
    name: "天心区",
    pid: "430000",
    id: "430103",
    cid: "430100"
  },
  {
    name: "芙蓉区",
    pid: "430000",
    id: "430102",
    cid: "430100"
  },
  {
    name: "宁乡县",
    pid: "430000",
    id: "430124",
    cid: "430100"
  },
  {
    name: "开福区",
    pid: "430000",
    id: "430105",
    cid: "430100"
  },
  {
    name: "岳麓区",
    pid: "430000",
    id: "430104",
    cid: "430100"
  },
  {
    name: "浏阳市",
    pid: "430000",
    id: "430181",
    cid: "430100"
  },
  {
    name: "长沙县",
    pid: "430000",
    id: "430121",
    cid: "430100"
  },
  {
    name: "辰溪县",
    pid: "430000",
    id: "431223",
    cid: "431200"
  },
  {
    name: "沅陵县",
    pid: "430000",
    id: "431222",
    cid: "431200"
  },
  {
    name: "会同县",
    pid: "430000",
    id: "431225",
    cid: "431200"
  },
  {
    name: "鹤城区",
    pid: "430000",
    id: "431202",
    cid: "431200"
  },
  {
    name: "溆浦县",
    pid: "430000",
    id: "431224",
    cid: "431200"
  },
  {
    name: "新晃侗族自治县",
    pid: "430000",
    id: "431227",
    cid: "431200"
  },
  {
    name: "麻阳苗族自治县",
    pid: "430000",
    id: "431226",
    cid: "431200"
  },
  {
    name: "靖州苗族侗族自治县",
    pid: "430000",
    id: "431229",
    cid: "431200"
  },
  {
    name: "芷江侗族自治县",
    pid: "430000",
    id: "431228",
    cid: "431200"
  },
  {
    name: "洪江市",
    pid: "430000",
    id: "431281",
    cid: "431200"
  },
  {
    name: "通道侗族自治县",
    pid: "430000",
    id: "431230",
    cid: "431200"
  },
  {
    name: "中方县",
    pid: "430000",
    id: "431221",
    cid: "431200"
  },
  {
    name: "衡南县",
    pid: "430000",
    id: "430422",
    cid: "430400"
  },
  {
    name: "衡阳县",
    pid: "430000",
    id: "430421",
    cid: "430400"
  },
  {
    name: "衡东县",
    pid: "430000",
    id: "430424",
    cid: "430400"
  },
  {
    name: "南岳区",
    pid: "430000",
    id: "430412",
    cid: "430400"
  },
  {
    name: "衡山县",
    pid: "430000",
    id: "430423",
    cid: "430400"
  },
  {
    name: "祁东县",
    pid: "430000",
    id: "430426",
    cid: "430400"
  },
  {
    name: "雁峰区",
    pid: "430000",
    id: "430406",
    cid: "430400"
  },
  {
    name: "珠晖区",
    pid: "430000",
    id: "430405",
    cid: "430400"
  },
  {
    name: "蒸湘区",
    pid: "430000",
    id: "430408",
    cid: "430400"
  },
  {
    name: "石鼓区",
    pid: "430000",
    id: "430407",
    cid: "430400"
  },
  {
    name: "常宁市",
    pid: "430000",
    id: "430482",
    cid: "430400"
  },
  {
    name: "耒阳市",
    pid: "430000",
    id: "430481",
    cid: "430400"
  },
  {
    name: "湘潭县",
    pid: "430000",
    id: "430321",
    cid: "430300"
  },
  {
    name: "雨湖区",
    pid: "430000",
    id: "430302",
    cid: "430300"
  },
  {
    name: "岳塘区",
    pid: "430000",
    id: "430304",
    cid: "430300"
  },
  {
    name: "湘乡市",
    pid: "430000",
    id: "430381",
    cid: "430300"
  },
  {
    name: "韶山市",
    pid: "430000",
    id: "430382",
    cid: "430300"
  },
  {
    name: "君山区",
    pid: "430000",
    id: "430611",
    cid: "430600"
  },
  {
    name: "岳阳县",
    pid: "430000",
    id: "430621",
    cid: "430600"
  },
  {
    name: "岳阳楼区",
    pid: "430000",
    id: "430602",
    cid: "430600"
  },
  {
    name: "湘阴县",
    pid: "430000",
    id: "430624",
    cid: "430600"
  },
  {
    name: "华容县",
    pid: "430000",
    id: "430623",
    cid: "430600"
  },
  {
    name: "平江县",
    pid: "430000",
    id: "430626",
    cid: "430600"
  },
  {
    name: "云溪区",
    pid: "430000",
    id: "430603",
    cid: "430600"
  },
  {
    name: "临湘市",
    pid: "430000",
    id: "430682",
    cid: "430600"
  },
  {
    name: "汨罗市",
    pid: "430000",
    id: "430681",
    cid: "430600"
  },
  {
    name: "邵东县",
    pid: "430000",
    id: "430521",
    cid: "430500"
  },
  {
    name: "邵阳县",
    pid: "430000",
    id: "430523",
    cid: "430500"
  },
  {
    name: "北塔区",
    pid: "430000",
    id: "430511",
    cid: "430500"
  },
  {
    name: "新邵县",
    pid: "430000",
    id: "430522",
    cid: "430500"
  },
  {
    name: "大祥区",
    pid: "430000",
    id: "430503",
    cid: "430500"
  },
  {
    name: "洞口县",
    pid: "430000",
    id: "430525",
    cid: "430500"
  },
  {
    name: "双清区",
    pid: "430000",
    id: "430502",
    cid: "430500"
  },
  {
    name: "隆回县",
    pid: "430000",
    id: "430524",
    cid: "430500"
  },
  {
    name: "绥宁县",
    pid: "430000",
    id: "430527",
    cid: "430500"
  },
  {
    name: "城步苗族自治县",
    pid: "430000",
    id: "430529",
    cid: "430500"
  },
  {
    name: "新宁县",
    pid: "430000",
    id: "430528",
    cid: "430500"
  },
  {
    name: "武冈市",
    pid: "430000",
    id: "430581",
    cid: "430500"
  },
  {
    name: "武陵源区",
    pid: "430000",
    id: "430811",
    cid: "430800"
  },
  {
    name: "桑植县",
    pid: "430000",
    id: "430822",
    cid: "430800"
  },
  {
    name: "慈利县",
    pid: "430000",
    id: "430821",
    cid: "430800"
  },
  {
    name: "永定区",
    pid: "430000",
    id: "430802",
    cid: "430800"
  },
  {
    name: "安乡县",
    pid: "430000",
    id: "430721",
    cid: "430700"
  },
  {
    name: "澧县",
    pid: "430000",
    id: "430723",
    cid: "430700"
  },
  {
    name: "汉寿县",
    pid: "430000",
    id: "430722",
    cid: "430700"
  },
  {
    name: "鼎城区",
    pid: "430000",
    id: "430703",
    cid: "430700"
  },
  {
    name: "桃源县",
    pid: "430000",
    id: "430725",
    cid: "430700"
  },
  {
    name: "武陵区",
    pid: "430000",
    id: "430702",
    cid: "430700"
  },
  {
    name: "临澧县",
    pid: "430000",
    id: "430724",
    cid: "430700"
  },
  {
    name: "石门县",
    pid: "430000",
    id: "430726",
    cid: "430700"
  },
  {
    name: "津市市",
    pid: "430000",
    id: "430781",
    cid: "430700"
  },
  {
    name: "沅江市",
    pid: "430000",
    id: "430981",
    cid: "430900"
  },
  {
    name: "南县",
    pid: "430000",
    id: "430921",
    cid: "430900"
  },
  {
    name: "安化县",
    pid: "430000",
    id: "430923",
    cid: "430900"
  },
  {
    name: "桃江县",
    pid: "430000",
    id: "430922",
    cid: "430900"
  },
  {
    name: "赫山区",
    pid: "430000",
    id: "430903",
    cid: "430900"
  },
  {
    name: "资阳区",
    pid: "430000",
    id: "430902",
    cid: "430900"
  },
  {
    name: "零陵区",
    pid: "430000",
    id: "431102",
    cid: "431100"
  },
  {
    name: "道县",
    pid: "430000",
    id: "431124",
    cid: "431100"
  },
  {
    name: "双牌县",
    pid: "430000",
    id: "431123",
    cid: "431100"
  },
  {
    name: "宁远县",
    pid: "430000",
    id: "431126",
    cid: "431100"
  },
  {
    name: "冷水滩区",
    pid: "430000",
    id: "431103",
    cid: "431100"
  },
  {
    name: "江永县",
    pid: "430000",
    id: "431125",
    cid: "431100"
  },
  {
    name: "新田县",
    pid: "430000",
    id: "431128",
    cid: "431100"
  },
  {
    name: "蓝山县",
    pid: "430000",
    id: "431127",
    cid: "431100"
  },
  {
    name: "江华瑶族自治县",
    pid: "430000",
    id: "431129",
    cid: "431100"
  },
  {
    name: "东安县",
    pid: "430000",
    id: "431122",
    cid: "431100"
  },
  {
    name: "祁阳县",
    pid: "430000",
    id: "431121",
    cid: "431100"
  },
  {
    name: "苏仙区",
    pid: "430000",
    id: "431003",
    cid: "431000"
  },
  {
    name: "临武县",
    pid: "430000",
    id: "431025",
    cid: "431000"
  },
  {
    name: "北湖区",
    pid: "430000",
    id: "431002",
    cid: "431000"
  },
  {
    name: "嘉禾县",
    pid: "430000",
    id: "431024",
    cid: "431000"
  },
  {
    name: "桂东县",
    pid: "430000",
    id: "431027",
    cid: "431000"
  },
  {
    name: "汝城县",
    pid: "430000",
    id: "431026",
    cid: "431000"
  },
  {
    name: "安仁县",
    pid: "430000",
    id: "431028",
    cid: "431000"
  },
  {
    name: "资兴市",
    pid: "430000",
    id: "431081",
    cid: "431000"
  },
  {
    name: "桂阳县",
    pid: "430000",
    id: "431021",
    cid: "431000"
  },
  {
    name: "永兴县",
    pid: "430000",
    id: "431023",
    cid: "431000"
  },
  {
    name: "宜章县",
    pid: "430000",
    id: "431022",
    cid: "431000"
  },
  {
    name: "永顺县",
    pid: "430000",
    id: "433127",
    cid: "433100"
  },
  {
    name: "保靖县",
    pid: "430000",
    id: "433125",
    cid: "433100"
  },
  {
    name: "古丈县",
    pid: "430000",
    id: "433126",
    cid: "433100"
  },
  {
    name: "龙山县",
    pid: "430000",
    id: "433130",
    cid: "433100"
  },
  {
    name: "吉首市",
    pid: "430000",
    id: "433101",
    cid: "433100"
  },
  {
    name: "凤凰县",
    pid: "430000",
    id: "433123",
    cid: "433100"
  },
  {
    name: "花垣县",
    pid: "430000",
    id: "433124",
    cid: "433100"
  },
  {
    name: "泸溪县",
    pid: "430000",
    id: "433122",
    cid: "433100"
  },
  {
    name: "鹿泉区",
    pid: "130000",
    id: "130110",
    cid: "130100"
  },
  {
    name: "井陉县",
    pid: "130000",
    id: "130121",
    cid: "130100"
  },
  {
    name: "元氏县",
    pid: "130000",
    id: "130132",
    cid: "130100"
  },
  {
    name: "栾城区",
    pid: "130000",
    id: "130111",
    cid: "130100"
  },
  {
    name: "赵县",
    pid: "130000",
    id: "130133",
    cid: "130100"
  },
  {
    name: "无极县",
    pid: "130000",
    id: "130130",
    cid: "130100"
  },
  {
    name: "平山县",
    pid: "130000",
    id: "130131",
    cid: "130100"
  },
  {
    name: "行唐县",
    pid: "130000",
    id: "130125",
    cid: "130100"
  },
  {
    name: "桥西区",
    pid: "130000",
    id: "130104",
    cid: "130100"
  },
  {
    name: "灵寿县",
    pid: "130000",
    id: "130126",
    cid: "130100"
  },
  {
    name: "正定县",
    pid: "130000",
    id: "130123",
    cid: "130100"
  },
  {
    name: "长安区",
    pid: "130000",
    id: "130102",
    cid: "130100"
  },
  {
    name: "井陉矿区",
    pid: "130000",
    id: "130107",
    cid: "130100"
  },
  {
    name: "赞皇县",
    pid: "130000",
    id: "130129",
    cid: "130100"
  },
  {
    name: "裕华区",
    pid: "130000",
    id: "130108",
    cid: "130100"
  },
  {
    name: "新华区",
    pid: "130000",
    id: "130105",
    cid: "130100"
  },
  {
    name: "高邑县",
    pid: "130000",
    id: "130127",
    cid: "130100"
  },
  {
    name: "深泽县",
    pid: "130000",
    id: "130128",
    cid: "130100"
  },
  {
    name: "藁城区",
    pid: "130000",
    id: "130109",
    cid: "130100"
  },
  {
    name: "晋州市",
    pid: "130000",
    id: "130183",
    cid: "130100"
  },
  {
    name: "新乐市",
    pid: "130000",
    id: "130184",
    cid: "130100"
  },
  {
    name: "武强县",
    pid: "130000",
    id: "131123",
    cid: "131100"
  },
  {
    name: "武邑县",
    pid: "130000",
    id: "131122",
    cid: "131100"
  },
  {
    name: "枣强县",
    pid: "130000",
    id: "131121",
    cid: "131100"
  },
  {
    name: "景县",
    pid: "130000",
    id: "131127",
    cid: "131100"
  },
  {
    name: "故城县",
    pid: "130000",
    id: "131126",
    cid: "131100"
  },
  {
    name: "冀州区",
    pid: "130000",
    id: "131103",
    cid: "131100"
  },
  {
    name: "安平县",
    pid: "130000",
    id: "131125",
    cid: "131100"
  },
  {
    name: "桃城区",
    pid: "130000",
    id: "131102",
    cid: "131100"
  },
  {
    name: "饶阳县",
    pid: "130000",
    id: "131124",
    cid: "131100"
  },
  {
    name: "阜城县",
    pid: "130000",
    id: "131128",
    cid: "131100"
  },
  {
    name: "深州市",
    pid: "130000",
    id: "131182",
    cid: "131100"
  },
  {
    name: "安次区",
    pid: "130000",
    id: "131002",
    cid: "131000"
  },
  {
    name: "香河县",
    pid: "130000",
    id: "131024",
    cid: "131000"
  },
  {
    name: "永清县",
    pid: "130000",
    id: "131023",
    cid: "131000"
  },
  {
    name: "固安县",
    pid: "130000",
    id: "131022",
    cid: "131000"
  },
  {
    name: "大厂回族自治县",
    pid: "130000",
    id: "131028",
    cid: "131000"
  },
  {
    name: "文安县",
    pid: "130000",
    id: "131026",
    cid: "131000"
  },
  {
    name: "广阳区",
    pid: "130000",
    id: "131003",
    cid: "131000"
  },
  {
    name: "大城县",
    pid: "130000",
    id: "131025",
    cid: "131000"
  },
  {
    name: "三河市",
    pid: "130000",
    id: "131082",
    cid: "131000"
  },
  {
    name: "霸州市",
    pid: "130000",
    id: "131081",
    cid: "131000"
  },
  {
    name: "邱县",
    pid: "130000",
    id: "130430",
    cid: "130400"
  },
  {
    name: "馆陶县",
    pid: "130000",
    id: "130433",
    cid: "130400"
  },
  {
    name: "临漳县",
    pid: "130000",
    id: "130423",
    cid: "130400"
  },
  {
    name: "魏县",
    pid: "130000",
    id: "130434",
    cid: "130400"
  },
  {
    name: "鸡泽县",
    pid: "130000",
    id: "130431",
    cid: "130400"
  },
  {
    name: "邯郸县",
    pid: "130000",
    id: "130421",
    cid: "130400"
  },
  {
    name: "广平县",
    pid: "130000",
    id: "130432",
    cid: "130400"
  },
  {
    name: "复兴区",
    pid: "130000",
    id: "130404",
    cid: "130400"
  },
  {
    name: "涉县",
    pid: "130000",
    id: "130426",
    cid: "130400"
  },
  {
    name: "磁县",
    pid: "130000",
    id: "130427",
    cid: "130400"
  },
  {
    name: "邯山区",
    pid: "130000",
    id: "130402",
    cid: "130400"
  },
  {
    name: "成安县",
    pid: "130000",
    id: "130424",
    cid: "130400"
  },
  {
    name: "曲周县",
    pid: "130000",
    id: "130435",
    cid: "130400"
  },
  {
    name: "丛台区",
    pid: "130000",
    id: "130403",
    cid: "130400"
  },
  {
    name: "大名县",
    pid: "130000",
    id: "130425",
    cid: "130400"
  },
  {
    name: "峰峰矿区",
    pid: "130000",
    id: "130406",
    cid: "130400"
  },
  {
    name: "肥乡县",
    pid: "130000",
    id: "130428",
    cid: "130400"
  },
  {
    name: "永年县",
    pid: "130000",
    id: "130429",
    cid: "130400"
  },
  {
    name: "武安市",
    pid: "130000",
    id: "130481",
    cid: "130400"
  },
  {
    name: "南宫市",
    pid: "130000",
    id: "130581",
    cid: "130500"
  },
  {
    name: "沙河市",
    pid: "130000",
    id: "130582",
    cid: "130500"
  },
  {
    name: "邢台县",
    pid: "130000",
    id: "130521",
    cid: "130500"
  },
  {
    name: "平乡县",
    pid: "130000",
    id: "130532",
    cid: "130500"
  },
  {
    name: "临城县",
    pid: "130000",
    id: "130522",
    cid: "130500"
  },
  {
    name: "威县",
    pid: "130000",
    id: "130533",
    cid: "130500"
  },
  {
    name: "新河县",
    pid: "130000",
    id: "130530",
    cid: "130500"
  },
  {
    name: "广宗县",
    pid: "130000",
    id: "130531",
    cid: "130500"
  },
  {
    name: "桥西区",
    pid: "130000",
    id: "130503",
    cid: "130500"
  },
  {
    name: "隆尧县",
    pid: "130000",
    id: "130525",
    cid: "130500"
  },
  {
    name: "任县",
    pid: "130000",
    id: "130526",
    cid: "130500"
  },
  {
    name: "内丘县",
    pid: "130000",
    id: "130523",
    cid: "130500"
  },
  {
    name: "清河县",
    pid: "130000",
    id: "130534",
    cid: "130500"
  },
  {
    name: "桥东区",
    pid: "130000",
    id: "130502",
    cid: "130500"
  },
  {
    name: "柏乡县",
    pid: "130000",
    id: "130524",
    cid: "130500"
  },
  {
    name: "临西县",
    pid: "130000",
    id: "130535",
    cid: "130500"
  },
  {
    name: "巨鹿县",
    pid: "130000",
    id: "130529",
    cid: "130500"
  },
  {
    name: "南和县",
    pid: "130000",
    id: "130527",
    cid: "130500"
  },
  {
    name: "宁晋县",
    pid: "130000",
    id: "130528",
    cid: "130500"
  },
  {
    name: "路南区",
    pid: "130000",
    id: "130202",
    cid: "130200"
  },
  {
    name: "滦南县",
    pid: "130000",
    id: "130224",
    cid: "130200"
  },
  {
    name: "路北区",
    pid: "130000",
    id: "130203",
    cid: "130200"
  },
  {
    name: "乐亭县",
    pid: "130000",
    id: "130225",
    cid: "130200"
  },
  {
    name: "滦县",
    pid: "130000",
    id: "130223",
    cid: "130200"
  },
  {
    name: "丰南区",
    pid: "130000",
    id: "130207",
    cid: "130200"
  },
  {
    name: "玉田县",
    pid: "130000",
    id: "130229",
    cid: "130200"
  },
  {
    name: "古冶区",
    pid: "130000",
    id: "130204",
    cid: "130200"
  },
  {
    name: "开平区",
    pid: "130000",
    id: "130205",
    cid: "130200"
  },
  {
    name: "迁西县",
    pid: "130000",
    id: "130227",
    cid: "130200"
  },
  {
    name: "丰润区",
    pid: "130000",
    id: "130208",
    cid: "130200"
  },
  {
    name: "曹妃甸区",
    pid: "130000",
    id: "130209",
    cid: "130200"
  },
  {
    name: "迁安市",
    pid: "130000",
    id: "130283",
    cid: "130200"
  },
  {
    name: "遵化市",
    pid: "130000",
    id: "130281",
    cid: "130200"
  },
  {
    name: "海港区",
    pid: "130000",
    id: "130302",
    cid: "130300"
  },
  {
    name: "卢龙县",
    pid: "130000",
    id: "130324",
    cid: "130300"
  },
  {
    name: "青龙满族自治县",
    pid: "130000",
    id: "130321",
    cid: "130300"
  },
  {
    name: "昌黎县",
    pid: "130000",
    id: "130322",
    cid: "130300"
  },
  {
    name: "抚宁区",
    pid: "130000",
    id: "130306",
    cid: "130300"
  },
  {
    name: "山海关区",
    pid: "130000",
    id: "130303",
    cid: "130300"
  },
  {
    name: "北戴河区",
    pid: "130000",
    id: "130304",
    cid: "130300"
  },
  {
    name: "围场满族蒙古族自治县",
    pid: "130000",
    id: "130828",
    cid: "130800"
  },
  {
    name: "兴隆县",
    pid: "130000",
    id: "130822",
    cid: "130800"
  },
  {
    name: "平泉县",
    pid: "130000",
    id: "130823",
    cid: "130800"
  },
  {
    name: "承德县",
    pid: "130000",
    id: "130821",
    cid: "130800"
  },
  {
    name: "鹰手营子矿区",
    pid: "130000",
    id: "130804",
    cid: "130800"
  },
  {
    name: "丰宁满族自治县",
    pid: "130000",
    id: "130826",
    cid: "130800"
  },
  {
    name: "宽城满族自治县",
    pid: "130000",
    id: "130827",
    cid: "130800"
  },
  {
    name: "双桥区",
    pid: "130000",
    id: "130802",
    cid: "130800"
  },
  {
    name: "滦平县",
    pid: "130000",
    id: "130824",
    cid: "130800"
  },
  {
    name: "双滦区",
    pid: "130000",
    id: "130803",
    cid: "130800"
  },
  {
    name: "隆化县",
    pid: "130000",
    id: "130825",
    cid: "130800"
  },
  {
    name: "黄骅市",
    pid: "130000",
    id: "130983",
    cid: "130900"
  },
  {
    name: "河间市",
    pid: "130000",
    id: "130984",
    cid: "130900"
  },
  {
    name: "泊头市",
    pid: "130000",
    id: "130981",
    cid: "130900"
  },
  {
    name: "任丘市",
    pid: "130000",
    id: "130982",
    cid: "130900"
  },
  {
    name: "沧县",
    pid: "130000",
    id: "130921",
    cid: "130900"
  },
  {
    name: "青县",
    pid: "130000",
    id: "130922",
    cid: "130900"
  },
  {
    name: "孟村回族自治县",
    pid: "130000",
    id: "130930",
    cid: "130900"
  },
  {
    name: "运河区",
    pid: "130000",
    id: "130903",
    cid: "130900"
  },
  {
    name: "盐山县",
    pid: "130000",
    id: "130925",
    cid: "130900"
  },
  {
    name: "肃宁县",
    pid: "130000",
    id: "130926",
    cid: "130900"
  },
  {
    name: "东光县",
    pid: "130000",
    id: "130923",
    cid: "130900"
  },
  {
    name: "新华区",
    pid: "130000",
    id: "130902",
    cid: "130900"
  },
  {
    name: "海兴县",
    pid: "130000",
    id: "130924",
    cid: "130900"
  },
  {
    name: "献县",
    pid: "130000",
    id: "130929",
    cid: "130900"
  },
  {
    name: "南皮县",
    pid: "130000",
    id: "130927",
    cid: "130900"
  },
  {
    name: "吴桥县",
    pid: "130000",
    id: "130928",
    cid: "130900"
  },
  {
    name: "安国市",
    pid: "130000",
    id: "130683",
    cid: "130600"
  },
  {
    name: "涿州市",
    pid: "130000",
    id: "130681",
    cid: "130600"
  },
  {
    name: "望都县",
    pid: "130000",
    id: "130631",
    cid: "130600"
  },
  {
    name: "安新县",
    pid: "130000",
    id: "130632",
    cid: "130600"
  },
  {
    name: "高碑店市",
    pid: "130000",
    id: "130684",
    cid: "130600"
  },
  {
    name: "涞源县",
    pid: "130000",
    id: "130630",
    cid: "130600"
  },
  {
    name: "竞秀区",
    pid: "130000",
    id: "130602",
    cid: "130600"
  },
  {
    name: "阜平县",
    pid: "130000",
    id: "130624",
    cid: "130600"
  },
  {
    name: "蠡县",
    pid: "130000",
    id: "130635",
    cid: "130600"
  },
  {
    name: "顺平县",
    pid: "130000",
    id: "130636",
    cid: "130600"
  },
  {
    name: "易县",
    pid: "130000",
    id: "130633",
    cid: "130600"
  },
  {
    name: "涞水县",
    pid: "130000",
    id: "130623",
    cid: "130600"
  },
  {
    name: "曲阳县",
    pid: "130000",
    id: "130634",
    cid: "130600"
  },
  {
    name: "莲池区",
    pid: "130000",
    id: "130606",
    cid: "130600"
  },
  {
    name: "高阳县",
    pid: "130000",
    id: "130628",
    cid: "130600"
  },
  {
    name: "满城区",
    pid: "130000",
    id: "130607",
    cid: "130600"
  },
  {
    name: "容城县",
    pid: "130000",
    id: "130629",
    cid: "130600"
  },
  {
    name: "定兴县",
    pid: "130000",
    id: "130626",
    cid: "130600"
  },
  {
    name: "博野县",
    pid: "130000",
    id: "130637",
    cid: "130600"
  },
  {
    name: "唐县",
    pid: "130000",
    id: "130627",
    cid: "130600"
  },
  {
    name: "雄县",
    pid: "130000",
    id: "130638",
    cid: "130600"
  },
  {
    name: "清苑区",
    pid: "130000",
    id: "130608",
    cid: "130600"
  },
  {
    name: "徐水区",
    pid: "130000",
    id: "130609",
    cid: "130600"
  },
  {
    name: "怀来县",
    pid: "130000",
    id: "130730",
    cid: "130700"
  },
  {
    name: "涿鹿县",
    pid: "130000",
    id: "130731",
    cid: "130700"
  },
  {
    name: "康保县",
    pid: "130000",
    id: "130723",
    cid: "130700"
  },
  {
    name: "桥东区",
    pid: "130000",
    id: "130702",
    cid: "130700"
  },
  {
    name: "沽源县",
    pid: "130000",
    id: "130724",
    cid: "130700"
  },
  {
    name: "赤城县",
    pid: "130000",
    id: "130732",
    cid: "130700"
  },
  {
    name: "张北县",
    pid: "130000",
    id: "130722",
    cid: "130700"
  },
  {
    name: "宣化区",
    pid: "130000",
    id: "130705",
    cid: "130700"
  },
  {
    name: "阳原县",
    pid: "130000",
    id: "130727",
    cid: "130700"
  },
  {
    name: "下花园区",
    pid: "130000",
    id: "130706",
    cid: "130700"
  },
  {
    name: "怀安县",
    pid: "130000",
    id: "130728",
    cid: "130700"
  },
  {
    name: "桥西区",
    pid: "130000",
    id: "130703",
    cid: "130700"
  },
  {
    name: "尚义县",
    pid: "130000",
    id: "130725",
    cid: "130700"
  },
  {
    name: "蔚县",
    pid: "130000",
    id: "130726",
    cid: "130700"
  },
  {
    name: "崇礼区",
    pid: "130000",
    id: "130709",
    cid: "130700"
  },
  {
    name: "万全区",
    pid: "130000",
    id: "130708",
    cid: "130700"
  },
  {
    name: "息冢镇",
    pid: "130000",
    id: "139001116",
    cid: "139001"
  },
  {
    name: "子位镇",
    pid: "130000",
    id: "139001112",
    cid: "139001"
  },
  {
    name: "开元镇",
    pid: "130000",
    id: "139001113",
    cid: "139001"
  },
  {
    name: "周村镇",
    pid: "130000",
    id: "139001115",
    cid: "139001"
  },
  {
    name: "李亲顾镇",
    pid: "130000",
    id: "139001111",
    cid: "139001"
  },
  {
    name: "高蓬镇",
    pid: "130000",
    id: "139001109",
    cid: "139001"
  },
  {
    name: "西城乡",
    pid: "130000",
    id: "139001208",
    cid: "139001"
  },
  {
    name: "叮咛店镇",
    pid: "130000",
    id: "139001105",
    cid: "139001"
  },
  {
    name: "号头庄回族乡",
    pid: "130000",
    id: "139001204",
    cid: "139001"
  },
  {
    name: "东亭镇",
    pid: "130000",
    id: "139001106",
    cid: "139001"
  },
  {
    name: "杨家庄乡",
    pid: "130000",
    id: "139001205",
    cid: "139001"
  },
  {
    name: "大辛庄镇",
    pid: "130000",
    id: "139001107",
    cid: "139001"
  },
  {
    name: "大鹿庄乡",
    pid: "130000",
    id: "139001206",
    cid: "139001"
  },
  {
    name: "东旺镇",
    pid: "130000",
    id: "139001108",
    cid: "139001"
  },
  {
    name: "北城区街道",
    pid: "130000",
    id: "139001002",
    cid: "139001"
  },
  {
    name: "清风店镇",
    pid: "130000",
    id: "139001101",
    cid: "139001"
  },
  {
    name: "留早镇",
    pid: "130000",
    id: "1390011",
    cid: "139001"
  },
  {
    name: "西城区街道",
    pid: "130000",
    id: "139001003",
    cid: "139001"
  },
  {
    name: "庞村镇",
    pid: "130000",
    id: "139001102",
    cid: "139001"
  },
  {
    name: "长安路街道",
    pid: "130000",
    id: "139001004",
    cid: "139001"
  },
  {
    name: "砖路镇",
    pid: "130000",
    id: "139001103",
    cid: "139001"
  },
  {
    name: "明月店镇",
    pid: "130000",
    id: "139001104",
    cid: "139001"
  },
  {
    name: "东留春乡",
    pid: "130000",
    id: "139001203",
    cid: "139001"
  },
  {
    name: "南城区街道",
    pid: "130000",
    id: "139001001",
    cid: "139001"
  },
  {
    name: "邢邑镇",
    pid: "130000",
    id: "13900111",
    cid: "139001"
  },
  {
    name: "南智邱镇",
    pid: "130000",
    id: "139002106",
    cid: "139002"
  },
  {
    name: "中里厢乡",
    pid: "130000",
    id: "139002205",
    cid: "139002"
  },
  {
    name: "王口镇",
    pid: "130000",
    id: "139002107",
    cid: "139002"
  },
  {
    name: "小辛庄乡",
    pid: "130000",
    id: "139002206",
    cid: "139002"
  },
  {
    name: "辛集镇",
    pid: "130000",
    id: "1390021",
    cid: "139002"
  },
  {
    name: "张古庄镇",
    pid: "130000",
    id: "139002102",
    cid: "139002"
  },
  {
    name: "前营乡",
    pid: "130000",
    id: "139002201",
    cid: "139002"
  },
  {
    name: "天宫营乡",
    pid: "130000",
    id: "1390022",
    cid: "139002"
  },
  {
    name: "位伯镇",
    pid: "130000",
    id: "139002103",
    cid: "139002"
  },
  {
    name: "马庄乡",
    pid: "130000",
    id: "139002202",
    cid: "139002"
  },
  {
    name: "新垒头镇",
    pid: "130000",
    id: "139002104",
    cid: "139002"
  },
  {
    name: "和睦井乡",
    pid: "130000",
    id: "139002203",
    cid: "139002"
  },
  {
    name: "新城镇",
    pid: "130000",
    id: "139002105",
    cid: "139002"
  },
  {
    name: "田家庄乡",
    pid: "130000",
    id: "139002204",
    cid: "139002"
  },
  {
    name: "辛集经济开发区",
    pid: "130000",
    id: "1390025",
    cid: "139002"
  },
  {
    name: "旧城镇",
    pid: "130000",
    id: "139002101",
    cid: "139002"
  },
  {
    name: "盘山县",
    pid: "210000",
    id: "211122",
    cid: "211100"
  },
  {
    name: "大洼区",
    pid: "210000",
    id: "211104",
    cid: "211100"
  },
  {
    name: "兴隆台区",
    pid: "210000",
    id: "211103",
    cid: "211100"
  },
  {
    name: "双台子区",
    pid: "210000",
    id: "211102",
    cid: "211100"
  },
  {
    name: "太子河区",
    pid: "210000",
    id: "211011",
    cid: "211000"
  },
  {
    name: "辽阳县",
    pid: "210000",
    id: "211021",
    cid: "211000"
  },
  {
    name: "弓长岭区",
    pid: "210000",
    id: "211005",
    cid: "211000"
  },
  {
    name: "宏伟区",
    pid: "210000",
    id: "211004",
    cid: "211000"
  },
  {
    name: "文圣区",
    pid: "210000",
    id: "211003",
    cid: "211000"
  },
  {
    name: "白塔区",
    pid: "210000",
    id: "211002",
    cid: "211000"
  },
  {
    name: "灯塔市",
    pid: "210000",
    id: "211081",
    cid: "211000"
  },
  {
    name: "新邱区",
    pid: "210000",
    id: "210903",
    cid: "210900"
  },
  {
    name: "海州区",
    pid: "210000",
    id: "210902",
    cid: "210900"
  },
  {
    name: "细河区",
    pid: "210000",
    id: "210911",
    cid: "210900"
  },
  {
    name: "彰武县",
    pid: "210000",
    id: "210922",
    cid: "210900"
  },
  {
    name: "清河门区",
    pid: "210000",
    id: "210905",
    cid: "210900"
  },
  {
    name: "太平区",
    pid: "210000",
    id: "210904",
    cid: "210900"
  },
  {
    name: "阜新蒙古族自治县",
    pid: "210000",
    id: "210921",
    cid: "210900"
  },
  {
    name: "顺城区",
    pid: "210000",
    id: "210411",
    cid: "210400"
  },
  {
    name: "新宾满族自治县",
    pid: "210000",
    id: "210422",
    cid: "210400"
  },
  {
    name: "抚顺县",
    pid: "210000",
    id: "210421",
    cid: "210400"
  },
  {
    name: "望花区",
    pid: "210000",
    id: "210404",
    cid: "210400"
  },
  {
    name: "东洲区",
    pid: "210000",
    id: "210403",
    cid: "210400"
  },
  {
    name: "新抚区",
    pid: "210000",
    id: "210402",
    cid: "210400"
  },
  {
    name: "清原满族自治县",
    pid: "210000",
    id: "210423",
    cid: "210400"
  },
  {
    name: "海城市",
    pid: "210000",
    id: "210381",
    cid: "210300"
  },
  {
    name: "岫岩满族自治县",
    pid: "210000",
    id: "210323",
    cid: "210300"
  },
  {
    name: "千山区",
    pid: "210000",
    id: "210311",
    cid: "210300"
  },
  {
    name: "台安县",
    pid: "210000",
    id: "210321",
    cid: "210300"
  },
  {
    name: "立山区",
    pid: "210000",
    id: "210304",
    cid: "210300"
  },
  {
    name: "铁西区",
    pid: "210000",
    id: "210303",
    cid: "210300"
  },
  {
    name: "铁东区",
    pid: "210000",
    id: "210302",
    cid: "210300"
  },
  {
    name: "兴城市",
    pid: "210000",
    id: "211481",
    cid: "211400"
  },
  {
    name: "建昌县",
    pid: "210000",
    id: "211422",
    cid: "211400"
  },
  {
    name: "绥中县",
    pid: "210000",
    id: "211421",
    cid: "211400"
  },
  {
    name: "南票区",
    pid: "210000",
    id: "211404",
    cid: "211400"
  },
  {
    name: "龙港区",
    pid: "210000",
    id: "211403",
    cid: "211400"
  },
  {
    name: "连山区",
    pid: "210000",
    id: "211402",
    cid: "211400"
  },
  {
    name: "瓦房店市",
    pid: "210000",
    id: "210281",
    cid: "210200"
  },
  {
    name: "庄河市",
    pid: "210000",
    id: "210283",
    cid: "210200"
  },
  {
    name: "中山区",
    pid: "210000",
    id: "210202",
    cid: "210200"
  },
  {
    name: "金州区",
    pid: "210000",
    id: "210213",
    cid: "210200"
  },
  {
    name: "长海县",
    pid: "210000",
    id: "210224",
    cid: "210200"
  },
  {
    name: "旅顺口区",
    pid: "210000",
    id: "210212",
    cid: "210200"
  },
  {
    name: "甘井子区",
    pid: "210000",
    id: "210211",
    cid: "210200"
  },
  {
    name: "沙河口区",
    pid: "210000",
    id: "210204",
    cid: "210200"
  },
  {
    name: "西岗区",
    pid: "210000",
    id: "210203",
    cid: "210200"
  },
  {
    name: "普兰店区",
    pid: "210000",
    id: "210214",
    cid: "210200"
  },
  {
    name: "凌源市",
    pid: "210000",
    id: "211382",
    cid: "211300"
  },
  {
    name: "北票市",
    pid: "210000",
    id: "211381",
    cid: "211300"
  },
  {
    name: "双塔区",
    pid: "210000",
    id: "211302",
    cid: "211300"
  },
  {
    name: "喀喇沁左翼蒙古族自治县",
    pid: "210000",
    id: "211324",
    cid: "211300"
  },
  {
    name: "建平县",
    pid: "210000",
    id: "211322",
    cid: "211300"
  },
  {
    name: "朝阳县",
    pid: "210000",
    id: "211321",
    cid: "211300"
  },
  {
    name: "龙城区",
    pid: "210000",
    id: "211303",
    cid: "211300"
  },
  {
    name: "新民市",
    pid: "210000",
    id: "210181",
    cid: "210100"
  },
  {
    name: "沈河区",
    pid: "210000",
    id: "210103",
    cid: "210100"
  },
  {
    name: "于洪区",
    pid: "210000",
    id: "210114",
    cid: "210100"
  },
  {
    name: "和平区",
    pid: "210000",
    id: "210102",
    cid: "210100"
  },
  {
    name: "沈北新区",
    pid: "210000",
    id: "210113",
    cid: "210100"
  },
  {
    name: "法库县",
    pid: "210000",
    id: "210124",
    cid: "210100"
  },
  {
    name: "浑南区",
    pid: "210000",
    id: "210112",
    cid: "210100"
  },
  {
    name: "康平县",
    pid: "210000",
    id: "210123",
    cid: "210100"
  },
  {
    name: "苏家屯区",
    pid: "210000",
    id: "210111",
    cid: "210100"
  },
  {
    name: "铁西区",
    pid: "210000",
    id: "210106",
    cid: "210100"
  },
  {
    name: "皇姑区",
    pid: "210000",
    id: "210105",
    cid: "210100"
  },
  {
    name: "大东区",
    pid: "210000",
    id: "210104",
    cid: "210100"
  },
  {
    name: "辽中区",
    pid: "210000",
    id: "210115",
    cid: "210100"
  },
  {
    name: "开原市",
    pid: "210000",
    id: "211282",
    cid: "211200"
  },
  {
    name: "调兵山市",
    pid: "210000",
    id: "211281",
    cid: "211200"
  },
  {
    name: "铁岭县",
    pid: "210000",
    id: "211221",
    cid: "211200"
  },
  {
    name: "银州区",
    pid: "210000",
    id: "211202",
    cid: "211200"
  },
  {
    name: "昌图县",
    pid: "210000",
    id: "211224",
    cid: "211200"
  },
  {
    name: "西丰县",
    pid: "210000",
    id: "211223",
    cid: "211200"
  },
  {
    name: "清河区",
    pid: "210000",
    id: "211204",
    cid: "211200"
  },
  {
    name: "鲅鱼圈区",
    pid: "210000",
    id: "210804",
    cid: "210800"
  },
  {
    name: "西市区",
    pid: "210000",
    id: "210803",
    cid: "210800"
  },
  {
    name: "站前区",
    pid: "210000",
    id: "210802",
    cid: "210800"
  },
  {
    name: "大石桥市",
    pid: "210000",
    id: "210882",
    cid: "210800"
  },
  {
    name: "盖州市",
    pid: "210000",
    id: "210881",
    cid: "210800"
  },
  {
    name: "老边区",
    pid: "210000",
    id: "210811",
    cid: "210800"
  },
  {
    name: "义县",
    pid: "210000",
    id: "210727",
    cid: "210700"
  },
  {
    name: "黑山县",
    pid: "210000",
    id: "210726",
    cid: "210700"
  },
  {
    name: "凌河区",
    pid: "210000",
    id: "210703",
    cid: "210700"
  },
  {
    name: "古塔区",
    pid: "210000",
    id: "210702",
    cid: "210700"
  },
  {
    name: "凌海市",
    pid: "210000",
    id: "210781",
    cid: "210700"
  },
  {
    name: "北镇市",
    pid: "210000",
    id: "210782",
    cid: "210700"
  },
  {
    name: "太和区",
    pid: "210000",
    id: "210711",
    cid: "210700"
  },
  {
    name: "振安区",
    pid: "210000",
    id: "210604",
    cid: "210600"
  },
  {
    name: "振兴区",
    pid: "210000",
    id: "210603",
    cid: "210600"
  },
  {
    name: "凤城市",
    pid: "210000",
    id: "210682",
    cid: "210600"
  },
  {
    name: "东港市",
    pid: "210000",
    id: "210681",
    cid: "210600"
  },
  {
    name: "元宝区",
    pid: "210000",
    id: "210602",
    cid: "210600"
  },
  {
    name: "宽甸满族自治县",
    pid: "210000",
    id: "210624",
    cid: "210600"
  },
  {
    name: "南芬区",
    pid: "210000",
    id: "210505",
    cid: "210500"
  },
  {
    name: "明山区",
    pid: "210000",
    id: "210504",
    cid: "210500"
  },
  {
    name: "本溪满族自治县",
    pid: "210000",
    id: "210521",
    cid: "210500"
  },
  {
    name: "溪湖区",
    pid: "210000",
    id: "210503",
    cid: "210500"
  },
  {
    name: "平山区",
    pid: "210000",
    id: "210502",
    cid: "210500"
  },
  {
    name: "桓仁满族自治县",
    pid: "210000",
    id: "210522",
    cid: "210500"
  },
  {
    name: "乐至县",
    pid: "510000",
    id: "512022",
    cid: "512000"
  },
  {
    name: "安岳县",
    pid: "510000",
    id: "512021",
    cid: "512000"
  },
  {
    name: "雁江区",
    pid: "510000",
    id: "512002",
    cid: "512000"
  },
  {
    name: "理县",
    pid: "510000",
    id: "513222",
    cid: "513200"
  },
  {
    name: "红原县",
    pid: "510000",
    id: "513233",
    cid: "513200"
  },
  {
    name: "汶川县",
    pid: "510000",
    id: "513221",
    cid: "513200"
  },
  {
    name: "若尔盖县",
    pid: "510000",
    id: "513232",
    cid: "513200"
  },
  {
    name: "阿坝县",
    pid: "510000",
    id: "513231",
    cid: "513200"
  },
  {
    name: "壤塘县",
    pid: "510000",
    id: "513230",
    cid: "513200"
  },
  {
    name: "金川县",
    pid: "510000",
    id: "513226",
    cid: "513200"
  },
  {
    name: "九寨沟县",
    pid: "510000",
    id: "513225",
    cid: "513200"
  },
  {
    name: "松潘县",
    pid: "510000",
    id: "513224",
    cid: "513200"
  },
  {
    name: "马尔康市",
    pid: "510000",
    id: "513201",
    cid: "513200"
  },
  {
    name: "茂县",
    pid: "510000",
    id: "513223",
    cid: "513200"
  },
  {
    name: "黑水县",
    pid: "510000",
    id: "513228",
    cid: "513200"
  },
  {
    name: "小金县",
    pid: "510000",
    id: "513227",
    cid: "513200"
  },
  {
    name: "东兴区",
    pid: "510000",
    id: "511011",
    cid: "511000"
  },
  {
    name: "资中县",
    pid: "510000",
    id: "511025",
    cid: "511000"
  },
  {
    name: "市中区",
    pid: "510000",
    id: "511002",
    cid: "511000"
  },
  {
    name: "威远县",
    pid: "510000",
    id: "511024",
    cid: "511000"
  },
  {
    name: "隆昌县",
    pid: "510000",
    id: "511028",
    cid: "511000"
  },
  {
    name: "崇州市",
    pid: "510000",
    id: "510184",
    cid: "510100"
  },
  {
    name: "邛崃市",
    pid: "510000",
    id: "510183",
    cid: "510100"
  },
  {
    name: "蒲江县",
    pid: "510000",
    id: "510131",
    cid: "510100"
  },
  {
    name: "简阳市",
    pid: "510000",
    id: "510185",
    cid: "510100"
  },
  {
    name: "金堂县",
    pid: "510000",
    id: "510121",
    cid: "510100"
  },
  {
    name: "新津县",
    pid: "510000",
    id: "510132",
    cid: "510100"
  },
  {
    name: "青白江区",
    pid: "510000",
    id: "510113",
    cid: "510100"
  },
  {
    name: "郫县",
    pid: "510000",
    id: "510124",
    cid: "510100"
  },
  {
    name: "龙泉驿区",
    pid: "510000",
    id: "510112",
    cid: "510100"
  },
  {
    name: "彭州市",
    pid: "510000",
    id: "510182",
    cid: "510100"
  },
  {
    name: "都江堰市",
    pid: "510000",
    id: "510181",
    cid: "510100"
  },
  {
    name: "锦江区",
    pid: "510000",
    id: "510104",
    cid: "510100"
  },
  {
    name: "温江区",
    pid: "510000",
    id: "510115",
    cid: "510100"
  },
  {
    name: "新都区",
    pid: "510000",
    id: "510114",
    cid: "510100"
  },
  {
    name: "金牛区",
    pid: "510000",
    id: "510106",
    cid: "510100"
  },
  {
    name: "青羊区",
    pid: "510000",
    id: "510105",
    cid: "510100"
  },
  {
    name: "双流区",
    pid: "510000",
    id: "510116",
    cid: "510100"
  },
  {
    name: "成华区",
    pid: "510000",
    id: "510108",
    cid: "510100"
  },
  {
    name: "武侯区",
    pid: "510000",
    id: "510107",
    cid: "510100"
  },
  {
    name: "大邑县",
    pid: "510000",
    id: "510129",
    cid: "510100"
  },
  {
    name: "营山县",
    pid: "510000",
    id: "511322",
    cid: "511300"
  },
  {
    name: "蓬安县",
    pid: "510000",
    id: "511323",
    cid: "511300"
  },
  {
    name: "南部县",
    pid: "510000",
    id: "511321",
    cid: "511300"
  },
  {
    name: "嘉陵区",
    pid: "510000",
    id: "511304",
    cid: "511300"
  },
  {
    name: "顺庆区",
    pid: "510000",
    id: "511302",
    cid: "511300"
  },
  {
    name: "仪陇县",
    pid: "510000",
    id: "511324",
    cid: "511300"
  },
  {
    name: "高坪区",
    pid: "510000",
    id: "511303",
    cid: "511300"
  },
  {
    name: "西充县",
    pid: "510000",
    id: "511325",
    cid: "511300"
  },
  {
    name: "阆中市",
    pid: "510000",
    id: "511381",
    cid: "511300"
  },
  {
    name: "仁寿县",
    pid: "510000",
    id: "511421",
    cid: "511400"
  },
  {
    name: "彭山区",
    pid: "510000",
    id: "511403",
    cid: "511400"
  },
  {
    name: "青神县",
    pid: "510000",
    id: "511425",
    cid: "511400"
  },
  {
    name: "洪雅县",
    pid: "510000",
    id: "511423",
    cid: "511400"
  },
  {
    name: "东坡区",
    pid: "510000",
    id: "511402",
    cid: "511400"
  },
  {
    name: "丹棱县",
    pid: "510000",
    id: "511424",
    cid: "511400"
  },
  {
    name: "沿滩区",
    pid: "510000",
    id: "510311",
    cid: "510300"
  },
  {
    name: "富顺县",
    pid: "510000",
    id: "510322",
    cid: "510300"
  },
  {
    name: "荣县",
    pid: "510000",
    id: "510321",
    cid: "510300"
  },
  {
    name: "自流井区",
    pid: "510000",
    id: "510302",
    cid: "510300"
  },
  {
    name: "大安区",
    pid: "510000",
    id: "510304",
    cid: "510300"
  },
  {
    name: "贡井区",
    pid: "510000",
    id: "510303",
    cid: "510300"
  },
  {
    name: "峨边彝族自治县",
    pid: "510000",
    id: "511132",
    cid: "511100"
  },
  {
    name: "市中区",
    pid: "510000",
    id: "511102",
    cid: "511100"
  },
  {
    name: "金口河区",
    pid: "510000",
    id: "511113",
    cid: "511100"
  },
  {
    name: "井研县",
    pid: "510000",
    id: "511124",
    cid: "511100"
  },
  {
    name: "沙湾区",
    pid: "510000",
    id: "511111",
    cid: "511100"
  },
  {
    name: "马边彝族自治县",
    pid: "510000",
    id: "511133",
    cid: "511100"
  },
  {
    name: "五通桥区",
    pid: "510000",
    id: "511112",
    cid: "511100"
  },
  {
    name: "犍为县",
    pid: "510000",
    id: "511123",
    cid: "511100"
  },
  {
    name: "沐川县",
    pid: "510000",
    id: "511129",
    cid: "511100"
  },
  {
    name: "夹江县",
    pid: "510000",
    id: "511126",
    cid: "511100"
  },
  {
    name: "峨眉山市",
    pid: "510000",
    id: "511181",
    cid: "511100"
  },
  {
    name: "昭觉县",
    pid: "510000",
    id: "513431",
    cid: "513400"
  },
  {
    name: "金阳县",
    pid: "510000",
    id: "513430",
    cid: "513400"
  },
  {
    name: "德昌县",
    pid: "510000",
    id: "513424",
    cid: "513400"
  },
  {
    name: "甘洛县",
    pid: "510000",
    id: "513435",
    cid: "513400"
  },
  {
    name: "西昌市",
    pid: "510000",
    id: "513401",
    cid: "513400"
  },
  {
    name: "盐源县",
    pid: "510000",
    id: "513423",
    cid: "513400"
  },
  {
    name: "越西县",
    pid: "510000",
    id: "513434",
    cid: "513400"
  },
  {
    name: "木里藏族自治县",
    pid: "510000",
    id: "513422",
    cid: "513400"
  },
  {
    name: "冕宁县",
    pid: "510000",
    id: "513433",
    cid: "513400"
  },
  {
    name: "喜德县",
    pid: "510000",
    id: "513432",
    cid: "513400"
  },
  {
    name: "普格县",
    pid: "510000",
    id: "513428",
    cid: "513400"
  },
  {
    name: "宁南县",
    pid: "510000",
    id: "513427",
    cid: "513400"
  },
  {
    name: "会东县",
    pid: "510000",
    id: "513426",
    cid: "513400"
  },
  {
    name: "雷波县",
    pid: "510000",
    id: "513437",
    cid: "513400"
  },
  {
    name: "会理县",
    pid: "510000",
    id: "513425",
    cid: "513400"
  },
  {
    name: "美姑县",
    pid: "510000",
    id: "513436",
    cid: "513400"
  },
  {
    name: "布拖县",
    pid: "510000",
    id: "513429",
    cid: "513400"
  },
  {
    name: "石渠县",
    pid: "510000",
    id: "513332",
    cid: "513300"
  },
  {
    name: "白玉县",
    pid: "510000",
    id: "513331",
    cid: "513300"
  },
  {
    name: "德格县",
    pid: "510000",
    id: "513330",
    cid: "513300"
  },
  {
    name: "雅江县",
    pid: "510000",
    id: "513325",
    cid: "513300"
  },
  {
    name: "乡城县",
    pid: "510000",
    id: "513336",
    cid: "513300"
  },
  {
    name: "九龙县",
    pid: "510000",
    id: "513324",
    cid: "513300"
  },
  {
    name: "巴塘县",
    pid: "510000",
    id: "513335",
    cid: "513300"
  },
  {
    name: "康定市",
    pid: "510000",
    id: "513301",
    cid: "513300"
  },
  {
    name: "丹巴县",
    pid: "510000",
    id: "513323",
    cid: "513300"
  },
  {
    name: "理塘县",
    pid: "510000",
    id: "513334",
    cid: "513300"
  },
  {
    name: "泸定县",
    pid: "510000",
    id: "513322",
    cid: "513300"
  },
  {
    name: "色达县",
    pid: "510000",
    id: "513333",
    cid: "513300"
  },
  {
    name: "新龙县",
    pid: "510000",
    id: "513329",
    cid: "513300"
  },
  {
    name: "甘孜县",
    pid: "510000",
    id: "513328",
    cid: "513300"
  },
  {
    name: "炉霍县",
    pid: "510000",
    id: "513327",
    cid: "513300"
  },
  {
    name: "得荣县",
    pid: "510000",
    id: "513338",
    cid: "513300"
  },
  {
    name: "道孚县",
    pid: "510000",
    id: "513326",
    cid: "513300"
  },
  {
    name: "稻城县",
    pid: "510000",
    id: "513337",
    cid: "513300"
  },
  {
    name: "合江县",
    pid: "510000",
    id: "510522",
    cid: "510500"
  },
  {
    name: "泸县",
    pid: "510000",
    id: "510521",
    cid: "510500"
  },
  {
    name: "江阳区",
    pid: "510000",
    id: "510502",
    cid: "510500"
  },
  {
    name: "叙永县",
    pid: "510000",
    id: "510524",
    cid: "510500"
  },
  {
    name: "龙马潭区",
    pid: "510000",
    id: "510504",
    cid: "510500"
  },
  {
    name: "纳溪区",
    pid: "510000",
    id: "510503",
    cid: "510500"
  },
  {
    name: "古蔺县",
    pid: "510000",
    id: "510525",
    cid: "510500"
  },
  {
    name: "万源市",
    pid: "510000",
    id: "511781",
    cid: "511700"
  },
  {
    name: "宣汉县",
    pid: "510000",
    id: "511722",
    cid: "511700"
  },
  {
    name: "开江县",
    pid: "510000",
    id: "511723",
    cid: "511700"
  },
  {
    name: "通川区",
    pid: "510000",
    id: "511702",
    cid: "511700"
  },
  {
    name: "大竹县",
    pid: "510000",
    id: "511724",
    cid: "511700"
  },
  {
    name: "达川区",
    pid: "510000",
    id: "511703",
    cid: "511700"
  },
  {
    name: "渠县",
    pid: "510000",
    id: "511725",
    cid: "511700"
  },
  {
    name: "米易县",
    pid: "510000",
    id: "510421",
    cid: "510400"
  },
  {
    name: "仁和区",
    pid: "510000",
    id: "510411",
    cid: "510400"
  },
  {
    name: "盐边县",
    pid: "510000",
    id: "510422",
    cid: "510400"
  },
  {
    name: "西区",
    pid: "510000",
    id: "510403",
    cid: "510400"
  },
  {
    name: "东区",
    pid: "510000",
    id: "510402",
    cid: "510400"
  },
  {
    name: "宝兴县",
    pid: "510000",
    id: "511827",
    cid: "511800"
  },
  {
    name: "荥经县",
    pid: "510000",
    id: "511822",
    cid: "511800"
  },
  {
    name: "名山区",
    pid: "510000",
    id: "511803",
    cid: "511800"
  },
  {
    name: "天全县",
    pid: "510000",
    id: "511825",
    cid: "511800"
  },
  {
    name: "芦山县",
    pid: "510000",
    id: "511826",
    cid: "511800"
  },
  {
    name: "汉源县",
    pid: "510000",
    id: "511823",
    cid: "511800"
  },
  {
    name: "雨城区",
    pid: "510000",
    id: "511802",
    cid: "511800"
  },
  {
    name: "石棉县",
    pid: "510000",
    id: "511824",
    cid: "511800"
  },
  {
    name: "安州区",
    pid: "510000",
    id: "510705",
    cid: "510700"
  },
  {
    name: "平武县",
    pid: "510000",
    id: "510727",
    cid: "510700"
  },
  {
    name: "江油市",
    pid: "510000",
    id: "510781",
    cid: "510700"
  },
  {
    name: "三台县",
    pid: "510000",
    id: "510722",
    cid: "510700"
  },
  {
    name: "盐亭县",
    pid: "510000",
    id: "510723",
    cid: "510700"
  },
  {
    name: "游仙区",
    pid: "510000",
    id: "510704",
    cid: "510700"
  },
  {
    name: "北川羌族自治县",
    pid: "510000",
    id: "510726",
    cid: "510700"
  },
  {
    name: "涪城区",
    pid: "510000",
    id: "510703",
    cid: "510700"
  },
  {
    name: "梓潼县",
    pid: "510000",
    id: "510725",
    cid: "510700"
  },
  {
    name: "宜宾县",
    pid: "510000",
    id: "511521",
    cid: "511500"
  },
  {
    name: "翠屏区",
    pid: "510000",
    id: "511502",
    cid: "511500"
  },
  {
    name: "长宁县",
    pid: "510000",
    id: "511524",
    cid: "511500"
  },
  {
    name: "南溪区",
    pid: "510000",
    id: "511503",
    cid: "511500"
  },
  {
    name: "高县",
    pid: "510000",
    id: "511525",
    cid: "511500"
  },
  {
    name: "江安县",
    pid: "510000",
    id: "511523",
    cid: "511500"
  },
  {
    name: "兴文县",
    pid: "510000",
    id: "511528",
    cid: "511500"
  },
  {
    name: "屏山县",
    pid: "510000",
    id: "511529",
    cid: "511500"
  },
  {
    name: "珙县",
    pid: "510000",
    id: "511526",
    cid: "511500"
  },
  {
    name: "筠连县",
    pid: "510000",
    id: "511527",
    cid: "511500"
  },
  {
    name: "广汉市",
    pid: "510000",
    id: "510681",
    cid: "510600"
  },
  {
    name: "绵竹市",
    pid: "510000",
    id: "510683",
    cid: "510600"
  },
  {
    name: "什邡市",
    pid: "510000",
    id: "510682",
    cid: "510600"
  },
  {
    name: "中江县",
    pid: "510000",
    id: "510623",
    cid: "510600"
  },
  {
    name: "旌阳区",
    pid: "510000",
    id: "510603",
    cid: "510600"
  },
  {
    name: "罗江县",
    pid: "510000",
    id: "510626",
    cid: "510600"
  },
  {
    name: "华蓥市",
    pid: "510000",
    id: "511681",
    cid: "511600"
  },
  {
    name: "邻水县",
    pid: "510000",
    id: "511623",
    cid: "511600"
  },
  {
    name: "广安区",
    pid: "510000",
    id: "511602",
    cid: "511600"
  },
  {
    name: "岳池县",
    pid: "510000",
    id: "511621",
    cid: "511600"
  },
  {
    name: "武胜县",
    pid: "510000",
    id: "511622",
    cid: "511600"
  },
  {
    name: "前锋区",
    pid: "510000",
    id: "511603",
    cid: "511600"
  },
  {
    name: "旺苍县",
    pid: "510000",
    id: "510821",
    cid: "510800"
  },
  {
    name: "利州区",
    pid: "510000",
    id: "510802",
    cid: "510800"
  },
  {
    name: "苍溪县",
    pid: "510000",
    id: "510824",
    cid: "510800"
  },
  {
    name: "昭化区",
    pid: "510000",
    id: "510811",
    cid: "510800"
  },
  {
    name: "青川县",
    pid: "510000",
    id: "510822",
    cid: "510800"
  },
  {
    name: "朝天区",
    pid: "510000",
    id: "510812",
    cid: "510800"
  },
  {
    name: "剑阁县",
    pid: "510000",
    id: "510823",
    cid: "510800"
  },
  {
    name: "通江县",
    pid: "510000",
    id: "511921",
    cid: "511900"
  },
  {
    name: "巴州区",
    pid: "510000",
    id: "511902",
    cid: "511900"
  },
  {
    name: "恩阳区",
    pid: "510000",
    id: "511903",
    cid: "511900"
  },
  {
    name: "南江县",
    pid: "510000",
    id: "511922",
    cid: "511900"
  },
  {
    name: "平昌县",
    pid: "510000",
    id: "511923",
    cid: "511900"
  },
  {
    name: "船山区",
    pid: "510000",
    id: "510903",
    cid: "510900"
  },
  {
    name: "安居区",
    pid: "510000",
    id: "510904",
    cid: "510900"
  },
  {
    name: "大英县",
    pid: "510000",
    id: "510923",
    cid: "510900"
  },
  {
    name: "蓬溪县",
    pid: "510000",
    id: "510921",
    cid: "510900"
  },
  {
    name: "射洪县",
    pid: "510000",
    id: "510922",
    cid: "510900"
  },
  {
    name: "灵武市",
    pid: "640000",
    id: "640181",
    cid: "640100"
  },
  {
    name: "贺兰县",
    pid: "640000",
    id: "640122",
    cid: "640100"
  },
  {
    name: "永宁县",
    pid: "640000",
    id: "640121",
    cid: "640100"
  },
  {
    name: "兴庆区",
    pid: "640000",
    id: "640104",
    cid: "640100"
  },
  {
    name: "金凤区",
    pid: "640000",
    id: "640106",
    cid: "640100"
  },
  {
    name: "西夏区",
    pid: "640000",
    id: "640105",
    cid: "640100"
  },
  {
    name: "青铜峡市",
    pid: "640000",
    id: "640381",
    cid: "640300"
  },
  {
    name: "利通区",
    pid: "640000",
    id: "640302",
    cid: "640300"
  },
  {
    name: "同心县",
    pid: "640000",
    id: "640324",
    cid: "640300"
  },
  {
    name: "盐池县",
    pid: "640000",
    id: "640323",
    cid: "640300"
  },
  {
    name: "红寺堡区",
    pid: "640000",
    id: "640303",
    cid: "640300"
  },
  {
    name: "平罗县",
    pid: "640000",
    id: "640221",
    cid: "640200"
  },
  {
    name: "大武口区",
    pid: "640000",
    id: "640202",
    cid: "640200"
  },
  {
    name: "惠农区",
    pid: "640000",
    id: "640205",
    cid: "640200"
  },
  {
    name: "海原县",
    pid: "640000",
    id: "640522",
    cid: "640500"
  },
  {
    name: "中宁县",
    pid: "640000",
    id: "640521",
    cid: "640500"
  },
  {
    name: "沙坡头区",
    pid: "640000",
    id: "640502",
    cid: "640500"
  },
  {
    name: "隆德县",
    pid: "640000",
    id: "640423",
    cid: "640400"
  },
  {
    name: "西吉县",
    pid: "640000",
    id: "640422",
    cid: "640400"
  },
  {
    name: "彭阳县",
    pid: "640000",
    id: "640425",
    cid: "640400"
  },
  {
    name: "原州区",
    pid: "640000",
    id: "640402",
    cid: "640400"
  },
  {
    name: "泾源县",
    pid: "640000",
    id: "640424",
    cid: "640400"
  },
  {
    name: "通榆县",
    pid: "220000",
    id: "220822",
    cid: "220800"
  },
  {
    name: "洮北区",
    pid: "220000",
    id: "220802",
    cid: "220800"
  },
  {
    name: "洮南市",
    pid: "220000",
    id: "220881",
    cid: "220800"
  },
  {
    name: "大安市",
    pid: "220000",
    id: "220882",
    cid: "220800"
  },
  {
    name: "镇赉县",
    pid: "220000",
    id: "220821",
    cid: "220800"
  },
  {
    name: "榆树市",
    pid: "220000",
    id: "220182",
    cid: "220100"
  },
  {
    name: "德惠市",
    pid: "220000",
    id: "220183",
    cid: "220100"
  },
  {
    name: "农安县",
    pid: "220000",
    id: "220122",
    cid: "220100"
  },
  {
    name: "南关区",
    pid: "220000",
    id: "220102",
    cid: "220100"
  },
  {
    name: "九台区",
    pid: "220000",
    id: "220113",
    cid: "220100"
  },
  {
    name: "双阳区",
    pid: "220000",
    id: "220112",
    cid: "220100"
  },
  {
    name: "朝阳区",
    pid: "220000",
    id: "220104",
    cid: "220100"
  },
  {
    name: "宽城区",
    pid: "220000",
    id: "220103",
    cid: "220100"
  },
  {
    name: "绿园区",
    pid: "220000",
    id: "220106",
    cid: "220100"
  },
  {
    name: "二道区",
    pid: "220000",
    id: "220105",
    cid: "220100"
  },
  {
    name: "和龙市",
    pid: "220000",
    id: "222406",
    cid: "222400"
  },
  {
    name: "延吉市",
    pid: "220000",
    id: "222401",
    cid: "222400"
  },
  {
    name: "珲春市",
    pid: "220000",
    id: "222404",
    cid: "222400"
  },
  {
    name: "安图县",
    pid: "220000",
    id: "222426",
    cid: "222400"
  },
  {
    name: "龙井市",
    pid: "220000",
    id: "222405",
    cid: "222400"
  },
  {
    name: "图们市",
    pid: "220000",
    id: "222402",
    cid: "222400"
  },
  {
    name: "汪清县",
    pid: "220000",
    id: "222424",
    cid: "222400"
  },
  {
    name: "敦化市",
    pid: "220000",
    id: "222403",
    cid: "222400"
  },
  {
    name: "双辽市",
    pid: "220000",
    id: "220382",
    cid: "220300"
  },
  {
    name: "公主岭市",
    pid: "220000",
    id: "220381",
    cid: "220300"
  },
  {
    name: "梨树县",
    pid: "220000",
    id: "220322",
    cid: "220300"
  },
  {
    name: "铁西区",
    pid: "220000",
    id: "220302",
    cid: "220300"
  },
  {
    name: "伊通满族自治县",
    pid: "220000",
    id: "220323",
    cid: "220300"
  },
  {
    name: "铁东区",
    pid: "220000",
    id: "220303",
    cid: "220300"
  },
  {
    name: "蛟河市",
    pid: "220000",
    id: "220281",
    cid: "220200"
  },
  {
    name: "舒兰市",
    pid: "220000",
    id: "220283",
    cid: "220200"
  },
  {
    name: "桦甸市",
    pid: "220000",
    id: "220282",
    cid: "220200"
  },
  {
    name: "磐石市",
    pid: "220000",
    id: "220284",
    cid: "220200"
  },
  {
    name: "永吉县",
    pid: "220000",
    id: "220221",
    cid: "220200"
  },
  {
    name: "丰满区",
    pid: "220000",
    id: "220211",
    cid: "220200"
  },
  {
    name: "龙潭区",
    pid: "220000",
    id: "220203",
    cid: "220200"
  },
  {
    name: "昌邑区",
    pid: "220000",
    id: "220202",
    cid: "220200"
  },
  {
    name: "船营区",
    pid: "220000",
    id: "220204",
    cid: "220200"
  },
  {
    name: "二道江区",
    pid: "220000",
    id: "220503",
    cid: "220500"
  },
  {
    name: "集安市",
    pid: "220000",
    id: "220582",
    cid: "220500"
  },
  {
    name: "梅河口市",
    pid: "220000",
    id: "220581",
    cid: "220500"
  },
  {
    name: "通化县",
    pid: "220000",
    id: "220521",
    cid: "220500"
  },
  {
    name: "东昌区",
    pid: "220000",
    id: "220502",
    cid: "220500"
  },
  {
    name: "柳河县",
    pid: "220000",
    id: "220524",
    cid: "220500"
  },
  {
    name: "辉南县",
    pid: "220000",
    id: "220523",
    cid: "220500"
  },
  {
    name: "东丰县",
    pid: "220000",
    id: "220421",
    cid: "220400"
  },
  {
    name: "东辽县",
    pid: "220000",
    id: "220422",
    cid: "220400"
  },
  {
    name: "西安区",
    pid: "220000",
    id: "220403",
    cid: "220400"
  },
  {
    name: "龙山区",
    pid: "220000",
    id: "220402",
    cid: "220400"
  },
  {
    name: "宁江区",
    pid: "220000",
    id: "220702",
    cid: "220700"
  },
  {
    name: "乾安县",
    pid: "220000",
    id: "220723",
    cid: "220700"
  },
  {
    name: "扶余市",
    pid: "220000",
    id: "220781",
    cid: "220700"
  },
  {
    name: "长岭县",
    pid: "220000",
    id: "220722",
    cid: "220700"
  },
  {
    name: "前郭尔罗斯蒙古族自治县",
    pid: "220000",
    id: "220721",
    cid: "220700"
  },
  {
    name: "浑江区",
    pid: "220000",
    id: "220602",
    cid: "220600"
  },
  {
    name: "江源区",
    pid: "220000",
    id: "220605",
    cid: "220600"
  },
  {
    name: "临江市",
    pid: "220000",
    id: "220681",
    cid: "220600"
  },
  {
    name: "抚松县",
    pid: "220000",
    id: "220621",
    cid: "220600"
  },
  {
    name: "长白朝鲜族自治县",
    pid: "220000",
    id: "220623",
    cid: "220600"
  },
  {
    name: "靖宇县",
    pid: "220000",
    id: "220622",
    cid: "220600"
  },
  {
    name: "云霄县",
    pid: "350000",
    id: "350622",
    cid: "350600"
  },
  {
    name: "漳浦县",
    pid: "350000",
    id: "350623",
    cid: "350600"
  },
  {
    name: "芗城区",
    pid: "350000",
    id: "350602",
    cid: "350600"
  },
  {
    name: "诏安县",
    pid: "350000",
    id: "350624",
    cid: "350600"
  },
  {
    name: "龙文区",
    pid: "350000",
    id: "350603",
    cid: "350600"
  },
  {
    name: "长泰县",
    pid: "350000",
    id: "350625",
    cid: "350600"
  },
  {
    name: "东山县",
    pid: "350000",
    id: "350626",
    cid: "350600"
  },
  {
    name: "南靖县",
    pid: "350000",
    id: "350627",
    cid: "350600"
  },
  {
    name: "平和县",
    pid: "350000",
    id: "350628",
    cid: "350600"
  },
  {
    name: "华安县",
    pid: "350000",
    id: "350629",
    cid: "350600"
  },
  {
    name: "龙海市",
    pid: "350000",
    id: "350681",
    cid: "350600"
  },
  {
    name: "顺昌县",
    pid: "350000",
    id: "350721",
    cid: "350700"
  },
  {
    name: "浦城县",
    pid: "350000",
    id: "350722",
    cid: "350700"
  },
  {
    name: "光泽县",
    pid: "350000",
    id: "350723",
    cid: "350700"
  },
  {
    name: "延平区",
    pid: "350000",
    id: "350702",
    cid: "350700"
  },
  {
    name: "松溪县",
    pid: "350000",
    id: "350724",
    cid: "350700"
  },
  {
    name: "建阳区",
    pid: "350000",
    id: "350703",
    cid: "350700"
  },
  {
    name: "政和县",
    pid: "350000",
    id: "350725",
    cid: "350700"
  },
  {
    name: "邵武市",
    pid: "350000",
    id: "350781",
    cid: "350700"
  },
  {
    name: "武夷山市",
    pid: "350000",
    id: "350782",
    cid: "350700"
  },
  {
    name: "建瓯市",
    pid: "350000",
    id: "350783",
    cid: "350700"
  },
  {
    name: "长汀县",
    pid: "350000",
    id: "350821",
    cid: "350800"
  },
  {
    name: "上杭县",
    pid: "350000",
    id: "350823",
    cid: "350800"
  },
  {
    name: "新罗区",
    pid: "350000",
    id: "350802",
    cid: "350800"
  },
  {
    name: "武平县",
    pid: "350000",
    id: "350824",
    cid: "350800"
  },
  {
    name: "永定区",
    pid: "350000",
    id: "350803",
    cid: "350800"
  },
  {
    name: "连城县",
    pid: "350000",
    id: "350825",
    cid: "350800"
  },
  {
    name: "漳平市",
    pid: "350000",
    id: "350881",
    cid: "350800"
  },
  {
    name: "霞浦县",
    pid: "350000",
    id: "350921",
    cid: "350900"
  },
  {
    name: "古田县",
    pid: "350000",
    id: "350922",
    cid: "350900"
  },
  {
    name: "屏南县",
    pid: "350000",
    id: "350923",
    cid: "350900"
  },
  {
    name: "蕉城区",
    pid: "350000",
    id: "350902",
    cid: "350900"
  },
  {
    name: "寿宁县",
    pid: "350000",
    id: "350924",
    cid: "350900"
  },
  {
    name: "周宁县",
    pid: "350000",
    id: "350925",
    cid: "350900"
  },
  {
    name: "柘荣县",
    pid: "350000",
    id: "350926",
    cid: "350900"
  },
  {
    name: "福安市",
    pid: "350000",
    id: "350981",
    cid: "350900"
  },
  {
    name: "福鼎市",
    pid: "350000",
    id: "350982",
    cid: "350900"
  },
  {
    name: "马尾区",
    pid: "350000",
    id: "350105",
    cid: "350100"
  },
  {
    name: "平潭县",
    pid: "350000",
    id: "350128",
    cid: "350100"
  },
  {
    name: "闽侯县",
    pid: "350000",
    id: "350121",
    cid: "350100"
  },
  {
    name: "晋安区",
    pid: "350000",
    id: "350111",
    cid: "350100"
  },
  {
    name: "连江县",
    pid: "350000",
    id: "350122",
    cid: "350100"
  },
  {
    name: "罗源县",
    pid: "350000",
    id: "350123",
    cid: "350100"
  },
  {
    name: "鼓楼区",
    pid: "350000",
    id: "350102",
    cid: "350100"
  },
  {
    name: "闽清县",
    pid: "350000",
    id: "350124",
    cid: "350100"
  },
  {
    name: "台江区",
    pid: "350000",
    id: "350103",
    cid: "350100"
  },
  {
    name: "永泰县",
    pid: "350000",
    id: "350125",
    cid: "350100"
  },
  {
    name: "仓山区",
    pid: "350000",
    id: "350104",
    cid: "350100"
  },
  {
    name: "福清市",
    pid: "350000",
    id: "350181",
    cid: "350100"
  },
  {
    name: "长乐市",
    pid: "350000",
    id: "350182",
    cid: "350100"
  },
  {
    name: "海沧区",
    pid: "350000",
    id: "350205",
    cid: "350200"
  },
  {
    name: "湖里区",
    pid: "350000",
    id: "350206",
    cid: "350200"
  },
  {
    name: "集美区",
    pid: "350000",
    id: "350211",
    cid: "350200"
  },
  {
    name: "同安区",
    pid: "350000",
    id: "350212",
    cid: "350200"
  },
  {
    name: "翔安区",
    pid: "350000",
    id: "350213",
    cid: "350200"
  },
  {
    name: "思明区",
    pid: "350000",
    id: "350203",
    cid: "350200"
  },
  {
    name: "涵江区",
    pid: "350000",
    id: "350303",
    cid: "350300"
  },
  {
    name: "荔城区",
    pid: "350000",
    id: "350304",
    cid: "350300"
  },
  {
    name: "秀屿区",
    pid: "350000",
    id: "350305",
    cid: "350300"
  },
  {
    name: "仙游县",
    pid: "350000",
    id: "350322",
    cid: "350300"
  },
  {
    name: "城厢区",
    pid: "350000",
    id: "350302",
    cid: "350300"
  },
  {
    name: "梅列区",
    pid: "350000",
    id: "350402",
    cid: "350400"
  },
  {
    name: "宁化县",
    pid: "350000",
    id: "350424",
    cid: "350400"
  },
  {
    name: "三元区",
    pid: "350000",
    id: "350403",
    cid: "350400"
  },
  {
    name: "大田县",
    pid: "350000",
    id: "350425",
    cid: "350400"
  },
  {
    name: "尤溪县",
    pid: "350000",
    id: "350426",
    cid: "350400"
  },
  {
    name: "沙县",
    pid: "350000",
    id: "350427",
    cid: "350400"
  },
  {
    name: "将乐县",
    pid: "350000",
    id: "350428",
    cid: "350400"
  },
  {
    name: "泰宁县",
    pid: "350000",
    id: "350429",
    cid: "350400"
  },
  {
    name: "永安市",
    pid: "350000",
    id: "350481",
    cid: "350400"
  },
  {
    name: "建宁县",
    pid: "350000",
    id: "350430",
    cid: "350400"
  },
  {
    name: "明溪县",
    pid: "350000",
    id: "350421",
    cid: "350400"
  },
  {
    name: "清流县",
    pid: "350000",
    id: "350423",
    cid: "350400"
  },
  {
    name: "鲤城区",
    pid: "350000",
    id: "350502",
    cid: "350500"
  },
  {
    name: "安溪县",
    pid: "350000",
    id: "350524",
    cid: "350500"
  },
  {
    name: "丰泽区",
    pid: "350000",
    id: "350503",
    cid: "350500"
  },
  {
    name: "永春县",
    pid: "350000",
    id: "350525",
    cid: "350500"
  },
  {
    name: "洛江区",
    pid: "350000",
    id: "350504",
    cid: "350500"
  },
  {
    name: "德化县",
    pid: "350000",
    id: "350526",
    cid: "350500"
  },
  {
    name: "泉港区",
    pid: "350000",
    id: "350505",
    cid: "350500"
  },
  {
    name: "金门县",
    pid: "350000",
    id: "350527",
    cid: "350500"
  },
  {
    name: "石狮市",
    pid: "350000",
    id: "350581",
    cid: "350500"
  },
  {
    name: "晋江市",
    pid: "350000",
    id: "350582",
    cid: "350500"
  },
  {
    name: "南安市",
    pid: "350000",
    id: "350583",
    cid: "350500"
  },
  {
    name: "惠安县",
    pid: "350000",
    id: "350521",
    cid: "350500"
  },
  {
    name: "红坪镇",
    pid: "420000",
    id: "429021103",
    cid: "429021"
  },
  {
    name: "下谷坪土家族乡",
    pid: "420000",
    id: "429021202",
    cid: "429021"
  },
  {
    name: "新华镇",
    pid: "420000",
    id: "429021104",
    cid: "429021"
  },
  {
    name: "九湖镇",
    pid: "420000",
    id: "429021105",
    cid: "429021"
  },
  {
    name: "阳日镇",
    pid: "420000",
    id: "429021101",
    cid: "429021"
  },
  {
    name: "木鱼镇",
    pid: "420000",
    id: "429021102",
    cid: "429021"
  },
  {
    name: "松柏镇",
    pid: "420000",
    id: "4290211",
    cid: "429021"
  },
  {
    name: "宋洛乡",
    pid: "420000",
    id: "4290212",
    cid: "429021"
  },
  {
    name: "洪山区",
    pid: "420000",
    id: "420111",
    cid: "420100"
  },
  {
    name: "硚口区",
    pid: "420000",
    id: "420104",
    cid: "420100"
  },
  {
    name: "江夏区",
    pid: "420000",
    id: "420115",
    cid: "420100"
  },
  {
    name: "江汉区",
    pid: "420000",
    id: "420103",
    cid: "420100"
  },
  {
    name: "蔡甸区",
    pid: "420000",
    id: "420114",
    cid: "420100"
  },
  {
    name: "江岸区",
    pid: "420000",
    id: "420102",
    cid: "420100"
  },
  {
    name: "汉南区",
    pid: "420000",
    id: "420113",
    cid: "420100"
  },
  {
    name: "东西湖区",
    pid: "420000",
    id: "420112",
    cid: "420100"
  },
  {
    name: "青山区",
    pid: "420000",
    id: "420107",
    cid: "420100"
  },
  {
    name: "武昌区",
    pid: "420000",
    id: "420106",
    cid: "420100"
  },
  {
    name: "新洲区",
    pid: "420000",
    id: "420117",
    cid: "420100"
  },
  {
    name: "汉阳区",
    pid: "420000",
    id: "420105",
    cid: "420100"
  },
  {
    name: "黄陂区",
    pid: "420000",
    id: "420116",
    cid: "420100"
  },
  {
    name: "咸安区",
    pid: "420000",
    id: "421202",
    cid: "421200"
  },
  {
    name: "通山县",
    pid: "420000",
    id: "421224",
    cid: "421200"
  },
  {
    name: "崇阳县",
    pid: "420000",
    id: "421223",
    cid: "421200"
  },
  {
    name: "赤壁市",
    pid: "420000",
    id: "421281",
    cid: "421200"
  },
  {
    name: "通城县",
    pid: "420000",
    id: "421222",
    cid: "421200"
  },
  {
    name: "嘉鱼县",
    pid: "420000",
    id: "421221",
    cid: "421200"
  },
  {
    name: "麻洋镇",
    pid: "420000",
    id: "42900611",
    cid: "429006"
  },
  {
    name: "石河镇",
    pid: "420000",
    id: "42900612",
    cid: "429006"
  },
  {
    name: "小板镇",
    pid: "420000",
    id: "429006115",
    cid: "429006"
  },
  {
    name: "九真镇",
    pid: "420000",
    id: "429006116",
    cid: "429006"
  },
  {
    name: "马湾镇",
    pid: "420000",
    id: "429006113",
    cid: "429006"
  },
  {
    name: "卢市镇",
    pid: "420000",
    id: "429006114",
    cid: "429006"
  },
  {
    name: "胡市镇",
    pid: "420000",
    id: "429006119",
    cid: "429006"
  },
  {
    name: "皂市镇",
    pid: "420000",
    id: "429006118",
    cid: "429006"
  },
  {
    name: "多祥镇",
    pid: "420000",
    id: "429006111",
    cid: "429006"
  },
  {
    name: "沉湖管委会",
    pid: "420000",
    id: "429006452",
    cid: "429006"
  },
  {
    name: "干驿镇",
    pid: "420000",
    id: "429006112",
    cid: "429006"
  },
  {
    name: "白茅湖农场",
    pid: "420000",
    id: "429006451",
    cid: "429006"
  },
  {
    name: "多宝镇",
    pid: "420000",
    id: "4290061",
    cid: "429006"
  },
  {
    name: "蒋湖农场",
    pid: "420000",
    id: "42900645",
    cid: "429006"
  },
  {
    name: "汪场镇",
    pid: "420000",
    id: "429006104",
    cid: "429006"
  },
  {
    name: "渔薪镇",
    pid: "420000",
    id: "429006105",
    cid: "429006"
  },
  {
    name: "杨林街道",
    pid: "420000",
    id: "429006003",
    cid: "429006"
  },
  {
    name: "张港镇",
    pid: "420000",
    id: "429006102",
    cid: "429006"
  },
  {
    name: "净潭乡",
    pid: "420000",
    id: "429006201",
    cid: "429006"
  },
  {
    name: "蒋场镇",
    pid: "420000",
    id: "429006103",
    cid: "429006"
  },
  {
    name: "横林镇",
    pid: "420000",
    id: "429006108",
    cid: "429006"
  },
  {
    name: "彭市镇",
    pid: "420000",
    id: "429006109",
    cid: "429006"
  },
  {
    name: "黄潭镇",
    pid: "420000",
    id: "429006106",
    cid: "429006"
  },
  {
    name: "岳口镇",
    pid: "420000",
    id: "429006107",
    cid: "429006"
  },
  {
    name: "竟陵街道",
    pid: "420000",
    id: "429006001",
    cid: "429006"
  },
  {
    name: "侨乡街道开发区",
    pid: "420000",
    id: "429006002",
    cid: "429006"
  },
  {
    name: "拖市镇",
    pid: "420000",
    id: "429006101",
    cid: "429006"
  },
  {
    name: "佛子山镇",
    pid: "420000",
    id: "429006121",
    cid: "429006"
  },
  {
    name: "黄梅县",
    pid: "420000",
    id: "421127",
    cid: "421100"
  },
  {
    name: "蕲春县",
    pid: "420000",
    id: "421126",
    cid: "421100"
  },
  {
    name: "浠水县",
    pid: "420000",
    id: "421125",
    cid: "421100"
  },
  {
    name: "黄州区",
    pid: "420000",
    id: "421102",
    cid: "421100"
  },
  {
    name: "英山县",
    pid: "420000",
    id: "421124",
    cid: "421100"
  },
  {
    name: "麻城市",
    pid: "420000",
    id: "421181",
    cid: "421100"
  },
  {
    name: "武穴市",
    pid: "420000",
    id: "421182",
    cid: "421100"
  },
  {
    name: "罗田县",
    pid: "420000",
    id: "421123",
    cid: "421100"
  },
  {
    name: "红安县",
    pid: "420000",
    id: "421122",
    cid: "421100"
  },
  {
    name: "团风县",
    pid: "420000",
    id: "421121",
    cid: "421100"
  },
  {
    name: "竹根滩镇",
    pid: "420000",
    id: "4290051",
    cid: "429005"
  },
  {
    name: "江汉石油管理局",
    pid: "420000",
    id: "4290054",
    cid: "429005"
  },
  {
    name: "广华",
    pid: "420000",
    id: "429005004",
    cid: "429005"
  },
  {
    name: "高石碑镇",
    pid: "420000",
    id: "429005103",
    cid: "429005"
  },
  {
    name: "泰丰",
    pid: "420000",
    id: "429005005",
    cid: "429005"
  },
  {
    name: "熊口镇",
    pid: "420000",
    id: "429005104",
    cid: "429005"
  },
  {
    name: "潜江经济开发区",
    pid: "420000",
    id: "429005401",
    cid: "429005"
  },
  {
    name: "杨市",
    pid: "420000",
    id: "429005002",
    cid: "429005"
  },
  {
    name: "渔洋镇",
    pid: "420000",
    id: "429005101",
    cid: "429005"
  },
  {
    name: "周矶",
    pid: "420000",
    id: "429005003",
    cid: "429005"
  },
  {
    name: "王场镇",
    pid: "420000",
    id: "429005102",
    cid: "429005"
  },
  {
    name: "积玉口镇",
    pid: "420000",
    id: "429005107",
    cid: "429005"
  },
  {
    name: "张金镇",
    pid: "420000",
    id: "429005108",
    cid: "429005"
  },
  {
    name: "高场",
    pid: "420000",
    id: "429005006",
    cid: "429005"
  },
  {
    name: "老新镇",
    pid: "420000",
    id: "429005105",
    cid: "429005"
  },
  {
    name: "浩口镇",
    pid: "420000",
    id: "429005106",
    cid: "429005"
  },
  {
    name: "园林",
    pid: "420000",
    id: "429005001",
    cid: "429005"
  },
  {
    name: "周矶管理区",
    pid: "420000",
    id: "42900545",
    cid: "429005"
  },
  {
    name: "龙湾镇",
    pid: "420000",
    id: "429005109",
    cid: "429005"
  },
  {
    name: "运粮湖管理区",
    pid: "420000",
    id: "429005455",
    cid: "429005"
  },
  {
    name: "总口管理区",
    pid: "420000",
    id: "429005453",
    cid: "429005"
  },
  {
    name: "白鹭湖管理区",
    pid: "420000",
    id: "429005454",
    cid: "429005"
  },
  {
    name: "浩口原种场",
    pid: "420000",
    id: "429005457",
    cid: "429005"
  },
  {
    name: "后湖管理区",
    pid: "420000",
    id: "429005451",
    cid: "429005"
  },
  {
    name: "熊口管理区",
    pid: "420000",
    id: "429005452",
    cid: "429005"
  },
  {
    name: "荆州区",
    pid: "420000",
    id: "421003",
    cid: "421000"
  },
  {
    name: "石首市",
    pid: "420000",
    id: "421081",
    cid: "421000"
  },
  {
    name: "洪湖市",
    pid: "420000",
    id: "421083",
    cid: "421000"
  },
  {
    name: "沙市区",
    pid: "420000",
    id: "421002",
    cid: "421000"
  },
  {
    name: "江陵县",
    pid: "420000",
    id: "421024",
    cid: "421000"
  },
  {
    name: "监利县",
    pid: "420000",
    id: "421023",
    cid: "421000"
  },
  {
    name: "公安县",
    pid: "420000",
    id: "421022",
    cid: "421000"
  },
  {
    name: "松滋市",
    pid: "420000",
    id: "421087",
    cid: "421000"
  },
  {
    name: "彭场镇",
    pid: "420000",
    id: "429004109",
    cid: "429004"
  },
  {
    name: "杨林尾镇",
    pid: "420000",
    id: "429004108",
    cid: "429004"
  },
  {
    name: "赵西垸林场",
    pid: "420000",
    id: "429004405",
    cid: "429004"
  },
  {
    name: "张沟镇",
    pid: "420000",
    id: "42900411",
    cid: "429004"
  },
  {
    name: "排湖风景区",
    pid: "420000",
    id: "429004408",
    cid: "429004"
  },
  {
    name: "畜禽良种场",
    pid: "420000",
    id: "429004407",
    cid: "429004"
  },
  {
    name: "沔城回族镇",
    pid: "420000",
    id: "429004112",
    cid: "429004"
  },
  {
    name: "郭河镇",
    pid: "420000",
    id: "429004111",
    cid: "429004"
  },
  {
    name: "陈场镇",
    pid: "420000",
    id: "429004114",
    cid: "429004"
  },
  {
    name: "通海口镇",
    pid: "420000",
    id: "429004113",
    cid: "429004"
  },
  {
    name: "郑场镇",
    pid: "420000",
    id: "4290041",
    cid: "429004"
  },
  {
    name: "工业园区",
    pid: "420000",
    id: "4290044",
    cid: "429004"
  },
  {
    name: "干河街道",
    pid: "420000",
    id: "429004002",
    cid: "429004"
  },
  {
    name: "毛嘴镇",
    pid: "420000",
    id: "429004101",
    cid: "429004"
  },
  {
    name: "沙嘴街道",
    pid: "420000",
    id: "429004001",
    cid: "429004"
  },
  {
    name: "三伏潭镇",
    pid: "420000",
    id: "429004103",
    cid: "429004"
  },
  {
    name: "龙华山",
    pid: "420000",
    id: "429004003",
    cid: "429004"
  },
  {
    name: "豆河镇",
    pid: "420000",
    id: "429004102",
    cid: "429004"
  },
  {
    name: "长倘口镇",
    pid: "420000",
    id: "429004105",
    cid: "429004"
  },
  {
    name: "沙湖原种场",
    pid: "420000",
    id: "429004402",
    cid: "429004"
  },
  {
    name: "胡场镇",
    pid: "420000",
    id: "429004104",
    cid: "429004"
  },
  {
    name: "九合垸原种场",
    pid: "420000",
    id: "429004401",
    cid: "429004"
  },
  {
    name: "沙湖镇",
    pid: "420000",
    id: "429004107",
    cid: "429004"
  },
  {
    name: "五湖渔场",
    pid: "420000",
    id: "429004404",
    cid: "429004"
  },
  {
    name: "西流河镇",
    pid: "420000",
    id: "429004106",
    cid: "429004"
  },
  {
    name: "枝江市",
    pid: "420000",
    id: "420583",
    cid: "420500"
  },
  {
    name: "当阳市",
    pid: "420000",
    id: "420582",
    cid: "420500"
  },
  {
    name: "宜都市",
    pid: "420000",
    id: "420581",
    cid: "420500"
  },
  {
    name: "点军区",
    pid: "420000",
    id: "420504",
    cid: "420500"
  },
  {
    name: "兴山县",
    pid: "420000",
    id: "420526",
    cid: "420500"
  },
  {
    name: "伍家岗区",
    pid: "420000",
    id: "420503",
    cid: "420500"
  },
  {
    name: "远安县",
    pid: "420000",
    id: "420525",
    cid: "420500"
  },
  {
    name: "西陵区",
    pid: "420000",
    id: "420502",
    cid: "420500"
  },
  {
    name: "五峰土家族自治县",
    pid: "420000",
    id: "420529",
    cid: "420500"
  },
  {
    name: "夷陵区",
    pid: "420000",
    id: "420506",
    cid: "420500"
  },
  {
    name: "长阳土家族自治县",
    pid: "420000",
    id: "420528",
    cid: "420500"
  },
  {
    name: "猇亭区",
    pid: "420000",
    id: "420505",
    cid: "420500"
  },
  {
    name: "秭归县",
    pid: "420000",
    id: "420527",
    cid: "420500"
  },
  {
    name: "建始县",
    pid: "420000",
    id: "422822",
    cid: "422800"
  },
  {
    name: "恩施市",
    pid: "420000",
    id: "422801",
    cid: "422800"
  },
  {
    name: "巴东县",
    pid: "420000",
    id: "422823",
    cid: "422800"
  },
  {
    name: "利川市",
    pid: "420000",
    id: "422802",
    cid: "422800"
  },
  {
    name: "宣恩县",
    pid: "420000",
    id: "422825",
    cid: "422800"
  },
  {
    name: "咸丰县",
    pid: "420000",
    id: "422826",
    cid: "422800"
  },
  {
    name: "来凤县",
    pid: "420000",
    id: "422827",
    cid: "422800"
  },
  {
    name: "鹤峰县",
    pid: "420000",
    id: "422828",
    cid: "422800"
  },
  {
    name: "茅箭区",
    pid: "420000",
    id: "420302",
    cid: "420300"
  },
  {
    name: "竹溪县",
    pid: "420000",
    id: "420324",
    cid: "420300"
  },
  {
    name: "竹山县",
    pid: "420000",
    id: "420323",
    cid: "420300"
  },
  {
    name: "郧西县",
    pid: "420000",
    id: "420322",
    cid: "420300"
  },
  {
    name: "郧阳区",
    pid: "420000",
    id: "420304",
    cid: "420300"
  },
  {
    name: "张湾区",
    pid: "420000",
    id: "420303",
    cid: "420300"
  },
  {
    name: "房县",
    pid: "420000",
    id: "420325",
    cid: "420300"
  },
  {
    name: "丹江口市",
    pid: "420000",
    id: "420381",
    cid: "420300"
  },
  {
    name: "西塞山区",
    pid: "420000",
    id: "420203",
    cid: "420200"
  },
  {
    name: "黄石港区",
    pid: "420000",
    id: "420202",
    cid: "420200"
  },
  {
    name: "阳新县",
    pid: "420000",
    id: "420222",
    cid: "420200"
  },
  {
    name: "铁山区",
    pid: "420000",
    id: "420205",
    cid: "420200"
  },
  {
    name: "下陆区",
    pid: "420000",
    id: "420204",
    cid: "420200"
  },
  {
    name: "大冶市",
    pid: "420000",
    id: "420281",
    cid: "420200"
  },
  {
    name: "曾都区",
    pid: "420000",
    id: "421303",
    cid: "421300"
  },
  {
    name: "广水市",
    pid: "420000",
    id: "421381",
    cid: "421300"
  },
  {
    name: "随县",
    pid: "420000",
    id: "421321",
    cid: "421300"
  },
  {
    name: "汉川市",
    pid: "420000",
    id: "420984",
    cid: "420900"
  },
  {
    name: "安陆市",
    pid: "420000",
    id: "420982",
    cid: "420900"
  },
  {
    name: "应城市",
    pid: "420000",
    id: "420981",
    cid: "420900"
  },
  {
    name: "大悟县",
    pid: "420000",
    id: "420922",
    cid: "420900"
  },
  {
    name: "孝昌县",
    pid: "420000",
    id: "420921",
    cid: "420900"
  },
  {
    name: "孝南区",
    pid: "420000",
    id: "420902",
    cid: "420900"
  },
  {
    name: "云梦县",
    pid: "420000",
    id: "420923",
    cid: "420900"
  },
  {
    name: "沙洋县",
    pid: "420000",
    id: "420822",
    cid: "420800"
  },
  {
    name: "京山县",
    pid: "420000",
    id: "420821",
    cid: "420800"
  },
  {
    name: "掇刀区",
    pid: "420000",
    id: "420804",
    cid: "420800"
  },
  {
    name: "东宝区",
    pid: "420000",
    id: "420802",
    cid: "420800"
  },
  {
    name: "钟祥市",
    pid: "420000",
    id: "420881",
    cid: "420800"
  },
  {
    name: "梁子湖区",
    pid: "420000",
    id: "420702",
    cid: "420700"
  },
  {
    name: "鄂城区",
    pid: "420000",
    id: "420704",
    cid: "420700"
  },
  {
    name: "华容区",
    pid: "420000",
    id: "420703",
    cid: "420700"
  },
  {
    name: "宜城市",
    pid: "420000",
    id: "420684",
    cid: "420600"
  },
  {
    name: "谷城县",
    pid: "420000",
    id: "420625",
    cid: "420600"
  },
  {
    name: "襄城区",
    pid: "420000",
    id: "420602",
    cid: "420600"
  },
  {
    name: "南漳县",
    pid: "420000",
    id: "420624",
    cid: "420600"
  },
  {
    name: "襄州区",
    pid: "420000",
    id: "420607",
    cid: "420600"
  },
  {
    name: "樊城区",
    pid: "420000",
    id: "420606",
    cid: "420600"
  },
  {
    name: "保康县",
    pid: "420000",
    id: "420626",
    cid: "420600"
  },
  {
    name: "枣阳市",
    pid: "420000",
    id: "420683",
    cid: "420600"
  },
  {
    name: "老河口市",
    pid: "420000",
    id: "420682",
    cid: "420600"
  },
  {
    name: "电白区",
    pid: "440000",
    id: "440904",
    cid: "440900"
  },
  {
    name: "茂南区",
    pid: "440000",
    id: "440902",
    cid: "440900"
  },
  {
    name: "信宜市",
    pid: "440000",
    id: "440983",
    cid: "440900"
  },
  {
    name: "化州市",
    pid: "440000",
    id: "440982",
    cid: "440900"
  },
  {
    name: "高州市",
    pid: "440000",
    id: "440981",
    cid: "440900"
  },
  {
    name: "坡头区",
    pid: "440000",
    id: "440804",
    cid: "440800"
  },
  {
    name: "霞山区",
    pid: "440000",
    id: "440803",
    cid: "440800"
  },
  {
    name: "徐闻县",
    pid: "440000",
    id: "440825",
    cid: "440800"
  },
  {
    name: "赤坎区",
    pid: "440000",
    id: "440802",
    cid: "440800"
  },
  {
    name: "遂溪县",
    pid: "440000",
    id: "440823",
    cid: "440800"
  },
  {
    name: "麻章区",
    pid: "440000",
    id: "440811",
    cid: "440800"
  },
  {
    name: "吴川市",
    pid: "440000",
    id: "440883",
    cid: "440800"
  },
  {
    name: "雷州市",
    pid: "440000",
    id: "440882",
    cid: "440800"
  },
  {
    name: "廉江市",
    pid: "440000",
    id: "440881",
    cid: "440800"
  },
  {
    name: "大岭山镇",
    pid: "440000",
    id: "441900118",
    cid: "441900"
  },
  {
    name: "长安镇",
    pid: "440000",
    id: "441900119",
    cid: "441900"
  },
  {
    name: "黄江镇",
    pid: "440000",
    id: "441900114",
    cid: "441900"
  },
  {
    name: "清溪镇",
    pid: "440000",
    id: "441900115",
    cid: "441900"
  },
  {
    name: "塘厦镇",
    pid: "440000",
    id: "441900116",
    cid: "441900"
  },
  {
    name: "凤岗镇",
    pid: "440000",
    id: "441900117",
    cid: "441900"
  },
  {
    name: "常平镇",
    pid: "440000",
    id: "441900110",
    cid: "441900"
  },
  {
    name: "寮步镇",
    pid: "440000",
    id: "441900111",
    cid: "441900"
  },
  {
    name: "樟木头镇",
    pid: "440000",
    id: "441900112",
    cid: "441900"
  },
  {
    name: "大朗镇",
    pid: "440000",
    id: "441900113",
    cid: "441900"
  },
  {
    name: "桥头镇",
    pid: "440000",
    id: "441900107",
    cid: "441900"
  },
  {
    name: "高埗镇",
    pid: "440000",
    id: "441900129",
    cid: "441900"
  },
  {
    name: "谢岗镇",
    pid: "440000",
    id: "441900108",
    cid: "441900"
  },
  {
    name: "东坑镇",
    pid: "440000",
    id: "441900109",
    cid: "441900"
  },
  {
    name: "南城街道",
    pid: "440000",
    id: "441900004",
    cid: "441900"
  },
  {
    name: "茶山镇",
    pid: "440000",
    id: "441900103",
    cid: "441900"
  },
  {
    name: "洪梅镇",
    pid: "440000",
    id: "441900125",
    cid: "441900"
  },
  {
    name: "万江街道",
    pid: "440000",
    id: "441900005",
    cid: "441900"
  },
  {
    name: "石排镇",
    pid: "440000",
    id: "441900104",
    cid: "441900"
  },
  {
    name: "麻涌镇",
    pid: "440000",
    id: "441900126",
    cid: "441900"
  },
  {
    name: "松山湖管委会",
    pid: "440000",
    id: "441900401",
    cid: "441900"
  },
  {
    name: "莞城街道",
    pid: "440000",
    id: "441900006",
    cid: "441900"
  },
  {
    name: "企石镇",
    pid: "440000",
    id: "441900105",
    cid: "441900"
  },
  {
    name: "望牛墩镇",
    pid: "440000",
    id: "441900127",
    cid: "441900"
  },
  {
    name: "虎门港管委会",
    pid: "440000",
    id: "441900402",
    cid: "441900"
  },
  {
    name: "横沥镇",
    pid: "440000",
    id: "441900106",
    cid: "441900"
  },
  {
    name: "中堂镇",
    pid: "440000",
    id: "441900128",
    cid: "441900"
  },
  {
    name: "东莞生态园",
    pid: "440000",
    id: "441900403",
    cid: "441900"
  },
  {
    name: "虎门镇",
    pid: "440000",
    id: "441900121",
    cid: "441900"
  },
  {
    name: "厚街镇",
    pid: "440000",
    id: "441900122",
    cid: "441900"
  },
  {
    name: "石碣镇",
    pid: "440000",
    id: "441900101",
    cid: "441900"
  },
  {
    name: "沙田镇",
    pid: "440000",
    id: "441900123",
    cid: "441900"
  },
  {
    name: "东城街道",
    pid: "440000",
    id: "441900003",
    cid: "441900"
  },
  {
    name: "石龙镇",
    pid: "440000",
    id: "441900102",
    cid: "441900"
  },
  {
    name: "道滘镇",
    pid: "440000",
    id: "441900124",
    cid: "441900"
  },
  {
    name: "新会区",
    pid: "440000",
    id: "440705",
    cid: "440700"
  },
  {
    name: "江海区",
    pid: "440000",
    id: "440704",
    cid: "440700"
  },
  {
    name: "蓬江区",
    pid: "440000",
    id: "440703",
    cid: "440700"
  },
  {
    name: "恩平市",
    pid: "440000",
    id: "440785",
    cid: "440700"
  },
  {
    name: "鹤山市",
    pid: "440000",
    id: "440784",
    cid: "440700"
  },
  {
    name: "开平市",
    pid: "440000",
    id: "440783",
    cid: "440700"
  },
  {
    name: "台山市",
    pid: "440000",
    id: "440781",
    cid: "440700"
  },
  {
    name: "连南瑶族自治县",
    pid: "440000",
    id: "441826",
    cid: "441800"
  },
  {
    name: "清新区",
    pid: "440000",
    id: "441803",
    cid: "441800"
  },
  {
    name: "连山壮族瑶族自治县",
    pid: "440000",
    id: "441825",
    cid: "441800"
  },
  {
    name: "清城区",
    pid: "440000",
    id: "441802",
    cid: "441800"
  },
  {
    name: "阳山县",
    pid: "440000",
    id: "441823",
    cid: "441800"
  },
  {
    name: "佛冈县",
    pid: "440000",
    id: "441821",
    cid: "441800"
  },
  {
    name: "连州市",
    pid: "440000",
    id: "441882",
    cid: "441800"
  },
  {
    name: "英德市",
    pid: "440000",
    id: "441881",
    cid: "441800"
  },
  {
    name: "高明区",
    pid: "440000",
    id: "440608",
    cid: "440600"
  },
  {
    name: "三水区",
    pid: "440000",
    id: "440607",
    cid: "440600"
  },
  {
    name: "顺德区",
    pid: "440000",
    id: "440606",
    cid: "440600"
  },
  {
    name: "南海区",
    pid: "440000",
    id: "440605",
    cid: "440600"
  },
  {
    name: "禅城区",
    pid: "440000",
    id: "440604",
    cid: "440600"
  },
  {
    name: "阳春市",
    pid: "440000",
    id: "441781",
    cid: "441700"
  },
  {
    name: "阳东区",
    pid: "440000",
    id: "441704",
    cid: "441700"
  },
  {
    name: "江城区",
    pid: "440000",
    id: "441702",
    cid: "441700"
  },
  {
    name: "阳西县",
    pid: "440000",
    id: "441721",
    cid: "441700"
  },
  {
    name: "龙湖区",
    pid: "440000",
    id: "440507",
    cid: "440500"
  },
  {
    name: "澄海区",
    pid: "440000",
    id: "440515",
    cid: "440500"
  },
  {
    name: "潮南区",
    pid: "440000",
    id: "440514",
    cid: "440500"
  },
  {
    name: "潮阳区",
    pid: "440000",
    id: "440513",
    cid: "440500"
  },
  {
    name: "濠江区",
    pid: "440000",
    id: "440512",
    cid: "440500"
  },
  {
    name: "南澳县",
    pid: "440000",
    id: "440523",
    cid: "440500"
  },
  {
    name: "金平区",
    pid: "440000",
    id: "440511",
    cid: "440500"
  },
  {
    name: "东源县",
    pid: "440000",
    id: "441625",
    cid: "441600"
  },
  {
    name: "源城区",
    pid: "440000",
    id: "441602",
    cid: "441600"
  },
  {
    name: "和平县",
    pid: "440000",
    id: "441624",
    cid: "441600"
  },
  {
    name: "连平县",
    pid: "440000",
    id: "441623",
    cid: "441600"
  },
  {
    name: "龙川县",
    pid: "440000",
    id: "441622",
    cid: "441600"
  },
  {
    name: "紫金县",
    pid: "440000",
    id: "441621",
    cid: "441600"
  },
  {
    name: "金湾区",
    pid: "440000",
    id: "440404",
    cid: "440400"
  },
  {
    name: "斗门区",
    pid: "440000",
    id: "440403",
    cid: "440400"
  },
  {
    name: "香洲区",
    pid: "440000",
    id: "440402",
    cid: "440400"
  },
  {
    name: "陆丰市",
    pid: "440000",
    id: "441581",
    cid: "441500"
  },
  {
    name: "城区",
    pid: "440000",
    id: "441502",
    cid: "441500"
  },
  {
    name: "陆河县",
    pid: "440000",
    id: "441523",
    cid: "441500"
  },
  {
    name: "海丰县",
    pid: "440000",
    id: "441521",
    cid: "441500"
  },
  {
    name: "盐田区",
    pid: "440000",
    id: "440308",
    cid: "440300"
  },
  {
    name: "龙岗区",
    pid: "440000",
    id: "440307",
    cid: "440300"
  },
  {
    name: "宝安区",
    pid: "440000",
    id: "440306",
    cid: "440300"
  },
  {
    name: "南山区",
    pid: "440000",
    id: "440305",
    cid: "440300"
  },
  {
    name: "福田区",
    pid: "440000",
    id: "440304",
    cid: "440300"
  },
  {
    name: "罗湖区",
    pid: "440000",
    id: "440303",
    cid: "440300"
  },
  {
    name: "兴宁市",
    pid: "440000",
    id: "441481",
    cid: "441400"
  },
  {
    name: "蕉岭县",
    pid: "440000",
    id: "441427",
    cid: "441400"
  },
  {
    name: "平远县",
    pid: "440000",
    id: "441426",
    cid: "441400"
  },
  {
    name: "梅县区",
    pid: "440000",
    id: "441403",
    cid: "441400"
  },
  {
    name: "梅江区",
    pid: "440000",
    id: "441402",
    cid: "441400"
  },
  {
    name: "五华县",
    pid: "440000",
    id: "441424",
    cid: "441400"
  },
  {
    name: "丰顺县",
    pid: "440000",
    id: "441423",
    cid: "441400"
  },
  {
    name: "大埔县",
    pid: "440000",
    id: "441422",
    cid: "441400"
  },
  {
    name: "揭东区",
    pid: "440000",
    id: "445203",
    cid: "445200"
  },
  {
    name: "普宁市",
    pid: "440000",
    id: "445281",
    cid: "445200"
  },
  {
    name: "揭西县",
    pid: "440000",
    id: "445222",
    cid: "445200"
  },
  {
    name: "榕城区",
    pid: "440000",
    id: "445202",
    cid: "445200"
  },
  {
    name: "惠来县",
    pid: "440000",
    id: "445224",
    cid: "445200"
  },
  {
    name: "南雄市",
    pid: "440000",
    id: "440282",
    cid: "440200"
  },
  {
    name: "乐昌市",
    pid: "440000",
    id: "440281",
    cid: "440200"
  },
  {
    name: "翁源县",
    pid: "440000",
    id: "440229",
    cid: "440200"
  },
  {
    name: "曲江区",
    pid: "440000",
    id: "440205",
    cid: "440200"
  },
  {
    name: "浈江区",
    pid: "440000",
    id: "440204",
    cid: "440200"
  },
  {
    name: "武江区",
    pid: "440000",
    id: "440203",
    cid: "440200"
  },
  {
    name: "仁化县",
    pid: "440000",
    id: "440224",
    cid: "440200"
  },
  {
    name: "始兴县",
    pid: "440000",
    id: "440222",
    cid: "440200"
  },
  {
    name: "新丰县",
    pid: "440000",
    id: "440233",
    cid: "440200"
  },
  {
    name: "乳源瑶族自治县",
    pid: "440000",
    id: "440232",
    cid: "440200"
  },
  {
    name: "惠阳区",
    pid: "440000",
    id: "441303",
    cid: "441300"
  },
  {
    name: "惠城区",
    pid: "440000",
    id: "441302",
    cid: "441300"
  },
  {
    name: "龙门县",
    pid: "440000",
    id: "441324",
    cid: "441300"
  },
  {
    name: "惠东县",
    pid: "440000",
    id: "441323",
    cid: "441300"
  },
  {
    name: "博罗县",
    pid: "440000",
    id: "441322",
    cid: "441300"
  },
  {
    name: "饶平县",
    pid: "440000",
    id: "445122",
    cid: "445100"
  },
  {
    name: "潮安区",
    pid: "440000",
    id: "445103",
    cid: "445100"
  },
  {
    name: "湘桥区",
    pid: "440000",
    id: "445102",
    cid: "445100"
  },
  {
    name: "增城区",
    pid: "440000",
    id: "440118",
    cid: "440100"
  },
  {
    name: "天河区",
    pid: "440000",
    id: "440106",
    cid: "440100"
  },
  {
    name: "从化区",
    pid: "440000",
    id: "440117",
    cid: "440100"
  },
  {
    name: "海珠区",
    pid: "440000",
    id: "440105",
    cid: "440100"
  },
  {
    name: "越秀区",
    pid: "440000",
    id: "440104",
    cid: "440100"
  },
  {
    name: "南沙区",
    pid: "440000",
    id: "440115",
    cid: "440100"
  },
  {
    name: "荔湾区",
    pid: "440000",
    id: "440103",
    cid: "440100"
  },
  {
    name: "花都区",
    pid: "440000",
    id: "440114",
    cid: "440100"
  },
  {
    name: "番禺区",
    pid: "440000",
    id: "440113",
    cid: "440100"
  },
  {
    name: "黄埔区",
    pid: "440000",
    id: "440112",
    cid: "440100"
  },
  {
    name: "白云区",
    pid: "440000",
    id: "440111",
    cid: "440100"
  },
  {
    name: "四会市",
    pid: "440000",
    id: "441284",
    cid: "441200"
  },
  {
    name: "高要区",
    pid: "440000",
    id: "441204",
    cid: "441200"
  },
  {
    name: "德庆县",
    pid: "440000",
    id: "441226",
    cid: "441200"
  },
  {
    name: "鼎湖区",
    pid: "440000",
    id: "441203",
    cid: "441200"
  },
  {
    name: "封开县",
    pid: "440000",
    id: "441225",
    cid: "441200"
  },
  {
    name: "端州区",
    pid: "440000",
    id: "441202",
    cid: "441200"
  },
  {
    name: "怀集县",
    pid: "440000",
    id: "441224",
    cid: "441200"
  },
  {
    name: "广宁县",
    pid: "440000",
    id: "441223",
    cid: "441200"
  },
  {
    name: "三角镇",
    pid: "440000",
    id: "442000109",
    cid: "442000"
  },
  {
    name: "坦洲镇",
    pid: "440000",
    id: "442000107",
    cid: "442000"
  },
  {
    name: "港口镇",
    pid: "440000",
    id: "442000108",
    cid: "442000"
  },
  {
    name: "五桂山街道",
    pid: "440000",
    id: "442000006",
    cid: "442000"
  },
  {
    name: "古镇镇",
    pid: "440000",
    id: "442000105",
    cid: "442000"
  },
  {
    name: "大涌镇",
    pid: "440000",
    id: "442000116",
    cid: "442000"
  },
  {
    name: "沙溪镇",
    pid: "440000",
    id: "442000106",
    cid: "442000"
  },
  {
    name: "神湾镇",
    pid: "440000",
    id: "442000117",
    cid: "442000"
  },
  {
    name: "西区街道",
    pid: "440000",
    id: "442000004",
    cid: "442000"
  },
  {
    name: "东凤镇",
    pid: "440000",
    id: "442000103",
    cid: "442000"
  },
  {
    name: "三乡镇",
    pid: "440000",
    id: "442000114",
    cid: "442000"
  },
  {
    name: "南区街道",
    pid: "440000",
    id: "442000005",
    cid: "442000"
  },
  {
    name: "东升镇",
    pid: "440000",
    id: "442000104",
    cid: "442000"
  },
  {
    name: "板芙镇",
    pid: "440000",
    id: "442000115",
    cid: "442000"
  },
  {
    name: "东区街道",
    pid: "440000",
    id: "442000002",
    cid: "442000"
  },
  {
    name: "黄圃镇",
    pid: "440000",
    id: "442000101",
    cid: "442000"
  },
  {
    name: "阜沙镇",
    pid: "440000",
    id: "442000112",
    cid: "442000"
  },
  {
    name: "火炬开发区街道",
    pid: "440000",
    id: "442000003",
    cid: "442000"
  },
  {
    name: "民众镇",
    pid: "440000",
    id: "442000102",
    cid: "442000"
  },
  {
    name: "南朗镇",
    pid: "440000",
    id: "442000113",
    cid: "442000"
  },
  {
    name: "横栏镇",
    pid: "440000",
    id: "442000110",
    cid: "442000"
  },
  {
    name: "石岐区街道",
    pid: "440000",
    id: "442000001",
    cid: "442000"
  },
  {
    name: "小榄镇",
    pid: "440000",
    id: "442000100",
    cid: "442000"
  },
  {
    name: "南头镇",
    pid: "440000",
    id: "442000111",
    cid: "442000"
  },
  {
    name: "云安区",
    pid: "440000",
    id: "445303",
    cid: "445300"
  },
  {
    name: "云城区",
    pid: "440000",
    id: "445302",
    cid: "445300"
  },
  {
    name: "罗定市",
    pid: "440000",
    id: "445381",
    cid: "445300"
  },
  {
    name: "新兴县",
    pid: "440000",
    id: "445321",
    cid: "445300"
  },
  {
    name: "郁南县",
    pid: "440000",
    id: "445322",
    cid: "445300"
  },
  {
    name: "曲水镇",
    pid: "500000",
    id: "500228127",
    cid: "500228"
  },
  {
    name: "聚奎镇",
    pid: "500000",
    id: "50022811",
    cid: "500228"
  },
  {
    name: "屏锦镇",
    pid: "500000",
    id: "500228104",
    cid: "500228"
  },
  {
    name: "星桥镇",
    pid: "500000",
    id: "500228126",
    cid: "500228"
  },
  {
    name: "复平乡",
    pid: "500000",
    id: "500228203",
    cid: "500228"
  },
  {
    name: "梁平县双桂工业园区",
    pid: "500000",
    id: "500228401",
    cid: "500228"
  },
  {
    name: "云龙镇",
    pid: "500000",
    id: "500228103",
    cid: "500228"
  },
  {
    name: "蟠龙镇",
    pid: "500000",
    id: "500228125",
    cid: "500228"
  },
  {
    name: "龙胜乡",
    pid: "500000",
    id: "500228202",
    cid: "500228"
  },
  {
    name: "礼让镇",
    pid: "500000",
    id: "500228102",
    cid: "500228"
  },
  {
    name: "竹山镇",
    pid: "500000",
    id: "500228124",
    cid: "500228"
  },
  {
    name: "铁门乡",
    pid: "500000",
    id: "500228201",
    cid: "500228"
  },
  {
    name: "金带镇",
    pid: "500000",
    id: "500228109",
    cid: "500228"
  },
  {
    name: "福禄镇",
    pid: "500000",
    id: "500228108",
    cid: "500228"
  },
  {
    name: "合兴镇",
    pid: "500000",
    id: "50022812",
    cid: "500228"
  },
  {
    name: "新盛镇",
    pid: "500000",
    id: "500228107",
    cid: "500228"
  },
  {
    name: "袁驿镇",
    pid: "500000",
    id: "500228106",
    cid: "500228"
  },
  {
    name: "紫照乡",
    pid: "500000",
    id: "500228205",
    cid: "500228"
  },
  {
    name: "梁平县双桂街道",
    pid: "500000",
    id: "500228002",
    cid: "500228"
  },
  {
    name: "仁贤镇",
    pid: "500000",
    id: "500228101",
    cid: "500228"
  },
  {
    name: "大观镇",
    pid: "500000",
    id: "500228123",
    cid: "500228"
  },
  {
    name: "梁平县梁山街道",
    pid: "500000",
    id: "500228001",
    cid: "500228"
  },
  {
    name: "柏家镇",
    pid: "500000",
    id: "500228122",
    cid: "500228"
  },
  {
    name: "石安镇",
    pid: "500000",
    id: "500228121",
    cid: "500228"
  },
  {
    name: "安胜乡",
    pid: "500000",
    id: "5002282",
    cid: "500228"
  },
  {
    name: "虎城镇",
    pid: "500000",
    id: "500228116",
    cid: "500228"
  },
  {
    name: "碧山镇",
    pid: "500000",
    id: "500228115",
    cid: "500228"
  },
  {
    name: "回龙镇",
    pid: "500000",
    id: "500228114",
    cid: "500228"
  },
  {
    name: "和林镇",
    pid: "500000",
    id: "500228113",
    cid: "500228"
  },
  {
    name: "文化镇",
    pid: "500000",
    id: "500228119",
    cid: "500228"
  },
  {
    name: "龙门镇",
    pid: "500000",
    id: "500228118",
    cid: "500228"
  },
  {
    name: "七星镇",
    pid: "500000",
    id: "500228117",
    cid: "500228"
  },
  {
    name: "荫平镇",
    pid: "500000",
    id: "500228112",
    cid: "500228"
  },
  {
    name: "明达镇",
    pid: "500000",
    id: "500228111",
    cid: "500228"
  },
  {
    name: "梁平县农场",
    pid: "500000",
    id: "5002284",
    cid: "500228"
  },
  {
    name: "塘坊镇",
    pid: "500000",
    id: "500238111",
    cid: "500238"
  },
  {
    name: "长桂乡",
    pid: "500000",
    id: "50023821",
    cid: "500238"
  },
  {
    name: "双阳乡",
    pid: "500000",
    id: "50023824",
    cid: "500238"
  },
  {
    name: "田坝镇",
    pid: "500000",
    id: "500238113",
    cid: "500238"
  },
  {
    name: "中岗乡",
    pid: "500000",
    id: "500238234",
    cid: "500238"
  },
  {
    name: "朝阳镇",
    pid: "500000",
    id: "500238112",
    cid: "500238"
  },
  {
    name: "菱角镇",
    pid: "500000",
    id: "500238115",
    cid: "500238"
  },
  {
    name: "通城镇",
    pid: "500000",
    id: "500238114",
    cid: "500238"
  },
  {
    name: "土城镇",
    pid: "500000",
    id: "500238117",
    cid: "500238"
  },
  {
    name: "蒲莲镇",
    pid: "500000",
    id: "500238116",
    cid: "500238"
  },
  {
    name: "花台乡",
    pid: "500000",
    id: "500238237",
    cid: "500238"
  },
  {
    name: "兰英乡",
    pid: "500000",
    id: "500238239",
    cid: "500238"
  },
  {
    name: "城厢镇",
    pid: "500000",
    id: "5002381",
    cid: "500238"
  },
  {
    name: "宁河街道",
    pid: "500000",
    id: "500238001",
    cid: "500238"
  },
  {
    name: "天元乡",
    pid: "500000",
    id: "500238243",
    cid: "500238"
  },
  {
    name: "中梁乡",
    pid: "500000",
    id: "500238242",
    cid: "500238"
  },
  {
    name: "峰灵镇",
    pid: "500000",
    id: "50023811",
    cid: "500238"
  },
  {
    name: "下堡镇",
    pid: "500000",
    id: "500238109",
    cid: "500238"
  },
  {
    name: "天星乡",
    pid: "500000",
    id: "500238208",
    cid: "500238"
  },
  {
    name: "宁厂镇",
    pid: "500000",
    id: "500238102",
    cid: "500238"
  },
  {
    name: "柏杨街道",
    pid: "500000",
    id: "500238002",
    cid: "500238"
  },
  {
    name: "凤凰镇",
    pid: "500000",
    id: "500238101",
    cid: "500238"
  },
  {
    name: "古路镇",
    pid: "500000",
    id: "500238104",
    cid: "500238"
  },
  {
    name: "上磺镇",
    pid: "500000",
    id: "500238103",
    cid: "500238"
  },
  {
    name: "红池坝经济开发区",
    pid: "500000",
    id: "5002384",
    cid: "500238"
  },
  {
    name: "徐家镇",
    pid: "500000",
    id: "500238106",
    cid: "500238"
  },
  {
    name: "乌龙乡",
    pid: "500000",
    id: "500238227",
    cid: "500238"
  },
  {
    name: "文峰镇",
    pid: "500000",
    id: "500238105",
    cid: "500238"
  },
  {
    name: "胜利乡",
    pid: "500000",
    id: "500238204",
    cid: "500238"
  },
  {
    name: "鱼鳞乡",
    pid: "500000",
    id: "500238226",
    cid: "500238"
  },
  {
    name: "尖山镇",
    pid: "500000",
    id: "500238108",
    cid: "500238"
  },
  {
    name: "大河乡",
    pid: "500000",
    id: "500238207",
    cid: "500238"
  },
  {
    name: "白鹿镇",
    pid: "500000",
    id: "500238107",
    cid: "500238"
  },
  {
    name: "建坪乡",
    pid: "500000",
    id: "50023721",
    cid: "500237"
  },
  {
    name: "官渡镇",
    pid: "500000",
    id: "500237109",
    cid: "500237"
  },
  {
    name: "曲尺乡",
    pid: "500000",
    id: "500237208",
    cid: "500237"
  },
  {
    name: "抱龙镇",
    pid: "500000",
    id: "500237108",
    cid: "500237"
  },
  {
    name: "两坪乡",
    pid: "500000",
    id: "500237207",
    cid: "500237"
  },
  {
    name: "笃坪乡",
    pid: "500000",
    id: "500237229",
    cid: "500237"
  },
  {
    name: "龙门街道",
    pid: "500000",
    id: "500237002",
    cid: "500237"
  },
  {
    name: "庙宇镇",
    pid: "500000",
    id: "500237101",
    cid: "500237"
  },
  {
    name: "竹贤乡",
    pid: "500000",
    id: "500237222",
    cid: "500237"
  },
  {
    name: "高唐街道",
    pid: "500000",
    id: "500237001",
    cid: "500237"
  },
  {
    name: "福田镇",
    pid: "500000",
    id: "500237103",
    cid: "500237"
  },
  {
    name: "大昌镇",
    pid: "500000",
    id: "500237102",
    cid: "500237"
  },
  {
    name: "双龙镇",
    pid: "500000",
    id: "500237105",
    cid: "500237"
  },
  {
    name: "龙溪镇",
    pid: "500000",
    id: "500237104",
    cid: "500237"
  },
  {
    name: "三溪乡",
    pid: "500000",
    id: "500237225",
    cid: "500237"
  },
  {
    name: "骡坪镇",
    pid: "500000",
    id: "500237107",
    cid: "500237"
  },
  {
    name: "红椿乡",
    pid: "500000",
    id: "5002372",
    cid: "500237"
  },
  {
    name: "官阳镇",
    pid: "500000",
    id: "500237106",
    cid: "500237"
  },
  {
    name: "培石乡",
    pid: "500000",
    id: "500237227",
    cid: "500237"
  },
  {
    name: "邓家乡",
    pid: "500000",
    id: "500237231",
    cid: "500237"
  },
  {
    name: "铜鼓镇",
    pid: "500000",
    id: "50023711",
    cid: "500237"
  },
  {
    name: "当阳乡",
    pid: "500000",
    id: "500237219",
    cid: "500237"
  },
  {
    name: "大溪乡",
    pid: "500000",
    id: "500237211",
    cid: "500237"
  },
  {
    name: "巫峡镇",
    pid: "500000",
    id: "500237111",
    cid: "500237"
  },
  {
    name: "金坪乡",
    pid: "500000",
    id: "500237214",
    cid: "500237"
  },
  {
    name: "平河乡",
    pid: "500000",
    id: "500237216",
    cid: "500237"
  },
  {
    name: "太和土家族乡",
    pid: "500000",
    id: "500236272",
    cid: "500236"
  },
  {
    name: "永乐镇",
    pid: "500000",
    id: "500236131",
    cid: "500236"
  },
  {
    name: "鹤峰乡",
    pid: "500000",
    id: "500236274",
    cid: "500236"
  },
  {
    name: "康坪乡",
    pid: "500000",
    id: "50023627",
    cid: "500236"
  },
  {
    name: "草堂镇",
    pid: "500000",
    id: "500236118",
    cid: "500236"
  },
  {
    name: "汾河镇",
    pid: "500000",
    id: "500236119",
    cid: "500236"
  },
  {
    name: "安坪镇",
    pid: "500000",
    id: "500236132",
    cid: "500236"
  },
  {
    name: "冯坪乡",
    pid: "500000",
    id: "500236275",
    cid: "500236"
  },
  {
    name: "五马镇",
    pid: "500000",
    id: "500236133",
    cid: "500236"
  },
  {
    name: "长安土家族乡",
    pid: "500000",
    id: "500236276",
    cid: "500236"
  },
  {
    name: "青莲镇",
    pid: "500000",
    id: "500236134",
    cid: "500236"
  },
  {
    name: "龙桥土家族乡",
    pid: "500000",
    id: "500236277",
    cid: "500236"
  },
  {
    name: "云雾土家族乡",
    pid: "500000",
    id: "500236278",
    cid: "500236"
  },
  {
    name: "白帝镇",
    pid: "500000",
    id: "500236117",
    cid: "500236"
  },
  {
    name: "新民镇",
    pid: "500000",
    id: "50023613",
    cid: "500236"
  },
  {
    name: "康乐镇",
    pid: "500000",
    id: "50023612",
    cid: "500236"
  },
  {
    name: "青龙镇",
    pid: "500000",
    id: "500236129",
    cid: "500236"
  },
  {
    name: "大树镇",
    pid: "500000",
    id: "500236121",
    cid: "500236"
  },
  {
    name: "永安街道",
    pid: "500000",
    id: "500236001",
    cid: "500236"
  },
  {
    name: "竹园镇",
    pid: "500000",
    id: "500236122",
    cid: "500236"
  },
  {
    name: "岩湾乡",
    pid: "500000",
    id: "500236265",
    cid: "500236"
  },
  {
    name: "鱼复街道",
    pid: "500000",
    id: "500236002",
    cid: "500236"
  },
  {
    name: "公平镇",
    pid: "500000",
    id: "500236123",
    cid: "500236"
  },
  {
    name: "平安乡",
    pid: "500000",
    id: "500236266",
    cid: "500236"
  },
  {
    name: "夔门街道",
    pid: "500000",
    id: "500236003",
    cid: "500236"
  },
  {
    name: "朱衣镇",
    pid: "500000",
    id: "500236124",
    cid: "500236"
  },
  {
    name: "红土乡",
    pid: "500000",
    id: "500236267",
    cid: "500236"
  },
  {
    name: "甲高镇",
    pid: "500000",
    id: "500236125",
    cid: "500236"
  },
  {
    name: "羊市镇",
    pid: "500000",
    id: "500236126",
    cid: "500236"
  },
  {
    name: "石岗乡",
    pid: "500000",
    id: "500236269",
    cid: "500236"
  },
  {
    name: "吐祥镇",
    pid: "500000",
    id: "500236127",
    cid: "500236"
  },
  {
    name: "兴隆镇",
    pid: "500000",
    id: "500236128",
    cid: "500236"
  },
  {
    name: "治平乡",
    pid: "500000",
    id: "500229217",
    cid: "500229"
  },
  {
    name: "岚天乡",
    pid: "500000",
    id: "500229219",
    cid: "500229"
  },
  {
    name: "周溪乡",
    pid: "500000",
    id: "500229214",
    cid: "500229"
  },
  {
    name: "咸宜镇",
    pid: "500000",
    id: "50022911",
    cid: "500229"
  },
  {
    name: "明中乡",
    pid: "500000",
    id: "500229216",
    cid: "500229"
  },
  {
    name: "高楠镇",
    pid: "500000",
    id: "500229111",
    cid: "500229"
  },
  {
    name: "蓼子乡",
    pid: "500000",
    id: "500229211",
    cid: "500229"
  },
  {
    name: "鸡鸣乡",
    pid: "500000",
    id: "500229212",
    cid: "500229"
  },
  {
    name: "高观镇",
    pid: "500000",
    id: "500229107",
    cid: "500229"
  },
  {
    name: "双河乡",
    pid: "500000",
    id: "50022921",
    cid: "500229"
  },
  {
    name: "高燕镇",
    pid: "500000",
    id: "500229108",
    cid: "500229"
  },
  {
    name: "东安镇",
    pid: "500000",
    id: "500229109",
    cid: "500229"
  },
  {
    name: "沿河乡",
    pid: "500000",
    id: "500229208",
    cid: "500229"
  },
  {
    name: "坪坝镇",
    pid: "500000",
    id: "500229103",
    cid: "500229"
  },
  {
    name: "北屏乡",
    pid: "500000",
    id: "500229202",
    cid: "500229"
  },
  {
    name: "庙坝镇",
    pid: "500000",
    id: "500229104",
    cid: "500229"
  },
  {
    name: "厚坪乡",
    pid: "500000",
    id: "50022922",
    cid: "500229"
  },
  {
    name: "明通镇",
    pid: "500000",
    id: "500229105",
    cid: "500229"
  },
  {
    name: "修齐镇",
    pid: "500000",
    id: "500229106",
    cid: "500229"
  },
  {
    name: "左岚乡",
    pid: "500000",
    id: "500229205",
    cid: "500229"
  },
  {
    name: "葛城街道",
    pid: "500000",
    id: "500229001",
    cid: "500229"
  },
  {
    name: "河鱼乡",
    pid: "500000",
    id: "500229221",
    cid: "500229"
  },
  {
    name: "复兴街道",
    pid: "500000",
    id: "500229002",
    cid: "500229"
  },
  {
    name: "巴山镇",
    pid: "500000",
    id: "500229102",
    cid: "500229"
  },
  {
    name: "龙田乡",
    pid: "500000",
    id: "500229201",
    cid: "500229"
  },
  {
    name: "桂溪街道",
    pid: "500000",
    id: "500231001",
    cid: "500231"
  },
  {
    name: "长龙镇",
    pid: "500000",
    id: "500231122",
    cid: "500231"
  },
  {
    name: "太平镇",
    pid: "500000",
    id: "50023111",
    cid: "500231"
  },
  {
    name: "桂阳街道",
    pid: "500000",
    id: "500231002",
    cid: "500231"
  },
  {
    name: "新民镇",
    pid: "500000",
    id: "500231101",
    cid: "500231"
  },
  {
    name: "黄沙镇",
    pid: "500000",
    id: "500231121",
    cid: "500231"
  },
  {
    name: "裴兴镇",
    pid: "500000",
    id: "50023112",
    cid: "500231"
  },
  {
    name: "五洞镇",
    pid: "500000",
    id: "500231108",
    cid: "500231"
  },
  {
    name: "澄溪镇",
    pid: "500000",
    id: "500231109",
    cid: "500231"
  },
  {
    name: "高安镇",
    pid: "500000",
    id: "500231106",
    cid: "500231"
  },
  {
    name: "高峰镇",
    pid: "500000",
    id: "500231107",
    cid: "500231"
  },
  {
    name: "普顺镇",
    pid: "500000",
    id: "500231104",
    cid: "500231"
  },
  {
    name: "永安镇",
    pid: "500000",
    id: "500231105",
    cid: "500231"
  },
  {
    name: "大石乡",
    pid: "500000",
    id: "500231204",
    cid: "500231"
  },
  {
    name: "沙坪镇",
    pid: "500000",
    id: "500231102",
    cid: "500231"
  },
  {
    name: "周嘉镇",
    pid: "500000",
    id: "500231103",
    cid: "500231"
  },
  {
    name: "沙河乡",
    pid: "500000",
    id: "500231202",
    cid: "500231"
  },
  {
    name: "鹤游镇",
    pid: "500000",
    id: "500231111",
    cid: "500231"
  },
  {
    name: "坪山镇",
    pid: "500000",
    id: "500231112",
    cid: "500231"
  },
  {
    name: "三溪镇",
    pid: "500000",
    id: "500231119",
    cid: "500231"
  },
  {
    name: "白家镇",
    pid: "500000",
    id: "500231117",
    cid: "500231"
  },
  {
    name: "永平镇",
    pid: "500000",
    id: "500231118",
    cid: "500231"
  },
  {
    name: "杠家镇",
    pid: "500000",
    id: "500231115",
    cid: "500231"
  },
  {
    name: "包家镇",
    pid: "500000",
    id: "500231116",
    cid: "500231"
  },
  {
    name: "砚台镇",
    pid: "500000",
    id: "500231113",
    cid: "500231"
  },
  {
    name: "曹回镇",
    pid: "500000",
    id: "500231114",
    cid: "500231"
  },
  {
    name: "苍岭镇",
    pid: "500000",
    id: "500242112",
    cid: "500242"
  },
  {
    name: "天馆乡",
    pid: "500000",
    id: "500242211",
    cid: "500242"
  },
  {
    name: "酉水河镇",
    pid: "500000",
    id: "500242111",
    cid: "500242"
  },
  {
    name: "南腰界乡",
    pid: "500000",
    id: "500242217",
    cid: "500242"
  },
  {
    name: "官清乡",
    pid: "500000",
    id: "500242216",
    cid: "500242"
  },
  {
    name: "腴地乡",
    pid: "500000",
    id: "500242219",
    cid: "500242"
  },
  {
    name: "车田乡",
    pid: "500000",
    id: "500242218",
    cid: "500242"
  },
  {
    name: "板溪镇",
    pid: "500000",
    id: "500242114",
    cid: "500242"
  },
  {
    name: "万木乡",
    pid: "500000",
    id: "500242213",
    cid: "500242"
  },
  {
    name: "小河镇",
    pid: "500000",
    id: "500242113",
    cid: "500242"
  },
  {
    name: "宜居乡",
    pid: "500000",
    id: "500242212",
    cid: "500242"
  },
  {
    name: "板桥乡",
    pid: "500000",
    id: "500242215",
    cid: "500242"
  },
  {
    name: "两罾乡",
    pid: "500000",
    id: "500242214",
    cid: "500242"
  },
  {
    name: "清泉乡",
    pid: "500000",
    id: "50024222",
    cid: "500242"
  },
  {
    name: "后坪乡",
    pid: "500000",
    id: "50024221",
    cid: "500242"
  },
  {
    name: "花田乡",
    pid: "500000",
    id: "500242209",
    cid: "500242"
  },
  {
    name: "钟多街道",
    pid: "500000",
    id: "500242002",
    cid: "500242"
  },
  {
    name: "龙潭镇",
    pid: "500000",
    id: "500242101",
    cid: "500242"
  },
  {
    name: "浪坪乡",
    pid: "500000",
    id: "500242222",
    cid: "500242"
  },
  {
    name: "桃花源街道",
    pid: "500000",
    id: "500242001",
    cid: "500242"
  },
  {
    name: "庙溪乡",
    pid: "500000",
    id: "500242221",
    cid: "500242"
  },
  {
    name: "丁市镇",
    pid: "500000",
    id: "500242107",
    cid: "500242"
  },
  {
    name: "五福乡",
    pid: "500000",
    id: "500242206",
    cid: "500242"
  },
  {
    name: "黑水镇",
    pid: "500000",
    id: "500242106",
    cid: "500242"
  },
  {
    name: "偏柏乡",
    pid: "500000",
    id: "500242205",
    cid: "500242"
  },
  {
    name: "李溪镇",
    pid: "500000",
    id: "500242109",
    cid: "500242"
  },
  {
    name: "毛坝乡",
    pid: "500000",
    id: "500242208",
    cid: "500242"
  },
  {
    name: "龚滩镇",
    pid: "500000",
    id: "500242108",
    cid: "500242"
  },
  {
    name: "木叶乡",
    pid: "500000",
    id: "500242207",
    cid: "500242"
  },
  {
    name: "酉酬镇",
    pid: "500000",
    id: "500242103",
    cid: "500242"
  },
  {
    name: "铜鼓乡",
    pid: "500000",
    id: "500242202",
    cid: "500242"
  },
  {
    name: "楠木乡",
    pid: "500000",
    id: "500242224",
    cid: "500242"
  },
  {
    name: "麻旺镇",
    pid: "500000",
    id: "500242102",
    cid: "500242"
  },
  {
    name: "双泉乡",
    pid: "500000",
    id: "500242223",
    cid: "500242"
  },
  {
    name: "兴隆镇",
    pid: "500000",
    id: "500242105",
    cid: "500242"
  },
  {
    name: "可大乡",
    pid: "500000",
    id: "500242204",
    cid: "500242"
  },
  {
    name: "大溪镇",
    pid: "500000",
    id: "500242104",
    cid: "500242"
  },
  {
    name: "泔溪镇",
    pid: "500000",
    id: "50024211",
    cid: "500242"
  },
  {
    name: "涂市乡",
    pid: "500000",
    id: "5002422",
    cid: "500242"
  },
  {
    name: "高家镇",
    pid: "500000",
    id: "500230109",
    cid: "500230"
  },
  {
    name: "栗子乡",
    pid: "500000",
    id: "500230209",
    cid: "500230"
  },
  {
    name: "兴义镇",
    pid: "500000",
    id: "50023011",
    cid: "500230"
  },
  {
    name: "名山街道",
    pid: "500000",
    id: "500230",
    cid: "500230"
  },
  {
    name: "兴龙镇",
    pid: "500000",
    id: "50023012",
    cid: "500230"
  },
  {
    name: "双路镇",
    pid: "500000",
    id: "500230111",
    cid: "500230"
  },
  {
    name: "南天湖镇",
    pid: "500000",
    id: "500230118",
    cid: "500230"
  },
  {
    name: "保合镇",
    pid: "500000",
    id: "500230119",
    cid: "500230"
  },
  {
    name: "湛普镇",
    pid: "500000",
    id: "500230116",
    cid: "500230"
  },
  {
    name: "武平镇",
    pid: "500000",
    id: "500230114",
    cid: "500230"
  },
  {
    name: "包鸾镇",
    pid: "500000",
    id: "500230115",
    cid: "500230"
  },
  {
    name: "江池镇",
    pid: "500000",
    id: "500230112",
    cid: "500230"
  },
  {
    name: "龙河镇",
    pid: "500000",
    id: "500230113",
    cid: "500230"
  },
  {
    name: "仁沙镇",
    pid: "500000",
    id: "500230121",
    cid: "500230"
  },
  {
    name: "龙孔镇",
    pid: "500000",
    id: "500230122",
    cid: "500230"
  },
  {
    name: "三建乡",
    pid: "500000",
    id: "50023021",
    cid: "500230"
  },
  {
    name: "十直镇",
    pid: "500000",
    id: "500230107",
    cid: "500230"
  },
  {
    name: "太平坝乡",
    pid: "500000",
    id: "500230206",
    cid: "500230"
  },
  {
    name: "都督乡",
    pid: "500000",
    id: "500230207",
    cid: "500230"
  },
  {
    name: "董家镇",
    pid: "500000",
    id: "500230105",
    cid: "500230"
  },
  {
    name: "树人镇",
    pid: "500000",
    id: "500230106",
    cid: "500230"
  },
  {
    name: "三元镇",
    pid: "500000",
    id: "500230103",
    cid: "500230"
  },
  {
    name: "仙女湖镇",
    pid: "500000",
    id: "500230125",
    cid: "500230"
  },
  {
    name: "青龙乡",
    pid: "500000",
    id: "500230202",
    cid: "500230"
  },
  {
    name: "许明寺镇",
    pid: "500000",
    id: "500230104",
    cid: "500230"
  },
  {
    name: "虎威镇",
    pid: "500000",
    id: "500230101",
    cid: "500230"
  },
  {
    name: "暨龙镇",
    pid: "500000",
    id: "500230123",
    cid: "500230"
  },
  {
    name: "社坛镇",
    pid: "500000",
    id: "500230102",
    cid: "500230"
  },
  {
    name: "双龙镇",
    pid: "500000",
    id: "500230124",
    cid: "500230"
  },
  {
    name: "中和街道",
    pid: "500000",
    id: "500241001",
    cid: "500241"
  },
  {
    name: "官庄镇",
    pid: "500000",
    id: "500241105",
    cid: "500241"
  },
  {
    name: "龙池镇",
    pid: "500000",
    id: "500241106",
    cid: "500241"
  },
  {
    name: "石堤镇",
    pid: "500000",
    id: "500241107",
    cid: "500241"
  },
  {
    name: "峨溶镇",
    pid: "500000",
    id: "500241108",
    cid: "500241"
  },
  {
    name: "海洋乡",
    pid: "500000",
    id: "500241207",
    cid: "500241"
  },
  {
    name: "乌杨街道",
    pid: "500000",
    id: "500241002",
    cid: "500241"
  },
  {
    name: "平凯街道",
    pid: "500000",
    id: "500241003",
    cid: "500241"
  },
  {
    name: "清溪场镇",
    pid: "500000",
    id: "500241102",
    cid: "500241"
  },
  {
    name: "孝溪乡",
    pid: "500000",
    id: "500241201",
    cid: "500241"
  },
  {
    name: "隘口镇",
    pid: "500000",
    id: "500241103",
    cid: "500241"
  },
  {
    name: "溶溪镇",
    pid: "500000",
    id: "500241104",
    cid: "500241"
  },
  {
    name: "石耶镇",
    pid: "500000",
    id: "500241111",
    cid: "500241"
  },
  {
    name: "妙泉镇",
    pid: "500000",
    id: "500241116",
    cid: "500241"
  },
  {
    name: "岑溪乡",
    pid: "500000",
    id: "500241215",
    cid: "500241"
  },
  {
    name: "宋农镇",
    pid: "500000",
    id: "500241117",
    cid: "500241"
  },
  {
    name: "里仁镇",
    pid: "500000",
    id: "500241118",
    cid: "500241"
  },
  {
    name: "钟灵镇",
    pid: "500000",
    id: "500241119",
    cid: "500241"
  },
  {
    name: "梅江镇",
    pid: "500000",
    id: "500241112",
    cid: "500241"
  },
  {
    name: "涌洞乡",
    pid: "500000",
    id: "500241211",
    cid: "500241"
  },
  {
    name: "兰桥镇",
    pid: "500000",
    id: "500241113",
    cid: "500241"
  },
  {
    name: "膏田镇",
    pid: "500000",
    id: "500241114",
    cid: "500241"
  },
  {
    name: "溪口镇",
    pid: "500000",
    id: "500241115",
    cid: "500241"
  },
  {
    name: "中平乡",
    pid: "500000",
    id: "500241214",
    cid: "500241"
  },
  {
    name: "雅江镇",
    pid: "500000",
    id: "50024111",
    cid: "500241"
  },
  {
    name: "洪安镇",
    pid: "500000",
    id: "500241109",
    cid: "500241"
  },
  {
    name: "大溪乡",
    pid: "500000",
    id: "500241208",
    cid: "500241"
  },
  {
    name: "万朝镇",
    pid: "500000",
    id: "500240115",
    cid: "500240"
  },
  {
    name: "洗新乡",
    pid: "500000",
    id: "500240214",
    cid: "500240"
  },
  {
    name: "冷水镇",
    pid: "500000",
    id: "500240116",
    cid: "500240"
  },
  {
    name: "黄鹤镇",
    pid: "500000",
    id: "500240117",
    cid: "500240"
  },
  {
    name: "龙潭乡",
    pid: "500000",
    id: "500240216",
    cid: "500240"
  },
  {
    name: "新乐乡",
    pid: "500000",
    id: "500240217",
    cid: "500240"
  },
  {
    name: "鱼池镇",
    pid: "500000",
    id: "500240111",
    cid: "500240"
  },
  {
    name: "三河镇",
    pid: "500000",
    id: "500240112",
    cid: "500240"
  },
  {
    name: "大歇镇",
    pid: "500000",
    id: "500240113",
    cid: "500240"
  },
  {
    name: "枫木乡",
    pid: "500000",
    id: "500240212",
    cid: "500240"
  },
  {
    name: "桥头镇",
    pid: "500000",
    id: "500240114",
    cid: "500240"
  },
  {
    name: "中益乡",
    pid: "500000",
    id: "500240213",
    cid: "500240"
  },
  {
    name: "石家乡",
    pid: "500000",
    id: "50024021",
    cid: "500240"
  },
  {
    name: "王场镇",
    pid: "500000",
    id: "500240108",
    cid: "500240"
  },
  {
    name: "三益乡",
    pid: "500000",
    id: "500240207",
    cid: "500240"
  },
  {
    name: "沿溪镇",
    pid: "500000",
    id: "500240109",
    cid: "500240"
  },
  {
    name: "王家乡",
    pid: "500000",
    id: "500240208",
    cid: "500240"
  },
  {
    name: "河嘴乡",
    pid: "500000",
    id: "500240209",
    cid: "500240"
  },
  {
    name: "临溪镇",
    pid: "500000",
    id: "500240104",
    cid: "500240"
  },
  {
    name: "黎场乡",
    pid: "500000",
    id: "500240203",
    cid: "500240"
  },
  {
    name: "黄水镇",
    pid: "500000",
    id: "500240105",
    cid: "500240"
  },
  {
    name: "三星乡",
    pid: "500000",
    id: "500240204",
    cid: "500240"
  },
  {
    name: "马武镇",
    pid: "500000",
    id: "500240106",
    cid: "500240"
  },
  {
    name: "六塘乡",
    pid: "500000",
    id: "500240205",
    cid: "500240"
  },
  {
    name: "沙子镇",
    pid: "500000",
    id: "500240107",
    cid: "500240"
  },
  {
    name: "西沱镇",
    pid: "500000",
    id: "500240101",
    cid: "500240"
  },
  {
    name: "悦崃镇",
    pid: "500000",
    id: "500240103",
    cid: "500240"
  },
  {
    name: "下路街道",
    pid: "500000",
    id: "500240",
    cid: "500240"
  },
  {
    name: "龙沙镇",
    pid: "500000",
    id: "50024011",
    cid: "500240"
  },
  {
    name: "金铃乡",
    pid: "500000",
    id: "500240218",
    cid: "500240"
  },
  {
    name: "金竹乡",
    pid: "500000",
    id: "500240219",
    cid: "500240"
  },
  {
    name: "养鹿镇",
    pid: "500000",
    id: "500235141",
    cid: "500235"
  },
  {
    name: "南溪镇",
    pid: "500000",
    id: "500235128",
    cid: "500235"
  },
  {
    name: "故陵镇",
    pid: "500000",
    id: "500235107",
    cid: "500235"
  },
  {
    name: "双土镇",
    pid: "500000",
    id: "500235129",
    cid: "500235"
  },
  {
    name: "红狮镇",
    pid: "500000",
    id: "500235108",
    cid: "500235"
  },
  {
    name: "外郎乡",
    pid: "500000",
    id: "500235208",
    cid: "500235"
  },
  {
    name: "水口镇",
    pid: "500000",
    id: "500235142",
    cid: "500235"
  },
  {
    name: "黄石镇",
    pid: "500000",
    id: "500235121",
    cid: "500235"
  },
  {
    name: "堰坪镇",
    pid: "500000",
    id: "500235143",
    cid: "500235"
  },
  {
    name: "清水土家族自治乡",
    pid: "500000",
    id: "500235242",
    cid: "500235"
  },
  {
    name: "双江街道",
    pid: "500000",
    id: "500235001",
    cid: "500235"
  },
  {
    name: "巴阳镇",
    pid: "500000",
    id: "500235122",
    cid: "500235"
  },
  {
    name: "龙洞镇",
    pid: "500000",
    id: "500235144",
    cid: "500235"
  },
  {
    name: "青龙街道",
    pid: "500000",
    id: "500235002",
    cid: "500235"
  },
  {
    name: "沙市镇",
    pid: "500000",
    id: "500235123",
    cid: "500235"
  },
  {
    name: "后叶镇",
    pid: "500000",
    id: "500235145",
    cid: "500235"
  },
  {
    name: "人和街道",
    pid: "500000",
    id: "500235003",
    cid: "500235"
  },
  {
    name: "鱼泉镇",
    pid: "500000",
    id: "500235124",
    cid: "500235"
  },
  {
    name: "耀灵镇",
    pid: "500000",
    id: "500235146",
    cid: "500235"
  },
  {
    name: "盘龙街道",
    pid: "500000",
    id: "500235004",
    cid: "500235"
  },
  {
    name: "凤鸣镇",
    pid: "500000",
    id: "500235125",
    cid: "500235"
  },
  {
    name: "大阳镇",
    pid: "500000",
    id: "500235147",
    cid: "500235"
  },
  {
    name: "龙角镇",
    pid: "500000",
    id: "500235105",
    cid: "500235"
  },
  {
    name: "宝坪镇",
    pid: "500000",
    id: "500235127",
    cid: "500235"
  },
  {
    name: "蔈草镇",
    pid: "500000",
    id: "50023514",
    cid: "500235"
  },
  {
    name: "桑坪镇",
    pid: "500000",
    id: "50023513",
    cid: "500235"
  },
  {
    name: "泥溪镇",
    pid: "500000",
    id: "500235139",
    cid: "500235"
  },
  {
    name: "普安乡",
    pid: "500000",
    id: "500235216",
    cid: "500235"
  },
  {
    name: "渠马镇",
    pid: "500000",
    id: "500235118",
    cid: "500235"
  },
  {
    name: "上坝乡",
    pid: "500000",
    id: "500235239",
    cid: "500235"
  },
  {
    name: "洞鹿乡",
    pid: "500000",
    id: "500235218",
    cid: "500235"
  },
  {
    name: "石门乡",
    pid: "500000",
    id: "500235219",
    cid: "500235"
  },
  {
    name: "江口镇",
    pid: "500000",
    id: "500235131",
    cid: "500235"
  },
  {
    name: "高阳镇",
    pid: "500000",
    id: "500235132",
    cid: "500235"
  },
  {
    name: "平安镇",
    pid: "500000",
    id: "500235133",
    cid: "500235"
  },
  {
    name: "云阳镇",
    pid: "500000",
    id: "500235135",
    cid: "500235"
  },
  {
    name: "云安镇",
    pid: "500000",
    id: "500235136",
    cid: "500235"
  },
  {
    name: "路阳镇",
    pid: "500000",
    id: "500235115",
    cid: "500235"
  },
  {
    name: "栖霞镇",
    pid: "500000",
    id: "500235137",
    cid: "500235"
  },
  {
    name: "农坝镇",
    pid: "500000",
    id: "500235116",
    cid: "500235"
  },
  {
    name: "双龙镇",
    pid: "500000",
    id: "500235138",
    cid: "500235"
  },
  {
    name: "新津乡",
    pid: "500000",
    id: "500235215",
    cid: "500235"
  },
  {
    name: "乌杨镇",
    pid: "500000",
    id: "500233103",
    cid: "500233"
  },
  {
    name: "任家镇",
    pid: "500000",
    id: "500233102",
    cid: "500233"
  },
  {
    name: "善广乡",
    pid: "500000",
    id: "500233201",
    cid: "500233"
  },
  {
    name: "白公街道",
    pid: "500000",
    id: "500233002",
    cid: "500233"
  },
  {
    name: "新生镇",
    pid: "500000",
    id: "500233101",
    cid: "500233"
  },
  {
    name: "忠州街道",
    pid: "500000",
    id: "500233001",
    cid: "500233"
  },
  {
    name: "黄金镇",
    pid: "500000",
    id: "500233122",
    cid: "500233"
  },
  {
    name: "白石镇",
    pid: "500000",
    id: "50023312",
    cid: "500233"
  },
  {
    name: "官坝镇",
    pid: "500000",
    id: "50023311",
    cid: "500233"
  },
  {
    name: "野鹤镇",
    pid: "500000",
    id: "500233109",
    cid: "500233"
  },
  {
    name: "金声乡",
    pid: "500000",
    id: "500233208",
    cid: "500233"
  },
  {
    name: "汝溪镇",
    pid: "500000",
    id: "500233108",
    cid: "500233"
  },
  {
    name: "石宝镇",
    pid: "500000",
    id: "500233107",
    cid: "500233"
  },
  {
    name: "涂井乡",
    pid: "500000",
    id: "500233206",
    cid: "500233"
  },
  {
    name: "复兴镇",
    pid: "500000",
    id: "500233106",
    cid: "500233"
  },
  {
    name: "东溪镇",
    pid: "500000",
    id: "500233105",
    cid: "500233"
  },
  {
    name: "磨子土家族乡",
    pid: "500000",
    id: "500233204",
    cid: "500233"
  },
  {
    name: "洋渡镇",
    pid: "500000",
    id: "500233104",
    cid: "500233"
  },
  {
    name: "石子乡",
    pid: "500000",
    id: "500233203",
    cid: "500233"
  },
  {
    name: "新立镇",
    pid: "500000",
    id: "500233114",
    cid: "500233"
  },
  {
    name: "金鸡镇",
    pid: "500000",
    id: "500233113",
    cid: "500233"
  },
  {
    name: "马灌镇",
    pid: "500000",
    id: "500233112",
    cid: "500233"
  },
  {
    name: "石黄镇",
    pid: "500000",
    id: "500233111",
    cid: "500233"
  },
  {
    name: "兴峰乡",
    pid: "500000",
    id: "50023321",
    cid: "500233"
  },
  {
    name: "三汇镇",
    pid: "500000",
    id: "500233119",
    cid: "500233"
  },
  {
    name: "永丰镇",
    pid: "500000",
    id: "500233118",
    cid: "500233"
  },
  {
    name: "花桥镇",
    pid: "500000",
    id: "500233117",
    cid: "500233"
  },
  {
    name: "拔山镇",
    pid: "500000",
    id: "500233116",
    cid: "500233"
  },
  {
    name: "双桂镇",
    pid: "500000",
    id: "500233115",
    cid: "500233"
  },
  {
    name: "九龙坡区",
    pid: "500000",
    id: "500107",
    cid: "500100"
  },
  {
    name: "永川区",
    pid: "500000",
    id: "500118",
    cid: "500100"
  },
  {
    name: "沙坪坝区",
    pid: "500000",
    id: "500106",
    cid: "500100"
  },
  {
    name: "合川区",
    pid: "500000",
    id: "500117",
    cid: "500100"
  },
  {
    name: "江北区",
    pid: "500000",
    id: "500105",
    cid: "500100"
  },
  {
    name: "江津区",
    pid: "500000",
    id: "500116",
    cid: "500100"
  },
  {
    name: "大渡口区",
    pid: "500000",
    id: "500104",
    cid: "500100"
  },
  {
    name: "长寿区",
    pid: "500000",
    id: "500115",
    cid: "500100"
  },
  {
    name: "北碚区",
    pid: "500000",
    id: "500109",
    cid: "500100"
  },
  {
    name: "南岸区",
    pid: "500000",
    id: "500108",
    cid: "500100"
  },
  {
    name: "南川区",
    pid: "500000",
    id: "500119",
    cid: "500100"
  },
  {
    name: "綦江区",
    pid: "500000",
    id: "500110",
    cid: "500100"
  },
  {
    name: "开州区",
    pid: "500000",
    id: "500154",
    cid: "500100"
  },
  {
    name: "璧山区",
    pid: "500000",
    id: "500120",
    cid: "500100"
  },
  {
    name: "荣昌区",
    pid: "500000",
    id: "500153",
    cid: "500100"
  },
  {
    name: "潼南区",
    pid: "500000",
    id: "500152",
    cid: "500100"
  },
  {
    name: "铜梁区",
    pid: "500000",
    id: "500151",
    cid: "500100"
  },
  {
    name: "渝中区",
    pid: "500000",
    id: "500103",
    cid: "500100"
  },
  {
    name: "黔江区",
    pid: "500000",
    id: "500114",
    cid: "500100"
  },
  {
    name: "涪陵区",
    pid: "500000",
    id: "500102",
    cid: "500100"
  },
  {
    name: "巴南区",
    pid: "500000",
    id: "500113",
    cid: "500100"
  },
  {
    name: "万州区",
    pid: "500000",
    id: "500101",
    cid: "500100"
  },
  {
    name: "渝北区",
    pid: "500000",
    id: "500112",
    cid: "500100"
  },
  {
    name: "大足区",
    pid: "500000",
    id: "500111",
    cid: "500100"
  },
  {
    name: "双河镇",
    pid: "500000",
    id: "500232112",
    cid: "500232"
  },
  {
    name: "浩口苗族仡佬族乡",
    pid: "500000",
    id: "500232211",
    cid: "500232"
  },
  {
    name: "接龙乡",
    pid: "500000",
    id: "500232212",
    cid: "500232"
  },
  {
    name: "和顺镇",
    pid: "500000",
    id: "500232111",
    cid: "500232"
  },
  {
    name: "土坎镇",
    pid: "500000",
    id: "50023211",
    cid: "500232"
  },
  {
    name: "赵家乡",
    pid: "500000",
    id: "500232213",
    cid: "500232"
  },
  {
    name: "大洞河乡",
    pid: "500000",
    id: "500232214",
    cid: "500232"
  },
  {
    name: "火炉镇",
    pid: "500000",
    id: "500232101",
    cid: "500232"
  },
  {
    name: "后坪苗族土家族乡",
    pid: "500000",
    id: "50023221",
    cid: "500232"
  },
  {
    name: "白马镇",
    pid: "500000",
    id: "500232102",
    cid: "500232"
  },
  {
    name: "凤来乡",
    pid: "500000",
    id: "5002322",
    cid: "500232"
  },
  {
    name: "桐梓镇",
    pid: "500000",
    id: "500232109",
    cid: "500232"
  },
  {
    name: "土地乡",
    pid: "500000",
    id: "500232208",
    cid: "500232"
  },
  {
    name: "白云乡",
    pid: "500000",
    id: "500232209",
    cid: "500232"
  },
  {
    name: "羊角镇",
    pid: "500000",
    id: "500232107",
    cid: "500232"
  },
  {
    name: "沧沟乡",
    pid: "500000",
    id: "500232206",
    cid: "500232"
  },
  {
    name: "巷口镇",
    pid: "500000",
    id: "5002321",
    cid: "500232"
  },
  {
    name: "仙女山镇",
    pid: "500000",
    id: "500232108",
    cid: "500232"
  },
  {
    name: "文复苗族土家族乡",
    pid: "500000",
    id: "500232207",
    cid: "500232"
  },
  {
    name: "江口镇",
    pid: "500000",
    id: "500232105",
    cid: "500232"
  },
  {
    name: "平桥镇",
    pid: "500000",
    id: "500232106",
    cid: "500232"
  },
  {
    name: "黄莺乡",
    pid: "500000",
    id: "500232205",
    cid: "500232"
  },
  {
    name: "鸭江镇",
    pid: "500000",
    id: "500232103",
    cid: "500232"
  },
  {
    name: "庙垭乡",
    pid: "500000",
    id: "500232202",
    cid: "500232"
  },
  {
    name: "长坝镇",
    pid: "500000",
    id: "500232104",
    cid: "500232"
  },
  {
    name: "石桥苗族土家族乡",
    pid: "500000",
    id: "500232203",
    cid: "500232"
  },
  {
    name: "汉葭街道",
    pid: "500000",
    id: "500243001",
    cid: "500243"
  },
  {
    name: "靛水街道",
    pid: "500000",
    id: "500243003",
    cid: "500243"
  },
  {
    name: "郁山镇",
    pid: "500000",
    id: "500243102",
    cid: "500243"
  },
  {
    name: "岩东乡",
    pid: "500000",
    id: "500243201",
    cid: "500243"
  },
  {
    name: "双龙乡",
    pid: "500000",
    id: "500243223",
    cid: "500243"
  },
  {
    name: "绍庆街道",
    pid: "500000",
    id: "500243002",
    cid: "500243"
  },
  {
    name: "保家镇",
    pid: "500000",
    id: "500243101",
    cid: "500243"
  },
  {
    name: "善感乡",
    pid: "500000",
    id: "500243222",
    cid: "500243"
  },
  {
    name: "龙射镇",
    pid: "500000",
    id: "500243108",
    cid: "500243"
  },
  {
    name: "联合乡",
    pid: "500000",
    id: "500243207",
    cid: "500243"
  },
  {
    name: "普子镇",
    pid: "500000",
    id: "500243107",
    cid: "500243"
  },
  {
    name: "三义乡",
    pid: "500000",
    id: "500243206",
    cid: "500243"
  },
  {
    name: "龙塘乡",
    pid: "500000",
    id: "500243228",
    cid: "500243"
  },
  {
    name: "连湖镇",
    pid: "500000",
    id: "500243109",
    cid: "500243"
  },
  {
    name: "石柳乡",
    pid: "500000",
    id: "500243208",
    cid: "500243"
  },
  {
    name: "桑柘镇",
    pid: "500000",
    id: "500243104",
    cid: "500243"
  },
  {
    name: "大垭乡",
    pid: "500000",
    id: "500243225",
    cid: "500243"
  },
  {
    name: "高谷镇",
    pid: "500000",
    id: "500243103",
    cid: "500243"
  },
  {
    name: "鹿鸣乡",
    pid: "500000",
    id: "500243202",
    cid: "500243"
  },
  {
    name: "石盘乡",
    pid: "500000",
    id: "500243224",
    cid: "500243"
  },
  {
    name: "黄家镇",
    pid: "500000",
    id: "500243106",
    cid: "500243"
  },
  {
    name: "朗溪乡",
    pid: "500000",
    id: "500243227",
    cid: "500243"
  },
  {
    name: "鹿角镇",
    pid: "500000",
    id: "500243105",
    cid: "500243"
  },
  {
    name: "棣棠乡",
    pid: "500000",
    id: "500243204",
    cid: "500243"
  },
  {
    name: "润溪乡",
    pid: "500000",
    id: "500243226",
    cid: "500243"
  },
  {
    name: "走马乡",
    pid: "500000",
    id: "50024321",
    cid: "500243"
  },
  {
    name: "平安镇",
    pid: "500000",
    id: "500243111",
    cid: "500243"
  },
  {
    name: "新田镇",
    pid: "500000",
    id: "500243113",
    cid: "500243"
  },
  {
    name: "长生镇",
    pid: "500000",
    id: "500243112",
    cid: "500243"
  },
  {
    name: "芦塘乡",
    pid: "500000",
    id: "500243211",
    cid: "500243"
  },
  {
    name: "大同镇",
    pid: "500000",
    id: "500243118",
    cid: "500243"
  },
  {
    name: "诸佛乡",
    pid: "500000",
    id: "500243217",
    cid: "500243"
  },
  {
    name: "桐楼乡",
    pid: "500000",
    id: "500243219",
    cid: "500243"
  },
  {
    name: "太原镇",
    pid: "500000",
    id: "500243115",
    cid: "500243"
  },
  {
    name: "鞍子镇",
    pid: "500000",
    id: "500243114",
    cid: "500243"
  },
  {
    name: "乔梓乡",
    pid: "500000",
    id: "500243213",
    cid: "500243"
  },
  {
    name: "梅子垭镇",
    pid: "500000",
    id: "500243117",
    cid: "500243"
  },
  {
    name: "龙溪镇",
    pid: "500000",
    id: "500243116",
    cid: "500243"
  },
  {
    name: "万足镇",
    pid: "500000",
    id: "50024311",
    cid: "500243"
  },
  {
    name: "汾阳市",
    pid: "140000",
    id: "141182",
    cid: "141100"
  },
  {
    name: "孝义市",
    pid: "140000",
    id: "141181",
    cid: "141100"
  },
  {
    name: "交口县",
    pid: "140000",
    id: "141130",
    cid: "141100"
  },
  {
    name: "交城县",
    pid: "140000",
    id: "141122",
    cid: "141100"
  },
  {
    name: "文水县",
    pid: "140000",
    id: "141121",
    cid: "141100"
  },
  {
    name: "离石区",
    pid: "140000",
    id: "141102",
    cid: "141100"
  },
  {
    name: "临县",
    pid: "140000",
    id: "141124",
    cid: "141100"
  },
  {
    name: "兴县",
    pid: "140000",
    id: "141123",
    cid: "141100"
  },
  {
    name: "石楼县",
    pid: "140000",
    id: "141126",
    cid: "141100"
  },
  {
    name: "柳林县",
    pid: "140000",
    id: "141125",
    cid: "141100"
  },
  {
    name: "方山县",
    pid: "140000",
    id: "141128",
    cid: "141100"
  },
  {
    name: "岚县",
    pid: "140000",
    id: "141127",
    cid: "141100"
  },
  {
    name: "中阳县",
    pid: "140000",
    id: "141129",
    cid: "141100"
  },
  {
    name: "侯马市",
    pid: "140000",
    id: "141081",
    cid: "141000"
  },
  {
    name: "霍州市",
    pid: "140000",
    id: "141082",
    cid: "141000"
  },
  {
    name: "大宁县",
    pid: "140000",
    id: "141030",
    cid: "141000"
  },
  {
    name: "曲沃县",
    pid: "140000",
    id: "141021",
    cid: "141000"
  },
  {
    name: "永和县",
    pid: "140000",
    id: "141032",
    cid: "141000"
  },
  {
    name: "隰县",
    pid: "140000",
    id: "141031",
    cid: "141000"
  },
  {
    name: "襄汾县",
    pid: "140000",
    id: "141023",
    cid: "141000"
  },
  {
    name: "汾西县",
    pid: "140000",
    id: "141034",
    cid: "141000"
  },
  {
    name: "翼城县",
    pid: "140000",
    id: "141022",
    cid: "141000"
  },
  {
    name: "蒲县",
    pid: "140000",
    id: "141033",
    cid: "141000"
  },
  {
    name: "古县",
    pid: "140000",
    id: "141025",
    cid: "141000"
  },
  {
    name: "尧都区",
    pid: "140000",
    id: "141002",
    cid: "141000"
  },
  {
    name: "洪洞县",
    pid: "140000",
    id: "141024",
    cid: "141000"
  },
  {
    name: "浮山县",
    pid: "140000",
    id: "141027",
    cid: "141000"
  },
  {
    name: "安泽县",
    pid: "140000",
    id: "141026",
    cid: "141000"
  },
  {
    name: "乡宁县",
    pid: "140000",
    id: "141029",
    cid: "141000"
  },
  {
    name: "吉县",
    pid: "140000",
    id: "141028",
    cid: "141000"
  },
  {
    name: "晋源区",
    pid: "140000",
    id: "140110",
    cid: "140100"
  },
  {
    name: "清徐县",
    pid: "140000",
    id: "140121",
    cid: "140100"
  },
  {
    name: "阳曲县",
    pid: "140000",
    id: "140122",
    cid: "140100"
  },
  {
    name: "娄烦县",
    pid: "140000",
    id: "140123",
    cid: "140100"
  },
  {
    name: "小店区",
    pid: "140000",
    id: "140105",
    cid: "140100"
  },
  {
    name: "迎泽区",
    pid: "140000",
    id: "140106",
    cid: "140100"
  },
  {
    name: "杏花岭区",
    pid: "140000",
    id: "140107",
    cid: "140100"
  },
  {
    name: "尖草坪区",
    pid: "140000",
    id: "140108",
    cid: "140100"
  },
  {
    name: "万柏林区",
    pid: "140000",
    id: "140109",
    cid: "140100"
  },
  {
    name: "古交市",
    pid: "140000",
    id: "140181",
    cid: "140100"
  },
  {
    name: "阳高县",
    pid: "140000",
    id: "140221",
    cid: "140200"
  },
  {
    name: "南郊区",
    pid: "140000",
    id: "140211",
    cid: "140200"
  },
  {
    name: "天镇县",
    pid: "140000",
    id: "140222",
    cid: "140200"
  },
  {
    name: "新荣区",
    pid: "140000",
    id: "140212",
    cid: "140200"
  },
  {
    name: "广灵县",
    pid: "140000",
    id: "140223",
    cid: "140200"
  },
  {
    name: "城区",
    pid: "140000",
    id: "140202",
    cid: "140200"
  },
  {
    name: "灵丘县",
    pid: "140000",
    id: "140224",
    cid: "140200"
  },
  {
    name: "矿区",
    pid: "140000",
    id: "140203",
    cid: "140200"
  },
  {
    name: "浑源县",
    pid: "140000",
    id: "140225",
    cid: "140200"
  },
  {
    name: "左云县",
    pid: "140000",
    id: "140226",
    cid: "140200"
  },
  {
    name: "大同县",
    pid: "140000",
    id: "140227",
    cid: "140200"
  },
  {
    name: "平定县",
    pid: "140000",
    id: "140321",
    cid: "140300"
  },
  {
    name: "郊区",
    pid: "140000",
    id: "140311",
    cid: "140300"
  },
  {
    name: "盂县",
    pid: "140000",
    id: "140322",
    cid: "140300"
  },
  {
    name: "城区",
    pid: "140000",
    id: "140302",
    cid: "140300"
  },
  {
    name: "矿区",
    pid: "140000",
    id: "140303",
    cid: "140300"
  },
  {
    name: "潞城市",
    pid: "140000",
    id: "140481",
    cid: "140400"
  },
  {
    name: "沁县",
    pid: "140000",
    id: "140430",
    cid: "140400"
  },
  {
    name: "沁源县",
    pid: "140000",
    id: "140431",
    cid: "140400"
  },
  {
    name: "长治县",
    pid: "140000",
    id: "140421",
    cid: "140400"
  },
  {
    name: "郊区",
    pid: "140000",
    id: "140411",
    cid: "140400"
  },
  {
    name: "襄垣县",
    pid: "140000",
    id: "140423",
    cid: "140400"
  },
  {
    name: "城区",
    pid: "140000",
    id: "140402",
    cid: "140400"
  },
  {
    name: "屯留县",
    pid: "140000",
    id: "140424",
    cid: "140400"
  },
  {
    name: "平顺县",
    pid: "140000",
    id: "140425",
    cid: "140400"
  },
  {
    name: "黎城县",
    pid: "140000",
    id: "140426",
    cid: "140400"
  },
  {
    name: "壶关县",
    pid: "140000",
    id: "140427",
    cid: "140400"
  },
  {
    name: "长子县",
    pid: "140000",
    id: "140428",
    cid: "140400"
  },
  {
    name: "武乡县",
    pid: "140000",
    id: "140429",
    cid: "140400"
  },
  {
    name: "高平市",
    pid: "140000",
    id: "140581",
    cid: "140500"
  },
  {
    name: "沁水县",
    pid: "140000",
    id: "140521",
    cid: "140500"
  },
  {
    name: "阳城县",
    pid: "140000",
    id: "140522",
    cid: "140500"
  },
  {
    name: "城区",
    pid: "140000",
    id: "140502",
    cid: "140500"
  },
  {
    name: "陵川县",
    pid: "140000",
    id: "140524",
    cid: "140500"
  },
  {
    name: "泽州县",
    pid: "140000",
    id: "140525",
    cid: "140500"
  },
  {
    name: "山阴县",
    pid: "140000",
    id: "140621",
    cid: "140600"
  },
  {
    name: "应县",
    pid: "140000",
    id: "140622",
    cid: "140600"
  },
  {
    name: "右玉县",
    pid: "140000",
    id: "140623",
    cid: "140600"
  },
  {
    name: "朔城区",
    pid: "140000",
    id: "140602",
    cid: "140600"
  },
  {
    name: "怀仁县",
    pid: "140000",
    id: "140624",
    cid: "140600"
  },
  {
    name: "平鲁区",
    pid: "140000",
    id: "140603",
    cid: "140600"
  },
  {
    name: "平遥县",
    pid: "140000",
    id: "140728",
    cid: "140700"
  },
  {
    name: "灵石县",
    pid: "140000",
    id: "140729",
    cid: "140700"
  },
  {
    name: "介休市",
    pid: "140000",
    id: "140781",
    cid: "140700"
  },
  {
    name: "榆社县",
    pid: "140000",
    id: "140721",
    cid: "140700"
  },
  {
    name: "左权县",
    pid: "140000",
    id: "140722",
    cid: "140700"
  },
  {
    name: "和顺县",
    pid: "140000",
    id: "140723",
    cid: "140700"
  },
  {
    name: "榆次区",
    pid: "140000",
    id: "140702",
    cid: "140700"
  },
  {
    name: "昔阳县",
    pid: "140000",
    id: "140724",
    cid: "140700"
  },
  {
    name: "寿阳县",
    pid: "140000",
    id: "140725",
    cid: "140700"
  },
  {
    name: "太谷县",
    pid: "140000",
    id: "140726",
    cid: "140700"
  },
  {
    name: "祁县",
    pid: "140000",
    id: "140727",
    cid: "140700"
  },
  {
    name: "垣曲县",
    pid: "140000",
    id: "140827",
    cid: "140800"
  },
  {
    name: "夏县",
    pid: "140000",
    id: "140828",
    cid: "140800"
  },
  {
    name: "平陆县",
    pid: "140000",
    id: "140829",
    cid: "140800"
  },
  {
    name: "永济市",
    pid: "140000",
    id: "140881",
    cid: "140800"
  },
  {
    name: "河津市",
    pid: "140000",
    id: "140882",
    cid: "140800"
  },
  {
    name: "芮城县",
    pid: "140000",
    id: "140830",
    cid: "140800"
  },
  {
    name: "临猗县",
    pid: "140000",
    id: "140821",
    cid: "140800"
  },
  {
    name: "万荣县",
    pid: "140000",
    id: "140822",
    cid: "140800"
  },
  {
    name: "闻喜县",
    pid: "140000",
    id: "140823",
    cid: "140800"
  },
  {
    name: "盐湖区",
    pid: "140000",
    id: "140802",
    cid: "140800"
  },
  {
    name: "稷山县",
    pid: "140000",
    id: "140824",
    cid: "140800"
  },
  {
    name: "新绛县",
    pid: "140000",
    id: "140825",
    cid: "140800"
  },
  {
    name: "绛县",
    pid: "140000",
    id: "140826",
    cid: "140800"
  },
  {
    name: "静乐县",
    pid: "140000",
    id: "140926",
    cid: "140900"
  },
  {
    name: "神池县",
    pid: "140000",
    id: "140927",
    cid: "140900"
  },
  {
    name: "五寨县",
    pid: "140000",
    id: "140928",
    cid: "140900"
  },
  {
    name: "岢岚县",
    pid: "140000",
    id: "140929",
    cid: "140900"
  },
  {
    name: "原平市",
    pid: "140000",
    id: "140981",
    cid: "140900"
  },
  {
    name: "河曲县",
    pid: "140000",
    id: "140930",
    cid: "140900"
  },
  {
    name: "保德县",
    pid: "140000",
    id: "140931",
    cid: "140900"
  },
  {
    name: "定襄县",
    pid: "140000",
    id: "140921",
    cid: "140900"
  },
  {
    name: "偏关县",
    pid: "140000",
    id: "140932",
    cid: "140900"
  },
  {
    name: "五台县",
    pid: "140000",
    id: "140922",
    cid: "140900"
  },
  {
    name: "代县",
    pid: "140000",
    id: "140923",
    cid: "140900"
  },
  {
    name: "忻府区",
    pid: "140000",
    id: "140902",
    cid: "140900"
  },
  {
    name: "繁峙县",
    pid: "140000",
    id: "140924",
    cid: "140900"
  },
  {
    name: "宁武县",
    pid: "140000",
    id: "140925",
    cid: "140900"
  },
  {
    name: "上栗县",
    pid: "360000",
    id: "360322",
    cid: "360300"
  },
  {
    name: "芦溪县",
    pid: "360000",
    id: "360323",
    cid: "360300"
  },
  {
    name: "莲花县",
    pid: "360000",
    id: "360321",
    cid: "360300"
  },
  {
    name: "安源区",
    pid: "360000",
    id: "360302",
    cid: "360300"
  },
  {
    name: "湘东区",
    pid: "360000",
    id: "360313",
    cid: "360300"
  },
  {
    name: "九江县",
    pid: "360000",
    id: "360421",
    cid: "360400"
  },
  {
    name: "彭泽县",
    pid: "360000",
    id: "360430",
    cid: "360400"
  },
  {
    name: "庐山市",
    pid: "360000",
    id: "360483",
    cid: "360400"
  },
  {
    name: "瑞昌市",
    pid: "360000",
    id: "360481",
    cid: "360400"
  },
  {
    name: "共青城市",
    pid: "360000",
    id: "360482",
    cid: "360400"
  },
  {
    name: "湖口县",
    pid: "360000",
    id: "360429",
    cid: "360400"
  },
  {
    name: "都昌县",
    pid: "360000",
    id: "360428",
    cid: "360400"
  },
  {
    name: "浔阳区",
    pid: "360000",
    id: "360403",
    cid: "360400"
  },
  {
    name: "永修县",
    pid: "360000",
    id: "360425",
    cid: "360400"
  },
  {
    name: "德安县",
    pid: "360000",
    id: "360426",
    cid: "360400"
  },
  {
    name: "武宁县",
    pid: "360000",
    id: "360423",
    cid: "360400"
  },
  {
    name: "濂溪区",
    pid: "360000",
    id: "360402",
    cid: "360400"
  },
  {
    name: "修水县",
    pid: "360000",
    id: "360424",
    cid: "360400"
  },
  {
    name: "东湖区",
    pid: "360000",
    id: "360102",
    cid: "360100"
  },
  {
    name: "进贤县",
    pid: "360000",
    id: "360124",
    cid: "360100"
  },
  {
    name: "西湖区",
    pid: "360000",
    id: "360103",
    cid: "360100"
  },
  {
    name: "青山湖区",
    pid: "360000",
    id: "360111",
    cid: "360100"
  },
  {
    name: "新建区",
    pid: "360000",
    id: "360112",
    cid: "360100"
  },
  {
    name: "安义县",
    pid: "360000",
    id: "360123",
    cid: "360100"
  },
  {
    name: "南昌县",
    pid: "360000",
    id: "360121",
    cid: "360100"
  },
  {
    name: "青云谱区",
    pid: "360000",
    id: "360104",
    cid: "360100"
  },
  {
    name: "湾里区",
    pid: "360000",
    id: "360105",
    cid: "360100"
  },
  {
    name: "昌江区",
    pid: "360000",
    id: "360202",
    cid: "360200"
  },
  {
    name: "浮梁县",
    pid: "360000",
    id: "360222",
    cid: "360200"
  },
  {
    name: "乐平市",
    pid: "360000",
    id: "360281",
    cid: "360200"
  },
  {
    name: "珠山区",
    pid: "360000",
    id: "360203",
    cid: "360200"
  },
  {
    name: "弋阳县",
    pid: "360000",
    id: "361126",
    cid: "361100"
  },
  {
    name: "广丰区",
    pid: "360000",
    id: "361103",
    cid: "361100"
  },
  {
    name: "横峰县",
    pid: "360000",
    id: "361125",
    cid: "361100"
  },
  {
    name: "信州区",
    pid: "360000",
    id: "361102",
    cid: "361100"
  },
  {
    name: "铅山县",
    pid: "360000",
    id: "361124",
    cid: "361100"
  },
  {
    name: "玉山县",
    pid: "360000",
    id: "361123",
    cid: "361100"
  },
  {
    name: "上饶县",
    pid: "360000",
    id: "361121",
    cid: "361100"
  },
  {
    name: "婺源县",
    pid: "360000",
    id: "361130",
    cid: "361100"
  },
  {
    name: "德兴市",
    pid: "360000",
    id: "361181",
    cid: "361100"
  },
  {
    name: "万年县",
    pid: "360000",
    id: "361129",
    cid: "361100"
  },
  {
    name: "鄱阳县",
    pid: "360000",
    id: "361128",
    cid: "361100"
  },
  {
    name: "余干县",
    pid: "360000",
    id: "361127",
    cid: "361100"
  },
  {
    name: "金溪县",
    pid: "360000",
    id: "361027",
    cid: "361000"
  },
  {
    name: "宜黄县",
    pid: "360000",
    id: "361026",
    cid: "361000"
  },
  {
    name: "乐安县",
    pid: "360000",
    id: "361025",
    cid: "361000"
  },
  {
    name: "临川区",
    pid: "360000",
    id: "361002",
    cid: "361000"
  },
  {
    name: "崇仁县",
    pid: "360000",
    id: "361024",
    cid: "361000"
  },
  {
    name: "南丰县",
    pid: "360000",
    id: "361023",
    cid: "361000"
  },
  {
    name: "黎川县",
    pid: "360000",
    id: "361022",
    cid: "361000"
  },
  {
    name: "南城县",
    pid: "360000",
    id: "361021",
    cid: "361000"
  },
  {
    name: "广昌县",
    pid: "360000",
    id: "361030",
    cid: "361000"
  },
  {
    name: "东乡县",
    pid: "360000",
    id: "361029",
    cid: "361000"
  },
  {
    name: "资溪县",
    pid: "360000",
    id: "361028",
    cid: "361000"
  },
  {
    name: "樟树市",
    pid: "360000",
    id: "360982",
    cid: "360900"
  },
  {
    name: "高安市",
    pid: "360000",
    id: "360983",
    cid: "360900"
  },
  {
    name: "丰城市",
    pid: "360000",
    id: "360981",
    cid: "360900"
  },
  {
    name: "铜鼓县",
    pid: "360000",
    id: "360926",
    cid: "360900"
  },
  {
    name: "袁州区",
    pid: "360000",
    id: "360902",
    cid: "360900"
  },
  {
    name: "宜丰县",
    pid: "360000",
    id: "360924",
    cid: "360900"
  },
  {
    name: "靖安县",
    pid: "360000",
    id: "360925",
    cid: "360900"
  },
  {
    name: "万载县",
    pid: "360000",
    id: "360922",
    cid: "360900"
  },
  {
    name: "上高县",
    pid: "360000",
    id: "360923",
    cid: "360900"
  },
  {
    name: "奉新县",
    pid: "360000",
    id: "360921",
    cid: "360900"
  },
  {
    name: "定南县",
    pid: "360000",
    id: "360728",
    cid: "360700"
  },
  {
    name: "全南县",
    pid: "360000",
    id: "360729",
    cid: "360700"
  },
  {
    name: "宁都县",
    pid: "360000",
    id: "360730",
    cid: "360700"
  },
  {
    name: "瑞金市",
    pid: "360000",
    id: "360781",
    cid: "360700"
  },
  {
    name: "安远县",
    pid: "360000",
    id: "360726",
    cid: "360700"
  },
  {
    name: "龙南县",
    pid: "360000",
    id: "360727",
    cid: "360700"
  },
  {
    name: "章贡区",
    pid: "360000",
    id: "360702",
    cid: "360700"
  },
  {
    name: "上犹县",
    pid: "360000",
    id: "360724",
    cid: "360700"
  },
  {
    name: "石城县",
    pid: "360000",
    id: "360735",
    cid: "360700"
  },
  {
    name: "南康区",
    pid: "360000",
    id: "360703",
    cid: "360700"
  },
  {
    name: "崇义县",
    pid: "360000",
    id: "360725",
    cid: "360700"
  },
  {
    name: "信丰县",
    pid: "360000",
    id: "360722",
    cid: "360700"
  },
  {
    name: "会昌县",
    pid: "360000",
    id: "360733",
    cid: "360700"
  },
  {
    name: "大余县",
    pid: "360000",
    id: "360723",
    cid: "360700"
  },
  {
    name: "寻乌县",
    pid: "360000",
    id: "360734",
    cid: "360700"
  },
  {
    name: "于都县",
    pid: "360000",
    id: "360731",
    cid: "360700"
  },
  {
    name: "赣县",
    pid: "360000",
    id: "360721",
    cid: "360700"
  },
  {
    name: "兴国县",
    pid: "360000",
    id: "360732",
    cid: "360700"
  },
  {
    name: "安福县",
    pid: "360000",
    id: "360829",
    cid: "360800"
  },
  {
    name: "遂川县",
    pid: "360000",
    id: "360827",
    cid: "360800"
  },
  {
    name: "万安县",
    pid: "360000",
    id: "360828",
    cid: "360800"
  },
  {
    name: "井冈山市",
    pid: "360000",
    id: "360881",
    cid: "360800"
  },
  {
    name: "青原区",
    pid: "360000",
    id: "360803",
    cid: "360800"
  },
  {
    name: "永丰县",
    pid: "360000",
    id: "360825",
    cid: "360800"
  },
  {
    name: "泰和县",
    pid: "360000",
    id: "360826",
    cid: "360800"
  },
  {
    name: "峡江县",
    pid: "360000",
    id: "360823",
    cid: "360800"
  },
  {
    name: "吉州区",
    pid: "360000",
    id: "360802",
    cid: "360800"
  },
  {
    name: "新干县",
    pid: "360000",
    id: "360824",
    cid: "360800"
  },
  {
    name: "吉安县",
    pid: "360000",
    id: "360821",
    cid: "360800"
  },
  {
    name: "吉水县",
    pid: "360000",
    id: "360822",
    cid: "360800"
  },
  {
    name: "永新县",
    pid: "360000",
    id: "360830",
    cid: "360800"
  },
  {
    name: "分宜县",
    pid: "360000",
    id: "360521",
    cid: "360500"
  },
  {
    name: "渝水区",
    pid: "360000",
    id: "360502",
    cid: "360500"
  },
  {
    name: "贵溪市",
    pid: "360000",
    id: "360681",
    cid: "360600"
  },
  {
    name: "月湖区",
    pid: "360000",
    id: "360602",
    cid: "360600"
  },
  {
    name: "余江县",
    pid: "360000",
    id: "360622",
    cid: "360600"
  },
  {
    name: "肇源县",
    pid: "230000",
    id: "230622",
    cid: "230600"
  },
  {
    name: "肇州县",
    pid: "230000",
    id: "230621",
    cid: "230600"
  },
  {
    name: "大同区",
    pid: "230000",
    id: "230606",
    cid: "230600"
  },
  {
    name: "红岗区",
    pid: "230000",
    id: "230605",
    cid: "230600"
  },
  {
    name: "让胡路区",
    pid: "230000",
    id: "230604",
    cid: "230600"
  },
  {
    name: "龙凤区",
    pid: "230000",
    id: "230603",
    cid: "230600"
  },
  {
    name: "萨尔图区",
    pid: "230000",
    id: "230602",
    cid: "230600"
  },
  {
    name: "杜尔伯特蒙古族自治县",
    pid: "230000",
    id: "230624",
    cid: "230600"
  },
  {
    name: "林甸县",
    pid: "230000",
    id: "230623",
    cid: "230600"
  },
  {
    name: "宝清县",
    pid: "230000",
    id: "230523",
    cid: "230500"
  },
  {
    name: "友谊县",
    pid: "230000",
    id: "230522",
    cid: "230500"
  },
  {
    name: "集贤县",
    pid: "230000",
    id: "230521",
    cid: "230500"
  },
  {
    name: "宝山区",
    pid: "230000",
    id: "230506",
    cid: "230500"
  },
  {
    name: "四方台区",
    pid: "230000",
    id: "230505",
    cid: "230500"
  },
  {
    name: "岭东区",
    pid: "230000",
    id: "230503",
    cid: "230500"
  },
  {
    name: "尖山区",
    pid: "230000",
    id: "230502",
    cid: "230500"
  },
  {
    name: "饶河县",
    pid: "230000",
    id: "230524",
    cid: "230500"
  },
  {
    name: "向阳区",
    pid: "230000",
    id: "230402",
    cid: "230400"
  },
  {
    name: "绥滨县",
    pid: "230000",
    id: "230422",
    cid: "230400"
  },
  {
    name: "萝北县",
    pid: "230000",
    id: "230421",
    cid: "230400"
  },
  {
    name: "兴山区",
    pid: "230000",
    id: "230407",
    cid: "230400"
  },
  {
    name: "东山区",
    pid: "230000",
    id: "230406",
    cid: "230400"
  },
  {
    name: "兴安区",
    pid: "230000",
    id: "230405",
    cid: "230400"
  },
  {
    name: "南山区",
    pid: "230000",
    id: "230404",
    cid: "230400"
  },
  {
    name: "工农区",
    pid: "230000",
    id: "230403",
    cid: "230400"
  },
  {
    name: "恒山区",
    pid: "230000",
    id: "230303",
    cid: "230300"
  },
  {
    name: "鸡冠区",
    pid: "230000",
    id: "230302",
    cid: "230300"
  },
  {
    name: "鸡东县",
    pid: "230000",
    id: "230321",
    cid: "230300"
  },
  {
    name: "密山市",
    pid: "230000",
    id: "230382",
    cid: "230300"
  },
  {
    name: "虎林市",
    pid: "230000",
    id: "230381",
    cid: "230300"
  },
  {
    name: "麻山区",
    pid: "230000",
    id: "230307",
    cid: "230300"
  },
  {
    name: "城子河区",
    pid: "230000",
    id: "230306",
    cid: "230300"
  },
  {
    name: "梨树区",
    pid: "230000",
    id: "230305",
    cid: "230300"
  },
  {
    name: "滴道区",
    pid: "230000",
    id: "230304",
    cid: "230300"
  },
  {
    name: "讷河市",
    pid: "230000",
    id: "230281",
    cid: "230200"
  },
  {
    name: "铁锋区",
    pid: "230000",
    id: "230204",
    cid: "230200"
  },
  {
    name: "建华区",
    pid: "230000",
    id: "230203",
    cid: "230200"
  },
  {
    name: "甘南县",
    pid: "230000",
    id: "230225",
    cid: "230200"
  },
  {
    name: "龙沙区",
    pid: "230000",
    id: "230202",
    cid: "230200"
  },
  {
    name: "泰来县",
    pid: "230000",
    id: "230224",
    cid: "230200"
  },
  {
    name: "依安县",
    pid: "230000",
    id: "230223",
    cid: "230200"
  },
  {
    name: "龙江县",
    pid: "230000",
    id: "230221",
    cid: "230200"
  },
  {
    name: "拜泉县",
    pid: "230000",
    id: "230231",
    cid: "230200"
  },
  {
    name: "克东县",
    pid: "230000",
    id: "230230",
    cid: "230200"
  },
  {
    name: "梅里斯达斡尔族区",
    pid: "230000",
    id: "230208",
    cid: "230200"
  },
  {
    name: "碾子山区",
    pid: "230000",
    id: "230207",
    cid: "230200"
  },
  {
    name: "克山县",
    pid: "230000",
    id: "230229",
    cid: "230200"
  },
  {
    name: "富拉尔基区",
    pid: "230000",
    id: "230206",
    cid: "230200"
  },
  {
    name: "昂昂溪区",
    pid: "230000",
    id: "230205",
    cid: "230200"
  },
  {
    name: "富裕县",
    pid: "230000",
    id: "230227",
    cid: "230200"
  },
  {
    name: "五常市",
    pid: "230000",
    id: "230184",
    cid: "230100"
  },
  {
    name: "尚志市",
    pid: "230000",
    id: "230183",
    cid: "230100"
  },
  {
    name: "木兰县",
    pid: "230000",
    id: "230127",
    cid: "230100"
  },
  {
    name: "道外区",
    pid: "230000",
    id: "230104",
    cid: "230100"
  },
  {
    name: "巴彦县",
    pid: "230000",
    id: "230126",
    cid: "230100"
  },
  {
    name: "南岗区",
    pid: "230000",
    id: "230103",
    cid: "230100"
  },
  {
    name: "宾县",
    pid: "230000",
    id: "230125",
    cid: "230100"
  },
  {
    name: "道里区",
    pid: "230000",
    id: "230102",
    cid: "230100"
  },
  {
    name: "双城区",
    pid: "230000",
    id: "230113",
    cid: "230100"
  },
  {
    name: "方正县",
    pid: "230000",
    id: "230124",
    cid: "230100"
  },
  {
    name: "阿城区",
    pid: "230000",
    id: "230112",
    cid: "230100"
  },
  {
    name: "依兰县",
    pid: "230000",
    id: "230123",
    cid: "230100"
  },
  {
    name: "呼兰区",
    pid: "230000",
    id: "230111",
    cid: "230100"
  },
  {
    name: "香坊区",
    pid: "230000",
    id: "230110",
    cid: "230100"
  },
  {
    name: "松北区",
    pid: "230000",
    id: "230109",
    cid: "230100"
  },
  {
    name: "平房区",
    pid: "230000",
    id: "230108",
    cid: "230100"
  },
  {
    name: "延寿县",
    pid: "230000",
    id: "230129",
    cid: "230100"
  },
  {
    name: "通河县",
    pid: "230000",
    id: "230128",
    cid: "230100"
  },
  {
    name: "西安区",
    pid: "230000",
    id: "231005",
    cid: "231000"
  },
  {
    name: "爱民区",
    pid: "230000",
    id: "231004",
    cid: "231000"
  },
  {
    name: "阳明区",
    pid: "230000",
    id: "231003",
    cid: "231000"
  },
  {
    name: "林口县",
    pid: "230000",
    id: "231025",
    cid: "231000"
  },
  {
    name: "东安区",
    pid: "230000",
    id: "231002",
    cid: "231000"
  },
  {
    name: "东宁市",
    pid: "230000",
    id: "231086",
    cid: "231000"
  },
  {
    name: "穆棱市",
    pid: "230000",
    id: "231085",
    cid: "231000"
  },
  {
    name: "宁安市",
    pid: "230000",
    id: "231084",
    cid: "231000"
  },
  {
    name: "海林市",
    pid: "230000",
    id: "231083",
    cid: "231000"
  },
  {
    name: "绥芬河市",
    pid: "230000",
    id: "231081",
    cid: "231000"
  },
  {
    name: "北安市",
    pid: "230000",
    id: "231181",
    cid: "231100"
  },
  {
    name: "五大连池市",
    pid: "230000",
    id: "231182",
    cid: "231100"
  },
  {
    name: "嫩江县",
    pid: "230000",
    id: "231121",
    cid: "231100"
  },
  {
    name: "逊克县",
    pid: "230000",
    id: "231123",
    cid: "231100"
  },
  {
    name: "爱辉区",
    pid: "230000",
    id: "231102",
    cid: "231100"
  },
  {
    name: "孙吴县",
    pid: "230000",
    id: "231124",
    cid: "231100"
  },
  {
    name: "安达市",
    pid: "230000",
    id: "231281",
    cid: "231200"
  },
  {
    name: "肇东市",
    pid: "230000",
    id: "231282",
    cid: "231200"
  },
  {
    name: "海伦市",
    pid: "230000",
    id: "231283",
    cid: "231200"
  },
  {
    name: "望奎县",
    pid: "230000",
    id: "231221",
    cid: "231200"
  },
  {
    name: "兰西县",
    pid: "230000",
    id: "231222",
    cid: "231200"
  },
  {
    name: "青冈县",
    pid: "230000",
    id: "231223",
    cid: "231200"
  },
  {
    name: "北林区",
    pid: "230000",
    id: "231202",
    cid: "231200"
  },
  {
    name: "庆安县",
    pid: "230000",
    id: "231224",
    cid: "231200"
  },
  {
    name: "明水县",
    pid: "230000",
    id: "231225",
    cid: "231200"
  },
  {
    name: "绥棱县",
    pid: "230000",
    id: "231226",
    cid: "231200"
  },
  {
    name: "茄子河区",
    pid: "230000",
    id: "230904",
    cid: "230900"
  },
  {
    name: "桃山区",
    pid: "230000",
    id: "230903",
    cid: "230900"
  },
  {
    name: "新兴区",
    pid: "230000",
    id: "230902",
    cid: "230900"
  },
  {
    name: "勃利县",
    pid: "230000",
    id: "230921",
    cid: "230900"
  },
  {
    name: "抚远市",
    pid: "230000",
    id: "230883",
    cid: "230800"
  },
  {
    name: "富锦市",
    pid: "230000",
    id: "230882",
    cid: "230800"
  },
  {
    name: "同江市",
    pid: "230000",
    id: "230881",
    cid: "230800"
  },
  {
    name: "汤原县",
    pid: "230000",
    id: "230828",
    cid: "230800"
  },
  {
    name: "东风区",
    pid: "230000",
    id: "230805",
    cid: "230800"
  },
  {
    name: "前进区",
    pid: "230000",
    id: "230804",
    cid: "230800"
  },
  {
    name: "桦川县",
    pid: "230000",
    id: "230826",
    cid: "230800"
  },
  {
    name: "向阳区",
    pid: "230000",
    id: "230803",
    cid: "230800"
  },
  {
    name: "郊区",
    pid: "230000",
    id: "230811",
    cid: "230800"
  },
  {
    name: "桦南县",
    pid: "230000",
    id: "230822",
    cid: "230800"
  },
  {
    name: "呼玛县",
    pid: "230000",
    id: "232721",
    cid: "232700"
  },
  {
    name: "塔河县",
    pid: "230000",
    id: "232722",
    cid: "232700"
  },
  {
    name: "漠河县",
    pid: "230000",
    id: "232723",
    cid: "232700"
  },
  {
    name: "金山屯区",
    pid: "230000",
    id: "230709",
    cid: "230700"
  },
  {
    name: "美溪区",
    pid: "230000",
    id: "230708",
    cid: "230700"
  },
  {
    name: "五营区",
    pid: "230000",
    id: "230710",
    cid: "230700"
  },
  {
    name: "铁力市",
    pid: "230000",
    id: "230781",
    cid: "230700"
  },
  {
    name: "新青区",
    pid: "230000",
    id: "230707",
    cid: "230700"
  },
  {
    name: "翠峦区",
    pid: "230000",
    id: "230706",
    cid: "230700"
  },
  {
    name: "西林区",
    pid: "230000",
    id: "230705",
    cid: "230700"
  },
  {
    name: "上甘岭区",
    pid: "230000",
    id: "230716",
    cid: "230700"
  },
  {
    name: "友好区",
    pid: "230000",
    id: "230704",
    cid: "230700"
  },
  {
    name: "红星区",
    pid: "230000",
    id: "230715",
    cid: "230700"
  },
  {
    name: "南岔区",
    pid: "230000",
    id: "230703",
    cid: "230700"
  },
  {
    name: "乌伊岭区",
    pid: "230000",
    id: "230714",
    cid: "230700"
  },
  {
    name: "伊春区",
    pid: "230000",
    id: "230702",
    cid: "230700"
  },
  {
    name: "带岭区",
    pid: "230000",
    id: "230713",
    cid: "230700"
  },
  {
    name: "汤旺河区",
    pid: "230000",
    id: "230712",
    cid: "230700"
  },
  {
    name: "乌马河区",
    pid: "230000",
    id: "230711",
    cid: "230700"
  },
  {
    name: "嘉荫县",
    pid: "230000",
    id: "230722",
    cid: "230700"
  },
  {
    name: "湟中县",
    pid: "630000",
    id: "630122",
    cid: "630100"
  },
  {
    name: "湟源县",
    pid: "630000",
    id: "630123",
    cid: "630100"
  },
  {
    name: "大通回族土族自治县",
    pid: "630000",
    id: "630121",
    cid: "630100"
  },
  {
    name: "城西区",
    pid: "630000",
    id: "630104",
    cid: "630100"
  },
  {
    name: "城北区",
    pid: "630000",
    id: "630105",
    cid: "630100"
  },
  {
    name: "城东区",
    pid: "630000",
    id: "630102",
    cid: "630100"
  },
  {
    name: "城中区",
    pid: "630000",
    id: "630103",
    cid: "630100"
  },
  {
    name: "民和回族土族自治县",
    pid: "630000",
    id: "630222",
    cid: "630200"
  },
  {
    name: "平安区",
    pid: "630000",
    id: "630203",
    cid: "630200"
  },
  {
    name: "循化撒拉族自治县",
    pid: "630000",
    id: "630225",
    cid: "630200"
  },
  {
    name: "互助土族自治县",
    pid: "630000",
    id: "630223",
    cid: "630200"
  },
  {
    name: "乐都区",
    pid: "630000",
    id: "630202",
    cid: "630200"
  },
  {
    name: "化隆回族自治县",
    pid: "630000",
    id: "630224",
    cid: "630200"
  },
  {
    name: "泽库县",
    pid: "630000",
    id: "632323",
    cid: "632300"
  },
  {
    name: "尖扎县",
    pid: "630000",
    id: "632322",
    cid: "632300"
  },
  {
    name: "同仁县",
    pid: "630000",
    id: "632321",
    cid: "632300"
  },
  {
    name: "河南蒙古族自治县",
    pid: "630000",
    id: "632324",
    cid: "632300"
  },
  {
    name: "刚察县",
    pid: "630000",
    id: "632224",
    cid: "632200"
  },
  {
    name: "海晏县",
    pid: "630000",
    id: "632223",
    cid: "632200"
  },
  {
    name: "祁连县",
    pid: "630000",
    id: "632222",
    cid: "632200"
  },
  {
    name: "门源回族自治县",
    pid: "630000",
    id: "632221",
    cid: "632200"
  },
  {
    name: "都兰县",
    pid: "630000",
    id: "632822",
    cid: "632800"
  },
  {
    name: "乌兰县",
    pid: "630000",
    id: "632821",
    cid: "632800"
  },
  {
    name: "德令哈市",
    pid: "630000",
    id: "632802",
    cid: "632800"
  },
  {
    name: "格尔木市",
    pid: "630000",
    id: "632801",
    cid: "632800"
  },
  {
    name: "天峻县",
    pid: "630000",
    id: "632823",
    cid: "632800"
  },
  {
    name: "玉树市",
    pid: "630000",
    id: "632701",
    cid: "632700"
  },
  {
    name: "称多县",
    pid: "630000",
    id: "632723",
    cid: "632700"
  },
  {
    name: "杂多县",
    pid: "630000",
    id: "632722",
    cid: "632700"
  },
  {
    name: "曲麻莱县",
    pid: "630000",
    id: "632726",
    cid: "632700"
  },
  {
    name: "囊谦县",
    pid: "630000",
    id: "632725",
    cid: "632700"
  },
  {
    name: "治多县",
    pid: "630000",
    id: "632724",
    cid: "632700"
  },
  {
    name: "达日县",
    pid: "630000",
    id: "632624",
    cid: "632600"
  },
  {
    name: "甘德县",
    pid: "630000",
    id: "632623",
    cid: "632600"
  },
  {
    name: "班玛县",
    pid: "630000",
    id: "632622",
    cid: "632600"
  },
  {
    name: "玛沁县",
    pid: "630000",
    id: "632621",
    cid: "632600"
  },
  {
    name: "玛多县",
    pid: "630000",
    id: "632626",
    cid: "632600"
  },
  {
    name: "久治县",
    pid: "630000",
    id: "632625",
    cid: "632600"
  },
  {
    name: "共和县",
    pid: "630000",
    id: "632521",
    cid: "632500"
  },
  {
    name: "贵南县",
    pid: "630000",
    id: "632525",
    cid: "632500"
  },
  {
    name: "兴海县",
    pid: "630000",
    id: "632524",
    cid: "632500"
  },
  {
    name: "贵德县",
    pid: "630000",
    id: "632523",
    cid: "632500"
  },
  {
    name: "同德县",
    pid: "630000",
    id: "632522",
    cid: "632500"
  },
  {
    name: "山阳区",
    pid: "410000",
    id: "410811",
    cid: "410800"
  },
  {
    name: "博爱县",
    pid: "410000",
    id: "410822",
    cid: "410800"
  },
  {
    name: "修武县",
    pid: "410000",
    id: "410821",
    cid: "410800"
  },
  {
    name: "解放区",
    pid: "410000",
    id: "410802",
    cid: "410800"
  },
  {
    name: "武陟县",
    pid: "410000",
    id: "410823",
    cid: "410800"
  },
  {
    name: "孟州市",
    pid: "410000",
    id: "410883",
    cid: "410800"
  },
  {
    name: "沁阳市",
    pid: "410000",
    id: "410882",
    cid: "410800"
  },
  {
    name: "马村区",
    pid: "410000",
    id: "410804",
    cid: "410800"
  },
  {
    name: "中站区",
    pid: "410000",
    id: "410803",
    cid: "410800"
  },
  {
    name: "温县",
    pid: "410000",
    id: "410825",
    cid: "410800"
  },
  {
    name: "牧野区",
    pid: "410000",
    id: "410711",
    cid: "410700"
  },
  {
    name: "卫滨区",
    pid: "410000",
    id: "410703",
    cid: "410700"
  },
  {
    name: "原阳县",
    pid: "410000",
    id: "410725",
    cid: "410700"
  },
  {
    name: "红旗区",
    pid: "410000",
    id: "410702",
    cid: "410700"
  },
  {
    name: "获嘉县",
    pid: "410000",
    id: "410724",
    cid: "410700"
  },
  {
    name: "新乡县",
    pid: "410000",
    id: "410721",
    cid: "410700"
  },
  {
    name: "卫辉市",
    pid: "410000",
    id: "410781",
    cid: "410700"
  },
  {
    name: "辉县市",
    pid: "410000",
    id: "410782",
    cid: "410700"
  },
  {
    name: "封丘县",
    pid: "410000",
    id: "410727",
    cid: "410700"
  },
  {
    name: "凤泉区",
    pid: "410000",
    id: "410704",
    cid: "410700"
  },
  {
    name: "延津县",
    pid: "410000",
    id: "410726",
    cid: "410700"
  },
  {
    name: "长垣县",
    pid: "410000",
    id: "410728",
    cid: "410700"
  },
  {
    name: "南乐县",
    pid: "410000",
    id: "410923",
    cid: "410900"
  },
  {
    name: "清丰县",
    pid: "410000",
    id: "410922",
    cid: "410900"
  },
  {
    name: "濮阳县",
    pid: "410000",
    id: "410928",
    cid: "410900"
  },
  {
    name: "华龙区",
    pid: "410000",
    id: "410902",
    cid: "410900"
  },
  {
    name: "台前县",
    pid: "410000",
    id: "410927",
    cid: "410900"
  },
  {
    name: "范县",
    pid: "410000",
    id: "410926",
    cid: "410900"
  },
  {
    name: "石龙区",
    pid: "410000",
    id: "410404",
    cid: "410400"
  },
  {
    name: "卫东区",
    pid: "410000",
    id: "410403",
    cid: "410400"
  },
  {
    name: "郏县",
    pid: "410000",
    id: "410425",
    cid: "410400"
  },
  {
    name: "湛河区",
    pid: "410000",
    id: "410411",
    cid: "410400"
  },
  {
    name: "叶县",
    pid: "410000",
    id: "410422",
    cid: "410400"
  },
  {
    name: "宝丰县",
    pid: "410000",
    id: "410421",
    cid: "410400"
  },
  {
    name: "新华区",
    pid: "410000",
    id: "410402",
    cid: "410400"
  },
  {
    name: "鲁山县",
    pid: "410000",
    id: "410423",
    cid: "410400"
  },
  {
    name: "汝州市",
    pid: "410000",
    id: "410482",
    cid: "410400"
  },
  {
    name: "舞钢市",
    pid: "410000",
    id: "410481",
    cid: "410400"
  },
  {
    name: "潢川县",
    pid: "410000",
    id: "411526",
    cid: "411500"
  },
  {
    name: "平桥区",
    pid: "410000",
    id: "411503",
    cid: "411500"
  },
  {
    name: "固始县",
    pid: "410000",
    id: "411525",
    cid: "411500"
  },
  {
    name: "息县",
    pid: "410000",
    id: "411528",
    cid: "411500"
  },
  {
    name: "淮滨县",
    pid: "410000",
    id: "411527",
    cid: "411500"
  },
  {
    name: "光山县",
    pid: "410000",
    id: "411522",
    cid: "411500"
  },
  {
    name: "罗山县",
    pid: "410000",
    id: "411521",
    cid: "411500"
  },
  {
    name: "浉河区",
    pid: "410000",
    id: "411502",
    cid: "411500"
  },
  {
    name: "商城县",
    pid: "410000",
    id: "411524",
    cid: "411500"
  },
  {
    name: "新县",
    pid: "410000",
    id: "411523",
    cid: "411500"
  },
  {
    name: "涧西区",
    pid: "410000",
    id: "410305",
    cid: "410300"
  },
  {
    name: "宜阳县",
    pid: "410000",
    id: "410327",
    cid: "410300"
  },
  {
    name: "瀍河回族区",
    pid: "410000",
    id: "410304",
    cid: "410300"
  },
  {
    name: "汝阳县",
    pid: "410000",
    id: "410326",
    cid: "410300"
  },
  {
    name: "伊川县",
    pid: "410000",
    id: "410329",
    cid: "410300"
  },
  {
    name: "吉利区",
    pid: "410000",
    id: "410306",
    cid: "410300"
  },
  {
    name: "洛宁县",
    pid: "410000",
    id: "410328",
    cid: "410300"
  },
  {
    name: "新安县",
    pid: "410000",
    id: "410323",
    cid: "410300"
  },
  {
    name: "洛龙区",
    pid: "410000",
    id: "410311",
    cid: "410300"
  },
  {
    name: "孟津县",
    pid: "410000",
    id: "410322",
    cid: "410300"
  },
  {
    name: "西工区",
    pid: "410000",
    id: "410303",
    cid: "410300"
  },
  {
    name: "嵩县",
    pid: "410000",
    id: "410325",
    cid: "410300"
  },
  {
    name: "老城区",
    pid: "410000",
    id: "410302",
    cid: "410300"
  },
  {
    name: "栾川县",
    pid: "410000",
    id: "410324",
    cid: "410300"
  },
  {
    name: "偃师市",
    pid: "410000",
    id: "410381",
    cid: "410300"
  },
  {
    name: "夏邑县",
    pid: "410000",
    id: "411426",
    cid: "411400"
  },
  {
    name: "宁陵县",
    pid: "410000",
    id: "411423",
    cid: "411400"
  },
  {
    name: "睢县",
    pid: "410000",
    id: "411422",
    cid: "411400"
  },
  {
    name: "睢阳区",
    pid: "410000",
    id: "411403",
    cid: "411400"
  },
  {
    name: "虞城县",
    pid: "410000",
    id: "411425",
    cid: "411400"
  },
  {
    name: "梁园区",
    pid: "410000",
    id: "411402",
    cid: "411400"
  },
  {
    name: "柘城县",
    pid: "410000",
    id: "411424",
    cid: "411400"
  },
  {
    name: "民权县",
    pid: "410000",
    id: "411421",
    cid: "411400"
  },
  {
    name: "永城市",
    pid: "410000",
    id: "411481",
    cid: "411400"
  },
  {
    name: "鹤山区",
    pid: "410000",
    id: "410602",
    cid: "410600"
  },
  {
    name: "山城区",
    pid: "410000",
    id: "410603",
    cid: "410600"
  },
  {
    name: "淇滨区",
    pid: "410000",
    id: "410611",
    cid: "410600"
  },
  {
    name: "淇县",
    pid: "410000",
    id: "410622",
    cid: "410600"
  },
  {
    name: "浚县",
    pid: "410000",
    id: "410621",
    cid: "410600"
  },
  {
    name: "驿城区",
    pid: "410000",
    id: "411702",
    cid: "411700"
  },
  {
    name: "正阳县",
    pid: "410000",
    id: "411724",
    cid: "411700"
  },
  {
    name: "平舆县",
    pid: "410000",
    id: "411723",
    cid: "411700"
  },
  {
    name: "泌阳县",
    pid: "410000",
    id: "411726",
    cid: "411700"
  },
  {
    name: "确山县",
    pid: "410000",
    id: "411725",
    cid: "411700"
  },
  {
    name: "上蔡县",
    pid: "410000",
    id: "411722",
    cid: "411700"
  },
  {
    name: "西平县",
    pid: "410000",
    id: "411721",
    cid: "411700"
  },
  {
    name: "遂平县",
    pid: "410000",
    id: "411728",
    cid: "411700"
  },
  {
    name: "汝南县",
    pid: "410000",
    id: "411727",
    cid: "411700"
  },
  {
    name: "新蔡县",
    pid: "410000",
    id: "411729",
    cid: "411700"
  },
  {
    name: "北关区",
    pid: "410000",
    id: "410503",
    cid: "410500"
  },
  {
    name: "文峰区",
    pid: "410000",
    id: "410502",
    cid: "410500"
  },
  {
    name: "殷都区",
    pid: "410000",
    id: "410505",
    cid: "410500"
  },
  {
    name: "内黄县",
    pid: "410000",
    id: "410527",
    cid: "410500"
  },
  {
    name: "滑县",
    pid: "410000",
    id: "410526",
    cid: "410500"
  },
  {
    name: "汤阴县",
    pid: "410000",
    id: "410523",
    cid: "410500"
  },
  {
    name: "安阳县",
    pid: "410000",
    id: "410522",
    cid: "410500"
  },
  {
    name: "龙安区",
    pid: "410000",
    id: "410506",
    cid: "410500"
  },
  {
    name: "林州市",
    pid: "410000",
    id: "410581",
    cid: "410500"
  },
  {
    name: "郸城县",
    pid: "410000",
    id: "411625",
    cid: "411600"
  },
  {
    name: "川汇区",
    pid: "410000",
    id: "411602",
    cid: "411600"
  },
  {
    name: "沈丘县",
    pid: "410000",
    id: "411624",
    cid: "411600"
  },
  {
    name: "太康县",
    pid: "410000",
    id: "411627",
    cid: "411600"
  },
  {
    name: "淮阳县",
    pid: "410000",
    id: "411626",
    cid: "411600"
  },
  {
    name: "扶沟县",
    pid: "410000",
    id: "411621",
    cid: "411600"
  },
  {
    name: "商水县",
    pid: "410000",
    id: "411623",
    cid: "411600"
  },
  {
    name: "西华县",
    pid: "410000",
    id: "411622",
    cid: "411600"
  },
  {
    name: "鹿邑县",
    pid: "410000",
    id: "411628",
    cid: "411600"
  },
  {
    name: "项城市",
    pid: "410000",
    id: "411681",
    cid: "411600"
  },
  {
    name: "召陵区",
    pid: "410000",
    id: "411104",
    cid: "411100"
  },
  {
    name: "郾城区",
    pid: "410000",
    id: "411103",
    cid: "411100"
  },
  {
    name: "临颍县",
    pid: "410000",
    id: "411122",
    cid: "411100"
  },
  {
    name: "舞阳县",
    pid: "410000",
    id: "411121",
    cid: "411100"
  },
  {
    name: "源汇区",
    pid: "410000",
    id: "411102",
    cid: "411100"
  },
  {
    name: "禹州市",
    pid: "410000",
    id: "411081",
    cid: "411000"
  },
  {
    name: "长葛市",
    pid: "410000",
    id: "411082",
    cid: "411000"
  },
  {
    name: "许昌县",
    pid: "410000",
    id: "411023",
    cid: "411000"
  },
  {
    name: "襄城县",
    pid: "410000",
    id: "411025",
    cid: "411000"
  },
  {
    name: "魏都区",
    pid: "410000",
    id: "411002",
    cid: "411000"
  },
  {
    name: "鄢陵县",
    pid: "410000",
    id: "411024",
    cid: "411000"
  },
  {
    name: "禹王台区",
    pid: "410000",
    id: "410205",
    cid: "410200"
  },
  {
    name: "龙亭区",
    pid: "410000",
    id: "410202",
    cid: "410200"
  },
  {
    name: "祥符区",
    pid: "410000",
    id: "410212",
    cid: "410200"
  },
  {
    name: "尉氏县",
    pid: "410000",
    id: "410223",
    cid: "410200"
  },
  {
    name: "鼓楼区",
    pid: "410000",
    id: "410204",
    cid: "410200"
  },
  {
    name: "顺河回族区",
    pid: "410000",
    id: "410203",
    cid: "410200"
  },
  {
    name: "兰考县",
    pid: "410000",
    id: "410225",
    cid: "410200"
  },
  {
    name: "金明区",
    pid: "410000",
    id: "410211",
    cid: "410200"
  },
  {
    name: "通许县",
    pid: "410000",
    id: "410222",
    cid: "410200"
  },
  {
    name: "杞县",
    pid: "410000",
    id: "410221",
    cid: "410200"
  },
  {
    name: "唐河县",
    pid: "410000",
    id: "411328",
    cid: "411300"
  },
  {
    name: "社旗县",
    pid: "410000",
    id: "411327",
    cid: "411300"
  },
  {
    name: "新野县",
    pid: "410000",
    id: "411329",
    cid: "411300"
  },
  {
    name: "宛城区",
    pid: "410000",
    id: "411302",
    cid: "411300"
  },
  {
    name: "镇平县",
    pid: "410000",
    id: "411324",
    cid: "411300"
  },
  {
    name: "西峡县",
    pid: "410000",
    id: "411323",
    cid: "411300"
  },
  {
    name: "淅川县",
    pid: "410000",
    id: "411326",
    cid: "411300"
  },
  {
    name: "卧龙区",
    pid: "410000",
    id: "411303",
    cid: "411300"
  },
  {
    name: "内乡县",
    pid: "410000",
    id: "411325",
    cid: "411300"
  },
  {
    name: "桐柏县",
    pid: "410000",
    id: "411330",
    cid: "411300"
  },
  {
    name: "方城县",
    pid: "410000",
    id: "411322",
    cid: "411300"
  },
  {
    name: "南召县",
    pid: "410000",
    id: "411321",
    cid: "411300"
  },
  {
    name: "邓州市",
    pid: "410000",
    id: "411381",
    cid: "411300"
  },
  {
    name: "上街区",
    pid: "410000",
    id: "410106",
    cid: "410100"
  },
  {
    name: "巩义市",
    pid: "410000",
    id: "410181",
    cid: "410100"
  },
  {
    name: "惠济区",
    pid: "410000",
    id: "410108",
    cid: "410100"
  },
  {
    name: "二七区",
    pid: "410000",
    id: "410103",
    cid: "410100"
  },
  {
    name: "中原区",
    pid: "410000",
    id: "410102",
    cid: "410100"
  },
  {
    name: "金水区",
    pid: "410000",
    id: "410105",
    cid: "410100"
  },
  {
    name: "管城回族区",
    pid: "410000",
    id: "410104",
    cid: "410100"
  },
  {
    name: "中牟县",
    pid: "410000",
    id: "410122",
    cid: "410100"
  },
  {
    name: "新密市",
    pid: "410000",
    id: "410183",
    cid: "410100"
  },
  {
    name: "荥阳市",
    pid: "410000",
    id: "410182",
    cid: "410100"
  },
  {
    name: "登封市",
    pid: "410000",
    id: "410185",
    cid: "410100"
  },
  {
    name: "新郑市",
    pid: "410000",
    id: "410184",
    cid: "410100"
  },
  {
    name: "义马市",
    pid: "410000",
    id: "411281",
    cid: "411200"
  },
  {
    name: "陕州区",
    pid: "410000",
    id: "411203",
    cid: "411200"
  },
  {
    name: "湖滨区",
    pid: "410000",
    id: "411202",
    cid: "411200"
  },
  {
    name: "卢氏县",
    pid: "410000",
    id: "411224",
    cid: "411200"
  },
  {
    name: "渑池县",
    pid: "410000",
    id: "411221",
    cid: "411200"
  },
  {
    name: "灵宝市",
    pid: "410000",
    id: "411282",
    cid: "411200"
  },
  {
    name: "济源市王屋镇",
    pid: "410000",
    id: "419001109",
    cid: "419001"
  },
  {
    name: "济源市思礼镇",
    pid: "410000",
    id: "419001108",
    cid: "419001"
  },
  {
    name: "济源市大峪镇",
    pid: "410000",
    id: "419001107",
    cid: "419001"
  },
  {
    name: "济源市梨林镇",
    pid: "410000",
    id: "419001106",
    cid: "419001"
  },
  {
    name: "济源市坡头镇",
    pid: "410000",
    id: "419001105",
    cid: "419001"
  },
  {
    name: "济源市克井镇",
    pid: "410000",
    id: "4190011",
    cid: "419001"
  },
  {
    name: "济源市玉泉街道",
    pid: "410000",
    id: "419001005",
    cid: "419001"
  },
  {
    name: "济源市邵原镇",
    pid: "410000",
    id: "419001104",
    cid: "419001"
  },
  {
    name: "济源市下冶镇",
    pid: "410000",
    id: "41900111",
    cid: "419001"
  },
  {
    name: "济源市天坛街道",
    pid: "410000",
    id: "419001004",
    cid: "419001"
  },
  {
    name: "济源市承留镇",
    pid: "410000",
    id: "419001103",
    cid: "419001"
  },
  {
    name: "济源市北海街道",
    pid: "410000",
    id: "419001003",
    cid: "419001"
  },
  {
    name: "济源市轵城镇",
    pid: "410000",
    id: "419001102",
    cid: "419001"
  },
  {
    name: "济源市济水街道",
    pid: "410000",
    id: "419001002",
    cid: "419001"
  },
  {
    name: "济源市五龙口镇",
    pid: "410000",
    id: "419001101",
    cid: "419001"
  },
  {
    name: "济源市沁园街道",
    pid: "410000",
    id: "419001001",
    cid: "419001"
  },
  {
    name: "台中",
    pid: "710000",
    id: "710108",
    cid: "710100"
  },
  {
    name: "高雄",
    pid: "710000",
    id: "710119",
    cid: "710100"
  },
  {
    name: "台东",
    pid: "710000",
    id: "710107",
    cid: "710100"
  },
  {
    name: "宜兰",
    pid: "710000",
    id: "710118",
    cid: "710100"
  },
  {
    name: "屏东",
    pid: "710000",
    id: "710106",
    cid: "710100"
  },
  {
    name: "花莲",
    pid: "710000",
    id: "710117",
    cid: "710100"
  },
  {
    name: "澎湖",
    pid: "710000",
    id: "710105",
    cid: "710100"
  },
  {
    name: "新竹",
    pid: "710000",
    id: "710116",
    cid: "710100"
  },
  {
    name: "台南",
    pid: "710000",
    id: "710109",
    cid: "710100"
  },
  {
    name: "桃园",
    pid: "710000",
    id: "710111",
    cid: "710100"
  },
  {
    name: "台北",
    pid: "710000",
    id: "710110",
    cid: "710100"
  },
  {
    name: "基隆",
    pid: "710000",
    id: "710120",
    cid: "710100"
  },
  {
    name: "南投",
    pid: "710000",
    id: "710104",
    cid: "710100"
  },
  {
    name: "嘉义",
    pid: "710000",
    id: "710115",
    cid: "710100"
  },
  {
    name: "苗栗",
    pid: "710000",
    id: "710103",
    cid: "710100"
  },
  {
    name: "彰化",
    pid: "710000",
    id: "710114",
    cid: "710100"
  },
  {
    name: "连江",
    pid: "710000",
    id: "710102",
    cid: "710100"
  },
  {
    name: "新北",
    pid: "710000",
    id: "710113",
    cid: "710100"
  },
  {
    name: "金门",
    pid: "710000",
    id: "710101",
    cid: "710100"
  },
  {
    name: "云林",
    pid: "710000",
    id: "710112",
    cid: "710100"
  },
  {
    name: "临河区",
    pid: "150000",
    id: "150802",
    cid: "150800"
  },
  {
    name: "乌拉特中旗",
    pid: "150000",
    id: "150824",
    cid: "150800"
  },
  {
    name: "乌拉特后旗",
    pid: "150000",
    id: "150825",
    cid: "150800"
  },
  {
    name: "磴口县",
    pid: "150000",
    id: "150822",
    cid: "150800"
  },
  {
    name: "乌拉特前旗",
    pid: "150000",
    id: "150823",
    cid: "150800"
  },
  {
    name: "五原县",
    pid: "150000",
    id: "150821",
    cid: "150800"
  },
  {
    name: "杭锦后旗",
    pid: "150000",
    id: "150826",
    cid: "150800"
  },
  {
    name: "集宁区",
    pid: "150000",
    id: "150902",
    cid: "150900"
  },
  {
    name: "兴和县",
    pid: "150000",
    id: "150924",
    cid: "150900"
  },
  {
    name: "商都县",
    pid: "150000",
    id: "150923",
    cid: "150900"
  },
  {
    name: "化德县",
    pid: "150000",
    id: "150922",
    cid: "150900"
  },
  {
    name: "卓资县",
    pid: "150000",
    id: "150921",
    cid: "150900"
  },
  {
    name: "丰镇市",
    pid: "150000",
    id: "150981",
    cid: "150900"
  },
  {
    name: "四子王旗",
    pid: "150000",
    id: "150929",
    cid: "150900"
  },
  {
    name: "察哈尔右翼后旗",
    pid: "150000",
    id: "150928",
    cid: "150900"
  },
  {
    name: "察哈尔右翼中旗",
    pid: "150000",
    id: "150927",
    cid: "150900"
  },
  {
    name: "察哈尔右翼前旗",
    pid: "150000",
    id: "150926",
    cid: "150900"
  },
  {
    name: "凉城县",
    pid: "150000",
    id: "150925",
    cid: "150900"
  },
  {
    name: "乌审旗",
    pid: "150000",
    id: "150626",
    cid: "150600"
  },
  {
    name: "伊金霍洛旗",
    pid: "150000",
    id: "150627",
    cid: "150600"
  },
  {
    name: "东胜区",
    pid: "150000",
    id: "150602",
    cid: "150600"
  },
  {
    name: "鄂托克旗",
    pid: "150000",
    id: "150624",
    cid: "150600"
  },
  {
    name: "康巴什区",
    pid: "150000",
    id: "150603",
    cid: "150600"
  },
  {
    name: "杭锦旗",
    pid: "150000",
    id: "150625",
    cid: "150600"
  },
  {
    name: "准格尔旗",
    pid: "150000",
    id: "150622",
    cid: "150600"
  },
  {
    name: "鄂托克前旗",
    pid: "150000",
    id: "150623",
    cid: "150600"
  },
  {
    name: "达拉特旗",
    pid: "150000",
    id: "150621",
    cid: "150600"
  },
  {
    name: "额济纳旗",
    pid: "150000",
    id: "152923",
    cid: "152900"
  },
  {
    name: "阿拉善右旗",
    pid: "150000",
    id: "152922",
    cid: "152900"
  },
  {
    name: "阿拉善左旗",
    pid: "150000",
    id: "152921",
    cid: "152900"
  },
  {
    name: "新巴尔虎右旗",
    pid: "150000",
    id: "150727",
    cid: "150700"
  },
  {
    name: "扎赉诺尔区",
    pid: "150000",
    id: "150703",
    cid: "150700"
  },
  {
    name: "陈巴尔虎旗",
    pid: "150000",
    id: "150725",
    cid: "150700"
  },
  {
    name: "新巴尔虎左旗",
    pid: "150000",
    id: "150726",
    cid: "150700"
  },
  {
    name: "鄂伦春自治旗",
    pid: "150000",
    id: "150723",
    cid: "150700"
  },
  {
    name: "海拉尔区",
    pid: "150000",
    id: "150702",
    cid: "150700"
  },
  {
    name: "鄂温克族自治旗",
    pid: "150000",
    id: "150724",
    cid: "150700"
  },
  {
    name: "阿荣旗",
    pid: "150000",
    id: "150721",
    cid: "150700"
  },
  {
    name: "莫力达瓦达斡尔族自治旗",
    pid: "150000",
    id: "150722",
    cid: "150700"
  },
  {
    name: "根河市",
    pid: "150000",
    id: "150785",
    cid: "150700"
  },
  {
    name: "扎兰屯市",
    pid: "150000",
    id: "150783",
    cid: "150700"
  },
  {
    name: "额尔古纳市",
    pid: "150000",
    id: "150784",
    cid: "150700"
  },
  {
    name: "满洲里市",
    pid: "150000",
    id: "150781",
    cid: "150700"
  },
  {
    name: "牙克石市",
    pid: "150000",
    id: "150782",
    cid: "150700"
  },
  {
    name: "喀喇沁旗",
    pid: "150000",
    id: "150428",
    cid: "150400"
  },
  {
    name: "宁城县",
    pid: "150000",
    id: "150429",
    cid: "150400"
  },
  {
    name: "松山区",
    pid: "150000",
    id: "150404",
    cid: "150400"
  },
  {
    name: "翁牛特旗",
    pid: "150000",
    id: "150426",
    cid: "150400"
  },
  {
    name: "红山区",
    pid: "150000",
    id: "150402",
    cid: "150400"
  },
  {
    name: "林西县",
    pid: "150000",
    id: "150424",
    cid: "150400"
  },
  {
    name: "元宝山区",
    pid: "150000",
    id: "150403",
    cid: "150400"
  },
  {
    name: "克什克腾旗",
    pid: "150000",
    id: "150425",
    cid: "150400"
  },
  {
    name: "巴林左旗",
    pid: "150000",
    id: "150422",
    cid: "150400"
  },
  {
    name: "巴林右旗",
    pid: "150000",
    id: "150423",
    cid: "150400"
  },
  {
    name: "阿鲁科尔沁旗",
    pid: "150000",
    id: "150421",
    cid: "150400"
  },
  {
    name: "敖汉旗",
    pid: "150000",
    id: "150430",
    cid: "150400"
  },
  {
    name: "奈曼旗",
    pid: "150000",
    id: "150525",
    cid: "150500"
  },
  {
    name: "扎鲁特旗",
    pid: "150000",
    id: "150526",
    cid: "150500"
  },
  {
    name: "开鲁县",
    pid: "150000",
    id: "150523",
    cid: "150500"
  },
  {
    name: "科尔沁区",
    pid: "150000",
    id: "150502",
    cid: "150500"
  },
  {
    name: "库伦旗",
    pid: "150000",
    id: "150524",
    cid: "150500"
  },
  {
    name: "科尔沁左翼中旗",
    pid: "150000",
    id: "150521",
    cid: "150500"
  },
  {
    name: "科尔沁左翼后旗",
    pid: "150000",
    id: "150522",
    cid: "150500"
  },
  {
    name: "霍林郭勒市",
    pid: "150000",
    id: "150581",
    cid: "150500"
  },
  {
    name: "白云鄂博矿区",
    pid: "150000",
    id: "150206",
    cid: "150200"
  },
  {
    name: "九原区",
    pid: "150000",
    id: "150207",
    cid: "150200"
  },
  {
    name: "青山区",
    pid: "150000",
    id: "150204",
    cid: "150200"
  },
  {
    name: "石拐区",
    pid: "150000",
    id: "150205",
    cid: "150200"
  },
  {
    name: "东河区",
    pid: "150000",
    id: "150202",
    cid: "150200"
  },
  {
    name: "昆都仑区",
    pid: "150000",
    id: "150203",
    cid: "150200"
  },
  {
    name: "固阳县",
    pid: "150000",
    id: "150222",
    cid: "150200"
  },
  {
    name: "达尔罕茂明安联合旗",
    pid: "150000",
    id: "150223",
    cid: "150200"
  },
  {
    name: "土默特右旗",
    pid: "150000",
    id: "150221",
    cid: "150200"
  },
  {
    name: "正镶白旗",
    pid: "150000",
    id: "152529",
    cid: "152500"
  },
  {
    name: "镶黄旗",
    pid: "150000",
    id: "152528",
    cid: "152500"
  },
  {
    name: "太仆寺旗",
    pid: "150000",
    id: "152527",
    cid: "152500"
  },
  {
    name: "西乌珠穆沁旗",
    pid: "150000",
    id: "152526",
    cid: "152500"
  },
  {
    name: "东乌珠穆沁旗",
    pid: "150000",
    id: "152525",
    cid: "152500"
  },
  {
    name: "锡林浩特市",
    pid: "150000",
    id: "152502",
    cid: "152500"
  },
  {
    name: "苏尼特右旗",
    pid: "150000",
    id: "152524",
    cid: "152500"
  },
  {
    name: "二连浩特市",
    pid: "150000",
    id: "152501",
    cid: "152500"
  },
  {
    name: "苏尼特左旗",
    pid: "150000",
    id: "152523",
    cid: "152500"
  },
  {
    name: "阿巴嘎旗",
    pid: "150000",
    id: "152522",
    cid: "152500"
  },
  {
    name: "多伦县",
    pid: "150000",
    id: "152531",
    cid: "152500"
  },
  {
    name: "正蓝旗",
    pid: "150000",
    id: "152530",
    cid: "152500"
  },
  {
    name: "海南区",
    pid: "150000",
    id: "150303",
    cid: "150300"
  },
  {
    name: "乌达区",
    pid: "150000",
    id: "150304",
    cid: "150300"
  },
  {
    name: "海勃湾区",
    pid: "150000",
    id: "150302",
    cid: "150300"
  },
  {
    name: "赛罕区",
    pid: "150000",
    id: "150105",
    cid: "150100"
  },
  {
    name: "回民区",
    pid: "150000",
    id: "150103",
    cid: "150100"
  },
  {
    name: "武川县",
    pid: "150000",
    id: "150125",
    cid: "150100"
  },
  {
    name: "玉泉区",
    pid: "150000",
    id: "150104",
    cid: "150100"
  },
  {
    name: "和林格尔县",
    pid: "150000",
    id: "150123",
    cid: "150100"
  },
  {
    name: "新城区",
    pid: "150000",
    id: "150102",
    cid: "150100"
  },
  {
    name: "清水河县",
    pid: "150000",
    id: "150124",
    cid: "150100"
  },
  {
    name: "土默特左旗",
    pid: "150000",
    id: "150121",
    cid: "150100"
  },
  {
    name: "托克托县",
    pid: "150000",
    id: "150122",
    cid: "150100"
  },
  {
    name: "阿尔山市",
    pid: "150000",
    id: "152202",
    cid: "152200"
  },
  {
    name: "突泉县",
    pid: "150000",
    id: "152224",
    cid: "152200"
  },
  {
    name: "乌兰浩特市",
    pid: "150000",
    id: "152201",
    cid: "152200"
  },
  {
    name: "扎赉特旗",
    pid: "150000",
    id: "152223",
    cid: "152200"
  },
  {
    name: "科尔沁右翼中旗",
    pid: "150000",
    id: "152222",
    cid: "152200"
  },
  {
    name: "科尔沁右翼前旗",
    pid: "150000",
    id: "152221",
    cid: "152200"
  },
  {
    name: "贡嘎县",
    pid: "540000",
    id: "540522",
    cid: "540500"
  },
  {
    name: "桑日县",
    pid: "540000",
    id: "540523",
    cid: "540500"
  },
  {
    name: "乃东区",
    pid: "540000",
    id: "540502",
    cid: "540500"
  },
  {
    name: "琼结县",
    pid: "540000",
    id: "540524",
    cid: "540500"
  },
  {
    name: "曲松县",
    pid: "540000",
    id: "540525",
    cid: "540500"
  },
  {
    name: "错那县",
    pid: "540000",
    id: "540530",
    cid: "540500"
  },
  {
    name: "浪卡子县",
    pid: "540000",
    id: "540531",
    cid: "540500"
  },
  {
    name: "扎囊县",
    pid: "540000",
    id: "540521",
    cid: "540500"
  },
  {
    name: "措美县",
    pid: "540000",
    id: "540526",
    cid: "540500"
  },
  {
    name: "洛扎县",
    pid: "540000",
    id: "540527",
    cid: "540500"
  },
  {
    name: "加查县",
    pid: "540000",
    id: "540528",
    cid: "540500"
  },
  {
    name: "隆子县",
    pid: "540000",
    id: "540529",
    cid: "540500"
  },
  {
    name: "达孜县",
    pid: "540000",
    id: "540126",
    cid: "540100"
  },
  {
    name: "墨竹工卡县",
    pid: "540000",
    id: "540127",
    cid: "540100"
  },
  {
    name: "当雄县",
    pid: "540000",
    id: "540122",
    cid: "540100"
  },
  {
    name: "尼木县",
    pid: "540000",
    id: "540123",
    cid: "540100"
  },
  {
    name: "城关区",
    pid: "540000",
    id: "540102",
    cid: "540100"
  },
  {
    name: "曲水县",
    pid: "540000",
    id: "540124",
    cid: "540100"
  },
  {
    name: "堆龙德庆区",
    pid: "540000",
    id: "540103",
    cid: "540100"
  },
  {
    name: "林周县",
    pid: "540000",
    id: "540121",
    cid: "540100"
  },
  {
    name: "申扎县",
    pid: "540000",
    id: "542426",
    cid: "542400"
  },
  {
    name: "安多县",
    pid: "540000",
    id: "542425",
    cid: "542400"
  },
  {
    name: "班戈县",
    pid: "540000",
    id: "542428",
    cid: "542400"
  },
  {
    name: "索县",
    pid: "540000",
    id: "542427",
    cid: "542400"
  },
  {
    name: "嘉黎县",
    pid: "540000",
    id: "542422",
    cid: "542400"
  },
  {
    name: "那曲县",
    pid: "540000",
    id: "542421",
    cid: "542400"
  },
  {
    name: "聂荣县",
    pid: "540000",
    id: "542424",
    cid: "542400"
  },
  {
    name: "比如县",
    pid: "540000",
    id: "542423",
    cid: "542400"
  },
  {
    name: "双湖县",
    pid: "540000",
    id: "542431",
    cid: "542400"
  },
  {
    name: "尼玛县",
    pid: "540000",
    id: "542430",
    cid: "542400"
  },
  {
    name: "巴青县",
    pid: "540000",
    id: "542429",
    cid: "542400"
  },
  {
    name: "拉孜县",
    pid: "540000",
    id: "540225",
    cid: "540200"
  },
  {
    name: "萨嘎县",
    pid: "540000",
    id: "540236",
    cid: "540200"
  },
  {
    name: "昂仁县",
    pid: "540000",
    id: "540226",
    cid: "540200"
  },
  {
    name: "岗巴县",
    pid: "540000",
    id: "540237",
    cid: "540200"
  },
  {
    name: "谢通门县",
    pid: "540000",
    id: "540227",
    cid: "540200"
  },
  {
    name: "白朗县",
    pid: "540000",
    id: "540228",
    cid: "540200"
  },
  {
    name: "南木林县",
    pid: "540000",
    id: "540221",
    cid: "540200"
  },
  {
    name: "仲巴县",
    pid: "540000",
    id: "540232",
    cid: "540200"
  },
  {
    name: "江孜县",
    pid: "540000",
    id: "540222",
    cid: "540200"
  },
  {
    name: "亚东县",
    pid: "540000",
    id: "540233",
    cid: "540200"
  },
  {
    name: "定日县",
    pid: "540000",
    id: "540223",
    cid: "540200"
  },
  {
    name: "吉隆县",
    pid: "540000",
    id: "540234",
    cid: "540200"
  },
  {
    name: "桑珠孜区",
    pid: "540000",
    id: "540202",
    cid: "540200"
  },
  {
    name: "萨迦县",
    pid: "540000",
    id: "540224",
    cid: "540200"
  },
  {
    name: "聂拉木县",
    pid: "540000",
    id: "540235",
    cid: "540200"
  },
  {
    name: "仁布县",
    pid: "540000",
    id: "540229",
    cid: "540200"
  },
  {
    name: "康马县",
    pid: "540000",
    id: "540230",
    cid: "540200"
  },
  {
    name: "定结县",
    pid: "540000",
    id: "540231",
    cid: "540200"
  },
  {
    name: "卡若区",
    pid: "540000",
    id: "540302",
    cid: "540300"
  },
  {
    name: "丁青县",
    pid: "540000",
    id: "540324",
    cid: "540300"
  },
  {
    name: "察雅县",
    pid: "540000",
    id: "540325",
    cid: "540300"
  },
  {
    name: "八宿县",
    pid: "540000",
    id: "540326",
    cid: "540300"
  },
  {
    name: "左贡县",
    pid: "540000",
    id: "540327",
    cid: "540300"
  },
  {
    name: "江达县",
    pid: "540000",
    id: "540321",
    cid: "540300"
  },
  {
    name: "贡觉县",
    pid: "540000",
    id: "540322",
    cid: "540300"
  },
  {
    name: "类乌齐县",
    pid: "540000",
    id: "540323",
    cid: "540300"
  },
  {
    name: "边坝县",
    pid: "540000",
    id: "540330",
    cid: "540300"
  },
  {
    name: "芒康县",
    pid: "540000",
    id: "540328",
    cid: "540300"
  },
  {
    name: "洛隆县",
    pid: "540000",
    id: "540329",
    cid: "540300"
  },
  {
    name: "墨脱县",
    pid: "540000",
    id: "540423",
    cid: "540400"
  },
  {
    name: "巴宜区",
    pid: "540000",
    id: "540402",
    cid: "540400"
  },
  {
    name: "波密县",
    pid: "540000",
    id: "540424",
    cid: "540400"
  },
  {
    name: "察隅县",
    pid: "540000",
    id: "540425",
    cid: "540400"
  },
  {
    name: "朗县",
    pid: "540000",
    id: "540426",
    cid: "540400"
  },
  {
    name: "工布江达县",
    pid: "540000",
    id: "540421",
    cid: "540400"
  },
  {
    name: "米林县",
    pid: "540000",
    id: "540422",
    cid: "540400"
  },
  {
    name: "革吉县",
    pid: "540000",
    id: "542525",
    cid: "542500"
  },
  {
    name: "日土县",
    pid: "540000",
    id: "542524",
    cid: "542500"
  },
  {
    name: "措勤县",
    pid: "540000",
    id: "542527",
    cid: "542500"
  },
  {
    name: "改则县",
    pid: "540000",
    id: "542526",
    cid: "542500"
  },
  {
    name: "普兰县",
    pid: "540000",
    id: "542521",
    cid: "542500"
  },
  {
    name: "噶尔县",
    pid: "540000",
    id: "542523",
    cid: "542500"
  },
  {
    name: "札达县",
    pid: "540000",
    id: "542522",
    cid: "542500"
  },
  {
    name: "覃塘区",
    pid: "450000",
    id: "450804",
    cid: "450800"
  },
  {
    name: "港南区",
    pid: "450000",
    id: "450803",
    cid: "450800"
  },
  {
    name: "平南县",
    pid: "450000",
    id: "450821",
    cid: "450800"
  },
  {
    name: "港北区",
    pid: "450000",
    id: "450802",
    cid: "450800"
  },
  {
    name: "桂平市",
    pid: "450000",
    id: "450881",
    cid: "450800"
  },
  {
    name: "浦北县",
    pid: "450000",
    id: "450722",
    cid: "450700"
  },
  {
    name: "钦北区",
    pid: "450000",
    id: "450703",
    cid: "450700"
  },
  {
    name: "钦南区",
    pid: "450000",
    id: "450702",
    cid: "450700"
  },
  {
    name: "灵山县",
    pid: "450000",
    id: "450721",
    cid: "450700"
  },
  {
    name: "福绵区",
    pid: "450000",
    id: "450903",
    cid: "450900"
  },
  {
    name: "玉州区",
    pid: "450000",
    id: "450902",
    cid: "450900"
  },
  {
    name: "兴业县",
    pid: "450000",
    id: "450924",
    cid: "450900"
  },
  {
    name: "容县",
    pid: "450000",
    id: "450921",
    cid: "450900"
  },
  {
    name: "博白县",
    pid: "450000",
    id: "450923",
    cid: "450900"
  },
  {
    name: "陆川县",
    pid: "450000",
    id: "450922",
    cid: "450900"
  },
  {
    name: "北流市",
    pid: "450000",
    id: "450981",
    cid: "450900"
  },
  {
    name: "岑溪市",
    pid: "450000",
    id: "450481",
    cid: "450400"
  },
  {
    name: "万秀区",
    pid: "450000",
    id: "450403",
    cid: "450400"
  },
  {
    name: "龙圩区",
    pid: "450000",
    id: "450406",
    cid: "450400"
  },
  {
    name: "长洲区",
    pid: "450000",
    id: "450405",
    cid: "450400"
  },
  {
    name: "藤县",
    pid: "450000",
    id: "450422",
    cid: "450400"
  },
  {
    name: "苍梧县",
    pid: "450000",
    id: "450421",
    cid: "450400"
  },
  {
    name: "蒙山县",
    pid: "450000",
    id: "450423",
    cid: "450400"
  },
  {
    name: "七星区",
    pid: "450000",
    id: "450305",
    cid: "450300"
  },
  {
    name: "灌阳县",
    pid: "450000",
    id: "450327",
    cid: "450300"
  },
  {
    name: "象山区",
    pid: "450000",
    id: "450304",
    cid: "450300"
  },
  {
    name: "永福县",
    pid: "450000",
    id: "450326",
    cid: "450300"
  },
  {
    name: "资源县",
    pid: "450000",
    id: "450329",
    cid: "450300"
  },
  {
    name: "龙胜各族自治县",
    pid: "450000",
    id: "450328",
    cid: "450300"
  },
  {
    name: "临桂区",
    pid: "450000",
    id: "450312",
    cid: "450300"
  },
  {
    name: "灵川县",
    pid: "450000",
    id: "450323",
    cid: "450300"
  },
  {
    name: "雁山区",
    pid: "450000",
    id: "450311",
    cid: "450300"
  },
  {
    name: "叠彩区",
    pid: "450000",
    id: "450303",
    cid: "450300"
  },
  {
    name: "兴安县",
    pid: "450000",
    id: "450325",
    cid: "450300"
  },
  {
    name: "秀峰区",
    pid: "450000",
    id: "450302",
    cid: "450300"
  },
  {
    name: "全州县",
    pid: "450000",
    id: "450324",
    cid: "450300"
  },
  {
    name: "平乐县",
    pid: "450000",
    id: "450330",
    cid: "450300"
  },
  {
    name: "阳朔县",
    pid: "450000",
    id: "450321",
    cid: "450300"
  },
  {
    name: "恭城瑶族自治县",
    pid: "450000",
    id: "450332",
    cid: "450300"
  },
  {
    name: "荔浦县",
    pid: "450000",
    id: "450331",
    cid: "450300"
  },
  {
    name: "凭祥市",
    pid: "450000",
    id: "451481",
    cid: "451400"
  },
  {
    name: "龙州县",
    pid: "450000",
    id: "451423",
    cid: "451400"
  },
  {
    name: "宁明县",
    pid: "450000",
    id: "451422",
    cid: "451400"
  },
  {
    name: "天等县",
    pid: "450000",
    id: "451425",
    cid: "451400"
  },
  {
    name: "江州区",
    pid: "450000",
    id: "451402",
    cid: "451400"
  },
  {
    name: "大新县",
    pid: "450000",
    id: "451424",
    cid: "451400"
  },
  {
    name: "扶绥县",
    pid: "450000",
    id: "451421",
    cid: "451400"
  },
  {
    name: "港口区",
    pid: "450000",
    id: "450602",
    cid: "450600"
  },
  {
    name: "防城区",
    pid: "450000",
    id: "450603",
    cid: "450600"
  },
  {
    name: "上思县",
    pid: "450000",
    id: "450621",
    cid: "450600"
  },
  {
    name: "东兴市",
    pid: "450000",
    id: "450681",
    cid: "450600"
  },
  {
    name: "银海区",
    pid: "450000",
    id: "450503",
    cid: "450500"
  },
  {
    name: "海城区",
    pid: "450000",
    id: "450502",
    cid: "450500"
  },
  {
    name: "合浦县",
    pid: "450000",
    id: "450521",
    cid: "450500"
  },
  {
    name: "铁山港区",
    pid: "450000",
    id: "450512",
    cid: "450500"
  },
  {
    name: "平桂区",
    pid: "450000",
    id: "451103",
    cid: "451100"
  },
  {
    name: "钟山县",
    pid: "450000",
    id: "451122",
    cid: "451100"
  },
  {
    name: "昭平县",
    pid: "450000",
    id: "451121",
    cid: "451100"
  },
  {
    name: "八步区",
    pid: "450000",
    id: "451102",
    cid: "451100"
  },
  {
    name: "富川瑶族自治县",
    pid: "450000",
    id: "451123",
    cid: "451100"
  },
  {
    name: "西林县",
    pid: "450000",
    id: "451030",
    cid: "451000"
  },
  {
    name: "田阳县",
    pid: "450000",
    id: "451021",
    cid: "451000"
  },
  {
    name: "隆林各族自治县",
    pid: "450000",
    id: "451031",
    cid: "451000"
  },
  {
    name: "靖西市",
    pid: "450000",
    id: "451081",
    cid: "451000"
  },
  {
    name: "凌云县",
    pid: "450000",
    id: "451027",
    cid: "451000"
  },
  {
    name: "那坡县",
    pid: "450000",
    id: "451026",
    cid: "451000"
  },
  {
    name: "田林县",
    pid: "450000",
    id: "451029",
    cid: "451000"
  },
  {
    name: "乐业县",
    pid: "450000",
    id: "451028",
    cid: "451000"
  },
  {
    name: "平果县",
    pid: "450000",
    id: "451023",
    cid: "451000"
  },
  {
    name: "田东县",
    pid: "450000",
    id: "451022",
    cid: "451000"
  },
  {
    name: "右江区",
    pid: "450000",
    id: "451002",
    cid: "451000"
  },
  {
    name: "德保县",
    pid: "450000",
    id: "451024",
    cid: "451000"
  },
  {
    name: "柳江区",
    pid: "450000",
    id: "450206",
    cid: "450200"
  },
  {
    name: "柳北区",
    pid: "450000",
    id: "450205",
    cid: "450200"
  },
  {
    name: "城中区",
    pid: "450000",
    id: "450202",
    cid: "450200"
  },
  {
    name: "融安县",
    pid: "450000",
    id: "450224",
    cid: "450200"
  },
  {
    name: "鹿寨县",
    pid: "450000",
    id: "450223",
    cid: "450200"
  },
  {
    name: "柳南区",
    pid: "450000",
    id: "450204",
    cid: "450200"
  },
  {
    name: "三江侗族自治县",
    pid: "450000",
    id: "450226",
    cid: "450200"
  },
  {
    name: "鱼峰区",
    pid: "450000",
    id: "450203",
    cid: "450200"
  },
  {
    name: "融水苗族自治县",
    pid: "450000",
    id: "450225",
    cid: "450200"
  },
  {
    name: "柳城县",
    pid: "450000",
    id: "450222",
    cid: "450200"
  },
  {
    name: "合山市",
    pid: "450000",
    id: "451381",
    cid: "451300"
  },
  {
    name: "兴宾区",
    pid: "450000",
    id: "451302",
    cid: "451300"
  },
  {
    name: "金秀瑶族自治县",
    pid: "450000",
    id: "451324",
    cid: "451300"
  },
  {
    name: "武宣县",
    pid: "450000",
    id: "451323",
    cid: "451300"
  },
  {
    name: "象州县",
    pid: "450000",
    id: "451322",
    cid: "451300"
  },
  {
    name: "忻城县",
    pid: "450000",
    id: "451321",
    cid: "451300"
  },
  {
    name: "西乡塘区",
    pid: "450000",
    id: "450107",
    cid: "450100"
  },
  {
    name: "邕宁区",
    pid: "450000",
    id: "450109",
    cid: "450100"
  },
  {
    name: "良庆区",
    pid: "450000",
    id: "450108",
    cid: "450100"
  },
  {
    name: "青秀区",
    pid: "450000",
    id: "450103",
    cid: "450100"
  },
  {
    name: "上林县",
    pid: "450000",
    id: "450125",
    cid: "450100"
  },
  {
    name: "兴宁区",
    pid: "450000",
    id: "450102",
    cid: "450100"
  },
  {
    name: "马山县",
    pid: "450000",
    id: "450124",
    cid: "450100"
  },
  {
    name: "江南区",
    pid: "450000",
    id: "450105",
    cid: "450100"
  },
  {
    name: "横县",
    pid: "450000",
    id: "450127",
    cid: "450100"
  },
  {
    name: "宾阳县",
    pid: "450000",
    id: "450126",
    cid: "450100"
  },
  {
    name: "武鸣区",
    pid: "450000",
    id: "450110",
    cid: "450100"
  },
  {
    name: "隆安县",
    pid: "450000",
    id: "450123",
    cid: "450100"
  },
  {
    name: "大化瑶族自治县",
    pid: "450000",
    id: "451229",
    cid: "451200"
  },
  {
    name: "都安瑶族自治县",
    pid: "450000",
    id: "451228",
    cid: "451200"
  },
  {
    name: "宜州市",
    pid: "450000",
    id: "451281",
    cid: "451200"
  },
  {
    name: "罗城仫佬族自治县",
    pid: "450000",
    id: "451225",
    cid: "451200"
  },
  {
    name: "金城江区",
    pid: "450000",
    id: "451202",
    cid: "451200"
  },
  {
    name: "东兰县",
    pid: "450000",
    id: "451224",
    cid: "451200"
  },
  {
    name: "巴马瑶族自治县",
    pid: "450000",
    id: "451227",
    cid: "451200"
  },
  {
    name: "环江毛南族自治县",
    pid: "450000",
    id: "451226",
    cid: "451200"
  },
  {
    name: "南丹县",
    pid: "450000",
    id: "451221",
    cid: "451200"
  },
  {
    name: "凤山县",
    pid: "450000",
    id: "451223",
    cid: "451200"
  },
  {
    name: "天峨县",
    pid: "450000",
    id: "451222",
    cid: "451200"
  }
]
const cityData = {
  AP: AP,
  AC: AC,
  AD: AD
}
export default cityData;