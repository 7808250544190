import { render, staticRenderFns } from "./CurrentPrize.vue?vue&type=template&id=2bcb6d06&scoped=true"
import script from "./CurrentPrize.vue?vue&type=script&lang=js"
export * from "./CurrentPrize.vue?vue&type=script&lang=js"
import style0 from "./CurrentPrize.vue?vue&type=style&index=0&id=2bcb6d06&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bcb6d06",
  null
  
)

export default component.exports