<template>
  <div class="footerContent container">
    <div class="footerTop">
      <div class="container_box">
        <p>
          <img src="@/assets/images/footer/economize.png" alt="省" />
          品类齐全 省心购物
        </p>
        <p>
          <img src="@/assets/images/footer/excellent.png" alt="优" />
          品质保证 省心售后
        </p>
        <p>
          <img src="@/assets/images/footer/fast.png" alt="快" />
          物流直配 轻松到家
        </p>
        <p>
          <img src="@/assets/images/footer/integrity.png" alt="信" />
          诚信经营 值得信赖
        </p>
      </div>
    </div>
    <div class="footerMain container_box">
      <div class="ecodeImgBox">
        <div v-if="companyConfigData && companyConfigData.wechatDownloadImgUrl && companyConfigData.wechatDownloadImgUrl !== ''" class="weixinCode">
          <img
            :src="companyConfigData.wechatDownloadImgUrl" alt="" />
          <p>关注微信公众号</p>
        </div>
        <div v-if="companyConfigData && companyConfigData.appDownloadImgUrl && companyConfigData.appDownloadImgUrl !== ''" class="appCode">
          <img
            :src="companyConfigData.appDownloadImgUrl" alt="" />
          <p>移动端APP下载</p>
        </div>
      </div>
      <div class="footerUserInfo">
        <div class="footerItem" v-for="(item, index) of articleList" :key="'articleItem' + index">
          <h5>{{ item.name }}</h5>
          <div class="actList">
            <router-link :to="{
              name: 'articleInfo',
              query: { id: itemC.id },
            }" target="_blank" v-for="(itemC, indexC) of item.noticeDetailList" :key="'noticeDetail' + indexC">
              {{ itemC.title }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="copyRight container_box">
      <p v-if="companyConfigData && companyConfigData.recordNoList && companyConfigData.recordNoList.length > 0"
        class="recordNoList">
        <span v-for="(item, index) of companyConfigData.recordNoList" :key="'recordNo' + index">
          {{ item.name }}：
          <a v-if="item.url && item.url !== ''" :href="item.url" target="_blank">
            {{ item.value }}
          </a>
          <a v-else href="javascript:;">
            {{ item.value }}
          </a>
        </span>
      </p>
      <p>© 2021-2023 <a href="https://www.combrain.net" target="_blank">江药集团科技大连有限公司</a> 版权所有</p>
      <p>本网站未发布毒性药品、麻醉药品、精神药品、放射性药品、戒毒药品和医疗机构制剂的产品信息</p>
    </div>
  </div>
</template>
<script>
import { getAction } from "../../api/manage";
import Vue from "vue";
import { mapGetters } from 'vuex';
export default {
  name: "Footer",
  data() {
    return {
      articleList: [],
    };
  },
  created() {
    this.getArticleList();
  },
  methods: {
    async getArticleList() {
      let url = "notice/noticeBelow/list";
      let res = await getAction(url);
      if (res.code == 200 && res.success) {
        console.log("获取的帮助中心列表为++++++", res);
        let resData = res.result;
        this.articleList = resData.records;
        if (resData.records && resData.records.length > 0) {
          Vue.ls.set("articleListData", JSON.stringify(resData.records))
        }
      }
    },
  },
  computed: {
    ...mapGetters(['companyConfigData'])
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/footer";
</style>