<template>
  <div>
    <div v-if="!paySuccess" class="orderDetailBox container_box clear">
      <div class="leftBox">
        <div class="detail centerBox">
          <h3 class="backOrderBtn">
            <a style="padding-left: 10px" href="javascript:;"> 订单支付 </a>
          </h3>
          <div class="orderHeaderBox">
            <p>订单编号：{{ saleOrder.id }}</p>
            <p>订单时间：{{ saleOrder.createTime }}</p>
            <p>订单状态：待确认</p>
            <p>
              付款类型：{{ saleOrder.feeType === 1 ? "线下支付" : "线上支付" }}
            </p>
          </div>
          <div class="orderMoney">
            <p>订单金额：{{ saleOrder.originalSaleMoney }}元</p>
            <p v-if="saleOrder.confirmDiscountMoney > 0">
              优惠金额：{{ saleOrder.confirmDiscountMoney }}元
            </p>
            <p v-if="saleOrder.oosMoney > 0">
              缺货金额：{{ saleOrder.oosMoney }}元
            </p>
            <p v-if="saleOrder.logisticsMoney > 0">
              运费金额：{{ saleOrder.logisticsMoney }}元
            </p>
            <p class="payMoney">付款金额：{{ saleOrder.payMoney }}元</p>
          </div>
          <div class="addressBox">
            <p v-if="discountTicketList.length > 0">
              <i class="ticketIcon"></i>
              优惠券：{{ discountTicketList[0].name }}
            </p>
            <p v-if="false" class="address">
              <i class="addressIcon"></i>
              收货：张三（13802123145）河北省保定市七一东路未来石
            </p>
          </div>
        </div>
      </div>
      <!-- 选择支付方式操作 -->
      <div class="OrderRightBox">
        <div class="selectPayType" v-for="(item, index) of orderPayList" :key="index">
          <div :class="{ 'selected-pay-box': (payType == item.type) }">
            <div @click="payType = item.type" class="title">
              <img v-if="item.type == 1" class="title-icon" src="../assets/images/orderPay/saoma-icon.png" alt="">
              <img v-else-if="item.type == 2" class="title-icon" src="../assets/images/orderPay/other-pay-icon.png"
                alt="">
              <span>{{ item.title }}</span>
              <p class="title-tip">{{ item.tips }}</p>
              <div v-if="item.type == 2" @click="cretaOrderPayImg" class="crete-pay-order">生成二维码分享图</div>
            </div>
            <!-- 二维码支付   -->
            <div v-if="payType == 1 && payType == item.type" class="ecodeContent pay-content">
              <div class="ecodeBorder">
                <div v-if="liucheng == 0" @click="payCSS(0)" class="zhuangtai1 ecodeMain">
                  <i class="el-icon-refresh"></i>
                  <p>重新获取二维码</p>
                </div>
                <div v-else-if="liucheng == 1" class="zhuangtai1 ecodeMain">
                  <i class="el-icon-loading"></i>
                  <p>二维码加载中</p>
                </div>
                <div v-else-if="liucheng == 2" class="ecodeMain">
                  <img v-if="erweimaImg && erweimaImg !== ''" :src="erweimaImg" alt="二维码付款" />
                  <img v-else src="../assets/images/footer/ecode.png" alt="二维码图片" />
                </div>
              </div>
              <p class="payMoney">支付金额: {{ saleOrder.payMoney }}元</p>
            </div>
            <!-- 余额提示框 -->
            <div v-else-if="payType == 4 && payType == item.type" class="yueBox pay-content">
              <div class="yueTitle">
                <img src="../assets/images/orderPay/yuexIcon.png" alt="" />
                <p class="userYue">账户余额：{{ balance }}元</p>
              </div>
              <a v-if="yuePayType" @click="yuePay()" class="confirmPay" href="javascript:;">确定支付</a>
              <div v-else class="yebz">
                <i class="el-icon-warning-outline"></i>
                <span>当前余额不足，请换用其他支付方式</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="createOrderContent container_box">
      <div class="liucheng"></div>
      <div class="orderTip">
        <i class="successIcon"></i>
        <span>您的订单支付成功，请等待配货</span>
      </div>
      <div class="linkUrlBox">
        <router-link to="/" class="backIndex" href="javascript:;">
          返回首页
        </router-link>
        <router-link to="/orderList?state=3" class="linkOrderList" href="javascript:;">
          查看订单
        </router-link>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="325px" :before-close="handleClose">
      <div ref="preContent" class="pay-model-content">
        <div class="pay-header">
          <div class="logo-box">
            <img src="../assets/images/orderPay/logo-bg-white.png" alt="">
          </div>
          <div class="order-header-data">
            <h3>江药商城</h3>
            <p>订单编号：{{ saleOrder.id }}</p>
            <p>客户名称：{{ userName }}</p>
            <p>应付金额：￥{{ saleOrder.payMoney }}</p>
          </div>
        </div>
        <div class="qrcode-content">
          <div class="qrcode-box">
            <img :src="erweimaImg" alt="">
          </div>
          <p @click="saveQRcode" v-if="!hideBtnStatus" class="save-code-btn">
            保存二维码到本地
          </p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { getAction, postAction } from "../api/manage";
import { accAdd, getUserInfo, downloadFileByBase64 } from "../assets/js/common/common";
import { SHOPPING_ACCESS_TOKEN } from "../store/mutation-types";
export default {
  name: "OrderPay",
  data() {
    return {
      saleOrder: {},
      saleOrderId: "",
      liucheng: 0,
      payOrderId: "",
      payType: 1, // 默认微信支付
      order: {}, // 订单详情
      fee: {}, // 支付的详情
      timeName: "", // 定义定时器变量
      paySuccess: false, // 支付状态
      scratchStatus: false, // 刮奖免单的显示状态
      yuePayStatus: true,
      userName: "",
      erweimaImg: "",
      hideBtnStatus: false,
      balance: 0,
      orderPayList: [
        { type: 1, title: '扫码支付', tips: '使用微信或者支付宝扫码支付' },
        { type: 2, title: '他人代付', tips: '复制订单付款码发送给他人付款' }
      ],
      discountTicketList: [], //订单使用优惠券列表数据
      dialogVisible: false, // 分享层状态
    };
  },
  computed: {
    yuePayType() {
      let balance = this.balance;
      let payMoney = this.saleOrder.payMoney;
      if (balance > payMoney) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    let saleOrderId = this.$route.query.saleOrderId;
    let payOrderId = this.$route.query.payOrderId;
    this.saleOrderId = saleOrderId;
    this.payOrderId = payOrderId;
    let userInfo = getUserInfo();
    if (userInfo) {
      this.userName = userInfo.name;
      if (saleOrderId && payOrderId) {
        // 支付类型默认微信
        this.liucheng = 1;
        // 获取订单详情数据
        this.getOrderDeatil();
        // 加载二维码
        this.getPayOrderData();
        // 获取用户余额数据
        this.getCustomerBalance();
      }
    } else {
      this.$router.push("/login");
    }
  },
  // 实例销毁时清除定时器
  destroyed() {
    clearInterval(this.timeName);
  },
  // 1.微信app 2.支付宝app 3.银联 4.余额支付 5.中信聚合支付 6.公对公打款 7.江药商城扫码聚合支付 8.优惠券支付 9.免单
  methods: {
    // 保存当前二维码分享到本地
    saveQRcode() {
      let _this = this;
      this.hideBtnStatus = true;
      this.$nextTick(() => {
        let elementDom = _this.$refs.preContent
        html2canvas(elementDom, {
          dpi: 300, //按屏幕像素比增加像素
          backgroundColor: '#ffffff',
          useCORS: true, //是否使用CORS从服务器加载图像 !!!
          allowTaint: true, //是否允许跨域图像污染画布  !!!
        }).then((canvas) => {
          let imgData = canvas.toDataURL('image/jpeg')
          downloadFileByBase64(imgData, "客户支付码")
        }).catch(error => {
          _this.$alert("您当前浏览器不支持支付码生成，请切换其他浏览器尝试！", '提示信息', {
            type: 'error'
          })
        })
      })
    },
    // 生成二维码分享页
    cretaOrderPayImg() {
      this.dialogVisible = true
    },
    // 关闭当前的二维码分享页
    handleClose() {
      this.dialogVisible = false
      this.hideBtnStatus = false
    },
    // 获取用户的余额和药券数据
    getCustomerBalance() {
      let _this = this;
      let url = "/balance/customerBalance/query";
      getAction(url).then((res) => {
        if (res.code === 200 && res.success) {
          console.log("返回用户余额和药券的数据", res);
          let resData = res.result;
          if (resData) {
            _this.balance = resData.balanceMoney;
          }
        }
      });
    },
    // 重新加载二维码
    payCSS(cssN) {
      if (cssN === 0) {
        this.liucheng = 1;
        this.getPayOrderData();
      }
    },
    // 获取订单详情数据
    getOrderDeatil() {
      let that = this;
      let saleOrderId = this.saleOrderId;
      let url = "/saleOrder/saleOrder/querySaleOrder";
      let data = {
        saleOrderId: saleOrderId,
      };
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          console.log("订单详情的数据为++++++", res);
          let resData = res.result;
          that.saleOrder = resData.saleOrder;
          that.discountTicketList = resData.discountTicketList
        }
      });
    },
    // 获取订单支付的数据
    getPayOrderData() {
      // 开始轮询支付结果
      this.timeAgin();
      let _this = this;
      let payOrderId = this.payOrderId;
      let data = {
        payOrderId: payOrderId,
      };
      let url = "/pay/getErWeiCode";
      getAction(url, data).then((res) => {
        _this.erweimaImg = res; // 返回的支付的二维码的地址(base64位的)
        _this.liucheng = 2;
        // todo 现在没有返回二维码  先展示假的
        // _this.liucheng = 2;
        // if (res.code === 200) {
        //   _this.erweimaImg = res; // 返回的支付的二维码的地址(base64位的)
        //   _this.liucheng = 2;
        // }
      });
    },
    // 根据后台返回的地址生成对应的二维码
    qrcode(imgUrl) {
      // let qrcode = new QRCode('qrcode', {
      //   width: 200,
      //   height: 200,
      //   text: imgUrl, // 二维码地址
      //   colorDark: '#000',
      //   colorLight: '#fff'
      // })
    },
    // 初始请求支付订单的数据
    initialRequest() {
      let orderId = this.$route.query.orderId;
      let payOrderId = this.$route.query.payOrderId;
      let data = {
        feeSource: "1", // 初始为微信扫锚
        orderId: orderId,
        payOrderId: payOrderId,
      };
      let _this = this;
      let api = this.$api;
      let url = api + "order/payment.do";
    },
    // 支付金额
    payMoney() {
      let orderData = this.order;
      if (orderData.feeDiscountMoney && orderData.interest) {
      }
      let confirmMoney = orderData.confirmMoney;
      let feeDiscountMoney = orderData.feeDiscountMoney;
      let interest = orderData.interest;
      if (orderData.feeDiscountMoney !== 0 && orderData.interest !== 0) {
        return (confirmMoney - feeDiscountMoney - interest).toFixed(2);
      } else if (orderData.interest !== 0 && orderData.feeDiscountMoney === 0) {
        return (confirmMoney - interest).toFixed(2);
      } else if (orderData.interest === 0 && orderData.feeDiscountMoney !== 0) {
        return (confirmMoney - feeDiscountMoney).toFixed(2);
      } else {
        return confirmMoney.toFixed(2);
      }
    },
    // 余额支付
    yuePay() {
      clearInterval(this.timeName);
      let _this = this;
      let payOrderId = this.$route.query.payOrderId;
      let url = `/pay/balance/${payOrderId}`;
      postAction(url, null, true).then((res) => {
        if (res.code === 200 && res.success) {
          clearInterval(_this.timeName);
          _this.$alert("支付成功!", "提示信息:", { type: "success" });
          _this.paySuccess = true;
        }
      });
    },
    // 选择的支付方式
    paytypeAct(num) {
      let payOrderId = this.$route.query.payOrderId;
      this.payType = num;
      let _this = this;
      // 切换支付方式先关掉原来的定时器轮询
      clearInterval(this.timeName);
      if (num === 4) {
        return false;
      } else if (num === 12) {
        return false;
      } else if (num === 3) {
        let token = this.$ls.get(SHOPPING_ACCESS_TOKEN);

        // let url3 = `web/pay/unionPay/${payOrderId}?Shopping-Access-Token=${token}`;
        // window.open(url3);
      } else {
        this.liucheng = 1;
        // 重新加载二维码操作
        this.getPayOrderData();
      }
    },
    timeAgin() {
      let payOrderId = this.payOrderId;
      let _this = this;
      this.timeName = setInterval(function () {
        let url = "/pay/getStatus/" + payOrderId;
        getAction(url).then((res) => {
          if (res.code === 200 && res.success) {
            let payStatus = res.result;
            if (payStatus === 1) {
              _this.paySuccess = true;
              clearInterval(_this.timeName);
            }
          }
        });
      }, 5000);
    },
    closeScratchCard(data) {
      let _this = this;
      this.scratchStatus = 0;
      if (data === 1) {
        clearInterval(_this.timeName);
        _this.paySuccess = true; // 免单操作
      } else if (data === "0") {
        _this.paySuccess = false; // 继续支付
        // _this.initialRequest()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.orderDetailBox {
  @include clear;
  margin-top: 10px;
  margin-bottom: 25px;
}

@import "../assets/styles/order/left";
@import "../assets/styles/order/right";

.selectPayType {
  width: 860px;
  border: 2px solid #EFEFEF;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;

  .selected-pay-box {
    width: 100%;
    overflow: hidden;
    border: 2px solid #0F4C81;
  }

  .title {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 22px;
    margin-bottom: -2px;
    border-bottom: 2px solid #EFEFEF;

    .crete-pay-order {
      background-color: #0F4C81;
      color: #ffffff;
      width: 160px;
      height: 36px;
      border-radius: 6px;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
      line-height: 36px;
      margin-left: 56px;
    }

    span {
      font-size: 18px;
      font-weight: 400;
      font-weight: bold;
      color: #070707;
      margin-left: 8px;
    }

    .title-tip {
      font-size: 16px;
      color: #585858;
      font-weight: 400;
      margin-left: 80px;
    }

    .title-icon {
      width: 20px;
      height: 20px;
    }
  }

  .selectListBox {
    display: flex;
    justify-content: space-evenly;

    .activeItem {
      background-image: url("../assets/images/orderPay/selectedBg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border: none !important;
      box-shadow: 0px 0px 7px 5px rgba($color: #0f4c81, $alpha: 0.3);
    }

    .payItem {
      width: 200px;
      height: 80px;
      border-radius: 5px;
      border: 1px solid #d2d2d2;
      line-height: 78px;
      cursor: pointer;

      &:hover {
        border-width: 2px;
        border-color: #0f4c81;
      }

      img,
      p {
        float: left;
      }

      img {
        margin-top: 5px;
        padding: 0 5px;
      }

      p {
        font-size: 18px;
        color: #202020;
        line-height: 78px;
        letter-spacing: 2px;
        text-indent: 2px;
        font-weight: bold;
      }
    }

    .yinlianPay {
      img {
        padding: 0 15px;
      }
    }
  }
}

.yueBox {
  width: 500px;
  height: 300px;
  padding: 10px;
  margin: 50px auto;
  text-align: center;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/orderPay/yueBg.png");

  .yueTitle {
    @include clear;
    height: 80px;
    line-height: 80px;
    width: 100%;
    text-align: center;

    img,
    p {
      float: left;
    }

    p {
      font-size: 17px;
      font-weight: bold;
      color: #585858;
      letter-spacing: 2px;
      text-indent: 2px;
    }

    img {
      margin-top: 25px;
      margin-left: 125px;
      margin-right: 20px;
    }
  }

  .confirmPay {
    display: inline-block;
    line-height: 40px;
    width: 240px;
    height: 40px;
    background-color: $base-color;
    text-align: center;
    color: #ffffff;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 8px;
    text-indent: 8px;
    margin: 95px auto;

    &:hover {
      opacity: 0.8;
    }
  }

  .yebz {
    width: 100%;
    height: 50px;
    line-height: 50px;
    margin-top: 70px;

    i {
      font-size: 30px;
      color: $base-color;
      margin-top: 2px;
      margin-right: 10px;
    }

    i,
    span {
      vertical-align: middle;
    }

    span {
      color: #585858;
      font-size: 17px;
      letter-spacing: 2px;
      text-indent: 2px;
    }
  }
}

.ecodeContent {
  text-align: center;
  margin: 25px auto;

  .ecodeBorder {
    margin: 0 auto;
    width: 150px;
    height: 150px;
    background: url("../assets/images/orderPay/ecodeBorder.png") no-repeat center;
  }

  .ecodeMain {
    // padding: 18.5px;

    img {
      width: 150px;
      height: 150px;
    }
  }

  .zhuangtai1 {
    background-color: #000000;
    opacity: 0.6;
    width: 150px;
    height: 150px;
    text-align: center;
    cursor: pointer;

    i,
    p {
      color: #ffffff;
    }

    i {
      margin: 30px;
      font-size: 50px;
    }

    p {
      font-size: 16px;
      letter-spacing: 2px;
      text-indent: 2px;
    }
  }

  .payMoney {
    width: 100%;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    margin-top: 20px;
  }
}

.createOrderContent {
  width: 1223px;
  height: 358px;
  position: relative;
  background: url("~@/assets/images/order/create/createBg.png") no-repeat center;
  background-size: 100% 100%;

  .liucheng {
    position: absolute;
    left: 50%;
    top: 65px;
    transform: translate(-50%);
    width: 462px;
    height: 48px;
    margin: 39px auto;
    background: url("~@/assets/images/order/create/liucheng.png") no-repeat center;
  }

  .orderTip {
    font-size: 16px;
    color: $base-color;
    text-align: center;
    position: absolute;
    top: 225px;
    width: 100%;

    i {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-right: 12px;
      background: url("~@/assets/images/order/create/successIcon.png") no-repeat center;
    }

    i,
    span {
      vertical-align: middle;
    }

    span {
      letter-spacing: 1px;
      text-indent: 1px;
    }
  }

  .linkUrlBox {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 13px;
    bottom: 70px;

    a {
      color: $font-color;
      padding-left: 20px;

      &:hover {
        color: $base-color;
        text-decoration: underline;
      }
    }

    .backIndex {
      margin-right: 10px;
      background: url("~@/assets/images/order/create/backIndexIcon.png") no-repeat left center;
    }

    .linkOrderList {
      background: url("~@/assets/images/order/create/linkListIcon.png") no-repeat left center;
    }
  }
}

// 支付分享层样式
.pay-model-content {
  width: 285px;
  height: 428px;
  background: url("~@/assets/images/orderPay/pay-share-bg.png") no-repeat;
  background-size: 100% auto;
  display: flex;
  flex-direction: column;

  .pay-header {
    display: flex;
    flex-direction: row;
    padding: 18px;
    height: 120px;
    padding-bottom: 0;
    align-items: center;

    .logo-box {
      width: 67px;
      height: 67px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .order-header-data {
      height: 100%;

      h3,
      p {
        padding-left: 13px;
        width: 180px;
        height: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 20px;
        color: #ffffff;
        font-size: 12px;
        overflow: hidden;
      }

      h3 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
      }
    }

  }

  .qrcode-content {
    width: 100%;
    height: 305px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .qrcode-box {
      width: 170px;
      height: 170px;
      margin-top: 28px;

      img {
        width: 170px;
        height: 170px;
      }
    }

    .save-code-btn {
      cursor: pointer;
      width: 180px;
      height: 35px;
      color: #ffffff;
      background: linear-gradient(0deg, #2355F4, #4E7CF6);
      border-radius: 18px;
      line-height: 35px;
      font-size: 14px;
      margin-top: 28px;
      text-align: center;
    }
  }
}
</style>