import { render, staticRenderFns } from "./BrandZone.vue?vue&type=template&id=5e7db680&scoped=true"
import script from "./BrandZone.vue?vue&type=script&lang=js"
export * from "./BrandZone.vue?vue&type=script&lang=js"
import style0 from "./BrandZone.vue?vue&type=style&index=0&id=5e7db680&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e7db680",
  null
  
)

export default component.exports