<template>
  <div class="fastShoppingContent container">
    <div class="container_box">
      <div class="navList">
        <router-link to="/index">首页</router-link>
        <router-link class="active" to="/fastShopping">极速下单</router-link>
      </div>
      <div class="shoppingTable">
        <div class="searchTop">
          <div class="searchInputBox">
            <img
              class="searchIcon"
              src="../assets/images/fastShopping/searchIcon.png"
              alt=""
            />
            <input
              placeholder="请输入商品名称、首字母、批准文号进行检索"
              class="searchVal"
              ref="searchVal"
              v-model="searchVal"
              @keyup="searchProduct"
              @keyup.enter="searchProduct"
              type="text"
            />
            <button class="searchBtn">采购清单</button>
            <div v-show="showProductState" class="searchProductBox">
              <div class="scrollBox">
                <table id="searchProductList">
                  <tr id="thtr" class="thtr">
                    <th class="fxk"></th>
                    <th class="name">商品名称</th>
                    <th class="format">规格</th>
                    <th class="unit">单位</th>
                    <th class="manufacturer">厂家</th>
                    <th class="endDate">有效期</th>
                    <th class="price">单价</th>
                    <th class="inventory">库存</th>
                    <th class="amount">数量</th>
                  </tr>
                  <tbody class="tbody">
                    <el-scrollbar ref="scrollbar" class="scrollTableBody">
                      <tr
                        v-for="(item, index) of productList"
                        :class="{ active: index == selectProIndex }"
                        :key="'tableItem' + index"
                        @click="selectHandlePro(index)"
                      >
                        <td class="fxk">
                          <el-checkbox
                            @change="selectHandlePro(index)"
                            :value="
                              selectProIds.indexOf(item.id) > -1 ? true : false
                            "
                          ></el-checkbox>
                        </td>
                        <td class="name">{{ item.name }}</td>
                        <td class="format">{{ item.format }}</td>
                        <td class="unit">{{ item.unit }}</td>
                        <td class="manufacturer">
                          {{ item.manufacturer }}
                        </td>
                        <td class="endDate">
                          {{
                            item.inventoryList[0].endDate &&
                            item.inventoryList[0].endDate !== ""
                              ? item.inventoryList[0].endDate
                              : ""
                          }}
                        </td>

                        <td class="price" style="color: #fd2c17">
                          {{ showPrice(item) }}
                        </td>
                        <td class="inventory">
                          {{
                            item.inventoryNum > 0
                              ? item.inventoryNum > 999
                                ? "充裕"
                                : item.inventoryNum
                              : "无货"
                          }}
                        </td>
                        <td class="amount">
                          <div class="changeInput">
                            <input
                              :value="
                                item.inventoryList[0].addCartQuantity"
                              @change="
                                changeNum(item.inventoryList[0], item, $event)
                              "
                               oninput="value=value.replace(/[^0-9.]/g,'')"
                              ref="proInputNum"
                              type="text"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr class="loadingBottom" v-if="loading">
                        加载中...
                      </tr>
                      <tr class="loadingBottom" v-if="noMore">
                        没有更多了
                      </tr>
                    </el-scrollbar>
                  </tbody>
                </table>
              </div>
              <div class="tableActionBox">
                <button @click="keyupCancelOrder" class="cancelBtn">
                  取消(ESC)
                </button>
                <button @click="keyupEnter" class="confirmBtn">
                  确认(Enter)
                </button>
              </div>
              <div class="searchButtom">
                <p>
                  简易操作说明：按方向键控制浏览
                  <span class="el-icon el-icon-top"></span>或
                  <span class="el-icon el-icon-bottom"></span>,
                  点击<span>S</span>选中商品，输入数量，点击<span>C</span>取消选中，点击<span>Enter</span>将选中的商品加入清单
                </p>
              </div>
            </div>
          </div>
        </div>

        <el-checkbox-group
          v-model="checkedCart"
          @change="handleCheckedCitiesChange"
        >
          <table id="fastTableContent" v-loading="listOperatLoaing">
            <tr class="thtr">
              <th class="fxk"></th>
              <th class="name">商品名称</th>
              <th class="format">规格</th>
              <th class="unit">单位</th>
              <th class="manufacturer">厂家</th>
              <th class="endDate">有效期</th>
              <th class="price">单价</th>
              <th class="amount">数量</th>
              <th class="money">金额</th>
              <th class="action">操作</th>
            </tr>
            <tr
              v-for="(item, index) of shoppingCartList"
              :key="'tableItem' + index"
            >
              <td class="fxk">
                <el-checkbox :label="item.id"></el-checkbox>
              </td>
              <td class="name">{{ item.productName }}</td>
              <td class="format">{{ item.productFormat }}</td>
              <td class="unit">{{ item.productUnit }}</td>
              <td class="manufacturer">{{ item.productManufacturer }}</td>
              <td class="endDate">2021-09-30</td>
              <td class="price">￥ {{ item.price ? item.price : "--/--" }}</td>
              <td class="amount">
                <div class="changeInput">
                  <span @click="lessAmount(item)">-</span>
                  <input
                    @change="changeAmount(item, $event)"
                    onkeyup="item.amount=item.amount.replace(/[^0-9-]+/,'');"
                    :value="item.amount"
                    type="text"
                  />
                  <span @click="addAmount(item)">+</span>
                </div>
              </td>
              <td class="money">
                ￥{{ item.productMoney ? item.productMoney : "--/--" }}
              </td>
              <td class="action">
                <a @click="removeProduct(item)" href="JavaScript:;"> 删除 </a>
              </td>
            </tr>
          </table>
        </el-checkbox-group>
      </div>
      <div class="jionShopBox">
        <div class="actButtom">
          <el-checkbox
            class="allChecked"
            v-model="shopCartCheckAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <div @click="removeBatchCart" class="deleteIcon">
            <img
              src="../assets/images/fastShopping/delete.png"
              alt="批量删除"
            />
            批量删除
          </div>
        </div>
        <div class="cartDataBox">
          <p>
            已选品种<span>{{ checkedCart.length }}</span
            >种
          </p>
          <p>
            总金额：<span class="accMoney">￥{{ shopCartAllMoney }}</span>
          </p>
          <button v-if="!createOrderStatus" @click="createOrder">
            创建订单
          </button>
          <button disabled="true" v-else v-loading="createOrderStatus">
            创建订单
          </button>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="25%" height="15vh">
      <span>确定批量删除购物车?</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="hadleOk" @click="deleteShopCart">确定</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getAction, postAction } from "../api/manage";
import { ProductMixin } from "@/mixins/ProductMixin";
export default {
  name: "FastShopping",
  mixins: [ProductMixin],
  data() {
    return {
      isIndeterminate: false,
      checkStatus: false,
      showProductState: false,
      createOrderStatus: false,
      productList: [],
      actTicketData: {},
      shopCartCheckAll: true, // 清单全选状态操作
      shoppingCartList: [], // 用户添加快速下单的产品列表数据
      checkedCart: [], // 选中创建订单的产品id数组
      shopCartCheckIds: [], // 清单列表所有产品id的数组
      selectProIds: [], // 选中的产品id数组
      selectProList: [], // 选中的产品数据数组
      selectProIndex: -1,
      dialogVisible: false,
      listOperatLoaing: true,
      searchVal: "",
      productPage: {
        pageNo: 1, // 当前页数
        pages: 1, // 总页数
        pageSize: 20, // 每页显示的条数
        total: 0,
      },
      shopCartAllMoney: 0, // 订单的总金额
      loading: false,
      feeType: 0,
      orderStatus: false,
    };
  },
  computed: {
    noMore() {
      return this.productPage.pageNo >= this.productPage.pages;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  created() {
    this.getFastShoppingList();
  },
  mounted() {
    this.handleScroll();
    this.handleKeyup();
  },
  methods: {
    // 获取快速下单产品列表数据
    getFastShoppingList(choiceId) {
      let _this = this;
      let feeType = this.feeType;
      let data = {
        shoppingCartType: 2,
        feeType: feeType,
      };
      if (choiceId) {
        data.choiceId = choiceId;
      }
      let url = "/shopping/cart/getList";
      postAction(url, data).then((res) => {
        console.log("返回的快速下单产品列表", res);
        if (res.code === 200 && res.success) {
          let resData = res.result;
          // 执行渲染清单产品列表数据
          let checkedCart = this.checkedCart;
          let shopCartCheckIds = _this.shopCartCheckIds;
          if (
            typeof choiceId === "undefined" ||
            checkedCart === shopCartCheckIds
          ) {
            _this.renderShopCartData(resData, true);
          } else {
            _this.renderShopCartData(resData);
          }
        }
      });
    },
    // 点击指定产品选中操作
    selectHandlePro(index) {
      let selectProIndex = this.selectProIndex;
      if (selectProIndex == index) {
        this.keyupCancelPro();
        this.selectProIndex = -1;
      } else {
        this.selectProIndex = index;
        this.keyupSelectedPro();
      }
    },
    // 输入框快捷键操作
    handleKeyup() {
      let _this = this;
      window.onkeydown = function (e) {
        let showProductState = _this.showProductState;
        let selectProIndex = _this.selectProIndex;
        let productList = _this.productList;
        if (showProductState) {
          let keyCode = e.keyCode;
          if (keyCode == 38) {
            if (productList && productList.length > 0 && selectProIndex > 0) {
              e.preventDefault();
            }
            // 向上按键操作
            _this.$refs.searchVal.blur();
            console.log("向上按键操作");
            _this.keyupDrowUp();
          } else if (keyCode == 40) {
            if (
              productList &&
              productList.length > 0 &&
              selectProIndex < productList.length - 1
            ) {
              e.preventDefault();
            }
            _this.$refs.searchVal.blur();
            console.log("向下按键操作");
            // 向下按键操作
            _this.keyupDrownDown();
          } else if (keyCode == 67) {
            // 取消选中事件
            _this.keyupCancelPro();
            console.log("取消选中事件");
          }
        }
      };
      window.onkeyup = function (e) {
        let showProductState = _this.showProductState;
        if (showProductState) {
          let keyCode = e.keyCode;
          if (keyCode == 38) {
            let selectProIndex = _this.selectProIndex;
            if (selectProIndex == -1) {
              _this.$refs.searchVal.focus();
            }
          } else if (keyCode == 83) {
            // 选中产品事件
            console.log("选中产品事件");
            _this.keyupSelectedPro();
          } else if (keyCode == 27) {
            _this.keyupCancelOrder();
            console.log("取消下单操作事件");
          } else if (keyCode == 13) {
            console.log("添加到快速开单列表事件");
            _this.keyupEnter();
          }
        }
      };
    },
    // 快捷选中产品操作
    keyupSelectedPro() {
      let _this = this;
      let selectProIndex = _this.selectProIndex;
      let productList = _this.productList;
      if (selectProIndex > -1) {
        let selectProData = productList[selectProIndex];
        let selectProId = selectProData.id;
        let selectProIds = _this.selectProIds;
        if (selectProIds.indexOf(selectProId) < 0) {
          // 将选中的产品id放入selectProIds数组中
          _this.selectProIds.push(selectProId);
          _this.selectProList.push(selectProData);
        }
        // 执行选中产品的输入框focus事件
        _this.$refs.proInputNum[selectProIndex].select();
      }
    },
    // 快捷键(取消选中产品操作)
    keyupCancelPro() {
      let _this = this;
      let selectProIndex = _this.selectProIndex;
      let productList = _this.productList;
      if (selectProIndex > -1) {
        _this.$refs.proInputNum[selectProIndex].blur();
        let selectProData = productList[selectProIndex];
        let selectProId = selectProData.id;
        let selectProIds = this.selectProIds;
        // 检查取消选中的产品id在数组中的index值
        let proIndex = selectProIds.indexOf(selectProId);
        if (proIndex > -1) {
          // 从选中的数组中去掉当前的产品id
          _this.selectProIds.splice(proIndex, 1);
          _this.selectProList.splice(proIndex, 1);
          // 将去掉的产品输入框清空
          _this.productList[
            selectProIndex
          ].inventoryList[0].shoppingCartAmount = 0;
        }
      }
    },
    // 快捷键(向上键)
    keyupDrowUp() {
      let _this = this;
      let selectProIndex = _this.selectProIndex;
      let scrollbarEl = _this.$refs.scrollbar.wrap;
      // 获取当前滚动栏的高度
      let scrollTop = scrollbarEl.scrollTop;
      // 按键操作后选中的产品数量输入框移除焦点事件
      if (selectProIndex > -1) {
        _this.$refs.proInputNum[selectProIndex].blur();
      }
      if (selectProIndex >= 0) {
        _this.selectProIndex--;
        // let selectProIndex = _this.selectProIndex;
        // if (selectProIndex == -1) {
        //   _this.$refs.searchVal.focus();
        // }
        let selectIndexTop = (selectProIndex + 1) * 50;
        // 当选中的产品高度小于滚动框上的高度定位到上一个产品的滚动条位置
        if (selectIndexTop <= scrollTop) {
          scrollbarEl.scrollTop = selectIndexTop - 50;
        }
      }
    },
    // 快捷键(向下键)
    keyupDrownDown() {
      let _this = this;
      let selectProIndex = _this.selectProIndex;
      let scrollbarEl = _this.$refs.scrollbar.wrap;
      let total = _this.productPage.total;
      // 获取当前滚动栏的高度
      let scrollTop = scrollbarEl.scrollTop;
      // 按键操作后选中的产品数量输入框移除焦点事件
      if (selectProIndex > -1) {
        _this.$refs.proInputNum[selectProIndex].blur();
      }
      if (_this.selectProIndex < total - 1) {
        _this.selectProIndex++;
        let selectProIndex = _this.selectProIndex;
        let selectIndexTop = (selectProIndex + 1) * 50;
        // 当选中的产品是最后一个的时候滚动条显示出(没有更多数据！)
        if (selectProIndex == total - 1) {
          scrollbarEl.scrollTop = selectIndexTop - 350;
        } else if (selectIndexTop > scrollTop + 400) {
          // 当当前选中的产品高度大于滚动条框外，定位到最后一个选中产品的滚动高度
          scrollbarEl.scrollTop = selectIndexTop - 400;
        }
      }
    },
    // 快捷键(ESC键)
    keyupCancelOrder() {
      // 首先清空productIds
      this.selectProIds = [];
      this.selectProList = [];
      this.selectProIndex = -1;
      this.searchVal = "";
      this.$refs.searchVal.focus();
      this.showProductState = false;
    },
    // 快捷键(Enter)
    keyupEnter() {
      let _this = this;
      // 判断用户是否选中的产品
      let selectProList = this.selectProList;
      let showProductState = this.showProductState;
      console.log("selectProList+++++", selectProList);
      if (selectProList.length > 0 && showProductState) {
        let jionProList = [];
        for (let key of selectProList) {
          if (
            key.inventoryList &&
            key.inventoryList.length > 0 &&
            key.inventoryList[0].addCartQuantity &&
            key.inventoryList[0].addCartQuantity !== ""
          ) {
            let data = {
              uid: key.inventoryList[0].uid,
              addPrice: key.showPrice,
              amount: key.inventoryList[0].addCartQuantity,
              shoppingCartType: 2,
            };
            jionProList.push(data);
          }
        }
        console.log("添加产品列表返回的数据为+++++", jionProList);
        let url = "/shopping/cart/addList";
        postAction(url, jionProList).then((res) => {
          if (res.code === 200 && res.success) {
            _this.$message({
              message: "添加产品成功！",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            // 添加成功之后关闭搜索框区域
            _this.keyupCancelOrder();
            // 刷新产品列表
            _this.getFastShoppingList();
          } else {
            this.$alert(res.message, "提示信息", {
              type: "error",
            });
          }
        });
      }
    },

    // 监听滚动条滚动时间
    handleScroll() {
      let _this = this;
      let scrollbarEl = this.$refs.scrollbar.wrap;
      scrollbarEl.onscroll = () => {
        let scrollHeight = scrollbarEl.scrollHeight;
        let scrollTop = scrollbarEl.scrollTop;
        if (scrollTop >= scrollHeight - 400) {
          let pages = _this.productPage.pages;
          let pageNo = _this.productPage.pageNo;
          if (pageNo < pages) {
            _this.productPage.pageNo = _this.productPage.pageNo + 1;
            _this.loadProductData();
          }
        }
      };
    },
    // 搜索产品操作
    searchProduct(e) {
      let str = "";
      let searchVal = this.searchVal;
      str = searchVal.replace(/\ +/g, "");
      str = searchVal.replace(/[\r\n]/g, "");
      if (str !== "") {
        this.showProductState = true;
        this.productPage.pageNo = 1;
        this.loadProductData();
      } else {
        this.showProductState = false;
      }
    },
    // 加载产品数据
    loadProductData() {
      let _this = this;
      let searchVal = this.searchVal;
      let pageNo = this.productPage.pageNo;
      let pageSize = this.productPage.pageSize;
      let data = {
        searchVal: searchVal,
        pageNo: pageNo,
        pageSize: pageSize,
      };
      this.loading = true;
      let url = "/product/getlistE";
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          let pageData = resData.pageData;
          if ((pageData.current == pageData.pages) || pageData.total == 0) {
            _this.loading = false;
          }
          _this.productPage.pageNo = pageData.current;
          _this.productPage.pages = pageData.pages;
          _this.productPage.total = pageData.total;
          if (pageNo == 1) {
            _this.productList = pageData.records;
          } else if (pageNo > 1) {
            _this.productList = _this.productList.concat(pageData.records);
          }
          // if (pageData.records.length > 0) {
          //   if (pageData.current == pageData.pages) {
          //     _this.loading = false;
          //   }
          //   _this.productPage.pageNo = pageData.current;
          //   _this.productPage.pages = pageData.pages;
          //   _this.productPage.total = pageData.total;
          //   if (pageNo == 1) {
          //     _this.productList = pageData.records;
          //   } else if (pageNo > 1) {
          //     _this.productList = _this.productList.concat(pageData.records);
          //   }
          // }
        }
      });
    },
    // 移除产品数据
    removeProduct(item) {
      this.listOperatLoaing = true;
      let _this = this;
      let deleteUrl = "/shopping/cart/delete";
      let feeType = this.feeType;
      let data = {
        id: item.id,
        getList: 2,
        feeType: feeType,
      };
      postAction(deleteUrl, data).then((res) => {
        if (res.code === 200 && res.success) {
          // 删除成功后从全选数据里面移除删除的id
          let shopCartCheckIds = _this.shopCartCheckIds;
          if (shopCartCheckIds.indexOf(item.id) > -1) {
            let index = shopCartCheckIds.indexOf(item.id);
            shopCartCheckIds.splice(index, 1);
            _this.shopCartCheckIds = shopCartCheckIds;
          }
          let checkedCart = _this.checkedCart;
          if (checkedCart.indexOf(item.id) > -1) {
            let index = checkedCart.indexOf(item.id);
            checkedCart.splice(index, 1);
            _this.checkedCart = checkedCart;
          }
          _this.getFastShoppingList(checkedCart);
        }
      });
    },
    removeBatchCart() {
      this.dialogVisible = true;
    },
    // 批量删除购物车数据
    deleteShopCart() {
      let _this = this;
      this.listOperatLoaing = true;
      this.dialogVisible = false;
      let feeType = this.feeType;
      let checkedCart = this.checkedCart;
      let shopCartCheckIds = this.shopCartCheckIds;
      let actTicketData = this.actTicketData;
      let url = "/shopping/cart/deleteList?getList=1";
      let data = [];
      for (let key of checkedCart) {
        let itemData = {
          id: parseInt(key),
          feeType: feeType,
        };
        if (actTicketData && JSON.stringify(actTicketData) !== "{}") {
          itemData.useDiscountTicketId = actTicketData.id;
        }
        data.push(itemData);
      }
      postAction(url, data, true).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.$message({
            message: "已批量删除购物车!",
            offset: 400,
            duration: 1000,
            type: "success",
          });
          // 从选中的产品数组id中移除掉删掉的产品id
          for (let key of data) {
            if (checkedCart.indexOf(key.id) > -1) {
              let index = checkedCart.indexOf(key.id);
              checkedCart.splice(index, 1);
            }
            if (shopCartCheckIds.indexOf(key.id) > -1) {
              let index = shopCartCheckIds.indexOf(key.id);
              shopCartCheckIds.splice(index, 1);
            }
          }
          _this.checkedCart = checkedCart;
          _this.shopCartCheckIds = shopCartCheckIds;
          console.log("批量删除以后清单列表所有的产品id", shopCartCheckIds);
          console.log("批量删除以后清单列表勾选的产品id", checkedCart);
          _this.getFastShoppingList(checkedCart);
        }
      });
    },
    // 清单列表修改数量操作
    changeShopCartItemAount(item) {
      let _this = this;
      let inputNum = item.amount;
      if (inputNum > 0) {
        let addPrice = item.addPrice;
        let amount = inputNum;
        let id = item.id;
        let feeType = this.feeType;
        let productPriceStrategyId = item.productPriceStrategyId;
        let uid = item.uid;
        let addShopData = {
          addPrice: addPrice,
          amount: amount,
          feeType: feeType,
          uid: uid,
          id: id,
          shoppingCartType: 2,
        };
        if (productPriceStrategyId) {
          addShopData.productPriceStrategyId = productPriceStrategyId;
        }
        let addShopUrl = "/shopping/cart/add";
        postAction(addShopUrl, addShopData).then((res) => {
          if (res.success && res.code === 200) {
            let resData = res.result;
            let choiceId = this.checkedCart;
            _this.getFastShoppingList(choiceId);
          } else {
            this.$alert(res.message, "提示信息", {
              type: "error",
              dangerouslyUseHTMLString: true,
            });
          }
        });
      } else {
        this.$message({
          message: "添加产品的数量不能小于1",
          type: "warning",
          duration: 1000,
          offset: 400,
        });
      }
    },
    // 清单列表产品数量减少数量
    lessAmount(item) {
      let inputNum = parseInt(item.amount);
      console.log("inputNum+++++++", inputNum);
      let step = 1;
      if (item.productStep && item.productStep > 0) {
        step = parseInt(item.productStep);
      }
      let stockNum = parseInt(item.inventory);
      if (
        (inputNum > 0 && inputNum <= step && inputNum < stockNum) ||
        inputNum <= 0
      ) {
        inputNum = step;
      } else if (inputNum >= stockNum) {
        inputNum = stockNum;
      } else if (inputNum > step && inputNum <= stockNum) {
        inputNum = inputNum - step;
        if (inputNum % step) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
        }
      }
      item.amount = inputNum;
      this.changeShopCartItemAount(item);
    },
    // 清单列表产品数量添加数量
    addAmount(item) {
      let inputNum = parseInt(item.amount);
      let step = 1;
      if (item.productStep && item.productStep > 0) {
        step = parseInt(item.productStep);
      }
      let stockNum = parseInt(item.inventory);
      if (
        (inputNum > stockNum - step && inputNum < stockNum) ||
        inputNum >= stockNum
      ) {
        inputNum = stockNum;
      } else if (inputNum >= 0 && inputNum <= stockNum - step) {
        inputNum = inputNum + step;
        if (inputNum % step) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
        }
      }
      item.amount = inputNum;
      this.changeShopCartItemAount(item);
    },
    // 清单列表产品数量修改输入框数量
    changeAmount(item, e) {
      let step = 1;
      if (item.productStep && item.productStep > 0) {
        step = parseInt(item.productStep);
      }
      let inputNum = parseInt(e.target.value);
      let inventory = parseInt(item.inventory);
      // 强制改变用户的输入数量为步进数量
      if (inputNum >= inventory) {
        if (inputNum > inventory) {
          this.$message({
            message: `该产品最大购买量为:${inventory}, 已按照最大购买量添加！`,
            offset: 400,
            duration: 2000,
            type: 'success'
          })
        }
        inputNum = inventory;
      } else if (inputNum <= 0 && inputNum >= step) {
        // 输入的小于 0并且库存大于步进数量, 按照默认步进来计算
        inputNum = step;
      } else {
        if (step !== 1) {
          let mulNum = (inputNum - (inputNum % step)) / step + 1;
          inputNum = mulNum * step;
          if (inputNum > inventory) {
            inputNum = step * (mulNum - 1)
          }
          this.$message({
            message: `该产品不拆零销售, 修改的数量为:${inputNum}`,
            offset: 400,
            duration: 2000,
            type: 'success'
          })
        }
      }
      item.amount = inputNum;
      this.changeShopCartItemAount(item);
    },
    // 全选清单数据操作
    handleCheckAllChange(val) {
      this.checkedCart = val ? this.shopCartCheckIds : [];
      this.isIndeterminate = false;
      let choiceId = this.checkedCart;
      this.getFastShoppingList(choiceId);
    },
    // 清单列表复选框勾选操作
    handleCheckedCitiesChange(checkedIds) {
      let checkedCount = checkedIds.length;
      this.shopCartCheckAll = checkedCount === this.shopCartCheckIds.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.shopCartCheckIds.length;
      this.getFastShoppingList(checkedIds);
    },
    changePayType(e) {
      this.getFastShoppingList();
    },
    // 渲染列表数据
    renderShopCartData(resData, checkStatus) {
      let _this = this;
      // 暂停加载动画
      this.listOperatLoaing = false;
      // 渲染产品列表数据
      this.shoppingCartList = resData.shoppingCartList;
      // 检测用户是否有可用的优惠券,如果有存储起来默认创建订单的时候使用
      if (
        resData.canUseDiscountTicketList &&
        resData.canUseDiscountTicketList.length > 0
      ) {
        _this.actTicketData = resData.canUseDiscountTicketList[0];
      }
      // 默认全选购物车数据
      let shopCartCheckIds = [];
      for (let key of resData.shoppingCartList) {
        shopCartCheckIds.push(key.id);
      }
      _this.shopCartCheckIds = shopCartCheckIds;
      if (checkStatus) {
        _this.checkedCart = shopCartCheckIds;
      }
      // 展示的快速开单金额
      _this.shopCartAllMoney = resData.money;
      this.$forceUpdate();
    },
    // 创建订单操作

    createOrder() {
      const _this = this;
      const shoppingCartId = this.checkedCart;
      const feeType = this.feeType;
      const shopData = {
        shoppingCartId: shoppingCartId,
        feeType: feeType,
        sourceType: 1,
      };
      if (shoppingCartId.length > 0) {
        this.createOrderStatus = true;
        const url = "/saleOrder/saleOrder/createSaleOrder";
        console.log("创建订单传输的数据为++++++", shopData);
        postAction(url, shopData).then((res) => {
          if (res.code === 200 && res.success) {
            const resData = res.result;
            // 获取订单id，轮询请求订单配货状态
            let saleOrderId = resData.saleOrder.id;
            this.visitOrderStatus(saleOrderId);
          } else {
            _this.createOrderStatus = false;
            _this.$message({
              message: res.message,
              offset: 400,
              duration: 1000,
              type: "error",
            });
          }
        });
      } else {
        this.createOrderStatus = false;
      }
    },

    visitOrderStatus(id) {
      let _this = this;
      this.createOrderStatus = true;
      _this.querySaleOrderAllotStatus(id);
      this.orderStatus = setInterval(function () {
        _this.querySaleOrderAllotStatus(id);
      }, 2000);
    },
    querySaleOrderAllotStatus(id) {
      let _this = this;
      let url = "/saleOrder/saleOrder/querySaleOrderAllotStatus";
      let data = {
        saleOrderId: id,
      };
      getAction(url, data)
        .then((res) => {
          console.log("请求erp配货状态返回的数据为++++++", res);
          if (res.code === 200 && res.success) {
            if (res.result == "2") {
              _this.createOrderStatus = false;
              clearInterval(_this.orderStatus);
              // 创建订单成功后跳转确认订单页面
              _this.$router.push({
                name: "confirmOrder",
                query: {
                  saleOrderId: id,
                  shoppingCartType: 2,
                },
              });
            }
          } else {
            _this.createOrderStatus = false;
            _this.$alert(res.message, "创建订单失败：");
          }
        })
        .catch((error) => {
          _this.createOrderStatus = false;
          _this.$alert(error, "创建订单失败：");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/fastShopping/fastShopping";
</style>