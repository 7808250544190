<template>
  <div class="createOrderContent container_box">
    <div class="liucheng"></div>
    <div class="orderTip">
      <i class="successIcon"></i>
      <span>您的订单提交成功，请耐心等待配货结果...</span>
    </div>
    <div class="linkUrlBox">
      <router-link to="/" class="backIndex" href="javascript:;">
        返回首页
      </router-link>
      <router-link to="/orderList?state=3" class="linkOrderList" href="javascript:;">
        查看订单
      </router-link>
    </div>
  </div>
</template>

<script>
import { getAction } from '../api/manage';

export default {
  name: "CreateOrder",
  data() {
    return {
      orderStatus: null, // 订单配货定时器
    }
  },
  create() {
    let saleOrderId = this.$route.query.saleOrderId;
    if (saleOrderId && saleOrderId !== '') {
      this.visitOrderStatus()
    }
  },
  methods: {
    visitOrderStatus(id) {
      let _this = this;
      this.orderStatus = setInterval(function () {
        _this.querySaleOrderAllotStatus(id);
      }, 2000);
    },
    querySaleOrderAllotStatus(id) {
      let _this = this;
      let url = "/saleOrder/saleOrder/querySaleOrderAllotStatus1";
      let data = {
        saleOrderId: id,
      };
      getAction(url, data)
        .then((res) => {
          console.log("请求erp配货状态返回的数据为++++++", res);
          if (res.code === 200 && res.success) {
            let resultData = res.result;
            clearInterval(_this.orderStatus);
            _this.orderNextFlowRouter(resultData)
          } else {
            _this.$alert(res.message, "创建订单失败：");
          }
        })
        .catch((error) => {
          _this.$alert(error, "创建订单失败：");
        });
    },

    orderNextFlowRouter(saleOrder) {
      let _this = this;
      // 获取订单id，轮询请求订单配货状态
      // 判断订单下一节点状态
      let nextFlow = saleOrder.nextFlow;
      let saleOrderId = saleOrder.id;
      // 判断订单审核状态
      if (nextFlow && nextFlow !== '') {
        if (nextFlow === 'audit') { // 去审核
          this.$router.push({
            path: '/orderList',
            query: { state: 2 }
          })
        } else if (nextFlow === 'allot') { // 获取配货状态
          _this.orderStatus = setInterval(function () {
            _this.querySaleOrderAllotStatus(saleOrderId);
          }, 2000);
        } else if (nextFlow === 'confirm') { // 去确认订单
          // 创建订单成功后跳转确认订单页面
          _this.$router.push({
            name: "confirmOrder",
            query: {
              saleOrderId: saleOrderId,
            },
          });
        } else if (nextFlow === 'pay') { // 去支付订单
          let payOrderId = saleOrder.payOrderId;
          // 线上支付
          _this.$router.push({
            path: "/orderPay",
            query: { payOrderId: payOrderId, saleOrderId: saleOrderId },
          });
        }
      } else {
        this.$router.push({
          path: '/orderList',
          query: { state: 3 }
        })
      }
    },
  },
  unmounted() {
    clearInterval(this.orderStatus);
  },
  destroyed() {
    clearInterval(this.orderStatus);
  },

}
</script>

<style lang="scss" scoped>
@import "../assets/styles/order/create"
</style>