var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.maskShowType)?_c('div',{attrs:{"id":"mask"}},[_c('div',{staticClass:"blin"}),_c('div',{staticClass:"caidai"}),(_vm.showType == 1)?_c('div',{staticClass:"winning reback"},[_vm._m(0),_c('a',{staticClass:"btn",attrs:{"href":"javascript:;","target":"_self"},on:{"click":function($event){_vm.maskShowType = false}}}),_vm._m(1),_c('span',{attrs:{"id":"close"},on:{"click":function($event){_vm.maskShowType = false}}})]):(_vm.showType == 2)?_c('div',{staticClass:"show-box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.flowType == 1),expression:"flowType == 1"}],staticClass:"winning reback"},[_vm._m(2),_c('a',{staticClass:"btn",attrs:{"href":"javascript:;","target":"_self"},on:{"click":function($event){_vm.flowType = 2}}}),_vm._m(3),_c('span',{attrs:{"id":"close"},on:{"click":function($event){_vm.maskShowType = false}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.flowType == 2),expression:"flowType == 2"}],staticClass:"reback winning2"},[_vm._m(4),_c('div',{staticClass:"act-btn"},[_c('router-link',{staticClass:"btn",attrs:{"to":"/productList"}}),_c('a',{staticClass:"btn btn2",attrs:{"href":"javascript:;","target":"_self"},on:{"click":function($event){_vm.maskShowType = false}}})],1),_vm._m(5),_c('span',{attrs:{"id":"close"},on:{"click":function($event){_vm.maskShowType = false}}})])]):(_vm.showType == 3)?_c('div',{staticClass:"show-box"},[_c('div',{staticClass:"reback winning3"},[_c('div',{staticClass:"ticket-title"},[_vm._v(" 满2000元即领取奖券1张 ")]),_vm._m(6),_c('div',{staticClass:"act-btn"},[_c('router-link',{staticClass:"btn",attrs:{"to":"/productList"}})],1),_c('span',{attrs:{"id":"close"},on:{"click":function($event){_vm.maskShowType = false}}})])]):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket-content"},[_c('div',{staticClass:"ticket-title-bg"},[_c('p',[_vm._v("抽奖券")])]),_c('div',{staticClass:"ticket-num"},[_vm._v(" 1张 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"tip"},[_vm._v("前往个人中心 -> 我的抽奖券 "),_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("查看")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket-content"},[_c('div',{staticClass:"ticket-title-bg"},[_c('p',[_vm._v("抽奖券")])]),_c('div',{staticClass:"ticket-num"},[_vm._v(" 1张 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"tip"},[_vm._v("前往个人中心 -> 我的抽奖券 "),_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("查看")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket-content"},[_c('div',{staticClass:"ticket-title-bg"},[_c('p',[_vm._v("1314元")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"tip"},[_vm._v("前往个人中心 -> 我的抽奖券 "),_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("查看")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket-content"},[_c('div',{staticClass:"jiucha"}),_c('div',{staticClass:"ticket-title-bg"},[_c('p',[_vm._v("1314元")])])])
}]

export { render, staticRenderFns }