<template>
  <!--中奖提示-->
  <div v-if="maskShowType" id="mask">
    <div class="blin"></div>
    <div class="caidai"></div>
    <!-- 获取奖券动画页面 -->
    <div v-if="showType == 1" class="winning reback">
      <div class="ticket-content">
        <div class="ticket-title-bg">
          <p>抽奖券</p>
        </div>
        <div class="ticket-num">
          1张
        </div>
      </div>
      <a @click="maskShowType = false" href="javascript:;" target="_self" class="btn"></a>
      <p class="tip">前往个人中心 -> 我的抽奖券 <a href="javascript:;">查看</a></p>
      <span @click="maskShowType = false" id="close"></span>
    </div>
    <!-- 距离下张抽奖券 -->
    <div v-else-if="showType == 2" class="show-box">
      <div v-show="flowType == 1" class="winning reback">
        <div class="ticket-content">
          <div class="ticket-title-bg">
            <p>抽奖券</p>
          </div>
          <div class="ticket-num">
            1张
          </div>
        </div>
        <a @click="flowType = 2" href="javascript:;" target="_self" class="btn"></a>
        <p class="tip">前往个人中心 -> 我的抽奖券 <a href="javascript:;">查看</a></p>
        <span @click="maskShowType = false" id="close"></span>
      </div>
      <div v-show="flowType == 2" class="reback winning2">
        <div class="ticket-content">
          <div class="ticket-title-bg">
            <p>1314元</p>
          </div>
        </div>
        <div class="act-btn">
          <!-- 继续下单 -->
          <router-link to="/productList" class="btn"></router-link>
          <!-- 我的抽奖券 -->
          <a @click="maskShowType = false" href="javascript:;" target="_self" class="btn btn2"></a>
        </div>
        <p class="tip">前往个人中心 -> 我的抽奖券 <a href="javascript:;">查看</a></p>
        <span @click="maskShowType = false" id="close"></span>
      </div>
    </div>
    <!-- 距离抽奖券满足条件提示 -->
    <div v-else-if="showType == 3" class="show-box">
      <div class="reback winning3">
        <div class="ticket-title">
          满2000元即领取奖券1张
        </div>
        <div class="ticket-content">
          <div class="jiucha"></div>
          <div class="ticket-title-bg">
            <p>1314元</p>
          </div>
        </div>
        <div class="act-btn">
          <!-- 继续下单 -->
          <router-link to="/productList" class="btn"></router-link>
          <!-- 我的抽奖券 -->
        </div>
        <span @click="maskShowType = false" id="close"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WinningModal",
  props: {
    ticketData: {
      type: Object,
      require: true,
      default: function () {
        return {

        }
      }
    }
  },
  data() {
    return {
      maskShowType: false,
      showType: 1, // 1.登录动画 2.订单完成动画 3.订单金额不足抽奖条件提示
      flowType: 2, // 动画流程状态
    }
  },
  methods: {
    open() {
      // this.maskShowType = true
    }
  }
}
</script>

<style lang="scss" scoped>
/*中奖提示*/
#mask {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);

  .blin {
    width: 540px;
    height: 540px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -270px;
    margin-top: -270px;
    background-image: url("~@/assets/images/activity/redPack/gold.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    -o-animation: circle 10s linear infinite;
    -ms-animation: circle 10s linear infinite;
    -moz-animation: circle 10s linear infinite;
    -webkit-animation: circle 10s linear infinite;
    animation: circle 10s linear infinite;
  }

  @keyframes circle {
    0% {
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .caidai {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 540px;
    height: 760px;
    margin-left: -270px;
    margin-top: -380px;
    background-image: url("~@/assets/images/activity/redPack/dianzhui.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  .winning {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 430px;
    height: 500px;
    margin: -280px 0 0 -215px;
    -o-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    width: 430px;
    height: 500px;
    background-image: url("~@/assets/images/activity/luckDrawTicket/get-bg.png");
    background-size: 100% auto;
    background-repeat: no-repeat;

    .ticket-content {
      width: 276px;
      position: absolute;
      top: 200px;
      left: 87px;

      .ticket-title-bg {
        width: 276px;
        height: 82px;
        background: url("~@/assets/images/activity/luckDrawTicket/num-bg.png");
        background-size: 100% auto;

        p {
          width: 100%;
          height: 100%;
          font-size: 45px;
          font-weight: bold;
          letter-spacing: 5px;
          text-indent: 5px;
          line-height: 80px;
          text-align: center;
          color: #ffffff;
        }
      }

      .ticket-num {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 5px;
        text-indent: 5px;
        color: #FE4557;
        text-align: center;
      }
    }
  }

  .winning2 {

    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    -o-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    width: 365px;
    height: 420px;
    margin: -210px 0 0 -182px;
    background-image: url("~@/assets/images/activity/luckDrawTicket/haicha-bg.png");
    background-size: 100% auto;
    background-repeat: no-repeat;

    .ticket-content {
      width: 276px;
      margin: auto;
      margin-top: 160px;

      .ticket-title-bg {
        width: 276px;
        height: 82px;
        background: url("~@/assets/images/activity/luckDrawTicket/num-bg.png");
        background-size: 100% auto;

        p {
          width: 100%;
          height: 100%;
          font-size: 45px;
          font-weight: bold;
          letter-spacing: 5px;
          text-indent: 5px;
          line-height: 80px;
          text-align: center;
          color: #ffffff;
          @include ellipsis;
        }
      }

      .ticket-num {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 5px;
        text-indent: 5px;
        color: #FE4557;
        text-align: center;
      }
    }

    .tip {
      font-size: 20px;
      color: #ffffff;
      position: absolute;
      bottom: 0;
      line-height: 65px;
      width: 370px;
      text-align: center;
      font-weight: bold;

      a {
        color: #FFF100;
        font-size: 20px;
        letter-spacing: 2px;
        text-indent: 2px;
        font-weight: 700;
        text-decoration: underline;
      }
    }

    .act-btn {
      position: absolute;
      bottom: 55px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .btn {
        width: 120px;
        height: 40px;
        background-image: url("~@/assets/images/activity/luckDrawTicket/shop-btn.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        -o-animation: shake .5s 2 linear alternate;
        -ms-animation: shake .5s 2 linear alternate;
        -moz-animation: shake .5s 2 linear alternate;
        -webkit-animation: shake .5s 2 linear alternate;
        animation: shake .5s 2 linear alternate;

        &.btn2 {
          background-image: url("~@/assets/images/activity/luckDrawTicket/my-ticket-btn.png");
        }
      }

      @keyframes shake {
        50% {
          -o-transform: rotate(-5deg);
          -ms-transform: rotate(-5deg);
          -moz-transform: rotate(-5deg);
          -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
        }

        100% {
          -o-transform: rotate(5deg);
          -ms-transform: rotate(5deg);
          -moz-transform: rotate(5deg);
          -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
        }
      }
    }

    #close {
      right: -60px;
      top: -30px;
    }
  }

  .winning3 {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    -o-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    width: 365px;
    height: 420px;
    margin: -210px 0 0 -182px;
    background-image: url("~@/assets/images/activity/luckDrawTicket/ticket-bg.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    .ticket-title {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      letter-spacing: 2px;
      text-indent: 2px;
      text-align: center;
      line-height: 90px;
    }

    .ticket-content {
      width: 276px;
      margin: auto;
      margin-top: 5px;

      .jiucha {
        margin-left: 30px;
        margin-bottom: 5px;
        width: 95px;
        height: 59px;
        background: url("~@/assets/images/activity/luckDrawTicket/jiucha-ng.png");
        background-size: 100% auto;
      }

      .ticket-title-bg {
        width: 276px;
        height: 82px;
        background: url("~@/assets/images/activity/luckDrawTicket/num-bg.png");
        background-size: 100% auto;

        p {
          width: 100%;
          height: 100%;
          font-size: 45px;
          font-weight: bold;
          letter-spacing: 5px;
          text-indent: 5px;
          line-height: 80px;
          text-align: center;
          color: #ffffff;
          @include ellipsis;
        }
      }

      .ticket-num {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 5px;
        text-indent: 5px;
        color: #FE4557;
        text-align: center;
      }
    }

    .act-btn {
      position: absolute;
      bottom: 55px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .btn {
        width: 150px;
        height: 50px;
        background-image: url("~@/assets/images/activity/luckDrawTicket/shop-btn.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        -o-animation: shake .5s 2 linear alternate;
        -ms-animation: shake .5s 2 linear alternate;
        -moz-animation: shake .5s 2 linear alternate;
        -webkit-animation: shake .5s 2 linear alternate;
        animation: shake .5s 2 linear alternate;
      }

      @keyframes shake {
        50% {
          -o-transform: rotate(-5deg);
          -ms-transform: rotate(-5deg);
          -moz-transform: rotate(-5deg);
          -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
        }

        100% {
          -o-transform: rotate(5deg);
          -ms-transform: rotate(5deg);
          -moz-transform: rotate(5deg);
          -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
        }
      }
    }

    #close {
      right: -60px;
      top: -30px;
    }
  }

  .reback {
    -o-animation: reback .5s linear forwards;
    -ms-animation: reback .5s linear forwards;
    -moz-animation: reback .5s linear forwards;
    -webkit-animation: reback .5s linear forwards;
    animation: reback .5s linear forwards;
  }

  @keyframes reback {
    100% {
      -o-transform: scale(1);
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

.winning {
  .red-head {
    position: relative;
    top: -75px;
    width: 100%;
    height: 242px;
    background-image: url("~@/assets/images/activity/redPack/top.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
}

.winning {
  .pull {
    -o-animation: card .5s linear forwards;
    -ms-animation: card .5s linear forwards;
    -moz-animation: card .5s linear forwards;
    -webkit-animation: card .5s linear forwards;
    animation: card .5s linear forwards;
  }

  @keyframes card {
    100% {
      margin-top: -200px;
    }
  }

  .tip {
    font-size: 20px;
    color: #ffffff;
    position: absolute;
    bottom: 0;
    line-height: 65px;
    width: 370px;
    left: 40px;
    text-align: center;
    font-weight: bold;

    a {
      color: #FFF100;
      font-size: 20px;
      letter-spacing: 2px;
      text-indent: 2px;
      font-weight: 700;
      text-decoration: underline;
    }
  }

  .btn {
    position: absolute;
    left: 225px;
    bottom: 60px;
    z-index: 9;
    width: 260px;
    height: 58px;
    margin-left: -130px;
    background-image: url("~@/assets/images/activity/luckDrawTicket/shouxia-btn.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    -o-animation: shake .5s 2 linear alternate;
    -ms-animation: shake .5s 2 linear alternate;
    -moz-animation: shake .5s 2 linear alternate;
    -webkit-animation: shake .5s 2 linear alternate;
    animation: shake .5s 2 linear alternate;
  }

  @keyframes shake {
    50% {
      -o-transform: rotate(-5deg);
      -ms-transform: rotate(-5deg);
      -moz-transform: rotate(-5deg);
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg);
    }

    100% {
      -o-transform: rotate(5deg);
      -ms-transform: rotate(5deg);
      -moz-transform: rotate(5deg);
      -webkit-transform: rotate(5deg);
      transform: rotate(5deg);
    }
  }
}

#close {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  right: -25px;
  top: 0px;
  z-index: 10;
  width: 45px;
  height: 45px;
  background-image: url("~@/assets/images/activity/redPack/close.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  -o-animation: fadein .5s .5s linear forwards;
  -ms-animation: fadein .5s .5s linear forwards;
  -moz-animation: fadein .5s .5s linear forwards;
  -webkit-animation: fadein .5s .5s linear forwards;
  animation: fadein .5s .5s linear forwards;
}

@keyframes fadein {
  100% {
    opacity: 1;
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>