<template>
  <div class="productClassify">
    <div class="productClassBox">
      <div class="screenTitle">
        <span>药品筛选:</span>
        <div class="screenTopRight">
            <span v-if="manufacturer" class="productFlSpan">
              {{manufacturer}}
              <i class="removeIcon el-icon-close" @click="selectAct('manufacturer',manufacturer)"></i>
            </span>
          <span v-if="format"  class="productFlSpan">
            {{format}}
              <i class="removeIcon el-icon-close" @click="selectAct('format',format)"></i>
            </span>
          <span v-if="unit"  class="productFlSpan">
            {{unit}}
              <i class="removeIcon el-icon-close" @click="selectAct('unit',unit)"></i>
            </span>
        </div>
      </div>
      <div class="screenContent">
        <div class="screenTop clearfix">
          <div class="screenTitle fl">
            生产厂家：
          </div>
          <div class="screenbox fl">
            <!--默认显示-->
            <div :class="!manufacturerSide ? 'autoList' : ''" class="screenListBox manufacturerListBox">
              <!--点击锁定类目后的样式-->
              <span class="manufacturerSpan" v-for="(item,index) of manufacturerMap"
                    @click='selectManufacturerSide(item)'
                    :class="{manufacturerNone : item.length === 0}">
                    {{index}}
                  </span>
            </div>
            <div class="screenListBox autoList manufacturerBottom">
                  <span v-for="(item,index) of manufacturerList"
                        @click="selectAct('manufacturer',item)"
                        :class="{activeFeilei: manufacturer === item}"
                  >
                    {{item}}
                  </span>
            </div>
          </div>
        </div>
        <div class="screenTop clearfix">
          <div class="screenTitle fl">
            规格：
          </div>
          <div class="screenbox fl">
            <!--默认显示-->
            <div :class="!formatSide ? 'autoList' : ''" class="screenListBox">
              <!--点击锁定类目后的样式-->
              <span v-for="(item,index) of formatList"
                    @click="selectAct('format',item)"
                    :class="{activeFeilei: format === item}">
                    {{item}}
                  </span>
            </div>
          </div>
          <div class="rightbox fr">
            <a @click="tabSideType('formatSide',false)" v-show="formatSide" href="javascript:void(0);" class="zhan-er">
              展开<i class="el-icon-arrow-down"></i>
            </a>
            <a @click="tabSideType('formatSide',true)" v-show="!formatSide" href="javascript:void(0);" class="shou-er">
              收起<i class="el-icon-arrow-up"></i>
            </a>
          </div>

        </div>
        <div v-if="false" class="screenTop clearfix">
          <div class="screenTitle fl">
            单位：
          </div>
          <div class="screenbox fl">
            <!--默认显示-->
            <div :class="!unitSide ? 'autoList' : ''" class="screenListBox">
              <!--点击锁定类目后的样式-->
              <span v-for="(item,index) of unitList"
                    @click="selectAct('unit',item)"
                    :class="{activeFeilei: unit === item}">
                    {{item}}
                  </span>
            </div>
          </div>
          <div class="rightbox fr">
            <a @click="tabSideType('unitSide',false)" v-show="unitSide" href="javascript:void(0);" class="zhan-er">
              展开<i class="el-icon-arrow-down"></i>
            </a>
            <a @click="tabSideType('unitSide',true)" v-show="!unitSide" href="javascript:void(0);" class="shou-er">
              收起<i class="el-icon-arrow-up"></i>
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getAction} from "../../api/manage";

  export default {
    name: 'Classify',
    props: ['formatList', 'manufacturerMap', 'unitList'],
    data() {
      return {
        feileiText: '一级分类',
        clickoutside: true,
        manufacturerSide: true, // 厂家展开收起状态
        formatSide: true, // 规格展开收起状态
        unitSide: true, // 规格展开收起状态
        manufacturerList: [], // 产品分类选中的列表数据
        format: null,
        unit: null,
        manufacturer: null
      }
    },
    created() {
      this.getProductCategory()
    },
    methods: {
      // 选择厂商的操作
      selectManufacturerSide(item){
        let manufacturerList = this.manufacturerList
        if(manufacturerList === item){
          this.manufacturerList = []
        }else {
          this.manufacturerList = item
        }
      },
      // 选择产品规格
      selectAct(name, item) {
        let data = {}
        data.name = name
        let nameSide = name + 'Side'
        if(this[name] === item) {
          this[name] = null
          data.item = ''
          this[nameSide] = true
        }else {
          this[name] = item
          data.item = item
          this[nameSide] = false
        }
        this.$emit('screenProduct',data)
      },
      // 获取产品分类的数据为
      getProductCategory() {
        let _this = this;
        let url = 'product/getProductCategory';
        getAction(url).then(res => {
          if (res.code === 200 && res.success) {
            _this.allClassifyList = res.result;
            _this.nowClassifyList = res.result;
          }
        })
      },
      tabSideType(name, type) {
        this[name] = type
      },
      selectActFrist(item) {
        this.firstClassifyData = item;
        let activeClassList = this.activeClassList
        if (activeClassList.length === 0) {
          this.feileiText = '二级分类'
        } else if (activeClassList.length === 1) {
          this.feileiText = '三级分类'
        }
        this.activeClassList.push(item);
        this.nowClassifyList = item.children
        this.$forceUpdate();
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/styles/product/classify";
</style>
