<template>
  <div class="jfShop clearfix">
    <header-top></header-top>
    <div class="nav content">
      <div class="content_box">
        <div class="logo_box" style="width: 250px; text-align: left">
          <router-link to="/">
            <img v-if="companyConfigData && companyConfigData.pcDarkLogoUrl && companyConfigData.pcDarkLogoUrl !== ''"
            :src="companyConfigData.pcDarkLogoUrl" alt="江药商城"/>
            <img v-else src="@/assets/images/common/logo.png" />
          </router-link>
        </div>
        <ul class="nav_list">
          <li class="navLi" :class="{ active: navType === 0 }">
            <a href="javascript:;" class="navLia" @click="changeNavType(0)">积分首页</a>
          </li>
          <li class="navLi" :class="{ active: navType === 1 }">
            <a href="javascript:;" class="navLia" @click="changeNavType(1)">礼品列表</a>
          </li>
          <li class="navLi" :class="{ active: navType === 2 }">
            <a href="javascript:;" class="navLia" @click="changeNavType(2)">个人中心</a>
          </li>
          <li class="navLi">
            <router-link class="navLia" to="/">返回首页</router-link>
          </li>
        </ul>
      </div>
    </div>
    <IntegralShop @changeNavType="changeNavType" :userData="userData" :bannerUrl="bannerUrl"
      @getUserIntegral="getUserIntegral" :userIntegral="userIntegral" v-if="navType === 0">
    </IntegralShop>
    <IntegralList :userIntegral="userIntegral" :navType="navType" :userData="userData" :usedIntegral="usedIntegral"
      ref="refList" @changeNavType="changeNavType" v-if="navType === 1 || navType === 2">
    </IntegralList>
    <sign-intergral @changeNavType="changeNavType" v-if="navType === 3"></sign-intergral>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IntegralShop from "./IntegralShop";
import IntegralList from "./IntegralList";
import { getUserInfo } from "@/assets/js/common/common";
import { getAction } from "../../api/manage";
import Footer from "../common/Footer.vue";
import HeaderTop from "../common/HeaderTop.vue";
import SignIntergral from "./SignIntergral.vue";

export default {
  name: "IntegralCenter",
  components: { HeaderTop, Footer, IntegralList, IntegralShop, SignIntergral },
  data() {
    return {
      userData: {},
      navType: 0,
      userIntegral: 0, // 用户积分数据
      usedIntegral: 0, // 用户积分数据
      bannerUrl: "",
    };
  },
  created() {
    let navType = this.$route.query.navType
    let userData = getUserInfo();
    if (userData) {
      this.userData = userData;
      if (navType && navType !== '') {
        this.navType = navType
      } else {
        this.navType = 0;
      }
      this.changeNavType(this.navType)
      this.getUserIntegral();
      this.getIntegralShopBanner();
    } else {
      this.$router.push("/login");
    }
  },
  computed: {
    ...mapGetters(["companyConfigData"]),
  },
  methods: {
    // 获取积分商城banner图
    getIntegralShopBanner() {
      let _this = this;
      let url = "integralMall/getBannerUrl";
      getAction(url).then((res) => {
        console.log("积分商城的banner图为++++++++", res);
        if (res.code === 200 && res.success) {
          _this.bannerUrl = res.message;
        }
      });
    },
    // 切换当行类型
    changeNavType(type) {
      this.navType = type;
      if (type === 1) {
        this.$refs.refList.page = 1;
        this.$refs.refList.getGiftList();
      } else if (type === 2) {
        this.$refs.refList.page = 1;
        this.$refs.refList.getUserIntegral();
      }
    },
    // 获取用户的积分数据
    getUserIntegral() {
      let _this = this;
      let userData = this.userData;
      let companyId = ""
      if (userData.company && userData.company.id) {
        companyId = userData.company.id;
      }
      let url = "/integral/customerIntegral/queryForIntegral?companyId=" + companyId;
      getAction(url).then((res) => {
        console.log("获取用户的积分数据为+++++", res);
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.userIntegral = resData.integral;
          _this.usedIntegral = resData.usedIntegral;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/intergralCenter/intergralShop";
</style>
