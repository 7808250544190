<template>
  <div class="ticketContent">
    <div class="ticketHeader">
      <div class="ticektTypeBox">
        <p @click="changeTicketStatus(0)" :class="{ active: ticketStatus == 0 }">
          待使用
        </p>
        <p @click="changeTicketStatus(1)" :class="{ active: ticketStatus == 1 }">
          已使用
        </p>
        <p @click="changeTicketStatus(3)" :class="{ active: ticketStatus == 3 }">
          已过期
        </p>
      </div>
      <p class="yxsj">有效时间</p>
      <p class="hqsj">获取时间</p>
      <p class="sysj">{{ ticketStatus == 1 ? "使用时间" : '' }}</p>
    </div>
    <div class="ticketListBox" v-loading="loadingStatus">
      <div v-if="ticketDataList.length > 0">
        <div @click="useTicket(item)" v-for="(item, index) of ticketDataList" :key="'ticketIndex' + index"
          class="ticketItem">
          <p class="name">{{ item.ticketName }}</p>
          <p class="price">{{ item.ticketType === 1 ? item.ticketDiscountMoney + '元' : item.ticketDiscountMoney * 100 +
            '折' }}
          </p>
          <p class="detail">{{ item.ticketDetail }}</p>
          <p class="yxsj">{{ timeClear(item.ticketStartTime) }}-{{ timeClear(item.ticketEndTime) }}</p>

          <p class="hqsj">{{ timeClear(item.createTime) }}</p>
          <p class="sysj">{{ item.useTime }}</p>
        </div>
      </div>
      <div v-else class="dataNone">
        <span class="dataImg ticketNoneImg"></span>
      </div>
    </div>

    <div class="pageFooter">
      <el-pagination background :hide-on-single-page="true" class="pageBox" layout="prev, pager, next, jumper"
        @current-change="currentChange" :page-size="pageSize" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAction } from "../api/manage";
export default {
  name: "TicketList",
  data() {
    return {
      loadingStatus: false,
      ticketDataList: [], // 奖券的数据列表
      ticketType: 2, // 奖券类型，1：抽奖，2：优惠劵
      ticketStatus: 0, // 奖券状态：0待使用，1已使用，-1已过期，-2系统撤回
      total: 0, // 总页数
      pageNo: 1, // 当前页
      pageSize: 10, // 当前页
    };
  },

  created() {
    this.getTicketList();
  },
  methods: {
    // 去使用优惠券
    useTicket(item) {
      let astrictProductFlag = item.astrictProductFlag;
      let ticketStatus = this.ticketStatus
      if (ticketStatus == 0) {
        if (astrictProductFlag !== 0) {
          let discountTicketId = item.ticketId;
          this.$router.push({
            name: 'productList',
            query: {
              discountTicketId: discountTicketId
            }
          })
        } else {
          this.$router.push({
            name: 'productList'
          })
        }
      }
    },
    // 切换奖券查询规则
    changeTicketType(val) {
      this.ticketType = val;
      this.getTicketList();
    },
    // 使用券操作
    goUseTicket(item) {
      let _this = this;
      if (this.ticketType === 1) {
        // 抽奖券使用
        _this.$router.push({
          path: "/horseRaceLamp",
          query: { activityId: item.activityGambleId },
        });
      } else if (this.ticketType === 2) {
        // 秒杀券使用操作
        _this.$router.push({ path: "/productList" });
      }
    },
    changeTicketStatus(type) {
      let ticketStatus = parseInt(type);
      this.ticketStatus = ticketStatus;
      this.pageNo = 1;
      this.getTicketList();
    },
    selectedTap() {
      this.getTicketList();
    },
    statusClick(num) {
      this.ticketStatus = num;
      this.getTicketList();
    },
    // 获取奖券列表
    getTicketList() {
      let _this = this;
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let ticketStatus = this.ticketStatus;
      let ticketType = this.ticketType;
      this.loadingStatus = true;
      let url = "";
      let data = {};
      if (ticketType === 1) {
        url = "/activityGamble/customerActivityGambleTicket/list";
        data = {
          status: parseInt(ticketStatus),
          pageNo: parseInt(pageNo),
          pageSize: pageSize,
        };
      } else {
        url = "/customer/customerDiscountTicket/list";
        data = {
          status: parseInt(ticketStatus),
          pageNo: parseInt(pageNo),
          pageSize: pageSize,
        };
      }
      getAction(url, data).then((res) => {
        this.loadingStatus = false;
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.ticketDataList = resData.records;
          _this.pageNo = resData.current;
          _this.total = resData.total;
        }
      });
    },
    // 奖券状态
    ticketStatusText(status) {
      switch (status) {
        case "0":
          return "待使用";
        case "1":
          return "已使用";
        case "-1":
          return "已过期";
        case "-2":
          return "系统已撤回";
      }
    },
    // 处理时间格式
    timeClear(time) {
      if (time && typeof time !== "undefined") {
        let timeArr = time.split(" ");
        return `${timeArr[0]}`;
      }
    },
    // 转盘活动显示
    showZhuanpan(data) {
      let _this = this;
      if (_this.isAcountDep === 1) {
        _this.$router.push({ name: "adventures", params: { zhuanPanShow: 1 } });
      } else if (_this.isAcountDep === 0) {
        _this.$layer.alert("太可惜了,您不具有抽奖资格!");
      } else {
        _this.$layer.alert("活动已结束!");
      }
    },
    // 关闭转盘
    closeSelf(data) {
      this.zhuanPanShow = data;
    },
    // 切换页面返回的列表数据
    currentChange(val) {
      this.pageNo = val;
      this.getTicketList();
    },
  },
};
</script>

<style lang="scss" scpoed>
@import "../assets/styles/center/ticketList";
</style>