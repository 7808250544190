<template>
  <div class="liveBroContainer">
    <header-top></header-top>
    <div class="liveHeader">
      <div class="content container_box">
        <router-link to="/">
          <img src="../assets/images/common/logo.png" alt="logo" />
        </router-link>
        <div class="headerText">江中医药直播</div>
      </div>
    </div>
    <div class="liveHeaderContent container_box">
      <div class="liveLogo">
        <img :src="liveDetailData.imgPath" alt="">
      </div>
      <div class="liveData">
        <p class="title">{{ liveDetailData.name }}</p>
        <p class="detail">{{ liveDetailData.detail }}</p>
      </div>
      <div class="rightActive">
        <div class="guanzhu">
          <p v-if="!liveDetailData.collectFlag" @click="guanzhu(liveDetailData, true)">+关注</p>
          <p v-else @click="guanzhu(liveDetailData, false)" class="yiguanzhu">已关注</p>
        </div>
        <p class="goShopCart">
          <router-link to="/shoppingCart">前往购物车</router-link>
        </p>
        <div @click="changeShareStatus" class="shareContent">
          <img src="../assets/images/liveBro/liveShare.png" alt="">
          <span>分享</span>
          <i class="el-icon-arrow-right"></i>
          <div v-show="shareShow" class="shareCodeImg">
            <img class="ercode" src="@/assets/images/footer/ecode.png" alt="">
            <p>手机扫一扫</p>
            <p class="copyUrl">复制链接</p>
          </div>
        </div>
      </div>
    </div>
    <div class="liveDetailMain clear container_box">
      <!-- 使用TcpPlayer -->
      <div class="videoLeftBox">
        <video id="my-video" class="video-js vjs-default-skin" autoplay="autoplay" playsinline webkit-playsinline>
        </video>
      </div>
      <div class="videoRightBox">
        <el-tabs @tab-click="changeProList" v-model="tabIndex" type="border-card">
          <el-tab-pane label="直播间" key="0">
            <div class="rightContent danmuContent">
              <el-scrollbar ref="leftScrollbar" class="danmuList">
                <div class="danmuData" v-for="(item, index) of rollmsg" :key="'danmu' + index">
                  <div v-if="item.type === 1">
                    <p class="name">{{ item.uname }}：</p>
                    <p class="remark">{{ item.content }}</p>
                  </div>
                  <div v-else-if="item.type === 2">
                    <p class="name come">{{ item.uname }}进入了直播间</p>
                  </div>
                </div>
              </el-scrollbar>
              <div class="sendInput">
                <el-input v-model="textMsg" @change="sendUserText" placeholder="说点什么吧......">
                  <el-button class="sendIcon" slot="append" @click.stop="sendUserText" icon="el-icon-s-promotion">
                  </el-button>
                </el-input>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="产品列表" key="1">
            <div class="rightContent productList">
              <el-scrollbar class="scrollList">
                <ul class="productUl">
                  <li class="productLi" v-for="(item, index) of productList" :key="'productId' + item.id + index">
                    <div class="productBox">
                      <div class="dataBox">
                        <div class="proDataTop">
                          <div class="name">
                            <div v-if="productDataImg(item) !== ''" class="proImgBox">
                              <img class="proIcon" src="../assets/images/liveBro/proIcon.png" alt="">
                              <div class="productImg">
                                <span class="listImgBox">
                                  <img :src="productDataImg(item)" alt="产品图片" />
                                </span>
                              </div>
                            </div>
                            <span class="proName"
                              :title="item.productName + `${item.productFormat ? '/' + item.productFormat : ''}`">
                              <router-link :to="{
                                path: '/productDetail',
                                query: { productId: item.productId, liveActivityId: item.productActivityPriceStrategyId }
                              }" target="_blank">{{ item.productName }}/{{ item.productFormat }}
                              </router-link>
                            </span>
                          </div>
                          <p :title="item.productManufacturer" class="manufacturer">
                            <router-link :to="{
                              path: '/productDetail',
                              query: { productId: item.productId, liveActivityId: item.productActivityPriceStrategyId }
                            }" target="_blank">{{ item.productManufacturer }}
                            </router-link>
                          </p>
                        </div>
                        <div class="proDataBottom">
                          <p class="priceBox">
                            <img src="../assets/images/liveBro/priceIcon.png" alt="">
                            <span :title="item.price">￥{{ item.price }}
                            </span>
                          </p>
                          <div class="amoutBox">
                            <span @click="lessNumber(item)">-</span>
                            <input :class="{
                              shoppingHas:
                                item.shoppingCartAmount && item.shoppingCartAmount > 0,
                            }" :value="
                            item.addCartQuantity" @focus="selectShopAmount($event)" type="text" @input="changeActInput(item, $event)"
                              @keyup.enter="actJionShopCart(item)" />
                            <span @click="addNumber(item)">+</span>
                          </div>
                          <div @click="actJionShopCart(item)" class="jionBtnBox">
                            <img src="@/assets/images/product/list/jionShop.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </el-scrollbar>
            </div>
          </el-tab-pane>
          <el-tab-pane label="优惠券" key="2">
            <div class="rightContent ticketList">
              <el-scrollbar class="ticketDataList">
                <div class="ticketDataBox" v-for="(item, index) of ticketList" :key="'ticketId' + item.id + index">
                  <div class="ticketLeft">
                    <div v-if="item.ticketType == 1" class="price">
                      <p class="money">{{ item.ticketDiscountMoney }}</p>
                      <p class="typeText">元</p>
                    </div>
                    <div v-if="item.ticketType == 2" class="price">
                      <p class="money">
                        {{ parseFloat((item.ticketDiscountMoney * 10).toPrecision(12)) }}
                      </p>
                      <p class="typeText">折</p>
                    </div>
                    <div class="ticketData">
                      <p class="name">{{ item.ticketName }}</p>
                      <p class="date">
                        仅{{ timeClear(item.startTime) }}-{{
                        timeClear(item.endTime)
                        }}使用
                      </p>
                    </div>
                    <div class="detaill">{{ item.ticketDetail }}</div>
                  </div>
                  <div class="ticketRight">
                    <a v-if="item.ticketReceiveStatus == 1" class="sanzi" href="javascript:;">已领取</a>
                    <a v-if="item.ticketReceiveStatus == 0" class="sanzi" href="javascript:;">已领完</a>
                    <a v-else @click="lingquTicket(item)" href="javascript:;">立即领取</a>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { deleteAction, getAction, postAction } from "../api/manage";
import Footer from "../components/common/Footer.vue";
import HeaderTop from "../components/common/HeaderTop.vue";
import { ProductMixin } from "@/mixins/ProductMixin";
import { createTouristToken, getUserInfo } from "../assets/js/common/common";
import { SHOPPING_ACCESS_TOKEN } from "../store/mutation-types";
export default {
  name: "LiveBroDetail",
  mixins: [ProductMixin],
  components: { HeaderTop, Footer },
  data() {
    return {
      liveActivityId: "",
      liveDetailData: {},
      videoUrl: "",
      path: "ws://b2b.365my.net/websocket/liveActivity",
      socket: null,
      textMsg: "",
      tabIndex: "0",
      productList: [], // 产品列表数据
      ticketList: [], // 奖券列表数据
      rollmsg: [], // 弹幕列表数据
      userInfo: {},
      videoPlayer: null,
      shareShow: false,
    };
  },
  created() {
    if (this.videoPlayer != null) {
      //判断是否已经初始化视频
      this.videoPlayer.dispose(); //销毁video
    }
    let userInfo = getUserInfo();
    if (userInfo) {
      this.userInfo = userInfo;
      this.loginState = true;
    }
    let liveActivityId = this.$route.query.id;
    if (liveActivityId && liveActivityId !== "") {
      this.liveActivityId = liveActivityId;
      console.log("获取的直播间详情id为++++++", liveActivityId);
      this.getLiveBroData();
      // 连接websocket
      this.init();
    }
  },
  computed: {
    ...mapGetters(["companyConfigData"]),
  },
  methods: {
    changeShareStatus() {
      this.shareShow = !this.shareShow
      console.log("this.shareShow", this.shareShow)
    },
    // 用户发送文本数据
    sendUserText() {
      let remark = this.textMsg;
      let liveActivityId = this.liveActivityId;
      let _this = this;
      if (remark !== "") {
        let data = {
          remark: remark,
          liveActivityId: liveActivityId,
        };
        let url = "/liveActivity/liveActivityRemark/add";
        postAction(url, data).then((res) => {
          if (res.code == 200 && res.success) {
            let myName = _this.userInfo.name;
            _this.addRollmsgList(myName, remark, 1);
            _this.textMsg = "";
          }
        });
      }
    },
    // 添加弹幕信息
    addRollmsgList(name, remark, type) {
      let _this = this;
      let cusName = this.clearUserName(name);
      let item = {
        uname: cusName,
        content: remark,
        type: type,
      };
      this.rollmsg.push(item);
      this.$nextTick(() => {
        this.$refs["leftScrollbar"].wrap.scrollTop =
          this.$refs["leftScrollbar"].wrap.scrollHeight;
      });
    },
    // 处理发送人员姓名
    clearUserName(name) {
      let cusName = "";
      if (name.length > 6) {
        cusName = name.substring(0, 5) + "***";
      } else {
        cusName = name;
      }
      return cusName;
    },
    lingquTicket(item) {
      let _this = this;
      let data = {
        id: item.id,
      };
      let url =
        "/discountTicket/discountTicketStrategyReceive/receiveDiscountTicket";
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          item.ticketReceiveStatus = 1;
        }
      });
    },
    // 处理时间格式
    timeClear(time) {
      if (time && typeof time !== "undefined") {
        let timeArr = time.split(" ");
        let dateArr = timeArr[0].split("-");

        return `${dateArr[1]}.${dateArr[2]}`;
      }
    },
    // 显示产品图片
    productDataImg(item) {
      if (item.productVO && item.productVO.picUrl && item.productVO.picUrl !== "") {
        let imgsArr = item.productVO.picUrl.split(",");
        let imgSrc = imgsArr[0];
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },

    // 展开购物车列表
    changeProList() {
      let _this = this;
      let index = this.tabIndex;
      // 1是商品列表 2是优惠券
      let url = "";
      let liveActivityId = this.liveActivityId;
      let data = {
        liveActivityId: liveActivityId,
      };
      if (index !== "0") {
        if (index == "1") {
          url = "/liveActivity/productActivityPriceStrategy/queryById";
        } else if (index == "2") {
          url =
            "/liveActivity/discountTicketStrategyReceive/getCanReceiveDiscountTicketlist";
        }
        getAction(url, data).then((res) => {
          if (res.code == 200 && res.success) {
            let resData = res.result;
            if (index == "1") {
              _this.productList =
                resData.productActivityPriceStrategyDetailList;
            } else {
              _this.ticketList = resData;
            }
          }
        });
      }
    },
    init() {
      let _this = this;
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        let token = this.$ls.get(SHOPPING_ACCESS_TOKEN);
        let decodeToken = "";
        if (token) {
          decodeToken = this.$base64.decode(token);
        } else {
          decodeToken = createTouristToken();
        }
        let liveActivityId = _this.liveActivityId;
        this.socket = new WebSocket(
          `${this.path}/${liveActivityId}/${decodeToken}`
        );
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
      }
    },
    open() {
      console.log("socket连接成功");
    },
    error() {
      console.log("连接错误");
    },
    getMessage(msg) {
      let _this = this;
      // type 1:弹幕  2：礼物  3：点赞数量  5：产品推荐  6：优惠券推荐  7：用户上线
      if (msg.data) {
        let resData = JSON.parse(msg.data);
        let type = resData.type;
        let returnData = resData.data;
        if (type == 1) {
          let remark = returnData.remark;
          let name = returnData.customerName;
          _this.addRollmsgList(name, remark, 1);
        } else if (type == 7) {
          let name = returnData.customerName;
          _this.addRollmsgList(name, "", 2);
        } else if (type == 5) {
          _this.tabIndex = "1";
          _this.changeProList();
        } else if (type == 6) {
          _this.tabIndex = "2";
          _this.changeProList();
        }
      }
    },
    send() {
      _this.socket.send(params);
    },
    close() {
      console.log("socket已经关闭");
    },
    renderGkrs(item) {
      if (item.status === 1) {
        let gkrsNum = item.lookCount;
        if (gkrsNum > 9999) {
          let gkrsNumW = gkrsNum / 10000;
          return gkrsNumW + "万";
        } else {
          return gkrsNum;
        }
      } else {
        return "暂未开播！";
      }
    },

    // 关注直播间操作
    guanzhu(item, flag) {
      let _this = this;
      let liveDetailId = item.id;
      // 关注直播间操作
      if (flag) {
        let url = "/liveActivity/customerCollectLiveActivity/add";
        let data = {
          liveActivityId: liveDetailId,
        };
        postAction(url, data).then((res) => {
          if (res.code === 200 && res.success) {
            _this.$message({
              message: "已关注该主播！",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            item.collectFlag = true;
          }
        });
      } else {
        // 取消直播间关注
        let url =
          "/liveActivity/customerCollectLiveActivity/delete?id=" + liveDetailId;
        let data = {
          id: liveDetailId,
        };
        deleteAction(url, data).then((res) => {
          if (res.code === 200 && res.success) {
            _this.$message({
              message: "已取消关注",
              offset: 400,
              duration: 1000,
              type: "error",
            });
            item.collectFlag = false;
          }
        });
      }
    },
    getLiveBroData() {
      let _this = this;
      let liveActivityId = this.liveActivityId;
      let data = {
        id: liveActivityId,
      };
      let url = "/liveActivity/liveActivity/queryById";
      getAction(url, data).then((res) => {
        if (res.code === 200 && res.success) {
          let resData = res.result;
          console.log("获取直播间详情的数据为++++++", resData);
          _this.videoUrl = resData.pullStreamPath;
          _this.liveDetailData = resData;
          // 渲染直播间弹幕数据
          let liveActivityRemarkList = resData.liveActivityRemarkList;
          if (liveActivityRemarkList && liveActivityRemarkList.length > 0) {
            for (let key of liveActivityRemarkList) {
              _this.addRollmsgList(key.customerName, key.remark, 1);
            }
          }
          // todo 这是之前使用rtmp的代码
          // _this.$nextTick(() => {
          //   _this.videoPlayer = videojs("my-video"); //关联video的id
          //   // _this.videoPlayer.src({
          //   //   type: "rtmp/flv",
          //   //   src: _this.videoUrl, //要播放的视频流url
          //   // });
          //   _this.videoPlayer.play(); //进行播放
          // });
          // 下面是使用TcpPlayer的代码
          var player = TCPlayer('my-video', {}); // player-container-id 为播放器容器 ID，必须与 html 中一致
          player.src(resData.pullStreamPathWebrtc); // url 播放地址
        }
      });
    },
  },
  destroyed() {
    // 销毁监听
    console.log("销毁socket");
    this.socket.onclose = this.close;
  },
  mounted() {
    // rtmp协议播放
    // this.videoPlayer = videojs("my-video", {
    //   preload: "auto", // 预加载
    //   autoplay: true, // 自动播放
    //   flash: {
    //     swf: "//vjs.zencdn.net/swf/5.4.2/video-js.swf",
    //   },
    //   notSupportedMessage: "你的浏览器不支持Flash播放",
    // });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/liveBro/liveBro.scss";
#my-video {
  ::v-deep .vjs-button.vjs-playing{
    display: none !important;
  }
}
</style>