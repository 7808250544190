<template>
  <div class="error-page">
    <div class="main-bg1"></div>
    <h3 class="error-title">找不到当前页面</h3>
    <p class="error-main-tip">
      抱歉你访问的页面不存在。
    </p>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  data() {
    return {
      companyConfigData: {},
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.error-page {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 310px;

  .main-bg1 {
    width: 310px;
    height: 230px;
    background: url("~@/assets/images/common/404-bg.png") no-repeat center;
  }

  .error-title {
    font-size: 34px;
    margin-top: 30px;
  }

  .error-main-tip {
    text-align: center;
    margin-top: 30px;
    line-height: 35px;
    font-size: 18px;
    font-weight: 400;
    color: #585858;
  }

  .customer-tel {
    margin-top: 30px;
    font-size: 17px;
    font-weight: bold;
    color: $act-color;
  }

  .link-service {
    margin-top: 30px;
    display: inline-block;
    width: 120px;
    height: 35px;
    border-radius: 5px;
    text-align: center;
    line-height: 35px;
    font-size: 15px;
    background-color: $base-color;
    color: #ffffff;
  }
}
</style>