<template>
  <div class="carouselBox container" :style="`background-color: ${carouselBgUrl}`">
    <div class="container_box">
      <el-carousel v-if="JSON.stringify(carouselList) !== '[{}]'" @change="carouseChange" ref="carousel"
        indicator-position="none" :interval="5000" class="carouseListBox" trigger="click" height="420px">
        <el-carousel-item v-for="(item, index) of carouselList" :key="'indexCarousel' + index">
          <a @click="gotoUrl(item)" href="javascript:;">
            <img :src="$util.resizeImg(item.picUrl, 730, 420)" alt="" />
          </a>
        </el-carousel-item>

        <div class="indexBox">
          <span v-for="(item, index) of carouselList" :key="'span' + index" @click="tagIndex(index)"
            :class="{ active: carouselActiveIndex === index }">
            {{ item.title }}
          </span>
        </div>
      </el-carousel>
      <div class="carouselRightBox">
        <div class="userActContent">
          <div class="userImgBox">
            <img v-if="userInfo && userInfo.img && userInfo.img !== ''" :src="userInfo.img" alt="用户头像" />
            <img v-else src="@/assets/images/common/logo-icon.jpg" alt="用户头像" />
            <p v-if="loginState">
              <span :title="userInfo.name">{{ userInfo.name }}</span>
            </p>
            <p v-else>请登录后操作！</p>
          </div>
          <div v-if="loginState" class="userActBox">
            <a @click="Logout()" class="logoutLink active" href="javascript:;">退出</a>
          </div>
          <div v-else class="userActBox">
            <router-link class="loginLink active" to="/login">登录</router-link>
            <router-link class="registerLink" to="/register">注册</router-link>
          </div>
        </div>
        <div class="userinfoContent">
          <div>
            <router-link to="/shoppingCart">
              <img src="@/assets/images/index/userinfoIcon(3).png" alt="购物车" />
              <span>购物车</span>
            </router-link>
          </div>
          <div>
            <router-link to="/orderList?state=3">
              <img src="@/assets/images/index/userinfoIcon(1).png" alt="我的订单" />
              <span>我的订单</span>
            </router-link>
          </div>
          <div>
            <router-link to="/productList?collectFlag=1">
              <img src="@/assets/images/index/userinfoIcon(6).png" alt="我的收藏" />
              <span>我的收藏</span>
            </router-link>
          </div>
          <div>
            <router-link to="/integralCenter">
              <img src="@/assets/images/index/userinfoIcon(2).png" alt="积分商城" />
              <span>积分商城</span>
            </router-link>
          </div>
          <div>
            <router-link to="/wantToBuyProduct">
              <img src="@/assets/images/index/userinfoIcon(5).png" alt="产品求购" />
              <span>产品求购</span>
            </router-link>
          </div>
          <div>
            <a :href="`http://wpa.qq.com/msgrd?v=3&uin=${companyConfigData.customerServiceQq}&site=qq&menu=yes`"
              target="_blank">
              <img src="@/assets/images/index/userinfoIcon(4).png" alt="联系客服" />
              <span>联系客服</span></a>
          </div>
        </div>
        <hr class="hr" />
        <div class="noticeContent">
          <div class="noticeTop">
            <div class="noticeTitle">
              <img src="@/assets/images/index/noticeIcon.png" alt="通知公告" />
              <p>通知公告</p>
            </div>
            <router-link v-if="companyNoticeList && companyNoticeList.length > 0"
              :to="`/notice?id=` + companyNoticeList[0].id"> 更多>> </router-link>
          </div>
          <div class="noticeList">
            <router-link v-for="(item, index) of newNoticeData" :key="'newNotice' + index" :to="{
              name: 'notice',
              query: { id: item.noticeId, detailId: item.id },
            }">{{ item.title }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getAction, postAction } from "../../api/manage";
import { getUserInfo } from "../../assets/js/common/common";
export default {
  name: "Carousel",
  data() {
    return {
      carouselActiveIndex: 0,
      carouselList: [{}],
      newNoticeData: [],
      carouselBgUrl: "", // 轮播图的主题色
      userInfo: null,
      loginState: false,
    };
  },
  created() {
    this.getIndexCarousel();
    let companyNoticeList = this.$ls.get('companyNoticeList');
    if (!companyNoticeList) {
      this.getNewNotice();
    }
    let userInfo = getUserInfo();
    if (userInfo) {
      this.userInfo = userInfo;
      this.loginState = true;
    } else {
      this.loginState = false;
    }
  },
  computed: {
    ...mapGetters(["companyConfigData", "companyNoticeList"]),
  },
  methods: {
    ...mapActions(["Logout"]),
    gotoUrl(item) {
      let userInfo = this.userInfo;
      let gotoUrlType = item.gotoUrlType || '1'
      let gotoUrl = item.gotoUrl;
      if (gotoUrl && gotoUrl !== '') {
        if (gotoUrlType == '3') {
          window.open(gotoUrl + `?Shopping-Access-Token=${userInfo.token}`);
        } else {
          window.open(gotoUrl)
        }
      } else {
        return false
      }
    },
    carouseChange(item) {
      let resData = this.carouselList;
      this.carouselActiveIndex = item;
      this.carouselBgUrl = resData[item].bgcolor;
    },
    // 查看当前的公告的数据
    getNewNotice() {
      let _this = this;
      let data = {
        pageNo: 1,
        pageSize: 4,
      };
      let url = "/notice/noticeDetail/list";
      getAction(url, data).then((res) => {
        this.noticeLoading = false;
        if (res.code === 200 && res.success) {
          let resData = res.result;
          _this.$ls.set('companyNoticeList', resData.records)
          _this.newNoticeData = resData.records;
        }
      });
    },
    getIndexCarousel() {
      // 1. 获取首页轮播图
      const _this = this;
      const dataLun = {
        carouselType: 1,
      };
      const urllun = "/homeShow/carousel/getCarouselList";
      postAction(urllun, dataLun).then((res) => {
        if (res.code === 200 && res.success) {
          const resData = res.result;
          // 判断返回的轮播图数据是否存在
          if (resData.length > 0) {
            _this.carouselList = resData;
            _this.carouselBgUrl = resData[0].bgcolor;
          }
        }
      });
    },
    tagIndex(index) {
      this.$refs.carousel.setActiveItem(index);
    },
  },
  destroyed() { },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/index/carousel";

::v-deep .el-carousel__indicators {
  display: none !important;
}
</style>