<template>
  <div class="areaContainer" :style="
    flashSaleDetail &&
      flashSaleDetail.backgroundColor &&
      flashSaleDetail.backgroundColor !== ''
      ? `background-color:${flashSaleDetail.backgroundColor}`
      : ''
  ">
    <!-- banner区域 -->
    <div v-if="flashSaleDetail && flashSaleDetail.picUrl && flashSaleDetail.picUrl !== ''" class="areaBanner container">
      <img :src="flashSaleDetail.picUrl" alt="秒杀专区banner图" />
    </div>
    <!-- end banner区域 -->
    <!-- 秒杀活动专区 -->
    <div class="activityBox container_box">
      <!-- 限时抢购 -->
      <div id="xsqgNav" v-if="flashSale.list.length > 0" class="xsqgContent activityItem">
        <div v-if="flashSaleDetail.endDate && flashSaleDetail.endTime" class="activityTitle">
          <span class="tipText">距离结束还剩:</span>
          <CountDown :target="countDownText(flashSaleDetail)" :on-end="onEndHandle">
          </CountDown>
        </div>
        <ul v-loading="flashSale.loading" class="imglistbox">
          <li v-for="(item, index) of flashSale.list"
            :class="{ 'offline-sale-item': item.offlineSaleFlag && item.offlineSaleFlag == '1' }"
            v-bind:key="'imgProduct' + index">
            <div>
              <div class="productImgBox">
                <router-link :to="{
                  path: '/productDetail',
                  query: { productId: item.productId, productActivityPriceStrategyId: item.productActivityPriceStrategyId },
                }" style="display: inline-block" :title="item.productName" target="_blank">
                  <!-- 查看专区产品遮罩图 -->
                  <img
                    v-if="flashSaleDetail && flashSaleDetail.productBackgroundPic && flashSaleDetail.productBackgroundPic !== ''"
                    :src="flashSaleDetail.productBackgroundPic" class="maskImg" alt="产品遮罩图" />
                  <img class="productImg" v-lazy="productDataImg(item)" />
                </router-link>
              </div>
            </div>
            <!--产品名称-->
            <div class="img-mingzi">
              {{ item.productName }}
            </div>
            <!-- 秒杀产品显示的价格 -->
            <div v-if="item.price" class="proPriceContent clearfix">
              <p class="priceBox fl">￥{{ item.price }}</p>
              <p v-if="item.originalPrice" class="yjPriceBox fl">
                <i class="priceName">原价:</i>
                ￥{{ item.originalPrice }}
              </p>
            </div>
            <div v-if="loginState && item.inventory > 0" class="tipBox">
              <span class="tip1">秒杀</span>
            </div>
            <div class="tipBox" v-else></div>
            <div style="cursor: pointer" @click="searchFatory(item.productManufacturer)" class="img-compamy"
              :title="item.productManufacturer">
              {{
                item.productManufacturer &&
                item.productManufacturer.replace(/\s*/g, "") !== ""
                ? item.productManufacturer
                : "暂无厂家信息！"
              }}
            </div>
            <div class="img-guige">
              规格：<span :title="item.productFormat">{{
                item.productFormat
              }}</span>
            </div>
            <div class="img-guige">
              有效期:
              {{
                item.productionDate && item.productionDate !== ""
                ? item.productionDate
                : "暂无效期"
              }}
            </div>
            <div class="img-xiaoqi">
              <span v-if="item.inventory" class="kucun">
                限购数量：
                {{
                  item.canSaleAmount === null || item.canSaleAmount === 99999999
                  ? item.customerBuyAmountAstrict == -1
                    ? "不限购"
                    : item.customerBuyAmountAstrict
                  : item.canSaleAmount
                }}
              </span>
              <span v-else class="kucun">库存：暂无库存</span>
            </div>
            <div v-if="!loginState" class="cz_content">
              <div class="caozuoBox">
                <p class="zwkcText">会员可见</p>
              </div>
              <div @click="gotoLogin" class="jionBtnBox">
                <img src="../assets/images/product/img/jionShop.png" />
                加入购物车
              </div>
            </div>
            <div v-else-if="item.inventory && item.inventory > 0" class="cz_content">

              <div class="amoutBox">
                <span @click="lessNumber(item)">-</span>
                <input :class="{
                  shoppingHas:
                    item.shoppingCartAmount && item.shoppingCartAmount > 0,
                }" :value=" item.addCartQuantity ? item.addCartQuantity : 1" @focus="selectShopAmount($event)" type="text" @change="changeActInput(item, $event)"
                  @keyup.enter="actJionShopCart(item)" />
                <span @click="addNumber(item)">+</span>
              </div>
              <div @click="actJionShopCart(item)" class="jionBtnBox">
                <img src="../assets/images/product/img/jionShop.png" />
                {{ (item.offlineSaleFlag && item.offlineSaleFlag == '1' && item.productNotputSaleCreateOrder == '1') ?
                  '开票员订货' : '加入购物车' }}
              </div>
            </div>
            <div v-else class="cz_content">
              <div class="caozuoBox">
                <p class="zwkcText">暂无库存</p>
              </div>
              <div class="jionBtnBox spqgBtnBox">
                <router-link :to="{ path: '/wantToBuyProduct', query: qiugouDenglu(item) }">
                  <img src="../assets/images/product/img/search.png" />
                  商品求购
                </router-link>
              </div>
            </div>
          </li>
        </ul>

        <div class="pageFooter">
          <el-pagination background v-if="flashSale.totalCount > 0" :hide-on-single-page="true"
            layout="prev, pager, next, jumper" :current-page="flashSale.pageNo" @current-change="currentChange1"
            :page-size="flashSale.pageSize" :total="flashSale.totalCount">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProductMixin } from "@/mixins/ProductMixin";
import { getAction } from "../api/manage";
import { getUserInfo } from "../assets/js/common/common";

import CountDown from '../components/common/CountDown/CountDown.vue'
export default {
  name: "SecshaZone",
  mixins: [ProductMixin],
  components: {
    CountDown
  },
  data() {
    return {
      loginState: false,
      flashSaleDetail: {},
      flashSaleId: '',
      limitedTime: null,
      flashSale: {
        list: [],
        pageSize: 20,
        pageNo: 1,
        totalCount: 1,
        loading: false,
      }
    };
  },
  created() {
    let flashSaleId = this.$route.query.id
    if (flashSaleId && flashSaleId !== '') {
      this.flashSaleId = flashSaleId
    }
    let userInfo = getUserInfo();
    if (userInfo) {
      this.loginState = true;
    }
    this.getFlashSaleList();
  },
  methods: {
    defaultInventory(list) {
      console.log("activityBoxlist +++++++++++++++++", list)
      const arr = [];
      if (list && typeof list !== "undefined" && list.length > 0) {
        arr.push(list[0]);
      }
      return arr;
    },
    // 当天倒计时问题
    countDownText(item) {
      let returnText = 0;
      console.log("item.nowTime+++++++", item.nowTime)
      if (item && item.nowTime && item.nowTime !== '' && item.endTime && item.endTime !== '') {
        let nowTimeArr = item.nowTime.split(' ');
        let endTime = item.endTime;
        let nowEndTime = new Date(`${nowTimeArr[0]} ${endTime}`).getTime()
        console.log("nowEndTime++++++++", nowEndTime)
        returnText = nowEndTime
      }
      return returnText
    },
    // 倒计时结束回调事件
    onEndHandle() {
      this.getFlashSaleList()
    },
    // 获取秒杀专区
    getFlashSaleList() {
      let _this = this;
      let pageNo = this.flashSale.pageNo;
      let pageSize = this.flashSale.pageSize;
      this.flashSale.loading = true;
      let flashSaleId = this.flashSaleId
      let url =
        "/productActivityPriceStrategy/productActivityPriceStrategy/queryDays";
      let data = {
        id: flashSaleId,
        pageNo: pageNo,
        pageSize: pageSize,
      };
      getAction(url, data).then((res) => {
        _this.flashSale.loading = false;
        if (res.code == 200 && res.success && res.result) {
          let resData = res.result[0];
          if (
            resData &&
            resData.productActivityPriceStrategyDetailList &&
            resData.productActivityPriceStrategyDetailList.length > 0
          ) {
            _this.flashSaleDetail = resData;
            _this.flashSale.list =
              resData.productActivityPriceStrategyDetailList;
          }
        }
      });
    },
    currentChange1(val) {
      this.flashSale.pageNo = val;
      this.getFlashSaleList();
    },
    // 显示产品图片
    productDataImg(item) {
      if (item.productPicUrl && item.productPicUrl !== "") {
        let imgsArr = item.productPicUrl.split(",");
        let imgSrc = imgsArr[0];
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },
    // 限时抢购倒计时
    xsqgCountDown(item) {
      let _this = this;
      let nowStatus = _this.limitedStatus(item);
      // 2. 判断不同场次的限时抢购的活动是否开始
      item.nowStatus = nowStatus;
      // 3. 当开始时间小于当前时间，结束时间又大于当前时间，证明活动已经开始，进行结束倒计时显示
      if (nowStatus) {
        let nowTimeArr = item.nowTime.split(" ");
        let ItemStartTime = `${nowTimeArr[0]} ${item.startTime}`;
        let ItemEndTime = `${nowTimeArr[0]} ${item.endTime}`;
        let ItemNowTime = `${item.nowTime}`;
        let endTime = Date.parse(new Date(ItemEndTime)) / 1000;
        let startTime = Date.parse(new Date(ItemStartTime)) / 1000;
        let nowTime = Date.parse(new Date(ItemNowTime)) / 1000;
        // 4. 计算出总的倒计时秒数
        let allTimeS = 0;
        allTimeS = endTime - nowTime;
        // 5. 开始执行定时器倒计时
        _this.limitedTime = setInterval(function (e) {
          allTimeS--;
          _this.countBackTime2(item, allTimeS);
        }, 1000);
      }
    },
    // 判断活动时间是否开始
    limitedStatus(item) {
      let nowTimeArr = item.nowTime.split(" ");
      let ItemStartTime = `${nowTimeArr[0]} ${item.startTime}`;
      let ItemEndTime = `${nowTimeArr[0]} ${item.endTime}`;
      let ItemNowTime = `${item.nowTime}`;
      let endTime = Date.parse(new Date(ItemEndTime)) / 1000;
      let startTime = Date.parse(new Date(ItemStartTime)) / 1000;
      let nowTime = Date.parse(new Date(ItemNowTime)) / 1000;
      if (endTime > nowTime && nowTime > startTime) {
        return true;
      } else {
        this.deleteNavList(2);
        return false;
      }
    },
    // 倒计时返回值
    countBackTime2(item, allTimeS) {
      let _this = this;
      let suM = allTimeS % 60; //求出所于的秒数
      suM = suM < 10 ? "0" + suM : suM;
      let suF = ((allTimeS - suM) / 60) % 60; //求出所于分钟数
      suF = suF < 10 ? "0" + suF : suF;
      let fenS = suF * 60;
      let suH = ((allTimeS - fenS - suM) / (60 * 60)) % 24; //求出所于小时数
      suH = suH < 10 ? "0" + suH : suH;
      let shiS = suH * 60 * 60;
      let suT = (allTimeS - shiS - fenS - suM) / (24 * 60 * 60); //求出所于小时数
      suT = suT < 10 ? "0" + suT : suT;
      if (suM <= 0 && suF <= 0 && suH <= 0 && suT <= 0) {
        // 移除倒计时
        clearInterval(_this.limitedTime);
      } else {
        _this.$set(item, "suT", suT);
        _this.$set(item, "suH", suH);
        _this.$set(item, "suF", suF);
        _this.$set(item, "suM", suM);
      }
    },
  },
  unmounted() {
    clearInterval(this.limitedTime);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/activity/activity";
@import "../assets/styles/product/img/img";

.activityBox {
  .xsqgContent {
    margin-top: 0px;
    padding-top: 20px;
  }
}

.pageFooter {
  margin-bottom: 50px;
}
</style>