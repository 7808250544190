<template>
  <div v-if="editAddStatus" class="editAddressBox">
    <div class="maskBox"></div>
    <div class="addressModel">
      <div class="editTitle">
        {{ addActType == 1 ? "添加" : "修改" }}收货地址
        <span @click="closeAddressModel" class="close">&times;</span>
      </div>
      <div class="addressMain">
        <div class="dizhi">
          <div class="labelContent">
            <p class="labelLeft">所在地区:</p>
            <div class="rightBox">
              <select
                @change="renderCity(provinceId)"
                v-model="provinceId"
                name="province"
                id="province"
              >
                <option
                  v-for="(item, index) of provinceList"
                  :key="'province' + index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
              <select
                @change="renderDistrict(cityId)"
                v-model="cityId"
                name="city"
                id="city"
              >
                <option
                  v-for="(item, index) of cityList"
                  :key="'city' + index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
              <select v-model="districtId" name="district" id="district">
                <option
                  v-for="(item, index) of districtList"
                  :key="'district' + index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <p class="tip tip1">{{ verifyAddressMsg.tip1 }}</p>
          <div class="labelContent">
            <p class="labelLeft">详细地址:</p>
            <div class="rightBox">
              <input v-model="address" class="xxdzInput" type="text" />
            </div>
          </div>
          <p class="tip tip2">{{ verifyAddressMsg.tip2 }}</p>
          <div class="labelContent">
            <p class="labelLeft">收货人:</p>
            <div class="rightBox">
              <input v-model="contactsName" type="text" />
            </div>
          </div>
          <p class="tip tip3">{{ verifyAddressMsg.tip3 }}</p>
          <div class="labelContent">
            <p class="labelLeft">联系方式:</p>

            <div class="rightBox">
              <input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="contactsTel" type="text" />
            </div>
          </div>
          <p class="tip tip4">{{ verifyAddressMsg.tip4 }}</p>
          <div class="activeBox">
            <a @click="closeAddressModel" class="cancel" href="javascript:;"
              >取消</a
            >
            <a @click="saveAddressAct" class="saveBtn" href="javascript:;"
              >保存</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cityData from "@/assets/js/common/citydata2";
import { postAction, putAction } from "../../api/manage";

export default {
  name: "AddressModel",
  data() {
    return {
      editAddStatus: false,
      url: {
        add: "/customer/customerAddress/add",
        edit: "/customer/customerAddress/edit",
      },
      addActType: 1,
      provinceList: [],
      cityList: [],
      districtList: [],
      addressId: null,
      provinceId: "",
      cityId: "",
      districtId: "",
      contactsName: "",
      contactsTel: "",
      address: "",
      verifyAddressMsg: {
        tip1: "",
        tip2: "",
        tip3: "",
        tip4: "",
      },
    };
  },
  methods: {
    add() {
      this.edit({});
    },
    edit(record) {
      // 清空地址数据
      this.editAddStatus = true;
      this.clearAddressForm();
      this.model = Object.assign({}, record);
      console.log("修改用户地址的数据为", record);
      if (JSON.stringify(record) === "{}") {
        this.addActType = 1;
        this.addressId = null;
      } else {
        this.addActType = 2;
        this.renderFormData(record);
      }
      this.renderProvince();
      this.renderCity(record.provinceId);
      this.renderDistrict(record.cityId);
      this.visible = true;
    },
    clearAddressForm() {
      this.provinceId = "";
      this.cityId = "";
      this.districtId = "";
      this.contactsName = "";
      this.contactsTel = "";
      this.address = "";
    },
    renderFormData(record) {
      this.addressId = record.id;
      this.provinceId = record.provinceId;
      this.cityId = record.cityId;
      this.districtId = record.districtId;
      this.contactsName = record.contactsName;
      this.contactsTel = record.contactsTel;
      this.address = record.address;
    },
    renderProvince() {
      console.log("cityData+++++++", cityData);
      this.provinceList = cityData.AP;
    },
    renderCity(provinceId) {
      if (!provinceId || typeof provinceId === "undefined") {
        provinceId = "031";
        this.model.provinceId = "031";
      }
      this.cityList = [];
      this.districtList = [];
      let cityList = [];
      for (let key of cityData.AC) {
        if (key.pid === provinceId) {
          cityList.push(key);
        }
      }
      this.cityList = cityList;
    },
    renderDistrict(cityId) {
      if (!cityId || typeof cityId === "undefined") {
        cityId = "0312";
        this.model.cityId = "0312";
      }
      let districtList = [];
      for (let key of cityData.AD) {
        if (key.cid === cityId) {
          districtList.push(key);
        }
      }
      this.districtList = districtList;
    },
    // 保存地址操作
    saveAddressAct() {
      // 先验证提交的收货地址数据
      let _this = this;
      let addressId = this.addressId;
      let provinceId = this.provinceId;
      let cityId = this.cityId;
      let districtId = this.districtId;
      let contactsName = this.contactsName;
      let contactsTel = this.contactsTel;
      let address = this.address;
      // 验证省市县数据
      if (provinceId == "" || cityId == "" || districtId == "") {
        this.verifyAddressMsg.tip1 = "*请选择收货地址的省市县数据！";
      } else {
        this.verifyAddressMsg.tip1 = "";
      }
      // 验证详细地址数据
      if (address == "") {
        this.verifyAddressMsg.tip2 = "*请填写详细地址数据！";
      } else {
        this.verifyAddressMsg.tip2 = "";
      }
      // 验证收货人信息
      if (contactsName == "") {
        this.verifyAddressMsg.tip3 = "*请填写收货人姓名！";
      } else {
        this.verifyAddressMsg.tip3 = "";
      }
      // 验证收货联系方式
      if (contactsTel == "") {
        this.verifyAddressMsg.tip4 = "*请填写收货联系方式！";
      } else if (!/^1[3|4|5|6|7|8|9][0-9]{9}$/.test(contactsTel)) {
        this.verifyAddressMsg.tip4 = "*请填写正确的手机号！";
      } else {
        this.verifyAddressMsg.tip4 = "";
      }
      if (
        this.verifyAddressMsg.tip1 !== "" ||
        this.verifyAddressMsg.tip2 !== "" ||
        this.verifyAddressMsg.tip3 !== "" ||
        this.verifyAddressMsg.tip4 !== ""
      ) {
        return false;
      } else {
        let data = {
          provinceId: provinceId,
          cityId: cityId,
          districtId: districtId,
          contactsName: contactsName,
          contactsTel: contactsTel,
          address: address,
        };
        if (addressId && addressId !== "") {
          data.id = addressId;
          let url = this.url.edit;
          console.log("修改地址数据提交的数据为", data);
          putAction(url, data).then((res) => {
            console.log("修改地址返回的数据为", res);
            if (res.code === 200 && res.success) {
              _this.editAddStatus = false;
              _this.$message({
                message: "已修改地址数据！",
                offset: 400,
                duration: 1000,
                type: "success",
              });
              _this.$emit("saveAddress");
            }
          });
        } else {
          let url = this.url.add;
          postAction(url, data).then((res) => {
            console.log("添加地址返回的数据为", res);
            if (res.code === 200 && res.success) {
              _this.editAddStatus = false;
              _this.$message({
                message: "已添加地址数据！",
                offset: 400,
                duration: 1000,
                type: "success",
              });
              _this.$emit("saveAddress");
            }
          });
          console.log("添加地址提交的地址数据为++++++", data);
        }
      }
    },
    // 关闭地址修改遮罩
    closeAddressModel() {
      this.clearAddressForm();
      this.editAddStatus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/addressModel";
</style>