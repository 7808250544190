import Vue from 'vue'
import { getUserInfo } from "@/assets/js/common/common";
import { MessageBox } from 'element-ui';
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login'
import ForgetPwd from '@/views/ForgetPwd'
import Index from '@/views/Index'
import FastShopping from '@/views/FastShopping'
import BestShopping from '@/views/BestShopping'
import ShoppingCart from '@/views/ShoppingCart'
import ProductList from '@/views/ProductList'
import ProductDetail from '@/views/ProductDetail'
import GiftDetail from '@/views/GiftDetail'
import SpecialArea from '@/views/SpecialArea'
import ActivityZone from '@/views/activityZone/ActivityZone'
import OrderPlacing from '@/views/activityZone/OrderPlacing'
import OrderMeetingProduct from '@/views/activityZone/OrderMeetingProduct'
import OrderMeetingProductDetail from '@/views/activityZone/OrderMeetingProductDetail'
import ClassifiedProducts from '@/views/activityZone/ClassifiedProducts'
import BrandProductArea from '@/views/activityZone/BrandProductArea'
import FlashSale from '@/views/activityZone/FlashSale'
import CombinationSale from '@/views/activityZone/CombinationSale'
import BrandZone from '@/views/BrandZone'
import Groceries from '@/views/Groceries'
import Medicine from '@/views/Medicine'
import CreateOrder from '@/views/CreateOrder'
import OrderPay from '@/views/OrderPay'
import OrderList from '@/views/OrderList'
import RefundList from '@/views/RefundList'
import OrderDetail from '@/views/OrderDetail'
import ApplyForReturn from '@/views/ApplyForReturn'
import Logistics from '@/views/Logistics'
import ConfirmOrder from '@/views/ConfirmOrder'
import AddressList from '@/views/AddressList'
import FeedBack from '@/views/FeedBack'
import FeedbackList from '@/views/FeedbackList'
import Center from '@/views/Center'
import NoticeList from '@/views/NoticeList'
import ArticleInfo from '@/views/ArticleInfo'
import Credentials from '@/views/Credentials'
import MyAccount from '@/views/MyAccount'
import MyLuckDrawTicket from '@/components/MyAccount/MyLuckDrawTicket'
import ChangeAccount from '@/views/ChangeAccount'
import WantToBuyProduct from '@/views/WantToBuyProduct'
import TicketList from '@/views/TicketList'
import Activity from '@/views/Activity'
import CouponList from '@/views/CouponList'
import SecshaZone from '@/views/SecshaZone'
import PromotionZone from '@/views/PromotionZone'
import NearTermZone from '@/views/NearTermZone'
import GiftList from '@/views/GiftList'
import MessageList from '@/views/MessageList'
import Collect from '@/views/Collect'
import MyWallet from '@/views/MyWallet'
import Register from '@/views/Register'
import LuckDraw from '@/views/LuckDraw'
import LuckDrawNew from '@/views/LuckDrawNew'
import LiveBro from '@/views/LiveBro'
import LiveBroDetail from '@/views/liveBroDetail'
// 开票员开票
import LinkToSale from '@/views/LinkToSale'
// 积分商城
import IntegralCenter from "../components/IntegralCenter/IntergralCenter";
import SignIntergral from "../components/IntegralCenter/SignIntergral";
// 404页面
import ErrorPage from '@/views/ErrorPage'
import MaintainPage from '@/views/MaintainPage'
Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: Home,
  redirect: '/index', // 默认显示的子路由
  children: [{
    path: '/index',
    name: 'index',
    component: Index,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/fastShopping',
    name: 'fastShopping',
    component: FastShopping,
    meta: {
      title: '快速下单'
    }
  },
  {
    path: '/bestShopping',
    name: 'bestShopping',
    component: BestShopping,
    meta: {
      title: '极速采购'
    }
  },
  {
    path: '/shoppingCart',
    name: 'shoppingCart',
    component: ShoppingCart,
    meta: {
      title: '购物车'
    }
  },
  {
    path: '/productList',
    name: 'productList',
    component: ProductList,
    meta: {
      title: '产品列表'
    }
  },
  {
    path: '/groceries',
    name: 'groceries',
    component: Groceries,
    meta: {
      title: '百货专区'
    }
  },
  {
    path: '/medicine',
    name: 'medicine',
    component: Medicine,
    meta: {
      title: '医药专区'
    }
  },
  {
    path: '/specialArea',
    name: 'specialArea',
    component: SpecialArea,
    meta: {
      title: '专区列表'
    },

  },
  {
    path: '/brandZone',
    name: 'brandZone',
    component: BrandZone,
    meta: {
      title: '品牌专区'
    }
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: ProductDetail,
    meta: {
      title: '产品详情'
    }
  },
  {
    path: 'orderMeetingProductDetail',
    component: OrderMeetingProductDetail,
    meta: {
      title: '订货会商品详情'
    }
  },
  {
    path: '/activity',
    name: 'activity',
    component: Activity,
    meta: {
      title: '活动专区'
    }
  },

  {
    path: '/couponList',
    name: 'couponList',
    component: CouponList,
    meta: {
      title: '优惠券列表'
    }
  },
  {
    path: '/secshaZone',
    name: 'secshaZone',
    component: SecshaZone,
    meta: {
      title: '秒杀专区'
    }
  },
  {
    path: '/activity/flash-sale',
    name: 'flashSale',
    component: FlashSale,
    meta: {
      title: '限时抢购'
    }
  },
  {
    path: '/activity/combination-sale',
    name: 'combinationSale',
    component: CombinationSale,
    meta: {
      title: '组合销售'
    }
  },
  {
    path: '/promotionZone',
    name: 'promotionZone',
    component: PromotionZone,
    meta: {
      title: '促销专区'
    }
  },
  {
    path: '/nearTermZone',
    name: 'nearTermZone',
    component: NearTermZone,
    meta: {
      title: '近效期产品专区'
    }
  },
  {
    path: '/createOrder',
    name: 'createOrder',
    component: CreateOrder,
    meta: {
      title: '创建订单'
    }
  },
  {
    path: '/orderPay',
    name: 'orderPay',
    component: OrderPay,
    meta: {
      title: '支付订单'
    }
  },
  {
    path: '/articleInfo',
    name: 'articleInfo',
    component: ArticleInfo,
    meta: {
      title: '帮助中心'
    }
  },
  {
    path: '/center',
    component: Center,
    redirect: '/orderList', // 默认显示的子路由
    children: [{
      path: '/orderList',
      name: 'orderList',
      component: OrderList,
      meta: {
        title: '订单列表'
      }
    },
    {
      path: '/refundList',
      name: 'refundList',
      component: RefundList,
      meta: {
        title: '售后订单'
      }
    },
    {
      path: '/addressList',
      name: 'addressList',
      component: AddressList,
      meta: {
        title: '地址管理'
      }
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: FeedBack,
      meta: {
        title: '意见反馈'
      }
    },
    {
      path: '/feedbackList',
      name: 'feedbackList',
      component: FeedbackList,
      meta: {
        title: '我的反馈'
      }
    },
    {
      path: '/giftList',
      name: 'giftList',
      component: GiftList,
      meta: {
        title: '礼品列表'
      }
    },
    {
      path: '/messageList',
      name: 'messageList',
      component: MessageList,
      meta: {
        title: '我的消息'
      }
    },
    {
      path: '/collect',
      name: 'collect',
      component: Collect,
      meta: {
        title: '我的收藏'
      }
    },
    {
      path: '/myWallet',
      name: 'myWallet',
      component: MyWallet,
      meta: {
        title: '我的钱包'
      }
    },
    {
      path: '/notice',
      name: 'notice',
      component: NoticeList,
      meta: {
        title: '公告管理'
      }
    },
    {
      path: '/credentials',
      name: 'credentials',
      component: Credentials,
      meta: {
        title: '平台资质'
      }
    },
    {
      path: '/changeAccount',
      name: 'changeAccount',
      component: ChangeAccount,
      meta: {
        title: '切换账户'
      }
    },
    {
      path: '/myAccount',
      name: 'myAccount',
      component: MyAccount,
      meta: {
        title: '我的账户'
      }
    },
    {
      path: 'myLuckDrawTicket',
      name: 'myLuckDrawTicket',
      component: MyLuckDrawTicket,
      meta: {
        title: '我的抽奖券'
      }
    },
    {
      path: '/wantToBuyProduct',
      name: 'wantToBuyProduct',
      component: WantToBuyProduct,
      meta: {
        title: '我的求购'
      }
    },
    {
      path: '/linkToSale',
      name: 'linkToSale',
      component: LinkToSale,
      meta: {
        title: '开票员订货系统'
      }
    },
    {
      path: '/ticketList',
      name: 'ticketList',
      component: TicketList,
      meta: {
        title: '我的奖券'
      }
    },
    ]
  },
  {
    path: '/confirmOrder',
    name: 'confirmOrder',
    component: ConfirmOrder,
    meta: {
      title: '确认订单'
    }
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: OrderDetail,
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/applyReturn',
    name: 'applyReturn',
    component: ApplyForReturn,
    meta: {
      title: '申请退款'
    }
  },
  {
    path: '/logistics',
    name: 'logistics',
    component: Logistics,
    meta: {
      title: '订单物流'
    }
  },
  ]
},
{
  path: '/integralCenter',
  name: 'integralCenter',
  component: IntegralCenter,
  meta: {
    title: '积分商城'
  }
},

{
  path: '/activityZone',
  name: 'activityZone',
  component: ActivityZone,
  redirect: '/activityZone/orderPlacing',
  children: [{
    path: 'orderPlacing',
    name: 'orderPlacing',
    component: OrderPlacing,
    meta: {
      title: '亚邦订货会'
    }
  },
  {
    path: 'classifiedProducts',
    name: 'classifiedProducts',
    component: ClassifiedProducts,
    meta: {
      title: '订货会分类商品'
    }
  },
  {
    path: 'brandProductArea',
    name: 'brandProductArea',
    component: BrandProductArea,
    meta: {
      title: '厂家品种'
    }
  },
  {
    path: 'orderMeetingProduct',
    component: OrderMeetingProduct,
    meta: {
      title: '订货会商品'
    }
  }
  ]
},
{
  path: '/giftDetail',
  name: 'giftDetail',
  component: GiftDetail,
  meta: {
    title: '礼品详情'
  }
},
{
  path: '/signIntergral',
  name: 'signIntergral',
  component: SignIntergral,
  meta: {
    title: '签到领积分'
  }
},
{
  path: '/luckDraw',
  name: 'luckDraw',
  component: LuckDraw,
  meta: {
    title: '抽奖活动'
  }
},
{
  path: '/luckDrawNew',
  name: 'luckDrawNew',
  component: LuckDrawNew,
  meta: {
    title: '抽奖活动'
  }
},
{
  path: '/login',
  name: 'login',
  component: Login
},
{
  path: '/liveBro',
  name: 'liveBro',
  component: LiveBro
},
{
  path: '/liveBroDetail',
  name: 'liveBroDetail',
  component: LiveBroDetail
},
{
  path: '/forgetPwd',
  name: 'forgetPwd',
  component: ForgetPwd
},
{
  path: '/register',
  name: 'register',
  component: Register
},
{
  path: '/404',
  component: ErrorPage,
  hidden: true
},
{
  path: '/maintain',
  component: MaintainPage,
  hidden: true
},

//这个*匹配必须放在最后，将改路由配置放到所有路由的配置信息的最后，否则会其他路由path匹配造成影响。     
{
  path: '*',
  redirect: '/404',
  hidden: true
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 判断用户是否登录
router.beforeEach((to, from, next) => {
  let path = to.path
  let userInfo = getUserInfo();
  // 对路由进行验证     
  if (userInfo) { // 已经登陆
    // 判断用户当前身份是否具有百货地址登录权限
    if (path == '/groceries') {
      if (userInfo.baihuoToken && userInfo.baihuoToken !== '') {
        location.href = `${userInfo.baihuoUrl}?baihuoToken=${userInfo.baihuoToken}`
      } else {
        next({ path: '/' })
        // 先提示客户不具备百货身份，然后染回上一页
        MessageBox({ message: "您的账号不具备百货商城浏览权限", title: "温馨提示", type: 'warning' });
      }
    } else if (path == '/medicine') {
      if (userInfo.yaopinToken && userInfo.yaopinToken !== '') {
        location.href = `${userInfo.yaopinUrl}?yaopinToken=${userInfo.yaopinToken}`
      } else {
        next({ path: '/' })
        // 先提示客户不具备百货身份，然后染回上一页
        MessageBox({ message: "您的账号不具备药品商城浏览权限", title: "温馨提示", type: 'warning' });
      }
    } else {
      next()   // 正常跳转到你设置好的页面
    }
  } else {
    // 设置用户不登录不能浏览的路由为
    if (path !== '/index' && path !== '/articleInfo' && path !== '/login') {
      next({ path: '/login' })
      // MessageBox({ message: "请登录后，再进行操作！", title: "提示信息：", type: 'error' });
    } else {
      next()
    }
  }
})
// 处理同一个路由下路由不变替换报错问题

// 重写push|replace方法
//先把VueRouter的push和replace方法保存一份
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function (location, resolve, reject) {
    // 此函数上下文(this指向)为VueRouter的一个实例
    if (resolve && reject) {    //如果我们自己指定了成功/失败的回调，则自己传入
        originPush.call(this, location, resolve, reject)
        //若此时直接使用originPush()方法，则函数内的this指向window（内部代码将无法执行）。故应用call或apply方法修改this指向
    } else {    //如果我们没有指定成功/失败的回调，则自动帮我们生成，防止报错
        originPush.call(this, location, () => { }, () => { })
    }
}
// replace方法同理
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
      originReplace.call(this, location, resolve, reject)
  } else {
      originReplace.call(this, location, () => { }, () => { })
  }
}
export default router