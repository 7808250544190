<template>
  <div class="luck-draw-ticket">
    <div class="header">抽奖券共9张</div>
    <div class="ticket-list clear">
      <div v-for="item in 10" :key="item" class="ticket-box">
        <div class="content">
          <div class="title">
            <p>9月16日江药唐山第一届订货节抽奖券</p>
          </div>
          <div class="ticket-no">
            <p>抽奖号码：
              <span v-if="item%3 == 0">{{ ticketCode(item) }}</span>
              <span class="btn" v-else>点击查看</span>
            </p>
          </div>
          <div class="tip">
            <p>说明: 此奖券仅限订货会现场抽奖使用。奖券解释权归主办方所有。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyLuckDrawTicket',
  data() {
    return {

    }
  },
  methods: {
    ticketCode(item) {
      let arr = item.toString().split('');
      let qianzhui = ''
      for (let i = 0; i < 5 - arr.length; i++) {
        qianzhui = qianzhui + '0'
      }
      return qianzhui + item

    }
  }
}
</script>

<style lang="scss" scoped>
.luck-draw-ticket {
  width: 930px;
  float: right;

  .header {
    height: 35px;
    line-height: 35px;
    padding-left: 15px;
    background: #f3f3f3;
    font-size: 13px;
    color: $font-color;
  }

  .ticket-list {
    width: 100%;

    .ticket-box {
      float: left;
      width: 450px;
      height: 135px;
      border-radius: 8px;
      overflow: hidden;
      margin: 10px 30px 0 0;

      p {
        text-align: center;
        color: #ffffff;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      .title {
        height: 45px;
        background: linear-gradient(0deg, #f85e09 0%, #F83600 100%);

        p {
          line-height: 45px;
          font-size: 23px;
          font-weight: 600;
          @include ellipsis;
        }
      }

      .ticket-no {
        width: 100%;
        height: 60px;
        background-image: url("../../assets/images/activity/luckDrawTicket/ticket-card-bg.png");
        background-size: 100% 100%;

        p {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 35px;
          font-weight: 600;
          line-height: 60px;
          -webkit-text-stroke: 1px #0A006D;
          text-stroke: 1px #0A006D;
          
          span {
            font-size: 35px;
            &.btn {
              display: inline-block;
              width: 100px;
              height: 35px;
              line-height: 35px;
              font-size: 17px;
              text-stroke: 0px #0A006D;
              -webkit-text-stroke: 0px #0A006D;
              background-color: #008AFF;
              border-radius: 8px;
              cursor: pointer;
            }
          }
        }
      }

      .tip {
        height: 30px;
        background-color: #008AFF;

        p {
          @include ellipsis;
          line-height: 30px;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>