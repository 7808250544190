<template>
  <div class="productDetailBox container_box">
    <div v-if="false" style="width: 100%; text-align: center">
      <img src="@/assets/images/product/loading.gif" alt="" />
    </div>
    <div v-else id="xiangqing" class="container">
      <div class="container_box clearfix">
        <div id="contentListTOP">
          <div class="dataLeft">
            <router-link to="/" class="dataWeizhi">首页</router-link>
            <b class="icon-arrow-right"> &gt; </b>
            <router-link to="/productList" class="dataWeizhi">商品列表</router-link>
            <b class="icon-arrow-right"> &gt; </b>
            <a href="javascript:;" class="dataWeizhi">{{
              productDetail.name
            }}</a>
          </div>
        </div>
        <div class="xq_main">
          <div class="xq_main_top clearfix">
            <div class="DetilLeft">
              <div class="xq_datu">
                <div @mousemove="spanMove($event)" @mouseleave="spanOut()" class="jqzoom">
                  <img ref="jqzoomImg" v-if="activeImg && activeImg !== ''" :src="activeImg" alt="商品图片" />
                  <img ref="jqzoomImg" v-else src="../assets/images/common/zwtp.png" alt="商品图片" />
                  <span :style="`top: ${spanMoveStyle.top}px;left: ${spanMoveStyle.left}px;`" v-show="spanMoveShow"
                    ref="moveSpan" class="moveSpan"></span>
                </div>
              </div>
              <div class="product_img_list">
                <el-carousel :arrow="sliceArrs(
                  productImg(productDetail),
                  5
                ).length > 1 ? 'hover' : 'never'" class="xiaotuCar" :autoplay="false" trigger="click">
                  <el-carousel-item v-for="(item, index) of sliceArrs(
                    productImg(productDetail),
                    5
                  )" :key="'xsqgItem' + index">
                    <ul class="xiaotu_list clearfix">
                      <li @mousemove="proImgclick(itemc, indexc)" :class="activeImgIndex == indexc ? 'active' : ''"
                        v-for="(itemc, indexc) of item" :key="indexc">
                        <img :src="`${$imgPath}${itemc}`" alt="" />
                      </li>
                    </ul>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="shareBox">
                <span> 分享至：</span>
                <vshare :vshareConfig="vshareConfig"></vshare>
              </div>
            </div>
            <div class="bigPic" ref="bigPic" v-show="bigDivShow">
              <img :style="`top: ${picMoveStyle.top}px;left: ${picMoveStyle.left}px;`" ref="bigPicImg"
                v-if="bigImg && bigImg !== ''" :src="bigImg" alt="商品图片" />
              <img :style="`top: ${picMoveStyle.top}px;left: ${picMoveStyle.left}px;`" ref="bigPicImg" v-else
                src="../assets/images/common/zwtp.png" alt="商品图片" />
            </div>
            <div class="mid">
              <div class="money clear">
                <p class="name">
                  {{ productDetail.name }}
                  <span class="spgg">{{ productDetail.format }}</span>
                </p>
                <div class="flash-data">
                  <!-- 秒杀活动内容提示信息 -->
                  <div v-if="productDetail.productActivityPriceStrategyDetail" class="flash-tips">
                    <img src="../assets/images/activity/flash-sale/flash-time-icon.png" alt="">
                    <span class="title-text">秒杀活动</span>
                    <div class="limit-list">
                      <span>每个ID</span>
                      <span v-if="productDetail.step && productDetail.step > 1" class="limit">不拆零{{ productDetail.step
                      }}{{
  productDetail.unit }}</span>
                      <span v-if="false" class="limit">最低起购10{{ productDetail.unit }}</span>
                      <span
                        v-if="productDetail.productActivityPriceStrategyDetail.customerBuyAmountAstrict && productDetail.productActivityPriceStrategyDetail.customerBuyAmountAstrict !== ''"
                        class="limit">最高限购{{ productDetail.productActivityPriceStrategyDetail.customerBuyAmountAstrict
                        }}{{ productDetail.unit }}</span>
                    </div>
                  </div>
                  <div v-if="false" class="flash-schedule">
                    <p>已抢：{{ scheduleNumber }}%</p>
                    <p class="schedule-bar">
                      <span class="schedule" :class="'schedule-' + scheduleNumber"></span>
                    </p>
                  </div>
                </div>
                <!-- 价格区域判断-->
                <div class="productList">
                  <!-- 判断当前是否有库存 -->
                  <p class="sales-price" v-if="!productDetail.inventoryList ||
                    productDetail.inventoryList.length <= 0
                    ">
                    <span class="sj">售价：<i v-if="loginState">--/--</i>
                      <i v-else>会员可见</i>
                    </span>
                  </p>

                  <p class="sales-price"
                    v-else-if="productDetail.offlineSaleFlag && productDetail.offlineSaleFlag == '1' && productDetail.productNotputSaleShowPrice == '0'">
                    <span class="sj">售价：<i>请联系开票员</i>
                    </span>
                  </p>
                  <p class="sales-price" v-else-if="productDetail.productActivityPriceStrategyFlag === 1 &&
                    productDetail.productActivityPriceStrategyDetail &&
                    productDetail.productActivityPriceStrategyDetail
                      .canSaleAmount
                    ">
                    <span class="sj">售价：<i>￥{{
                      productDetail.productActivityPriceStrategyDetail.price
                    }}</i></span>
                    <span class="yuanjia">原价：<i>￥{{
                      productDetail.productActivityPriceStrategyDetail
                        .originalPrice
                    }}</i></span>
                  </p>
                  <p class="sales-price" v-else-if="inventoryAct.discountPrice &&
                      inventoryAct.discountPrice !== ''
                      ">
                    <span class="sj">售价：<i>￥{{ inventoryAct.discountPrice }}</i></span>
                    <span class="yuanjia">原价：<i>￥{{ inventoryAct.price }}</i></span>
                  </p>
                  <p class="sales-price" v-else-if="inventoryAct.originalPrice &&
                    inventoryAct.originalPrice !== ''
                    ">
                    <span class="sj">售价：<i>￥{{ inventoryAct.price }}</i></span>
                    <span class="yuanjia">原价：<i>￥{{ inventoryAct.originalPrice }}</i></span>
                  </p>
                  <p class="sales-price" v-else>
                    <span class="sj">售价：<i>￥{{ inventoryAct.price }}</i></span>
                  </p>

                  <!-- ERP建议零售价 -->
                  <p v-if="inventoryAct.b2cPrice && inventoryAct.b2cPrice !== '' && inventoryAct.b2cPrice !== 0"
                    class="jylsj-price">
                    <span class="price">建议零售价：￥{{ inventoryAct.b2cPrice }}</span>
                  </p>
                  <p v-if="productDetail.policyFlag &&
                    productDetail.productPolicyList &&
                    productDetail.productPolicyList.length > 0
                    " class="cxzc">
                    <span v-if="inventoryAct.deliveryTime &&
                      inventoryAct.deliveryTime !== ''
                      " :style="inventoryAct.deliveryTime === '24'
    ? 'background-color:#009944'
    : 'background-color:#0f4c81'
    " class="cxText">{{
    inventoryAct.deliveryTime
    ? inventoryAct.deliveryTime
    : "24H"
  }}</span>
                    <span v-for="labelItem in classifyLabelList(inventoryAct)" :key="labelItem.id" class="cxText tip1">{{
                      labelItem.name
                    }}</span>
                    <span v-if="inventoryAct.newCustomerPriceFlag &&
                      inventoryAct.newCustomerPriceFlag == '1'" class="cxText tip3">特价</span>
                    <span v-if="productDetail.productActivityPriceStrategyFlag === 1 &&
                      productDetail.productActivityPriceStrategyDetail &&
                      productDetail.productActivityPriceStrategyDetail
                        .canSaleAmount
                      " class="cxText tip1">秒杀</span>
                    <span class="cxText tip2" v-if="productDetail.inventoryList &&
                      productDetail.inventoryList.length > 0 &&
                      productDetail.inventoryList[0].priceTitle &&
                      productDetail.inventoryList[0].priceTitle !== ''
                      ">{{ productDetail.inventoryList[0].priceTitle }}</span>
                    <span v-if="productDetail.inventoryList &&
                      productDetail.inventoryList.length > 0 && productDetail.inventoryList[0].nearDateFlag"
                      class="cxText tip4">近效期</span>
                    <!-- <span
                      class="cxText tip5"
                      v-if="
                        (productDetail.productActivityPriceStrategyFlag !== 1 ||
                          productDetail.productActivityPriceStrategyDetail
                            .canSaleAmount === 0) &&
                        productDetail.minDiscountTicket &&
                        productDetail.minDiscountTicket.name !== ''
                      "
                    >
                      {{ productDetail.minDiscountTicket.name }}
                    </span> -->
                    <span v-if="productDetail.discountTicketList &&
                      productDetail.discountTicketList.length > 0
                      ">
                      <i class="cxText tip5 ticketTip" style="font-style: normal"
                        v-for="item of productDetail.discountTicketList" :key="item.id">{{ item.name }}</i>
                    </span>
                    <span class="cxText">促销</span>
                    {{ productDetail.productPolicyList[0].remark }}
                  </p>

                  <p v-else-if="loginState &&
                    productDetail.inventoryList &&
                    productDetail.inventoryList.length > 0
                    " class="cxzc">
                    <span v-if="inventoryAct.newCustomerPriceFlag &&
                      inventoryAct.newCustomerPriceFlag == '1'" class="cxText tip3">特价</span>
                    <span v-if="productDetail.productActivityPriceStrategyFlag === 1 &&
                      productDetail.productActivityPriceStrategyDetail &&
                      productDetail.productActivityPriceStrategyDetail
                        .canSaleAmount
                      " class="cxText tip1">秒杀</span>
                    <span v-if="inventoryAct.deliveryTime &&
                      inventoryAct.deliveryTime !== ''
                      " :style="inventoryAct.deliveryTime === '24'
    ? 'background-color:#009944'
    : 'background-color:#0f4c81'
    " class="cxText">{{
    inventoryAct.deliveryTime
    ? inventoryAct.deliveryTime
    : "24H"
  }}</span>
                    <span class="cxText tip2" v-if="productDetail.inventoryList &&
                        productDetail.inventoryList.length > 0 &&
                        productDetail.inventoryList[0].priceTitle &&
                        productDetail.inventoryList[0].priceTitle !== ''
                        ">{{ productDetail.inventoryList[0].priceTitle }}</span>
                    <span v-if="productDetail.policyFlag" class="tip3">促销</span>
                    <span v-if="productDetail.inventoryList &&
                      productDetail.inventoryList.length > 0 && productDetail.inventoryList[0].nearDateFlag"
                      class="cxText tip4">近效期</span>
                    <!-- <span
                      class="cxText tip5"
                      v-if="
                        (productDetail.productActivityPriceStrategyFlag !== 1 ||
                          productDetail.productActivityPriceStrategyDetail
                            .canSaleAmount === 0) &&
                        productDetail.minDiscountTicket &&
                        productDetail.minDiscountTicket.name !== ''
                      "
                    >
                      {{ productDetail.minDiscountTicket.name }}
                    </span> -->
                    <span v-if="productDetail.discountTicketList &&
                      productDetail.discountTicketList.length > 0
                      ">
                      <i class="cxText tip5 ticketTip" @click="$router.push('/couponList')" style="font-style: normal"
                        v-for="item of productDetail.discountTicketList" :key="item.id">{{ item.name }}</i>
                    </span>
                  </p>
                </div>
              </div>
              <div v-if="goodsType !== '2'" class="text textBox">
                <p class="sccj">
                  <span class="justy">生产厂家：</span>{{ productDetail.manufacturer }}
                </p>
                <p v-if="productDetail.holder && productDetail.holder !== ''">
                  <span>上市许可持有人：</span>{{ productDetail.holder }}
                </p>
                <p>
                  <span class="justy">包装单位：</span>{{ productDetail.unit }}
                </p>
                <p v-if="productDetail.mediumPkgSize || productDetail.bigPkgSize">
                  <span class="justy">中/大包装：</span>{{ productDetail.mediumPkgSize }}/{{
                    productDetail.bigPkgSize
                  }}
                </p>
                <p>
                  <span class="justy">批准文号：</span>{{
                    productDetail.license
                    ? productDetail.license
                    : "暂无信息"
                  }}
                </p>
                <!-- <p>
                  <span class="justy">产品批号：</span>{{
                      inventoryAct.batchNumber
                        ? inventoryAct.batchNumber
                        : (loginState ? "暂无信息" : "会员可见")
                  }}
                </p> -->
                <div>
                  <p>
                    <span class="justy">有效期至：</span>{{
                      inventoryAct.endDate
                      ? ` ${renderEndDate(inventoryAct.endDate)}`
                      : (loginState ? "暂无信息" : "会员可见")
                    }}
                  </p>
                </div>
              </div>
              <div v-if="goodsType !== '2'" class="format-box">
                <div class="title"><span>包装{{ productDetail.step > 1 ? '（不拆零）' : '（拆零）' }}</span></div>
                <div v-if="productDetail.step > 1" class="format-list">
                  <span class="act">指定包装/{{ productDetail.step }}{{ productDetail.unit }}</span>
                </div>
                <div v-else class="format-list">
                  <span :class="{ act: productStep == 1 }" @click="setStepValue(1)"
                    v-if="productDetail.mediumPkgSize && productDetail.mediumPkgSize > 1" class="small">小包/1{{
                      productDetail.unit }}</span>
                  <span :class="{ act: productStep == productDetail.mediumPkgSize }"
                    @click="setStepValue(productDetail.mediumPkgSize)" v-if="productDetail.mediumPkgSize"
                    class="medium">中包/{{ productDetail.mediumPkgSize }}{{ productDetail.unit }}</span>
                  <span :class="{ act: productStep == productDetail.bigPkgSize }"
                    @click="setStepValue(productDetail.bigPkgSize)"
                    v-if="productDetail.bigPkgSize && productDetail.bigPkgSize > 1" class="big">件装/{{
                      productDetail.bigPkgSize }}{{ productDetail.unit }}</span>
                </div>
              </div>
              <!-- 组合套餐表 -->
              <div v-if="goodsType == '2'" class="combination-list">
                <p class="title">套餐内商品</p>
                <el-scrollbar>
                  <div class="goods-list">
                    <div v-for="item in 4" :key="item" class="goods-item">
                      <div class="goods-pic-box">
                        <img src="../assets/images/test/goods-demo.png" alt="">
                      </div>
                      <p class="goods-name">逍遥丸</p>
                      <p class="price">￥ <span>19.9</span> </p>
                      <p>套餐内数量：2</p>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
              <div class="inventoryNumBox">
                <div v-if="productDetail.inventoryList &&
                  productDetail.inventoryList.length > 1
                  " class="selectInventory">
                  <span v-for="(item, index) of productDetail.inventoryList" :key="'inventory' + index"
                    :class="{ selected: selectInventoryIndex == index }" @click="selectInventory(item, index)"
                    class="kuwei">
                    {{ item.inventoryTypeName && item.inventoryTypeName !== '' ? item.inventoryTypeName : index + 1 }}库
                  </span>
                </div>
                <div class="inputBox">
                  数量:
                  <div class="inputNumber fr">
                    <span @click="lessNum(inventoryAct, productDetail)" class="mins"> &minus; </span>
                    <input @change="changeNum(inventoryAct, productDetail, $event)" @focus="selectShopAmount($event)"
                      :class="inventoryAct.shoppingCartAmount &&
                        inventoryAct.shoppingCartAmount > 0
                        ? 'cartHasThis'
                        : ''
                        " :value="inventoryAct.addCartQuantity"
                      @keyup.enter="jionShopCart(inventoryAct, productDetail, $event)"
                      oninput="value=value.replace(/[^0-9.]/g,'')" type="text" class="shopNumber" />
                    <span @click="addNum(inventoryAct, productDetail)" class="plus"> &plus; </span>
                  </div>
                </div>

                <div v-if="productDetail.productActivityPriceStrategyDetail" class="kucunBox act">
                  个人限购：
                  {{
                    productDetail.productActivityPriceStrategyDetail
                      .canSaleAmount === 99999999
                    ? "不限购"
                    : productDetail.productActivityPriceStrategyDetail
                      .canSaleAmount
                  }}
                </div>
                <div class="kucunBox">
                  库存：
                  {{ loginState ? showInventoryText(inventoryAct.inventory, productDetail) : "会员可见" }}
                </div>
              </div>
              <div class="caozuoBox">
                <a v-if="(inventoryAct.inventory > 0 || !loginState)"
                  @click="jionShopCart(inventoryAct, productDetail, $event)" href="javascript:;" class="jionBtn">
                  <img src="../assets/images/product/detail/cartIcon.png" alt="" />

                  {{ (productDetail.offlineSaleFlag && productDetail.offlineSaleFlag == '1') ? '开票员订货' : '加入购物车' }}
                </a>
                <a v-else @click="qiugouDenglu(productDetail)" href="javascript:;" class="jionBtn ypcgtn">
                  <img src="../assets/images/product/img/search.png" alt="" />
                  商品求购
                </a>
                <a href="javascript:;" v-if="productDetail.isCollect === 1" @click="customerCollect(productDetail, 0)"
                  class="qxscBtn">
                  取消收藏
                </a>
                <a v-else href="javascript:;" @click="customerCollect(productDetail, 1)" class="shoucangBtn">
                  <img src="../assets/images/product/detail/shoucang.png" alt="" />添加收藏</a>
              </div>
              <div class="imgTip">
                温馨提示：产品图片仅供参考，请以实物为准。
              </div>
            </div>
          </div>
        </div>
        <div class="detailTagBox">
          <div class="productRecommend">
            <div class="title">产品推荐排行榜</div>
            <div class="proList">
              <div class="proItem" v-for="(item, index) of productRecList" :key="item.id">
                <router-link :to="{
                  name: 'productDetail',
                  query: {
                    productId: item.productId,
                  },
                }" target="_blank">
                  <img class="numberImg" v-if="index == 0" src="../assets/images/product/detail/no1.png" alt="" />
                  <img class="numberImg" v-else-if="index == 1" src="../assets/images/product/detail/no2.png" alt="" />
                  <img class="numberImg" v-else-if="index == 2" src="../assets/images/product/detail/no3.png" alt="" />
                  <div class="proImgBox">
                    <img v-lazy="productImg(item)[0]" alt="" />
                  </div>
                  <div class="proData">
                    <div class="name">{{ item.name }}</div>
                    <div v-if="loginState" class="price">￥{{ item.showPrice ? item.showPrice : '--/--' }}</div>
                    <div v-else class="price">会员可见</div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="detailContent">
            <el-tabs type="border-card">
              <el-tab-pane label="商品详情">
                <div class="productDetailList clearfix">
                  <p>
                    <span>商品编号：</span><i>{{ productDetail.productId }}</i>
                  </p>
                  <p v-if="false">
                    <span>库存编码：</span><i>{{ inventoryAct.uid }}</i>
                  </p>
                  <p>
                    <span>商品名称：</span><i>{{ productDetail.name }}</i>
                  </p>
                  <p>
                    <span>生产厂家：</span><i>{{
                      productDetail.manufacturer
                      ? productDetail.manufacturer
                      : "暂无厂家信息"
                    }}</i>
                  </p>
                  <p v-if="productDetail.holder && productDetail.holder !== ''">
                    <span style="width: 100px;">上市许可持有人：</span><i>{{
                      productDetail.holder
                      ? productDetail.holder
                      : "暂无上市许可持有人信息"
                    }}</i>
                  </p>
                  <p>
                    <span>商品分类：</span><i>{{
                      productDetail.category
                      ? productDetail.category
                      : "暂无分类信息"
                    }}</i>
                  </p>
                  <p>
                    <span>商品剂型：</span><i>{{
                      productDetail.dosageForm
                      ? productDetail.dosageForm
                      : "暂无剂型信息"
                    }}</i>
                  </p>
                  <p>
                    <span>包装单位：</span><i>{{
                      productDetail.unit
                      ? productDetail.unit
                      : "暂无包装单位信息"
                    }}</i>
                  </p>
                  <p>
                    <span>药品规格：</span><i>{{
                      productDetail.format
                      ? productDetail.format
                      : "暂无药品规格信息"
                    }}</i>
                  </p>
                  <p>
                    <span>批准文号：</span><i>{{
                      productDetail.license
                      ? productDetail.license
                      : "暂无批准文号信息"
                    }}</i>
                  </p>
                </div>
                <div v-if="productDetail.productBook && productDetail.productBook.book
                    " class="productBookBox clearfix">
                  <div class="detailTitle fl">
                    <p class="productBook">商品说明书</p>
                  </div>
                  <div v-if="productDetail.productBook.book" class="productBookContent fl"
                    v-html="productDetail.productBook.book"></div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                v-if="companyConfigData && companyConfigData.afterSalesGuarantee && companyConfigData.afterSalesGuarantee !== ''"
                label="售后保障">
                <div class="afterSalesGuarantee fl" v-html="companyConfigData.afterSalesGuarantee"></div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteAction, getAction, postAction } from "@/api/manage";
import { accAdd } from "@/assets/js/common/common";
import { getUserInfo } from "../assets/js/common/common";
import { ProductMixin } from "@/mixins/ProductMixin";
import { mapGetters } from 'vuex';
export default {
  name: "ProductDetail",
  mixins: [ProductMixin],
  data() {
    return {
      vshareConfig: {
        shareList: [
          "qzone",
          "tsina",
          "sqq",
          "weixin",
          // 此处放分享列表（ID）
        ],
      },
      goodsType: '1',
      productId: "",
      inventoryAct: {},
      productDetail: {},
      loading: false,
      productImgArr: [],
      activeBottom: "detail",
      activeImgIndex: 0,
      activeImg: "",
      bigImg: "",
      shoppingCartAmount: 0,
      tId: "_t1",
      tabProductList: [],
      tabIndex: 0,
      selectedTabList: [],
      hasClick: true,
      haslogin: false,
      floorListData: [],
      companyId: null,
      loginState: false,
      selectInventoryIndex: 0,
      bigDivShow: false, // 大图展示状态
      spanMoveShow: false, // 移动模块展示状态
      picMoveStyle: {
        top: 0,
        left: 0
      },
      spanMoveStyle: {
        top: 0,
        left: 0
      },
      productRecList: [
      ], // 产品推荐代码
      scheduleNumber: 20
    };
  },
  computed: {
    ...mapGetters(['companyConfigData'])
  },
  created() {
    let userInfo = getUserInfo();
    if (userInfo) {
      this.loginState = true;
      this.companyId = userInfo.companyId;
    } else {
      this.loginState = false;
    }
    this.backTop();
    const productId = this.$route.query.productId;
    const liveActivityId = this.$route.query.liveActivityId;
    if (liveActivityId && liveActivityId !== '') {
      this.$alert("直播价格仅限直播间内享受！", "提示信息", {
        type: "error",
      });
    }
    this.productId = productId;
    this.getProductDetail();
    this.getProductRecommend()
    this.tabIndex = 0;
    if (this.tabProductList.legnth > 0) {
      this.selectedTabList = this.tabProductList[0].list;
    }
  },
  methods: {
    classifyLabelList(item) {
      let labelList = []
      if (item.productLabelList && item.productLabelList.length > 0) {
        let productLabelList = item.productLabelList
        labelList = productLabelList.filter(key => {
          return (key.id === 340 || key.id === 341 || key.id === 342)
        })
      }
      return labelList
    },
    // 用户端设置产品输入框步进操作
    setStepValue(value) {
      console.log("value+++++++++++", value)
      let productStep = 1;
      if (typeof (value) === 'string' && value !== '') {
        productStep = JSON.parse(value)
      } else if (typeof (value) === 'number' && value > 1) {
        productStep = value
      }
      // 判断当前选中的规格步进是否大于库存
      let inventory = this.inventoryAct.inventory
      if (productStep > inventory) {
        this.inventoryAct.addCartQuantity = inventory
      } else {
        this.inventoryAct.addCartQuantity = productStep
      }
      console.log("用户当前选择的步进数量为++++++", productStep)
      this.productStep = productStep
    },
    // 获取热搜产品列表数据
    async getProductRecommend() {
      let url = "homeShow/areaProductPush/listById"
      let productId = this.productId
      let data = {
        productId: productId
      }
      let res = await postAction(url, data);
      if (res.code == 200 && res.success) {
        let resData = res.result;
        this.productRecList = this.sliceArrs(resData, 3)[0];
        console.log("获取产品推荐列表数据为", this.productRecList)
      }
    },
    // 产品图片均分数组
    sliceArrs(array, size) {
      var result = [];
      for (var x = 0; x < Math.ceil(array.length / size); x++) {
        var start = x * size;
        var end = start + size;
        result.push(array.slice(start, end));
      }
      return result;
    },
    // 光标选中全选文字
    selectShopAmount(e) {
      e.currentTarget.select();
    },
    // 点击返回顶部方法
    backTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    },
    // 显示产品图片
    productImg(item) {
      if (item.picUrl && item.picUrl !== "") {
        let imgsArr = item.picUrl.split(",");
        let imgSrc = imgsArr;
        return imgSrc;
      } else {
        let imgSrc = "";
        return imgSrc;
      }
    },
    // 渲染总库存
    renderAllInventory(productDetail) {
      let inventoryList = productDetail.inventoryList;
      if (inventoryList && inventoryList.length > 0) {
        let allNum = 0;
        for (let key of inventoryList) {
          allNum = accAdd(allNum, key.inventory);
        }
        return allNum;
      } else {
        return "暂无库存";
      }
    },
    // 修改效期数据
    renderEndDate(endDate) {
      return endDate.replace(" 00:00:00", "");
    },
    // 获取产品详情数据
    getProductDetail() {
      const _this = this;
      const productId = this.productId;
      const url = "/product/getProductDetail";
      const data = {
        productId: productId,
      };
      this.loading = true;
      getAction(url, data).then((res) => {
        this.loading = false;
        if (res.code === 200 && res.success) {
          _this.productDetail = res.result;
          if (res.result.picUrl && res.result.picUrl !== "") {
            let productPicUrl = res.result.picUrl.split(",");
            _this.activeImg = `${this.$imgPath}${productPicUrl[0]}`;
            _this.bigImg = `${this.$imgPath}${productPicUrl[0]}`;
          }
          if (res.result.inventoryList && res.result.inventoryList.length > 0) {
            _this.inventoryList = res.result.inventoryList;
            _this.inventoryAct = res.result.inventoryList[0];
            _this.selectInventoryIndex = 0;
            if (_this.inventoryAct.uid) {
              const uidArr = _this.inventoryAct.uid.split(",,");
              const tId = uidArr[uidArr.length - 1];
              _this.tId = tId;
            }
          }
        }
      });
    },
    spanMove(e) {
      let normalPic = this.$refs.jqzoomImg;
      let spanMove = this.$refs.moveSpan;
      let bigDiv = this.$refs.bigPic;
      let picMove = this.$refs.bigPicImg;
      this.bigDivShow = true;
      this.spanMoveShow = true;
      let bigSize = picMove.offsetHeight;
      let littleSize = normalPic.offsetHeight;
      let n = bigSize / littleSize;
      let picX = normalPic.getBoundingClientRect().left;
      let picY = normalPic.getBoundingClientRect().top;
      let mouseX = e.clientX - picX;
      let mouseY = e.clientY - picY;
      let spanW = spanMove.offsetWidth;
      let spanH = spanMove.offsetHeight;
      let normalPicW = normalPic.offsetWidth;
      let normalPicH = normalPic.offsetHeight;
      let bigW = bigDiv.offsetHeight;
      let picMoveLeft = 0;
      let picMoveTop = 0;
      let spanMoveLeft = 0;
      let spanMoveTop = 0;
      if (mouseX <= spanW / 2) {
        picMoveLeft = 0;
        spanMoveLeft = 0;
      } else if (mouseX > spanW / 2 && mouseX < normalPicW - spanW / 2) {
        let tempX = mouseX - spanW / 2;
        picMoveLeft = -n * tempX;
        spanMoveLeft = tempX;
      } else {
        picMoveLeft = -n * (normalPicW - spanW);
        spanMoveLeft = normalPicW - spanW;
      }
      if (mouseY <= spanH / 2) {
        picMoveTop = 0;
        spanMoveTop = 0;
      } else if (mouseY > spanH / 2 && mouseY < normalPicH - spanH / 2) {
        let tempY = mouseY - spanH / 2;
        picMoveTop = -n * tempY;
        spanMoveTop = tempY;
      } else {
        picMoveTop = -(n * normalPicH - bigW);
        spanMoveTop = normalPicH - spanH;
      }
      this.picMoveStyle = {
        top: picMoveTop,
        left: picMoveLeft
      };
      this.spanMoveStyle = {
        top: spanMoveTop,
        left: spanMoveLeft
      }
    },
    spanOut() {
      this.bigDivShow = false;
      this.spanMoveShow = false;
    },
    proImgclick(item, index) {
      this.bigImg = `${this.$imgPath}${item}`;
      this.activeImg = `${this.$imgPath}${item}`;
      this.activeImgIndex = index;
    },
    handleBottomClick(tab, event) { },
    selectInventory(item, index) {
      if (item.uid) {
        const uidArr = item.uid.split(",,");
        const tId = uidArr[uidArr.length - 1];
        this.tId = tId;
      }
      this.selectInventoryIndex = index;
      this.inventoryAct = item;
    },
    qiugouDenglu(item) {
      const productName = item.name;
      const format = item.format;
      const manufacturer = item.manufacturer;
      const productId = item.productId;
      const data = {
        productName: productName,
        format: format,
        manufacturer: manufacturer,
        productId: productId,
      };
      const requirement = this.$router.resolve({
        path: "/requirement",
        query: data,
      });
      window.open(requirement.href, "_blank");
    },
    customerCollect(item, flag) {
      const _this = this;
      this.$util.debounce(() => {
        let loginState = this.loginState;
        if (loginState) {
          let url = "";
          let data = {};
          if (flag === 1) {
            data = {
              productId: item.productId,
            };
            url = "/customerCollectProduct/customerCollectProduct/add";
            postAction(url, data).then((res) => {
              if (res.code === 200 && res.success) {
                _this.$message({
                  message: "已成功收藏该产品",
                  offset: 400,
                  duration: 1000,
                  type: "success",
                });
                item.isCollect = 1;

                _this.$store.commit("COLLECT_UPLOAD_STATUS", true);
              }
            });
          } else {
            data = {
              productId: item.productId,
            };
            url =
              "/customerCollectProduct/customerCollectProduct/cancelProductCollect";
            deleteAction(url, data).then((res) => {
              if (res.code === 200 && res.success) {
                _this.$message({
                  message: "已取消收藏该产品",
                  offset: 400,
                  duration: 1000,
                  type: "info",
                });
                item.isCollect = 0;
                _this.$store.commit("COLLECT_UPLOAD_STATUS", false);
              }
            });
          }
        } else {
          _this.$router.push("/login")
        }
      })
    },
  },
  mounted() {
    let _this = this;
    setTimeout(() => { _this.scheduleNumber = 55 }, 2000)

  },

  watch: {
    $route(to, from) {
      let routerName = to.name;
      if (routerName == "productDetail") {
        const productId = to.query.productId;
        this.productId = productId;
        this.getProductDetail();
        this.getProductRecommend()
        this.$nextTick(() => {

          this.shareList = [
            "qzone",
            "tsina",
            "sqq",
            "weixin",
            // 此处放分享列表（ID）
          ]
        })
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/product/productDetail";

.sccj {
  height: auto !important;

  .cyr {
    padding-left: 70px;
    color: #f44336;
    font-size: 12px;
  }
}
</style>
