<template>
  <div class="product-content">
    <div class="left-nav">
      <img src="../../assets/images/activity/yabang/brand-title-bg.png" alt="" class="box-header">

      <div class="recommend">
        <h3 class="title">推荐厂家</h3>
        <div class="brand-item" v-for="(item, index) in recommendBrand" :key="index">
          <div class="brand-box">
            <img src="../../assets/images/activity/yabang/pinpai-demo.jpg" alt="">
          </div>
          <span class="sort">{{ index + 1 }}</span>
        </div>
      </div>
    </div>
    <div class="product-box">
      <meeting-product/>
    </div>
  </div>
</template>

<script>
import MeetingProduct from './MeetingProduct.vue'
export default {
  components: { MeetingProduct },
  name: 'OrderMeetingProduct',
  data() {
    return {
      recommendBrand: [
        {
          id: 1,
          titleUrl: '', // 图片地址
          name: '' // 品牌名称
        },
        {
          id: 2,
          titleUrl: '', // 图片地址
          name: '' // 品牌名称
        },
        {
          id: 3,
          titleUrl: '', // 图片地址
          name: '' // 品牌名称
        },
        {
          id: 4,
          titleUrl: '', // 图片地址
          name: '' // 品牌名称
        },
        {
          id: 5,
          titleUrl: '', // 图片地址
          name: '' // 品牌名称
        },
        {
          id: 6,
          titleUrl: '', // 图片地址
          name: '' // 品牌名称
        }
      ],
      productList: []
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.product-content {
  min-width: 1188px;
  @include clear;

  .left-nav {
    width: 200px;
    min-height: 1145px;
    float: left;
    position: relative;

    .box-header {
      width: 200px;
      height: 86px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    .recommend {
      margin: 65px auto;
      width: 165px;
      min-height: 1145px;
      background: #CB2027;
      border: 3px solid #FFD69B;
      box-shadow: 0px 0px 24px 0px rgba(163, 6, 6, 0.82);
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .title {
        font-size: 28px;
        font-weight: 500;
        color: #FFE9BA;
        letter-spacing: 2px;
        text-indent: 2px;
        margin-top: 35px;
      }

      .brand-item {
        text-align: center;
        margin-top: 10px;
        cursor: pointer;
        .brand-box {
          width: 129px;
          height: 129px;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }

        .sort {
          display: inline-block;
          width: 21px;
          height: 21px;
          background: #FFE9BB;
          border-radius: 50%;
          font-size: 16px;
          color: #A90505;
          text-align: center;
          line-height: 21px;
          margin: 10px auto;
        }
      }
    }
  }
  .product-box {
    width: 980px;
    float: right;
    overflow: hidden;
  }
}
</style>