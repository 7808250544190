<template>
  <div class="homeContent">
    <header-top></header-top>
    <search-content @searchCollectProduct="searchCollectProduct" @searchProduct="searchProduct"></search-content>
    <nav-content @searchProduct="searchProduct" @searchProductCategory="searchProductCategory"></nav-content>
    <div class="contentMain">
      <router-view :key="$route.fullPath" ref="productSearch"></router-view>
    </div>
    <Footer v-if="footerShowState"></Footer>
    <right-nav ref="rightNavContent"></right-nav>
    <!-- <ticket-modal></ticket-modal> -->
  </div>
</template>

<script>
import Footer from "../components/common/Footer.vue";
import HeaderTop from "../components/common/HeaderTop.vue";
import NavContent from "../components/common/NavContent.vue";
import RightNav from "../components/common/RightNav.vue";
import SearchContent from "../components/common/SearchContent.vue";
import TicketModal from '../components/ticket/TicketModal.vue';
export default {
  components: { HeaderTop, SearchContent, NavContent, Footer, RightNav, TicketModal },
  name: "home",
  data() {
    return {
      searchVal: "",
      hasOrderPay: false,
      footerShowState: true,
      searchTopShow: false, // 顶部搜索栏显示状态
      scrollTop: 0, // 网页滚动条的值
    };
  },
  created() {
    let name = this.$route.name;
    if (name === "fastShopping") {
      this.footerShowState = false;
    } else {
      this.footerShowState = true;
    }
  },
  mounted() {
    this.$nextTick(function () {
      //修改事件监听
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  // 组件消失后移除监听事件
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    closeNavRight() {
      this.$refs.rightNavContent.closeRightNav()
    },
    searchProductCategory(productCategory) {
      this.$refs.productSearch.searchProductCategory(productCategory)
    },
    // 监听滚动条
    handleScroll() {
      //首先修改相对滚动位置
      this.scrollTop = this.scrollTop =
        window.pageYOffset || document.body.scrollTop;
      if (this.scrollTop > 200) {
        this.searchTopShow = true;
      } else {
        this.searchTopShow = false;
      }
    },
    searchProduct(seachData) {
      console.log("搜索产品数据为++++++", seachData)
      if (typeof (this.$refs.productSearch.searchProduct) == 'function') {
        this.$refs.productSearch.searchProduct(seachData.searchVal || seachData ,seachData.labelFlag);
      }
    },
    searchCollectProduct(seachVal) {
      this.$refs.productSearch.searchCollectProduct(seachVal);
    },
  },
  watch: {
    $route: {
      handler(to, form) {
        if (to.name == "fastShopping") {
          this.footerShowState = false;
        } else {
          this.footerShowState = true;
        }
      },
      deep: true,
    },
  },
};
</script>
