import api from './index'
import { axios } from '@/utils/request'

/**
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return axios({
    url: '/customer/login',
    method: 'post',
    data: parameter
  })
}
export function ywyLogin(parameter) {
  return axios({
    url: '/customer/userCustomerMap/login',
    method: 'post',
    data: parameter
  })
}

export function phoneLogin(parameter) {
  return axios({
    url: '/sys/phoneLogin',
    method: 'post',
    data: parameter
  })
}

export function getSmsCaptcha(parameter) {
  return axios({
    url: api.SendSms,
    method: 'post',
    data: parameter
  })
}

export function getInfo() {
  return axios({
    url: '/api/user/info',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getUserCompany(token) {
  return axios({
    url: '/company/businessCompany/query',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Shopping-Access-Token':  token
    }
  })
}

export function logout(token) {
  return axios({
    url: '/customer/logout',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Shopping-Access-Token':  token
    }
  })
}
